<h3>ChatGPT Bulk Testing Suite</h3>

<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link"
       routerLinkActive="active"
       routerLink="{{ createTabPath }}"
       [routerLinkActiveOptions]="{ exact: true }">
      New Project
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       routerLinkActive="active"
       routerLink="{{ indexTabPath }}"
       [routerLinkActiveOptions]="{ exact: true }">
      Projects
    </a>
  </li>
</ul>

<div *ngIf="router.url === createTabPath">
  <form class="generic-form" #llmPlaygroundProjectForm="ngForm" (ngSubmit)="onSubmit(llmPlaygroundProjectForm)">
    <h5>Create New Playground Project</h5>

    <div class="d-flex align-items-end">
      <div class="form-group">
        <label>Project Name</label>
        <input id="name"
          name="name"
          required
          class="form-control"
          [(ngModel)]="project.name"
          style="width: 400px;"
        >
      </div>

      <div class="form-group ml-4">
        <button type="submit" class="btn btn-primary" [disabled]="!llmPlaygroundProjectForm.valid">
          Create Project
        </button>
      </div>
    </div>

    <div *ngIf="llmPlaygroundProjectForm.errors">
      <pre class="text-danger">{{ llmPlaygroundProjectForm.errors }}</pre>
    </div>
  </form>
</div>

<div *ngIf="router.url === indexTabPath">
  <section class="mt-3" *ngIf="projects.length > 0; else: noProjects">
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th class="text-nowrap">PROJECT</th>
          <th class="text-nowrap">OWNER</th>
          <th class="text-nowrap">SHARED WITH</th>
          <th class="text-nowrap">CREATED</th>
          <th class="text-nowrap">UPDATED</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let llmProject of projects">
          <td class="text-nowrap">
            <a routerLink="{{llmProject.id}}">
              {{ llmProject.name }}
            </a>
          </td>
          <td class="text-nowrap">
            {{ llmProject.createdByName }}
          </td>
          <td class="text-nowrap">
            {{ getCollaboratorsFullNameList(llmProject.collaboratorPermissions) }}
          </td>
          <td class="text-right">
            {{ llmProject.createdAt | date: 'M/d/yyyy h:mm a' }}
          </td>
          <td class="text-right">
            {{ llmProject.updatedAt | date: 'M/d/yyyy h:mm a' }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  <ng-template #noProjects>
    <div *ngIf="loadingProjects" class="spinner">
      <div class="spinner-wrapper">
        <div class="rotator">
          <div class="inner-spin"></div>
          <div class="inner-spin"></div>
        </div>
      </div>
    </div>
    <section *ngIf="!loadingProjects" class="mt-3"><p>There are no playground projects.</p></section>
  </ng-template>
</div>
