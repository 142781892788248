<div *ngIf="validDefinition; else definitionWarning" class="dated">
  <div class="date-input">
    <label>
      Start Date
    </label>
    <input
      id="{{ identifier }}{{ startAtSuffix }}"
      name="{{ identifier }}{{ startAtSuffix }}"
      type="date"
      class="form-control"
      required
      autocomplete="off"
      placeholder="MM/DD/YYYY"
      [ngClass]="{ 'is-invalid': !!startAtValidationError }"
      [disabled]="startAtDisabled"
      [ngModel]="configOption.startAt | date: 'yyyy-MM-dd'"
      (ngModelChange)="onStartAtChange($event)"
    />
    <div *ngIf="startAtValidationError && startAtValidationError.message" class="invalid-feedback">
      {{ startAtValidationError.message }}
    </div>
  </div>
  <div class="date-input ml-1">
    <label>
      End Date
    </label>
    <input
      id="{{ identifier }}{{ endAtSuffix }}"
      name="{{ identifier }}{{ endAtSuffix }}"
      type="date"
      class="form-control"
      required
      autocomplete="off"
      placeholder="MM/DD/YYYY"
      [ngClass]="{ 'is-invalid': !!endAtValidationError }"
      [disabled]="endAtDisabled"
      [ngModel]="configOption.endAt | date: 'yyyy-MM-dd'"
      (ngModelChange)="onEndAtChange($event)"
    />
    <div *ngIf="endAtValidationError && endAtValidationError.message" class="invalid-feedback">
      {{ endAtValidationError.message }}
    </div>
  </div>
  <div class="actions ml-2">
    <div class="action">
      <a *ngIf="canDeactivate" href="#" (click)="onDeactivateClick($event)">
        <fa-icon [icon]="['fas', 'stop-circle']" size="1x" />
        Deactivate
      </a>
      <a *ngIf="canCancelDeactivate" href="#" (click)="onCancellDeactivateClick($event)">
        <fa-icon [icon]="['fas', 'undo']" size="1x" />
        Cancel
      </a>
    </div>
  </div>
</div>
<ng-template #definitionWarning>
  <p class="alert alert-warning">
    Invalid configuration definition. At present only 'boolean' configuration values can be 'dated'.
  </p>
</ng-template>
