<app-reports
  title="Inbound Fax Reports"
  [filtersForm]="filtersForm"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-date-range-option
        formControlName="processedDate"
        label="Processed Date" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-date-range-option
      [options]="report.filters"
      name="processedDate"
      label="Processed Date" />

  </ng-template>

</app-reports>
