<form *ngIf="!!drugMatchOptions?.length; else: noDrugMatchOptions">
  <div class="text-right mb-1">
    <button class="btn" [ngClass]="drugOcrButtonClass" (click)="onFindDrugsClick()">Find Drugs</button>
  </div>
  <div
    [id]="'drugMatch' + i"
    class="border rounded mb-1 p-1"
    *ngFor="let drugMatchOption of drugMatchOptions; let i = index; trackBy: trackItemsBy"
  >
    <div *ngIf="!drugMatchOption.collapsed">
      <app-validate-drug-match-heading
        [key]="drugMatchOption.key"
        [name]="drugMatchOption.matchingPrescriberDrug.name"
        [position]="i"
        [showWarningIndicator]="drugMatchOption.checkedWrittenDatesHaveWarnings"
        [collapsed]="drugMatchOption.collapsed"
        (collapseChange)="onCollapseChange($event)"
      />
      <app-validate-drug-match-details
        [matchingPrescriberDrug]="drugMatchOption.matchingPrescriberDrug"
        [checkedWrittenDates]="drugMatchOption.checkedWrittenDates"
      />
      <div class="mt-3">Does the consult note reference the claim RX or class of RX?</div>
      <app-validate-drug-match-verification
        [drugMatchOption]="drugMatchOption"
        [position]="i"
        (verificationChange)="onDrugMatchesChange($event)"
      />
      <div class="alert alert-warning mt-1" *ngIf="patientAttachment.drugMatchNotRequired">
        This client does not require that the drug be referenced on the consult note. If the drug is not referenced you may select <em>No</em> and verify the capture.
      </div>
    </div>
    <ng-container *ngIf="drugMatchOption.collapsed">
      <app-validate-drug-match-heading
        [key]="drugMatchOption.key"
        [name]="drugMatchOption.matchingPrescriberDrug.name"
        [position]="i"
        [showWarningIndicator]="drugMatchOption.checkedWrittenDatesHaveWarnings"
        [collapsed]="drugMatchOption.collapsed"
        (collapseChange)="onCollapseChange($event)"
      >
        <app-validate-drug-match-verification
          [drugMatchOption]="drugMatchOption"
          [position]="i"
          (verificationChange)="onDrugMatchesChange($event)"
        />
      </app-validate-drug-match-heading>
      <app-drug-akas [matchingPrescriberDrug]="drugMatchOption.matchingPrescriberDrug" />
      <div class="row no-gutters mt-3">
        <div class="col">
          <div><strong>RX-DRUG(S)</strong></div>
          <div>{{ drugMatchOption.matchingPrescriberDrug.relatedDrugNames | joinList }}</div>
        </div>
      </div>
    </ng-container>
    <app-additional-details-input
      *ngIf="!drugMatchOption.hideAdditionalDetails"
      [id]="drugMatchOption.key + 'Notes'"
      [frozen]="drugMatchOption.verification.frozen"
      [value]="drugMatchOption.verification.notes"
      (valueChange)="onAdditionalTextChange(drugMatchOption, $event)"
    />
    <div *ngIf="drugMatchOptions.length > 1 && i < drugMatchOptions.length - 1" class="mt-1 text-right">
      <button class="btn btn-sm btn-secondary py-1" (click)="onNextClick($event, i, drugMatchOptions.length)">
        NEXT
      </button>
    </div>
  </div>
</form>
<ng-template #noDrugMatchOptions>
  <p>Please select a prescriber with scripts for this patient.</p>
</ng-template>
