<div class="form-group col-auto">
  <div class="input-group mx-1">
    <ng-select
      class="month-select"
      [(ngModel)]="month"
      (ngModelChange)="monthChange.emit(month)"
      placeholder="Month"
      [items]="availableMonths"
      [clearable]="false" />
  </div>
  /
  <div class="input-group mx-1">
    <ng-select
      class="year-select"
      [(ngModel)]="year"
      (ngModelChange)="yearChange.emit(year)"
      placeholder="Year"
      [items]="availableYears"
      [clearable]="false" />
  </div>
</div>
