import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ClientChore } from '../models/client-chore.model';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { LimitOffsetPagingMeta } from '../models/paged-results/limit-offset-paging.model';
import { AvailableClientChore } from '../models/available-client-chore.model';

export class PagedClientChores implements PagedResults {
  clientChores: ClientChore[];
  meta: LimitOffsetPagingMeta;

  get records() {
    return this.clientChores;
  }
}

@Injectable()
export class ClientChoresService extends ApiService {
  static readonly directUploadPath = "/api/v1/client_chore_direct_uploads";

  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<ClientChore> {
    return this.http.get<ClientChore>(`${environment.captureApi.url}/client_chores/${id}`);
  }

  getList(
    page: number,
    pageSize: number,
    statuses: Array<string> = ['new'],
    clientIds: Array<string> = [],
    userIds: Array<string> = [],
    names: Array<string> = [],
    inactive: boolean = false,
    requiresReview: boolean = false,
  ): Observable<PagedClientChores> {
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);

    statuses.forEach(status => searchParams.append('statuses[]', status));
    clientIds.forEach(clientId => searchParams.append('client_ids[]', clientId));
    userIds.forEach(userId => searchParams.append('submitted_by_ids[]', userId));
    names.forEach(name => searchParams.append('names[]', name));
    searchParams.append('inactive', inactive.toString());
    searchParams.append('requires_review', requiresReview.toString());
    return this.http.get<PagedClientChores>(`${environment.captureApi.url}/client_chores?${searchParams.toString()}`);
  }

  getMyQueue(
    page: number,
    pageSize: number,
    statuses: Array<string> = ['new'],
    clientIds: Array<string> = [],
    userIds: Array<string> = [],
    names: Array<string> = [],
    inactive: boolean = false,
    requiresReview: boolean = false,
  ): Observable<PagedClientChores> {
    // FIXME
    const searchParams = new URLSearchParams(`page=${page}&per_page=${pageSize}`);

    statuses.forEach(status => searchParams.append('statuses[]', status));
    clientIds.forEach(clientId => searchParams.append('client_ids[]', clientId));
    userIds.forEach(userId => searchParams.append('submitted_by_ids[]', userId));
    names.forEach(name => searchParams.append('names[]', name));
    searchParams.append('inactive', inactive.toString());
    searchParams.append('requires_review', requiresReview.toString());
    return this.http.get<PagedClientChores>(`${environment.captureApi.url}/client_chores/my_queue?${searchParams.toString()}`);
  }

  getMyQueueCount(): Observable<number> {
    return this.http.get<{ count: number }>(`${environment.captureApi.url}/client_chores/my_queue_count`)
      .pipe(map(resultsData => resultsData.count));
  }

  create(clientId: number, choreName: string, signedBlobId: string, params: any): Observable<ClientChore> {
    return this.http.post<ClientChore>(`${environment.captureApi.url}/client_chores`, {
      signed_id: signedBlobId,
      client_id: clientId,
      name: choreName,
      params
    });
  }

  review(clientChoreId: number, reviewed: boolean): Observable<ClientChore> {
    return this.http.patch<ClientChore>(`${environment.captureApi.url}/client_chores/${clientChoreId}/review`, {
      reviewed
    });
  }

  fail(clientChoreId: number): Observable<ClientChore> {
    return this.http.patch<ClientChore>(`${environment.captureApi.url}/client_chores/${clientChoreId}/fail`, {});
  }

  restart(clientChoreId: number): Observable<ClientChore> {
    return this.http.patch<ClientChore>(`${environment.captureApi.url}/client_chores/${clientChoreId}/restart`, {});
  }

  getAvailableChores(): Observable<{ availableClientChores: AvailableClientChore[] }> {
    return this.http.get<{ availableClientChores: AvailableClientChore[] }>(
      `${environment.captureApi.url}/available_client_chores`
    );
  }

  getSubmittingUsers(): Observable<{ users: User[] }> {
    return this.http.get<{ users: User[] }>(
      `${environment.captureApi.url}/client_chores/submitting_users`
    );
  }

}
