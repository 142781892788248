<form *ngIf="!collapsed">
  <div class="mt-1">Select providers who work at this office location that match the consult note specialty</div>
  <div class="row mt-1">
    <div class="col-8">
      <div><strong>Prescribers at this office</strong></div>
      <div class="small">
        <strong>OFFICE FAX:</strong>
        <app-office-fax
          *ngIf="patientAttachment.faxedProviderOffice"
          [office]="patientAttachment.faxedProviderOffice.office" />
        <span class="multispecialty" *ngIf="patientAttachment.multipleSpecialtiesAtFaxedOffice">(multispecialty)</span>
      </div>
      <div class="small">
        <strong>OFFICE NAME:</strong> {{ officeNames | joinList }}
      </div>
    </div>
    <div class="col-4 text-right">
      <button class="btn btn-sm btn-secondary py-1" (click)="onRefreshClick()">
        <fa-icon [icon]="['fas', 'redo']" class="check" /> REFRESH
      </button>
    </div>
  </div>
  <ng-container *ngIf="!refreshing; else: spinner">
    <div class="prescribers border p-1">
      <div class="mt-2"><strong>Prescribers at this office with scripts for patient</strong></div>
      <div class="mb-1" *ngIf="providersWithPrescriptions.length > 0; else: noPrescribersFound">
        <app-validate-prescriber-match-verifications
          (verificationChange)="onPrescriberVerificationChange($event)"
          [prescriberMatchOptions]="providersWithPrescriptions"
        />
      </div>
      <hr />
      <div class="mt-2"><strong>Prescribers at this office without scripts for patient</strong></div>
      <div class="mb-1" *ngIf="providersWithoutPrescriptions.length > 0; else: noPrescribersFound">
        <app-validate-prescriber-match-verifications
          (verificationChange)="onPrescriberVerificationChange($event)"
          [prescriberMatchOptions]="providersWithoutPrescriptions"
        />
      </div>
    </div>
    <div class="mt-1">
      <app-additional-details-input
        *ngIf="!hideNotes"
        id="prescriberMatchNotes"
        [frozen]="notesFrozen"
        [value]="patientAttachment.prescriberMatchVerificationNotes"
        (valueChange)="onAdditionalTextChange($event)"
      />
    </div>
    <div class="mt-2"><strong>Prescribers at other offices with scripts for patient</strong></div>
    <div class="prescribers border p-1">
      <div *ngIf="providersAtOtherOfficesWithPrescriptions.length > 0; else: noPrescribersFound">
        <div *ngFor="let provider of providersAtOtherOfficesWithPrescriptions" class="my-2 pl-3">
          <app-provider [provider]="provider" />
        </div>
      </div>
    </div>
  </ng-container>
</form>
<ng-container *ngIf="collapsed">
  <ng-container *ngIf="allSelectedProviderOptions.length > 0; else: noPrescribersSelected">
    <div *ngFor="let prescriberMatchOption of allSelectedProviderOptions">
      <strong>{{ prescriberMatchOption.name }}</strong> ({{ prescriberMatchOption.specialty }})
    </div>
  </ng-container>
</ng-container>
<ng-template #noPrescribersFound>
  <div class="pl-3"><small>No Prescribers Found</small></div>
</ng-template>
<ng-template #noPrescribersSelected>
  <div>No Prescribers Selected</div>
</ng-template>
<ng-template #spinner> <mat-spinner diameter="30" /> </ng-template>
