import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DateUtils } from "app/core/lib/date-utils";
import { Capture } from "app/core/models/capture.model";
import { ClientConfigurationOption } from "app/core/models/client-configuration-option.model";
import { PatientPrescriber } from "app/core/models/patient-prescriber.model";
import { ClientReferralMatchOption } from "app/core/models/client-referral-match-option.model";
import { ReferralMatchOption } from "app/core/models/referral-match-option.model";
import { ReferralMatchVerification } from "app/core/models/referral-match-verification.model";
import { NavigationService } from "app/core/services/navigation.service";
import { ReferralMatchVerificationsService } from "app/core/services/referral-match-verifications.service";
import { isEmpty, isNull } from 'lodash-es';
import { EhrReferralMatchOption } from "app/core/models/ehr-referral-match-option.model";

@Component({
  selector: 'app-edit-referral-match-verifications-modal',
  templateUrl: './edit-referral-match-verifications-modal.component.html',
  styleUrls: ['./edit-referral-match-verifications-modal.component.scss'],
})
export class EditReferralMatchVerificationsModalComponent implements OnInit {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() clientConfigurationOptions: ClientConfigurationOption[];

  notes: string;

  referralMatchOptions: ReferralMatchOption[];
  clientReferralMatchOptions: ClientReferralMatchOption[];
  ehrReferralMatchOptions: EhrReferralMatchOption[];
  clientDateReferralMatchVerifications: ReferralMatchVerification[];
  responseErrors: string;
  showClientReferralDatesSection = false;

  constructor(
    private activeModal: NgbActiveModal,
    private referralMatchVerificationService: ReferralMatchVerificationsService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit() {
    this.showClientReferralDatesSection = this.shouldShowClientReferralDatesSection();
  }

  onSaveClick() {
    this.responseErrors = null;
    if (this.shouldSave()) {
      this.save();
    } else {
      this.activeModal.close();
    }
  }

  onCancelClick() {
    this.activeModal.close();
  }

  onNotesChange(notes: string) {
    this.notes = notes;
  }

  onReferralMatchOptionsChange(options: ReferralMatchOption[]) {
    this.referralMatchOptions = options;
  }

  onClientReferralMatchOptionsChange(options: ClientReferralMatchOption[]) {
    this.clientReferralMatchOptions = options;
  }

  onEhrReferralMatchOptionsChange(options: EhrReferralMatchOption[]) {
    this.ehrReferralMatchOptions = options;
  }

  onClientReferralMatchVerificationsChange(clientDateReferralMatchVerifications: ReferralMatchVerification[]) {
    this.clientDateReferralMatchVerifications = clientDateReferralMatchVerifications;
  }

  private shouldSave() {
    return (this.notes && this.notes !== this.patientPrescriber.referralMatchVerificationNotes) ||
      !isEmpty(this.referralMatchOptions) ||
      !isNull(this.clientDateReferralMatchVerifications) ||
      !isEmpty(this.clientReferralMatchOptions);
  }

  private save() {
    this.referralMatchVerificationService.bulkUpdate(
      this.patientPrescriber.id,
      this.capture,
      this.referralMatchVerificationsFromOptions,
      this.clientReferralMatchVerificationsFromOptions,
      this.ehrReferralMatchVerificationsFromOptions,
      this.clientDateReferralMatchVerifications,
      this.notes
    ).subscribe(
      _patientPrescriber => {
        this.activeModal.close();
        this.navigationService.reloadComponent();
      },
      response => {
        this.responseErrors = response.error.error;
      }
    );
  }

  private get referralMatchVerificationsFromOptions(): ReferralMatchVerification[] {
    if (!this.referralMatchOptions) return;

    return this.referralMatchOptions.map(rmo => rmo.verification);
  }

  private get clientReferralMatchVerificationsFromOptions(): ReferralMatchVerification[] {
    if (!this.clientReferralMatchOptions) return;

    return this.clientReferralMatchOptions.map(option => {
      const verification = option.verification;
      verification.peerClientReferralVerification = option.selected;
      return verification;
    });
  }

  private get ehrReferralMatchVerificationsFromOptions(): ReferralMatchVerification[] {
    if (!this.ehrReferralMatchOptions) return;

    return this.ehrReferralMatchOptions.map(option => {
      const verification: any = {};

      if (option.verification.id) {
        verification.id = option.verification.id;
      }

      if (option.selected) {
        verification.ehrReferralVerificationId =
          option.verification.ehrReferralVerification?.id ||
          option.verification.ehrReferralVerificationId;
      }

      return verification;
    });
  }

  private get referralDateMustBeBeforeWrittenDate() {
    return this.clientConfigurationOptions.find(
      o => o.option === "referral_date_must_be_before_rx_written_date" && o.value
    );
  }

  private get getClientReferralMatchVerificationDateFormatErrors() {
    const invalidDates = this.clientDateReferralMatchVerifications.filter(
      rmv => !DateUtils.isValid(rmv.clientReferralDate)
    );

    if (isEmpty(invalidDates)) return;

    return "At least one referral date is invalid";
  }

  private get earliestReferralDateBeforeWrittenDate() {
    const earlistReferralDate = this.clientDateReferralMatchVerifications.map(rmv => rmv.clientReferralDate).sort()[0];
    const writtenDate = this.capture.candidate.writtenDate;

    return earlistReferralDate && writtenDate && earlistReferralDate <= writtenDate;
  }

  private get getClientReferralMatchVerificationInvalidReferralDate() {
    if (!this.referralDateMustBeBeforeWrittenDate) return;
    if (this.earliestReferralDateBeforeWrittenDate) return;

    return "Referral Date must be before RX written date for this client";
  }

  private get getClientReferralMatchVerificationErrors() {
    // Ignore if we do not have any changes client verifications
    if (!this.clientDateReferralMatchVerifications) return;

    return [
      this.getClientReferralMatchVerificationDateFormatErrors,
      this.getClientReferralMatchVerificationInvalidReferralDate
    ];
  }

  public get errors() {
    const errors = [
      this.getClientReferralMatchVerificationErrors
    ].flat().filter(e => e);

    if (isEmpty(errors)) return;
    return errors.join('; ');
  }

  private shouldShowClientReferralDatesSection(): boolean {
    return this.patientPrescriber.referralMatchVerifications.
      some(rmv => rmv.client && !rmv.hasClientReferralTaskSubmission);
  }
}
