<div class="row m-2">
  <h4>Log Outbound Call</h4>
</div>
<form [formGroup]="providerCallLogForm">
  <div class="row ml-2">
    <div class="col-10">
      <div class="row">
        <div class="form-group col-3">
          <label><strong>Call Outcome</strong></label>
          <div>
            <ng-select
              id="selectedCallOutcome"
              class="single-row w-100"
              bindValue="value"
              bindLabel="display"
              [ngClass]="invalidCss('selectedCallOutcome')"
              formControlName="selectedCallOutcome"
              placeholder="Select call outcome"
              [items]="providerCallLogOutcomes"
              (change)="onSelectOutcome()"
            />
            <div *ngIf="validationRequired && selectedCallOutcomeRequired"
                class="invalid-feedback">Call Outcome is Required.</div>
          </div>
        </div>
        <div *ngIf="provider" class="form-group col-4">
          <label><strong>Office</strong></label>
          <div>
            <ng-select
              id="selectedOfficeFax"
              class="single-row w-100"
              bindValue="value"
              bindLabel="display"
              [ngClass]="invalidCss('selectedOfficeFax')"
              formControlName="selectedOfficeFax"
              placeholder="Select office"
              [items]="officeOptions"
              (change)="onSelectOffice()"
              (blur)="onBlur()"
              />
            <div *ngIf="validationRequired && officeOrPhoneRequired"
                 class="invalid-feedback">Phone or office is required.</div>
          </div>
        </div>
        <div *ngIf="office" class="form-group col-4">
          <label><strong>Provider</strong></label>
          <div>
            <ng-select
              id="selectedProviderNpi"
              class="single-row w-100"
              bindValue="value"
              bindLabel="display"
              [ngClass]="invalidCss('selectedProviderNpi')"
              formControlName="selectedProviderNpi"
              placeholder="Select provider"
              [items]="providerOptions"
              (change)="onSelectProvider()"
              (blur)="onBlur()"
            />
          </div>
        </div>
        <div class="form-group col-3">
          <label><strong>Phone</strong></label>
          <input
            id="enteredPhone"
            name="enteredPhone"
            class="form-control w-100"
            [ngClass]="invalidCss('enteredPhone')"
            formControlName="enteredPhone"
            (change)="onBlur()"
            (blur)="onBlur()"
          />
          <div *ngIf="validationRequired && officeOrPhoneRequired"
               class="invalid-feedback">Phone or office is required.</div>
          <div *ngIf="providerCallLogForm.value.enteredPhone && enteredPhoneInvalid"
               class="invalid-feedback">Phone is invalid.</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-10">
          <label><strong>Notes</strong></label>
          <textarea
            id="notes"
            name="notes"
            class="form-control"
            (change)="onBlur()"
            formControlName="notes"
            [ngClass]="invalidCss('notes')"
            (blur)="onNotesBlur()"
            rows="5"
            placeholder="Add call notes here."
          >
          </textarea>
          <div *ngIf="validationRequired && notesRequired"
               class="invalid-feedback">Notes are required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <button class="btn btn-primary" (click)="onFormSave()">Save</button>
      <button class="btn btn-info mr-5" (click)="onFormCancel()">Cancel</button>
    </div>
  </div>
</form>
