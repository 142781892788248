<div class="form-group col-auto">
  <label>{{ label }} From: </label>

  <app-month-picker
    [year]="values.fromYear"
    (yearChange)="onValueChange('fromYear', $event)"
    [month]="values.fromMonth"
    (monthChange)="onValueChange('fromMonth', $event)"
    [minYear]="minYear"
    [minMonth]="minMonth"
    [maxYear]="values.toYear || maxYear"
    [maxMonth]="values.toMonth || maxMonth" />

  <label> To: </label>

  <app-month-picker
    [year]="values.toYear"
    (yearChange)="onValueChange('toYear', $event)"
    [month]="values.toMonth"
    (monthChange)="onValueChange('toMonth', $event)"
    [minYear]="values.fromYear || minYear"
    [minMonth]="values.fromMonth || minMonth"
    [maxYear]="maxYear"
    [maxMonth]="maxMonth" />
</div>
