import { Component, OnInit } from '@angular/core';
import { AppToastService } from 'app/core/services/app-toast.service';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import { PrescriberIncorrectInformationRequest } from '../../../core/models/prescriber-incorrect-information-request.model';
import {
  PrescriberIncorrectInformationRequestsService,
  PagedPrescriberIncorrectInformationRequests,
} from '../../../core/services/prescriber-incorrect-information-requests.service';

@Component({
  selector: 'app-capture-admin-prescriber-incorrect-information-requests',
  templateUrl: './capture-admin-prescriber-incorrect-information-requests.component.html',
  styleUrls: ['./capture-admin-prescriber-incorrect-information-requests.component.scss'],
})
export class CaptureAdminPrescriberIncorrectInformationRequestsComponent implements OnInit {
  prescriberIncorrectInformationRequests: PrescriberIncorrectInformationRequest[] = [];
  paging: LimitOffsetPaging = LimitOffsetPaging.empty;
  loading = true;

  constructor(
    private prescriberIncorrectInformationRequestsService: PrescriberIncorrectInformationRequestsService,
    private toastService: AppToastService
  ) {}

  ngOnInit() {
    this.init();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.getPrescriberIncorrectInformationRequests(this.paging.currentPage);
  }

  onPageChange(page) {
    this.getPrescriberIncorrectInformationRequests(page);
  }

  private init() {
    this.getPrescriberIncorrectInformationRequests(this.paging.currentPage);
  }

  getPrescriberIncorrectInformationRequests(page) {
    this.loading = true;
    this.prescriberIncorrectInformationRequestsService
      .getList(page, this.paging.pageSize)
      .subscribe((result: PagedPrescriberIncorrectInformationRequests) => {
        this.prescriberIncorrectInformationRequests = result.prescriberIncorrectInformationRequests;
        this.paging = result.meta.paging;
        this.loading = false;
      });
  }

  resolve($event, request) {
    $event.preventDefault();

    this.prescriberIncorrectInformationRequestsService.update(request, { resolved: true }).subscribe(() => {
      this.toastService.show('Request resolved!', { cssClass: 'alert-success' });
      this.getPrescriberIncorrectInformationRequests(this.paging.currentPage);
    });
  }

  unresolve($event, request) {
    $event.preventDefault();

    this.prescriberIncorrectInformationRequestsService.update(request, { resolved: false }).subscribe(() => {
      this.toastService.show('Request unresolved!', { cssClass: 'alert-success' });
      this.getPrescriberIncorrectInformationRequests(this.paging.currentPage);
    });
  }
}
