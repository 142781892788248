<div class="my-2">
  <ng-container *ngIf="!loading; else: spinner">
    <table
      [formGroup]="formGroup"
      class="table table-sm"
      *ngIf="referralMatchOptions.length > 0 || showAddForm; else: noReferralMatchOptions">

      <thead>
        <tr>
          <th>Date Found</th>
          <th>Evidence Type</th>
          <th>Referral Date</th>
          <th>Prescriber</th>
          <th>Referral Specialty</th>
          <th>Found By</th>
          <th>Note To Client</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          formArrayName="options"
          *ngFor="let option of referralMatchOptions; let i = index">

          <ng-container>
            <tr [class.selected]="option.selected">
              <td>
                <label>
                  <input type="checkbox" [formControlName]="i" />
                  {{ option.ehrReferralVerification.createdAt | date: 'MM/dd/yyyy' }}
                </label>
              </td>
              <td>
                {{ option.ehrReferralVerification.reasons | referralVerificationReasonShortNames | joinList }}
              </td>
              <td>
                {{ option.ehrReferralVerification.referralDate | date: 'MM/dd/yyyy' }}
              </td>
              <td>
                <div>{{ option.ehrReferralVerification.prescriber.fullName }}</div>
                <div>NPI: {{ option.ehrReferralVerification.prescriber.npi }}</div>
              </td>
              <td>
                {{ option.ehrReferralVerification.referralSpecialty | blankValueDash }}
              </td>
              <td>
                {{ option.ehrReferralVerification.user?.fullName | blankValueDash }}
              </td>
              <td>
                {{ option.ehrReferralVerification.notes | blankValueDash }}
              </td>
              <td>
                <a href="javascript:;"
                  (click)="editingId = option.ehrReferralVerification.id"
                  [class.invisible]="optionEditable(option)">
                  Edit
                </a>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngIf="editingId === option.ehrReferralVerification.id">
            <tr class="edit-row">
              <td colspan="99" class="px-2">
                <app-ehr-referral-verification-form
                  [patientPrescriber]="patientPrescriber"
                  [prescriber]="prescriber"
                  [ehrReferralVerification]="option.ehrReferralVerification"
                  (canceled)="onEditCanceled()"
                  (saved)="onEditSave($event)" />
              </td>
            </tr>
          </ng-container>
        </ng-container>

        <tr>
          <td *ngIf="showAddForm" colspan="99">
            <app-ehr-referral-verification-form
              [patientPrescriber]="patientPrescriber"
              [prescriber]="prescriber"
              (canceled)="onAddCanceled()"
              (saved)="onAddSave($event)" />
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <button
    class="btn btn-sm btn-secondary mt-1"
    (click)="onAddClick()"
    *ngIf="editable && !showAddForm && !editingId">
    Add Referral Evidence
  </button>

</div>

<ng-template #spinner>
  <div class="mt-1 mb-2 ml-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noReferralMatchOptions>
  <div class="my-2">Patient has no applicable referral evidence.</div>
</ng-template>
