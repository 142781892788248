<app-reports
  title="Match Reports"
  [filtersForm]="filtersForm"
  [forceReportDetailsDisplay]="true"
  [reportsUrl]="reportsUrl"
  [reportsCountUrl]="reportsCountUrl">

  <ng-template appNewReportOptionsTemplate let-filtersForm="filtersForm">
    <ng-container [formGroup]="filtersForm">

      <app-report-capture-status-option formControlName="captureStatuses" />

      <app-report-client-option
        formControlName="clientIds"
        [clientFilters]="{ applicableToReferralClaims: true }" />

    </ng-container>
  </ng-template>

  <ng-template appReportDetailsTemplate let-report="report">

    <app-report-applied-client-option
      [options]="report.filters"
      [optionAssociations]="report.filterAssociations" />

    <app-report-applied-capture-status-option
      [options]="report.filters" />

  </ng-template>

</app-reports>
