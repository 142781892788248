<div class="row">
  <div class="col-4">
    <h6>LEGACY REFERRAL DETAILS</h6>
    <form>
      <div>Of the following, what data matches between 1) referral order and 2) prescriber details?</div>
      <div class="form-group mt-2">
        <div class="form-check" *ngFor="let matchOption of matchOptions">
          <input
            [id]="matchOption.key"
            [name]="matchOption.key"
            class="form-check-input"
            type="checkbox"
            [disabled]="true"
            [(ngModel)]="legacyReferralMatchVerification[matchOption.key]"
          />
          <label class="form-check-label" [for]="matchOption.key"> {{ matchOption.display }} </label>
        </div>
      </div>
    </form>
    <form class="form">
      <div class="form-group mt-2">
        <label for="notes">ADDITIONAL DETAIL</label>
        <fa-icon
          *ngIf="!showAdditionalDetails"
          [icon]="['fas', 'plus-square']"
          size="sm"
          (click)="onPlusClick()"
        />
        <fa-icon
          *ngIf="showAdditionalDetails"
          [icon]="['fas', 'minus-square']"
          size="sm"
          (click)="onMinusClick()"
        />
        <textarea
          id="notes"
          name="notes"
          class="form-control"
          *ngIf="showAdditionalDetails"
          [disabled]="true"
          [(ngModel)]="legacyReferralMatchVerification.notes"
          rows="5"
        >
        </textarea>
      </div>
    </form>
  </div>
</div>
