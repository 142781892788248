import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { sortBy } from 'lodash-es';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { UserTimingService } from '../../../core/services/user-timing.service';
import { PdfFileType } from '../../../core/enums/pdf-file-type.enum';
import { keyedPatientAttachmentStatusOptions } from '../../../core/options/patient-attachment-status.opts';
import { UserPresenceService } from 'app/core/services/user-presence.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-consult-note-others',
  templateUrl: './consult-note-others.component.html',
  styleUrls: ['./consult-note-others.component.scss'],
})
export class ConsultNoteOthersComponent implements OnInit, OnDestroy {
  patientAttachmentId: string;
  excludedPatientAttachment: PatientAttachment = null;
  otherPatientAttachments: PatientAttachment[] = [];
  selectedPatientAttachment: PatientAttachment = null;
  refreshing = true;
  pdfFileType: PdfFileType = PdfFileType.patientAttachment;
  statusOptions = keyedPatientAttachmentStatusOptions;
  usePdfKitViewer: boolean;

  constructor(
    private route: ActivatedRoute,
    private patientAttachmentsService: PatientAttachmentsService,
    private navigationService: NavigationService,
    private titleService: Title,
    private authService: AuthenticationService,
    private userTimingService: UserTimingService,
    private userPresenceService: UserPresenceService
  ) {
    this.route.paramMap.subscribe(paramsMap => {
      this.handleRouteChange(paramsMap);
    });
  }

  ngOnInit() {
    this.patientAttachmentId = this.route.snapshot.paramMap.get('id');
    const patientId = this.route.snapshot.paramMap.get('patientId');
    this.loadPatientAttachments(patientId, this.patientAttachmentId);
    this.userPresenceService.subscribeAndJoin("consultNote", this.patientAttachmentId)
    this.usePdfKitViewer = environment.featureData?.usePdfKitViewer && this.authService.isCaptureAdminUser;
  }

  ngOnDestroy() {
    this.stopTimeTracking();
    this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId)
  }

  onSelectionChange(pa: PatientAttachment) {
    this.selectedPatientAttachment = pa;
  }

  private loadPatientAttachments(patientId, excludedPatientAttachmentId): void {
    this.refreshing = true;
    this.patientAttachmentsService.getPatientList(patientId).subscribe(
      result => {
        const allPatientAttacments = sortBy(result.patientAttachments, ['markedAsInvalid', '-createdAt']);
        const excludedIndex = allPatientAttacments.findIndex(pa => pa.id.toString() === excludedPatientAttachmentId);

        if (excludedIndex > -1) {
          this.excludedPatientAttachment = allPatientAttacments[excludedIndex];
          allPatientAttacments.splice(excludedIndex, 1);
          this.otherPatientAttachments = allPatientAttacments;

          if (this.otherPatientAttachments.length > 0) {
            this.selectedPatientAttachment = this.otherPatientAttachments[0];
          }

          this.startTimeTracking(this.excludedPatientAttachment);
          this.setTitle();
          this.refreshing = false;
        } else {
          this.navigationService.notFound();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.navigationService.notFound();
        } else {
          console.error(err);
        }
      }
    );

    window.scrollTo(0, 0);
  }

  private setTitle() {
    const title = `R1 340B Recovery - ${this.excludedPatientAttachment.id} | Other Patient Documents`;
    this.titleService.setTitle(title);
  }

  private handleRouteChange(paramsMap) {
    const params = paramsMap['params'];
    const newPatientAttachmentId = params && params['id'];

    if (this.navigationService.shouldReloadPage(this.selectedPatientAttachment, newPatientAttachmentId)) {
      this.userPresenceService.leaveAndUnsubscribe("consultNote", this.patientAttachmentId)
      const patientId = params && params['patientId'];
      this.patientAttachmentId = newPatientAttachmentId;
      this.loadPatientAttachments(patientId, this.patientAttachmentId);
      this.userPresenceService.subscribeAndJoin("consultNote", this.patientAttachmentId)
    }
  }

  private startTimeTracking(patientAttachment) {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.track({
        page: 'other_patient_consult_notes',
        recordId: patientAttachment.id,
        status: patientAttachment.status,
      });
    }
  }

  private stopTimeTracking() {
    if (this.authService.isCaptureAdminUser) {
      this.userTimingService.stop();
    }
  }
}
