<h5>Referrals</h5>

<ng-container *ngIf="!loadingPatientReferrals; else: spinner">
  <div *ngIf="patientReferrals.length > 0 || peerClientReferralVerificationRmvs.length > 0; else: noPatientReferrals">
    <p>The referrals have been provided as evidence for these prescriptions</p>
  </div>

  <div *ngIf="patientReferrals.length > 0">
    <strong>Patient Referrals From EHR Feeds</strong>
    <app-patient-referrals [referrals]="patientReferrals" />
  </div>

  <div *ngIf="peerClientReferralVerificationRmvs.length > 0">
    <strong>Patient Referrals From Client Tasks</strong>
    <app-patient-peer-referral-match-verifications [referralMatchVerifications]="peerClientReferralVerificationRmvs" />
  </div>
</ng-container>

<ng-container *ngIf="task.clientHasReferralDocumentation">
  <hr />
  <p>Client has indicated they have sufficient referral documentation.</p>
  <strong>DOCUMENTATION DATE(S)</strong>
  <ng-container *ngIf="!loadingClientReferralVerifications; else: spinner">
    <div *ngIf="clientReferralVerificationDates.length > 0; else: noClientReferralVerifications">
      {{ clientReferralVerificationDates.join(', ') }}
    </div>
  </ng-container>
</ng-container>

<ng-template #noPatientReferrals> <p>There are no referrals.</p> </ng-template>
<ng-template #noClientReferralVerifications> <p>No dates provided.</p> </ng-template>
<ng-template #spinner> <mat-spinner [diameter]="30" /> </ng-template>
