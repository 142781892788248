import { remove } from 'lodash-es';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { isConsultNoteVerificationFrozen } from '../../../core/lib/verification-utils';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PatientPrescriberService } from '../../../core/services/patient-prescriber.service';
import { VerificationType } from '../../../core/enums/verification-type.enum';
import {
  ReferralMatchVerificationEvent,
  ReferralMatchVerificationEventType
} from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-edit-referral-match-verifications-modal',
  templateUrl: './edit-referral-match-verifications-modal.component.html',
  styleUrls: ['./edit-referral-match-verifications-modal.component.scss'],
})
export class EditReferralMatchVerificationsModalComponent implements OnInit {
  @Input() patientAttachment: PatientAttachment;
  @Input() patientPrescriber: PatientPrescriber;

  @Output() patientPrescriberChange = new EventEmitter<PatientPrescriber>();

  notesFrozen = false;
  showClientReferralDatesSection = false;

  constructor(
    private authService: AuthenticationService,
    private patientPrescriberService: PatientPrescriberService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.notesFrozen = isConsultNoteVerificationFrozen(
      this.authService,
      this.patientAttachment,
      VerificationType.referralMatch
    );
    this.showClientReferralDatesSection = this.shouldShowClientReferralDatesSection();
  }

  onCloseClick() {
    this.activeModal.close();
  }

  onVerificationChange(event: ReferralMatchVerificationEvent) {
    switch (event.type) {
      case ReferralMatchVerificationEventType.changed:
        this.updateReferralVerification(event.verification, event.tempId);
        break;
      case ReferralMatchVerificationEventType.removed:
        this.removeReferralVerification(event.verification);
        break;
    }
  }

  onNotesChange(value: string) {
    this.patientPrescriberService
      .update(this.patientPrescriber.id, value)
      .subscribe((patientPrescriber: PatientPrescriber) => {
        const modifiedPatientPrescriber: PatientPrescriber = Object.assign({}, this.patientPrescriber);
        modifiedPatientPrescriber.referralMatchVerificationNotes = patientPrescriber.referralMatchVerificationNotes;
        this.patientPrescriberChange.emit(modifiedPatientPrescriber);
      });
  }

  private updateReferralVerification(rmv: ReferralMatchVerification, tempId?: string) {
    const modifiedPatientPrescriber: PatientPrescriber = Object.assign({}, this.patientPrescriber);

    const index = modifiedPatientPrescriber.referralMatchVerifications.findIndex(
      ppRmv => ppRmv.id === rmv.id || ppRmv.id === tempId
    );

    if (index > -1) {
      modifiedPatientPrescriber.referralMatchVerifications[index] = rmv;
    } else {
      modifiedPatientPrescriber.referralMatchVerifications.push(rmv);
    }

    this.patientPrescriberChange.emit(modifiedPatientPrescriber);
  }

  private removeReferralVerification(rmv: ReferralMatchVerification) {
    const modifiedPatientPrescriber: PatientPrescriber = Object.assign({}, this.patientPrescriber);
    remove(modifiedPatientPrescriber.referralMatchVerifications, ppRmv => ppRmv.id === rmv.id);
    this.patientPrescriberChange.emit(modifiedPatientPrescriber);
  }

  private shouldShowClientReferralDatesSection(): boolean {
    return this.patientPrescriber.referralMatchVerifications.
      some(rmv => rmv.client && !rmv.hasClientReferralTaskSubmission);
  }
}
