<app-pagination-header [paging]="paging" [label]="'tasks'" (pageSizeChange)="onPageSizeChange($event)" />
<table class="table table-bordered table-hover">
  <thead class="thead-dark">
    <tr>
      <th>ID</th>
      <th>CLIENT</th>
      <th class="text-nowrap">TASK TYPE</th>
      <th class="text-nowrap">STATUS</th>
      <th class="text-nowrap">SOURCE</th>
      <th class="text-nowrap text-right">EST. VALUE &#x25BC;</th>
      <th class="text-right">EXPIRES ON</th>
      <th class="text-nowrap">PATIENT</th>
      <th class="text-nowrap">PRESCRIBER</th>
      <th class="text-nowrap">RX</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let task of tasks">
      <tr class="task-row">
        <td>
          <a routerLink="/capture-admin/referral-claims/clients/client-prescriber-tasks/{{ task.id }}">
            {{ task.id }}
          </a>
        </td>
        <td>{{ task.client.humanizedName }}</td>
        <td>
          <div>{{ task.humanizedType }}</div>
          <div class="text-muted small"><app-client-prescriber-task-visibility [task]="task" /></div>
        </td>
        <td class="task-status text-nowrap"><app-client-prescriber-task-status [task]="task" /></td>
        <td>{{ task.source | claimSource | blankValueDash }}</td>
        <td class="text-right">{{ task.estimatedValue | currency }}</td>
        <td class="text-right">
          <app-expiration-date [expirationDate]="task.nextClaimExpirationDate" />
        </td>
        <td><app-patient-name [patient]="task.patient" /></td>
        <td><app-prescriber-name [prescriber]="task.prescription.prescriber" /></td>
        <td>
          <div>{{ task.prescription.prescriptionFamily.humanizedName }}</div>
          <div class="small" placement="bottom-left" ngbTooltip="WRITTEN DATE">
            {{ task.prescription.writtenDate | date: 'MM/dd/yyyy' }}
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
