<ng-container *ngIf="!loading; else: spinner">
  <div class="row mx-0 mb-2">
    <div class="col-6 px-0 text-left">
      <app-office-nav
        [currentSection]="currentSection"
        (sectionClick)="onSectionClick($event)" />
    </div>
    <div class="col-6 text-right">
      <a class="nav-link" routerLink="/capture-admin/offices/{{ office.id }}/audits" target="_blank">
        View Change Log
      </a>
    </div>
  </div>
  <div class="page-container">
    <app-office-header
      *ngIf="!editing"
      [office]="office"
      (editDetails)="onEditDetails()"
    />
    <app-office-header-edit
      *ngIf="editing"
      [office]="office"
      (cancel)="onEditDetailsCancel()"
      (updated)="onUpdatedDetails()"
    />
    <div *ngIf="currentSection === 'officeDetails'">
      <app-office-detail [office]="office" />
    </div>
  </div>
  <div *ngIf="currentSection === 'communications'">
    <app-office-communications [office]="office" />
  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
