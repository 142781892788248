<ng-container *ngIf="!collapsed">
  <div class="mt-1">What is the date of the most recent specialist encounter?</div>
  <div class="help-text"><small class="text-muted font-italic">Found in consult note</small></div>
</ng-container>
<app-validate-specialist-encounter-verifications
  [collapsed]="collapsed"
  [patientAttachment]="patientAttachment"
  (verificationChange)="onVerificationChange($event)"
  (verificationRemove)="onVerificationRemove($event)" />
<app-validate-specialist-encounter-relevant-written-dates
  [collapsed]="collapsed"
  [patientAttachment]="patientAttachment"
  [writtenDates]="matchingPrescriberDrugsWrittenDates" />
