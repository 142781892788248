<app-provider-call-log-form [provider]="provider" [office]="office" (newProviderCallLog)="onNewCallLog()" />
<div class="row ml-3 m-2">
  <h4>Outbound Call Logs</h4>
</div>
<div *ngIf="providerCallLogs.length > 0; else: noProviderCallLogs">
  <div class="m-2" *ngIf="!refreshing">
    <div class="m-2">
      <app-pagination-header [paging]="paging" [label]="'Provider Call Logs'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th class="col-1">DATE CALLED</th>
          <th class="col-1">CALLED BY</th>
          <th class="col-2">CALL OUTCOME</th>
          <th *ngIf="provider" class="col-3">OFFICE</th>
          <th *ngIf="office" class="col-3">PROVIDER</th>
          <th class="col-2">PHONE</th>
          <th class="col-3">NOTES</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let providerCallLog of providerCallLogs">
          <td>{{ providerCallLog.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
          <td>{{ providerCallLog.calledBy?.fullName }}</td>
          <td>{{ providerCallLog.callOutcome }}</td>
          <td *ngIf="provider">
            <app-office [office]="providerCallLog.office" />
            <p *ngIf="!providerCallLog.office" class="red-cell" >Unknown</p>
          </td>
          <td *ngIf="office">
            <ng-container *ngIf="providerCallLog.provider; else: unknownProvider">
              <div>{{ providerCallLog.provider.displayName }}</div>
              NPI:
              <a target="_blank" href="/capture-admin/providers/{{providerCallLog.provider.npi}}">
                {{ providerCallLog.provider.npi }}
              </a>
              <a target="_blank" href="/capture-admin/providers/{{ providerCallLog.provider.npi }}">
                <fa-icon [icon]="['fas', 'external-link-alt']" size="sm" />
              </a>
              <app-copy-to-clipboard [text]="providerCallLog.provider.npi" />
            </ng-container>
          </td>
          <td [ngClass]="highlightPhone(providerCallLog.enteredPhone, providerCallLog.office?.phone)">
            <app-phone-number [number]="providerCallLog.enteredPhone || providerCallLog.office?.phone" [noValue]="' '" />
          </td>
          <td [innerHtml]='providerCallLog.notes | safeHtml'></td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </div>
</div>
<ng-template #noProviderCallLogs><p class="ml-2">No provider call logs.</p></ng-template>
<ng-template #unknownProvider><div>UNKNOWN</div></ng-template>
