<app-import-nav />

<h5 class="mt-3">Upload 340 Eligible Locations</h5>

<div class="row no-gutters" [hidden]="uploading">
  <div class="col-12">
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading the file.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <div>
      <app-file-upload
        [fileType]="'.csv'"
        (uploadStart)="onUploadStart()"
        (uploadCancel)="onUploadCancel()"
        (uploadError)="onUploadError()"
        (uploadComplete)="onUploadComplete($event)"
      />
    </div>
  </div>
</div>
<div class="row no-gutters" [hidden]="!uploading">
  <div class="col-12">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
    Uploading File...
  </div>
</div>
<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ul class="mx-3 pl-2">
    <li class="py-2">
      Possible fields
      <ul class="pl-2">
        <li><code>location_name</code> (required)</li>
        <li><code>location_id</code></li>
        <li><code>hrsa_id</code> (required)</li>
        <li><code>manage_patient_care</code></li>
        <li><code>eligibility_start_1</code> (date, format MM/DD/YYYY)</li>
        <li><code>eligibility_term_1</code> (date, format MM/DD/YYYY)</li>
        <li><code>eligibility_start_2</code></li>
        <li><code>eligibility_term_2</code></li>
        <li><code>eligibility_start_3</code></li>
        <li><code>eligibility_term_3</code></li>
        <li><code>eligibility_start_4</code></li>
        <li><code>eligibility_term_4</code></li>
        <li><code>eligibility_start_5</code></li>
        <li><code>eligibility_term_5</code></li>
      </ul>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ul>
</div>
