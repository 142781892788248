<div class="row mb-2" [formGroupName]="formArrayIndex">

  <div class="col-4">
    <ng-select
      class="auto-width-dropdown"
      #select
      appFormControlValidityClass
      bindValue="id"
      placeholder="Search for Office by Fax"
      formControlName="officeId"
      [items]="offices$ | async"
      [typeahead]="officesSearchInput$"
      [loading]="officesLoading">

      <ng-template ng-label-tmp let-office="item" let-clear="clear">
        <span class="ng-value-icon left" (click)="clear(office);" aria-hidden="true">×</span>
        <span class="ng-value-label">
          F: {{ office.fax | phoneNumber }} |
          <span *ngIf="office.name">
          {{ office.name }} |
          </span>
          {{ office.address1 }},
          <span *ngIf="office.address2">
            {{ office.address2 }},
          </span>
          {{ office.city }},
          {{ office.state }}
          {{ office.zip }}
        </span>
      </ng-template>

      <ng-template ng-option-tmp let-office="item">
        F: {{ office.fax | phoneNumber }} |
        <span *ngIf="office.name">
        {{ office.name }} |
        </span>
        {{ office.address1 }},
        <span *ngIf="office.address2">
          {{ office.address2 }},
        </span>
        {{ office.city }},
        {{ office.state }}
        {{ office.zip }}
      </ng-template>

      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div *ngIf="searchTerm.length < officesSearchMinFaxLength">
          <div class="ng-option ng-option-disabled">
            Enter at least 3 digits
          </div>
        </div>
        <div *ngIf="searchTerm.length >= officesSearchMinFaxLength">
          <div class="ng-option">
            No results found -
            <a
              href="/capture-admin/offices/all?new={{ searchTerm }}"
              target="_blank"
              (click)="select.close()">
              Add a New Office
            </a>
          </div>
        </div>
      </ng-template>
    </ng-select>

    <app-form-control-server-errors
      controlName="officeId" />
  </div>

  <div class="col-3">
    <ng-select
      appFormControlValidityClass
      bindValue="value"
      bindLabel="display"
      placeholder="Set Provider Fax Status"
      [items]="providerOfficeStatusOptions"
      formControlName="status" />

    <app-form-control-server-errors
      controlName="status" />
  </div>

  <div class="col-5">
    <input
      appFormControlValidityClass
      class="form-control"
      type="text"
      formControlName="note"
      placeholder="Enter Note">

    <app-form-control-server-errors
      controlName="note" />
  </div>

</div>
<div class="row mb-2" *ngIf="selectedProviderAlreadyAssociated">
  <div class="col-auto alert alert-warning ml-3 text-left p-2">
    <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning"/> This provider office association already exists.
  </div>
</div>
