import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-expiration-date',
  templateUrl: './expiration-date.component.html',
  styleUrls: ['./expiration-date.component.scss'],
})
export class ExpirationDateComponent implements OnInit {
  private expiringSoonDays = 30;

  @Input() expirationDate: Date | string;
  public expired: boolean;
  public expiringSoon: boolean;

  ngOnInit() {
    const expirationDateTime = DateTime.fromJSDate(new Date(this.expirationDate));
    this.expired = expirationDateTime < DateTime.now();
    this.expiringSoon = !this.expired && expirationDateTime < DateTime.now().plus({ days: this.expiringSoonDays });
  }
}
