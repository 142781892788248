import { Component, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { UsersService } from '../../../core/services/users.service';
import { User } from '../../../core/models/user.model';
import { UserType } from '../../../core/enums/user-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-capture-admin-user',
  templateUrl: './capture-admin-user.component.html',
  styleUrls: ['./capture-admin-user.component.scss'],
})
export class CaptureAdminUserComponent implements OnInit {
  user: User;
  loading = false;
  userTypes = UserType;

  constructor(private usersService: UsersService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.init();
  }

  onUserChange(user: User) {
    this.user = user;
  }

  public assignedClients() {
    const clientNames = this.user.assignedClients.map(c => c.name).sort();
    return clientNames.map(c => new TitleCasePipe().transform(c)).join(', ');
  }

  private init() {
    const userId = this.route.snapshot.paramMap.get('id');
    this.loadUser(userId);
  }

  private loadUser(userId): void {
    this.loading = true;
    this.usersService.get(userId).subscribe(
      user => {
        this.user = user;
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404'], { replaceUrl: true });
        } else {
          console.error(err);
        }
      }
    );
  }
}
