<div class="form-group col-auto">
  <ng-select
    class="w-100"
    bindLabel="display"
    bindValue="value"
    [placeholder]="label"
    [multiple]="multiple"
    [disabled]="disabled"
    (change)="onChange()"
    [items]="eligibilityStatusFilterOptions"
    [(ngModel)]="selectedStatuses"
    dropdownPosition="bottom" />
</div>