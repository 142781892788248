import { Component, Input, OnInit } from '@angular/core';
import { ClientPrescriberTask } from '../../../core/models/client-prescriber-task.model';

@Component({
  selector: 'app-client-prescriber-task-visibility',
  templateUrl: './client-prescriber-task-visibility.component.html',
  styleUrls: ['./client-prescriber-task-visibility.component.scss'],
})
export class ClientPrescriberTaskVisibilityComponent implements OnInit {
  @Input() task: ClientPrescriberTask;

  cssClasses = '';

  constructor() {}

  ngOnInit() {
    if (this.task.clientVisible) {
      this.cssClasses = 'alert-warning px-1';
    } else {
      this.cssClasses = 'alert-light bg-transparent';
    }
  }
}
