import { Component, Input, OnInit } from '@angular/core';
import { Prescriber } from '../../../core/models/prescriber.model';
import { ProviderOffice } from '../../../core/models/provider-office.model';
import { ProviderService } from '../../../core/services/provider.service';

@Component({
  selector: 'app-prescriber-offices-scrollable',
  templateUrl: './prescriber-offices-scrollable.component.html',
  styleUrls: ['./prescriber-offices-scrollable.component.scss'],
})
export class PrescriberOfficesScrollableComponent implements OnInit {
  @Input() prescriber: Prescriber;
  @Input() acceptableFaxStatusOnly = false;

  officesRefreshing = true;
  providerOffices: ProviderOffice[] = [];
  providerOfficeFaxStatuses = {};

  constructor(private providerService: ProviderService) { }

  ngOnInit() {
    this.loadOffices();
  }

  loadOffices() {
    if (this.prescriber.providerId) {
      this.officesRefreshing = true;
      this.providerService
        .getProviderOffices(this.prescriber.providerId, this.acceptableFaxStatusOnly)
        .subscribe((providerOffices: ProviderOffice[]) => {
          this.providerOffices = providerOffices;

          this.officesRefreshing = false;
        });
    }
  }
}
