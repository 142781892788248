<app-capture-history-item-title>
  {{ title }}
</app-capture-history-item-title>

<app-capture-history-item-body>
  {{ choreDisplayName }}, ID: {{ choreId }}
  <div *ngIf="error" class="text-danger">
    Error: {{ error }}
  </div>
</app-capture-history-item-body>
