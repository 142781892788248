<form class="content p-2" (ngSubmit)="onSubmit()" novalidate>
  <div class="form-row">
    <ng-container *ngFor="let searchField of searchFields">
      <div class="col-auto form-group">
        <label>
          <div>
            <strong>{{ searchField.placeholder }}: </strong>
          </div>

          <div>
            <input
              [(ngModel)]="captureSearchParams[searchField.name]"
              [id]="searchField.name"
              [name]="searchField.name"
              [type]="searchField.type"
              (ngModelChange)="onSearchFieldChanged()"
              class="form-control form-control-sm"
            />
          </div>
        </label>
      </div>
    </ng-container>
    <div class="col-auto form-group">
      <div class="btn-group">
        <button type="submit" [disabled]="!canSearch" class="btn btn-primary">Search</button>
        <button type="reset" (click)="onReset()" [disabled]="!canSearch" class="btn btn-info">Reset</button>
      </div>
    </div>
  </div>
</form>
<div class="content mt-2" *ngIf="!refreshing && hasQueried && hasResults">
  <ng-content />
  <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
</div>
<div class="mt-2  ml-1" *ngIf="!refreshing && hasQueried && !hasResults">Can't find the patient you're looking for? Try searching on just the patient's last name. If you're still unable to find the patient you're looking for, please search using the specific claim ID (i.e. case number in your Walgreens portal). Any questions? Reach out to <strong><a href="mailto:{{ supportEmailAddress }}">{{ supportEmailAddress }}</a></strong>.</div>
<div *ngIf="refreshing" class="mt-2 ml-1"><mat-spinner diameter="30" /></div>
