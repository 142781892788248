<app-capture-search [searchFields]="searchFields" (searchComplete)="onSearchComplete($event)">
  <table class="table table-bordered table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Claim ID</th>
        <th scope="col">Verification Date</th>
        <th scope="col">Status</th>
        <th scope="col">Client</th>
        <th scope="col">HRSA ID</th>
        <th scope="col">Claim Type</th>
        <th scope="col">Service Date</th>
        <th scope="col">Fill Number</th>
        <th scope="col">RX Reference</th>
        <th scope="col">NDC</th>
        <th scope="col">Prescriber NPI</th>
        <th scope="col">Store ID</th>
        <th scope="col">Quantity</th>
        <th scope="col">Patient Name</th>
        <th scope="col">Gender</th>
        <th scope="col">DOB</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let capture of captures">
        <td>
          <a routerLink="/partner-portal/captures/{{capture.id}}">{{ capture.candidate.claimIdentifier }}</a>
        </td>
        <td>{{ capture.verifiedAt | date: 'MM/dd/yyyy' }}</td>
        <td>{{ capture.status | captureStatus }}</td>
        <td>{{ capture.client.name }}</td>
        <td>{{ capture.client.hrsaIdStrings.join(', ') }}</td>
        <td>{{ capture.candidate.claimType }}</td>
        <td>{{ capture.candidate.serviceDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ capture.candidate.fillNumber }}</td>
        <td>{{ capture.candidate.referenceNumber }}</td>
        <td>{{ capture.candidate.ndc }}</td>
        <td>{{ capture.candidate.prescriberNpi }}</td>
        <td>{{ capture.candidate.storeIdentifier }}</td>
        <td>{{ capture.candidate.quantity }}</td>
        <td>{{ capture.candidate.patientFullName }}</td>
        <td>{{ capture.candidate.patientGender }}</td>
        <td>{{ capture.candidate.patientDob | date: 'MM/dd/yyyy' }}</td>
      </tr>
    </tbody>
  </table>
</app-capture-search>
