<div class="my-1">
  <span>
    <strong>Encounter Date {{ position + 1 }}</strong>
  </span>
  <div class="form-inline specialist-encounter-verification">
    <div class="input-group encounter-date">
      <input
        name="encounterDate"
        class="form-control encounter-date-input"
        type="date"
        autocomplete="off"
        [(ngModel)]="encounterDate"
        [disabled]="disabled"
        (blur)="onEncounterDateBlur()"
        placeholder="MM/DD/YYYY"
      />
    </div>
    <div *ngIf="canRemove" class="actions ml-1">
      <a href="#/remove" class="text-danger ml-1 text-small" (click)="onRemoveClick($event)">Remove</a>
    </div>
  </div>
</div>
