<table class="table table-bordered table-hover">
  <thead class="thead-dark">
    <tr>
      <th>CONSULT NOTE ID</th>
      <th>RECEIVED ON</th>
      <th *ngIf="!myWorkQueue">STATUS</th>
      <th>CLIENT</th>
      <th>FAX NUMBER</th>
      <th>OFFICE</th>
      <th>CE PATIENT</th>
      <th>OFFICE ESTIMATED VALUE</th>
      <th *ngIf="!myWorkQueue">ASSIGNED TO</th>
      <th>NEAREST EXPIRATION DATE</th>
      <th>PRIORITY</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let consultNoteQueueResult of consultNoteQueueResults"
        [ngClass]="{ 'selected' : isSelected(consultNoteQueueResult) }">
      <td class="id-column">
        <input
          type="checkbox"
          [checked]="isSelected(consultNoteQueueResult)"
          (click)="handleConsultNoteSelection(consultNoteQueueResult)"
          *ngIf="canAssignUser"
          class="mr-1"
        />
        <a routerLink="/capture-admin/consult-notes/{{consultNoteQueueResult.id}}">
          <span class="text-nowrap">{{ consultNoteQueueResult.id }}</span>
        </a>
        <div>
          <app-user-presence category="consultNote" [targetId]="consultNoteQueueResult.id" />
        </div>
      </td>
      <td class="text-right">
        {{ consultNoteQueueResult.createdAt | date: 'MM/dd/yyyy' }}
      </td>
      <td *ngIf="!myWorkQueue">
        {{ consultNoteQueueResult.status | patientAttachmentStatus }}
      </td>
      <td>
        {{ consultNoteQueueResult.client.name }}
      </td>
      <td>
        <app-phone-number [allowCopyToClipboard]="true" [number]="consultNoteQueueResult.outboundFaxNumber" />
      </td>
      <td>
        <app-consult-note-office [faxedProviderOffice]="consultNoteQueueResult.faxedProviderOffice" />
      </td>
      <td>
        <app-patient-name [patient]="consultNoteQueueResult.patient" [showAlternateNames]=false />
      </td>
      <td class="estimated-value-column">
        <app-consult-note-estimated-values [patientAttachment]="consultNoteQueueResult" />
      </td>
      <td *ngIf="!myWorkQueue">
        <ng-container *ngIf="consultNoteQueueResult.assignedTo">
          {{ consultNoteQueueResult.assignedTo.fullName }}
        </ng-container>
      </td>
      <td>
        {{ consultNoteQueueResult.nextHighValueExpirationDate | date: 'MM/dd/yyyy' }}
      </td>
      <td>
        {{ consultNoteQueueResult.priority }}
      </td>
    </tr>
  </tbody>
</table>
