<div class="form-group col-auto">
  <ng-select
    class="w-100"
    bindValue="id"
    bindLabel="username"
    placeholder="Filter by Client Lead"
    [multiple]="true"
    (change)="onChange()"
    [items]="users"
    [(ngModel)]="selectedUsers"
    dropdownPosition="bottom" />
</div>
