<app-pagination-header [paging]="paging" [label]="'tasks'" (pageSizeChange)="onPageSizeChange($event)" />
<table class="table table-bordered table-hover">
  <thead class="thead-dark">
    <tr>
      <th>ID</th>
      <th>CLIENT</th>
      <th>PATIENT</th>
      <th>PRESCRIBER</th>
      <th>STATUS</th>
      <th>SOURCE</th>
      <th class="text-nowrap text-right">EST. VALUE &#x25BC;</th>
      <th class="text-right">EXPIRES ON</th>
      <th class="text-right">LAST VIEWED</th>
      <th class="text-right">PRESCRIPTIONS ASSOCIATED</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let task of tasks">
      <tr class="task-row">
        <td>
          <a routerLink="/capture-admin/referral-claims/clients/client-referral-tasks/{{ task.id }}">
            {{ task.id }}
          </a>
        </td>
        <td>{{ task.client.humanizedName }}</td>
        <td>
          <div>{{ task.patient.fullName }}</div>
          <div class="small text-nowrap"><strong>MRN:</strong> {{ task.patient.mrn }}</div>
        </td>
        <td><app-prescriber-name [prescriber]="task.prescriber" /></td>
        <td>{{ task.status | taskStatus }}</td>
        <td>{{ task.source | claimSource | blankValueDash }}</td>
        <td class="text-right">{{ task.estimatedValue | currency }}</td>
        <td class="text-right">
          <app-expiration-date [expirationDate]="task.nextClaimExpirationDate" />
        </td>
        <td class="text-right">{{ task.clientLastViewedAt | date: 'MM/dd/yyyy' }}</td>
        <td class="text-right">
          <span>{{ task.prescriptionsCount | number }}</span>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
