<div class="wrapper">
  <div class="row">
    <div class="col sidebar pl-0 pr-0 sticky-top">
      <ul class="nav nav-sidebar flex-column">
        <li class="nav-item">
          <a class="nav-link"
             routerLink="captures"
             routerLinkActive="active"
          >
            <mat-icon>search</mat-icon>
            <p>Search</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link"
             routerLink="match-reports"
             routerLinkActive="active"
          >
             <mat-icon>bar_chart</mat-icon>
             <p>Reports</p>
          </a>
        </li>
      </ul>
    </div>
    <div class="col-11 content pt-5">
      <router-outlet />
    </div>
  </div>
</div>
