<app-client-task-history-item-title>
  {{ title }}
</app-client-task-history-item-title>
<app-client-task-history-item-body>
  <div class="my-2 h5">{{ submissionTitle }}</div>

  <ng-container *ngIf="isReferralTask">
    <div [ngSwitch]="clientTaskHistoryItem.data.choice">
     <div *ngSwitchCase="clientReferralTaskChoices.yes" class="pl-3">
        <dl>
          <dt>Supporting documentation type:</dt>
          <dd>
            <ul *ngIf="clientTaskHistoryItem.data.choiceReasons">
              <li *ngFor="let reason of clientTaskHistoryItem.data.choiceReasons">
                <ng-container [ngSwitch]="reason">
                  <ng-container *ngSwitchCase="verificationReasons.other">
                    {{ reason | capitalize }}<ng-container *ngIf="clientTaskHistoryItem.data.choiceOtherReasonNote">:</ng-container>
                    <ng-container *ngIf="clientTaskHistoryItem.data.choiceOtherReasonNote">
                      {{ clientTaskHistoryItem.data.choiceOtherReasonNote }}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="verificationReasons.specialityReferral">
                    {{ reason | capitalize }}<ng-container *ngIf="clientTaskHistoryItem.data.choiceSpecialityReferralSpeciality">:</ng-container>
                    <ng-container *ngIf="clientTaskHistoryItem.data.choiceSpecialityReferralSpeciality">
                      {{ clientTaskHistoryItem.data.choiceSpecialityReferralSpeciality }}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{ reason | capitalize }}
                  </ng-container>
                </ng-container>
              </li>
            </ul>
          </dd>
          <ng-container *ngIf="referralDates">
            <div>Referral Date(s): {{ referralDates | joinList }}</div>
          </ng-container>
          <div>Consult note task created by user: {{ clientTaskHistoryItem.data.collectConsultNote ? 'Yes' : 'No' }}</div>
        </dl>
     </div>
     <div *ngSwitchCase="clientReferralTaskChoices.no" class="pl-3">
        <div>Rejection Level: {{ referralRejectedReason | capitalize }}</div>
        <app-client-task-history-item-rejected-prescriptions
          [clientTaskHistoryItem]="clientTaskHistoryItem" />
     </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isConsultNoteTask">
    <div [ngSwitch]="clientTaskHistoryItem.data.choice">
      <div *ngSwitchCase="clientConsultNoteTaskChoices.yes" class="pl-3">

        <ng-container *ngIf="consultNoteDates">
          <div>Consult Note Date(s): {{ consultNoteDates | joinList }}</div>
        </ng-container>

        <ng-container *ngIf="verifiedPrescriptionIds">
          <div class="mt-3">Verified Prescriptions: {{ clientTaskHistoryItem.data.verifiedPrescriptionIds | joinList }}</div>
        </ng-container>

        <app-client-task-history-item-rejected-prescriptions
          [clientTaskHistoryItem]="clientTaskHistoryItem" />
      </div>

      <div *ngSwitchCase="clientConsultNoteTaskChoices.no" class="pl-3">
        <div>Rejection Level: {{ consultNoteRejectedReason | capitalize }}</div>
        <app-client-task-history-item-rejected-prescriptions
          [clientTaskHistoryItem]="clientTaskHistoryItem" />
      </div>

      <div *ngSwitchCase="clientConsultNoteTaskChoices.assignBackToPar8o" class="pl-3">
        <app-client-task-history-item-rejected-prescriptions
          [clientTaskHistoryItem]="clientTaskHistoryItem" />
      </div>

      <div *ngSwitchCase="clientConsultNoteTaskChoices.prescriberIsCeProvider" class="pl-3">
        <app-client-task-history-item-rejected-prescriptions
          [clientTaskHistoryItem]="clientTaskHistoryItem" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isPrescriberTask">
    <div [ngSwitch]="clientTaskHistoryItem.data.choice">
      <div *ngSwitchCase="clientPrescriberTaskChoices.yes" class="pl-3">
        <ng-container *ngIf="verifiedPrescriptionIds">
          <div class="mt-3">Verified Prescriptions: {{ clientTaskHistoryItem.data.verifiedPrescriptionIds | joinList }}</div>
        </ng-container>
      </div>

      <div *ngSwitchCase="clientPrescriberTaskChoices.no" class="pl-3">
        <app-client-task-history-item-rejected-prescriptions
          [clientTaskHistoryItem]="clientTaskHistoryItem" />
      </div>
    </div>
  </ng-container>

  <div *ngIf="hasAdditionDetails" class="mt-3 pl-3">Additional Details: {{ additionalDetails }}</div>
</app-client-task-history-item-body>
