<div class="modal-header px-3 py-1">
  <h5 id="modal-basic-title" class="modal-title text-white">Edit Referral Match Verifications</h5>
</div>
<div class="modal-body">
  <form>

    <div class="mt-3">
      <h5>Patient Referrals From EHR Feeds</h5>
      <app-referral-match-verifications-standard-edit
        [capture]="capture"
        [patientPrescriber]="patientPrescriber"
        (referralMatchOptionsChanged)="onReferralMatchOptionsChange($event)" />
    </div>

    <div class="mt-3">
      <h5>Patient Referrals From Client Tasks</h5>
      <app-referral-match-verifications-client-edit
        [capture]="capture"
        [patientPrescriber]="patientPrescriber"
        (clientReferralMatchOptionsChanged)="onClientReferralMatchOptionsChange($event)" />
    </div>

    <div class="mt-3">
      <h5>Referral Evidence Found in EHR</h5>
      <app-referral-match-verifications-ehr-edit
        [capture]="capture"
        [patientPrescriber]="patientPrescriber"
        (ehrReferralMatchOptionsChanged)="onEhrReferralMatchOptionsChange($event)" />
    </div>

    <div class="mt-3" *ngIf="showClientReferralDatesSection">
      <h5>Client Referral Dates</h5>
      <div class="bg-light border px-2">
        <app-referral-match-verifications-client-dates-edit
          [capture]="capture"
          [patientPrescriber]="patientPrescriber" />
      </div>
    </div>

    <div class="form-group mt-3 mb-2">
      <app-referral-match-verifications-notes
        [patientPrescriber]="patientPrescriber"
        (notesChanged)="onNotesChange($event)" />
    </div>
  </form>
  <div>
    <div class="text-right">
      <span *ngIf="errors" class="text-danger pr-2">{{ errors }}</span>
      <span *ngIf="responseErrors" class="text-danger pr-2">{{ responseErrors }}</span>
      <button type="button" class="btn btn-primary" (click)="onSaveClick()" [disabled]="errors">Save</button>
      <button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
    </div>
  </div>
</div>
