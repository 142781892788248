<h3>Log Call</h3>
<app-outbound-fax-selector (selectionChange)="onOutboundFaxChange($event)" />
<form *ngIf="inboundCallFormModel.outboundFaxId" (ngSubmit)="onSubmit()" novalidate>
  <div class="p-2 border rounded">
    <h4>Outcome</h4>
    <div><strong>Please log the outcome of this call</strong></div>
    <div class="form-check mt-1" *ngFor="let inboundCallReasonOption of inboundCallReasonOptions">
      <input
        [id]="inboundCallReasonOption.value"
        name="status"
        class="form-check-input"
        type="radio"
        [value]="inboundCallReasonOption.value"
        (change)="onReasonChange()"
        [(ngModel)]="inboundCallFormModel.reason"
      />
      <label class="form-check-label" [for]="inboundCallReasonOption.value">
        {{ inboundCallReasonOption.display }}
      </label>
    </div>
    <div class="form-group my-2">
      <label for="notes" class="my-1">ADDITIONAL DETAILS</label>
      <fa-icon *ngIf="!showNotes" [icon]="['fas', 'plus-square']" size="sm" (click)="onNotesPlusClick()" />
      <fa-icon *ngIf="showNotes" [icon]="['fas', 'minus-square']" size="sm" (click)="onNotesMinusClick()" />
      <textarea
        *ngIf="showNotes"
        id="notes"
        name="notes"
        class="form-control"
        [(ngModel)]="inboundCallFormModel.notes"
        rows="4"
      >
      </textarea>
    </div>
  </div>
  <div class="p-2 border rounded mt-2">
    <div class="form-group my-2">
      <h4>Update Communication Method</h4>
      <div class="form-check" *ngFor="let communicationMethod of communicationMethods">
        <fieldset>
          <input
            type="radio"
            [id]="communicationMethod.value"
            name="communicationMethod"
            class="form-check-input"
            [(ngModel)]="inboundCallFormModel.communicationMethod"
            [value]="communicationMethod.value"
          />
          <label [for]="communicationMethod.value" class="form-check-label"> {{ communicationMethod.display }} </label>
        </fieldset>
      </div>
    </div>
  </div>
  <div class="alert-danger text-danger mt-2" *ngIf="validationErrors.length > 0">
    <ul class="py-2">
      <li *ngFor="let error of validationErrors">{{ error }}</li>
    </ul>
  </div>
  <div class="alert-danger text-danger mt-2 p-2" *ngIf="serverError">
    A server error occurred. Please try again or contact support.
  </div>
  <div class="text-right actions mt-2">
    <button type="submit" class="btn btn-primary">Save</button>
    <a routerLink="/capture-admin/communications/inbound-calls" class="btn btn-secondary">Cancel</a>
  </div>
</form>
