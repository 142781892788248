<div id="validateReferralMatches" class="card mt-1">
  <h6 class="card-title m-0 px-2 py-1 d-flex">
    <mat-icon>groups</mat-icon>
    <div class="ml-1 pt-1">Review Selected Provider RMVs</div>
  </h6>
  <div class="card-body p-1">
    <ng-container *ngIf="matchingPatientPrescribers.length > 0; else: noPrescribersSelected">
      <div class="m-1 p-1 border" *ngFor="let mpp of matchingPatientPrescribers">
        <app-validate-referral-match-verifications-prescriber
          [patientAttachment]="patientAttachment"
          [matchingPatientPrescriber]="mpp"
        />
      </div>
    </ng-container>
    <div *ngIf="patientAttachment.shouldNotifyNoReferralRequired" class="m-0 mt-1 p-1 alert alert-success">
      Covered Entity’s policies and procedures support the capture of this claim if a consult note--that meets all
      requirements--has been retrieved, regardless of whether a referral is present.
    </div>
  </div>
</div>
<ng-template #noPrescribersSelected>
  <div class="m-2">No prescribers selected.</div>
</ng-template>
