<div class="card mt-1">
  <div class="card-body">
    <h4>Validate Patient Care Ownership</h4>
    <div>
      <div class="row">
        <div class="col-6">
          <h5 *ngIf="!patientInteractionIsVerified">Closest Patient Interaction to Rx Service Date ({{ candidate.serviceDate | date: 'MM/dd/yyyy' }}):</h5>
          <h5 *ngIf="patientInteractionIsVerified">Verifying Patient Interaction ({{ candidate.serviceDate | date: 'MM/dd/yyyy' }}):</h5>
          <table class="table table-sm">
            <thead>
              <th>ID</th>
              <th>DATE</th>
              <th>TYPE</th>
              <th>PROVIDER</th>
              <th>ENC-LOCATION</th>
              <th>ENC-TYPE</th>
            </thead>
            <tbody>
                <td>{{ closestPatientInteractionEhrIdentifier }}</td>
                <td>{{ closestPatientInteractionDate | date: 'MM/dd/yyyy' }}</td>
                <td>{{ closestPatientInteractionType }}</td>
                <td>{{ closestPatientInteractionProviderName }}</td>
                <td>{{ closestPatientInteractionLocation }}</td>
                <td>{{ closestPatientInteractionEncounterType }}</td>
            </tbody>
          </table>
          <div class="row mb-2">
            <div class="col" *ngIf="!patientInteractionIsVerified">
              <div *ngIf="closestPatientInteractionValid; else: patientInteractionNotValidTemplate">
                <p class="alert alert-success" rolw="alert">Patient Interaction is within {{ serviceDateRangeInMonths }} months of Rx Service Date</p>
              </div>
              <ng-template #patientInteractionNotValidTemplate>
                <p class="alert alert-danger" role="alert">There is no encounter within the client's approved service date window.</p>
              </ng-template>
            </div>
            <div class="col" *ngIf="patientInteractionIsVerified">
              <div>
                <p class="alert alert-success" role="alert">Verified Patient Interaction</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6" *ngIf="showIneligibleEncounters">
          <h5>Patient's Encounters</h5>

          <nav class="nav nav-pills mb-2">
            <a class="nav-item nav-link active"
               [class.active]="currentTab === 'eligibleEncounters'"
               (click)="tabClick($event, 'eligibleEncounters')"
               href="#">
              Eligible Encounters ({{ eligibleEncounters.length }})
            </a>
            <a class="nav-item nav-link"
               [class.active]="currentTab === 'ineligibleEncounters'"
               (click)="tabClick($event, 'ineligibleEncounters')"
               href="#">
              Ineligible Encounters ({{ ineligibleEncounters.length }})
            </a>
          </nav>

          <ng-container
            *ngIf="currentTab === 'eligibleEncounters'"
            [ngTemplateOutlet]="encounterList"
            [ngTemplateOutletContext]="{ encounters: eligibleEncounters }" />

          <ng-container
            *ngIf="currentTab === 'ineligibleEncounters'"
            [ngTemplateOutlet]="encounterList"
            [ngTemplateOutletContext]="{ encounters: ineligibleEncounters, rowClass: 'ineligible' }" />
        </div>

        <div class="col-6" *ngIf="!showIneligibleEncounters">
          <h5>Patient's Encounters ({{ eligibleEncounters.length }})</h5>

          <ng-container
            [ngTemplateOutlet]="encounterList"
            [ngTemplateOutletContext]="{ encounters: eligibleEncounters }" />
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #encounterList let-encounters="encounters" let-rowClass="rowClass">
  <table class="table table-sm">
    <thead>
      <th>ENC-ID</th>
      <th>ENC-DATE</th>
      <th>ENC-PROVIDER</th>
      <th>ENC-LOCATION</th>
      <th>ENC-TYPE</th>
      <th>ENC-PATIENT</th>
      <th>ENC-EHR INSTANCE</th>
    </thead>
    <tbody>
      <tr *ngFor="let encounter of encounters" class="encounter" [ngClass]="rowClass">
        <td>{{ encounter.ehrIdentifier }}</td>
        <td>{{ encounter.encounterDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ encounter.providerDisplayNameWithNpi }}</td>
        <td>{{ encounter.location }}</td>
        <td>{{ encounter.encounterType }}</td>
        <td>{{ encounter.sourcePatientMrn }}</td>
        <td>{{ encounter.ehrInstance }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
