<app-capture-history-item-title>
  Capture Details Updated
</app-capture-history-item-title>

<app-capture-history-item-body>
  <p *ngIf="statusReasons">
    {{ displayStatus }}: {{ statusReasons }}
  </p>

  <p *ngIf="additionalDetails">
    Additional Details: {{ additionalDetails }}
  </p>

  <p *ngIf="verifiedAtChanges">
    Verified At Changed From "{{ verifiedAtChanges[0] | date: 'MM/dd/yyyy' }}" To "{{ verifiedAtChanges[1] | date: 'MM/dd/yyyy' }}"
  </p>

  <p *ngIf="verifiedInvalidReviewedChanges && verifiedInvalidReviewedEnabled">
    Verified Invalid Review
  </p>
  <p *ngIf="verifiedInvalidReviewedChanges && !verifiedInvalidReviewedEnabled">
    Verified Invalid Review Removed
  </p>
</app-capture-history-item-body>
