<div class="row no-gutters mb-1">
  <div class="col">
    <span *ngIf="paging.count > 0">
      Viewing {{ viewing }} of <strong>{{ paging.count | number: '' }} </strong> {{ label }}
    </span>
    <ng-content />
  </div>
  <div class="col">
    <form novalidate class="form-inline float-right">
      <span *ngIf="paging.totalPages > 0">
        Page {{ paging.currentPage }} of {{ paging.totalPages }}
        |
      </span>
      <div class="form-group">
        <label for="pageSizes" class="mx-2">Page Size </label>
        <select id="pageSizes" (change)="onPageSizeChange($event)">
          <option [value]="pageSize" *ngFor="let pageSize of pageSizes" [selected]="pageSize === paging.pageSize">
            {{ pageSize }}
          </option>
        </select>
      </div>
      <div *ngIf="includeResetFiltersButton" class="ml-2">
        |
        <a href="javascript:;"
           class="text-danger"
           (click)="onResetFiltersClick($event)">
          Reset Filters
        </a>
      </div>
    </form>
  </div>
</div>
