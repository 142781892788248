<div class="card">
  <div class="card-body">
    <form>
      <div class="row small-gutters search-fields">
        <div class="col-md-2">
          <div class="form-group">
            <label><strong>Status:</strong></label>
            <ng-select
              class="w-100"
              bindValue="value"
              bindLabel="display"
              name="statuses"
              placeholder="Filter by Status"
              [multiple]="true"
              [items]="statusFilterOptions"
              (remove)="onStatusFilterChange()"
              (change)="onStatusFilterChange()"
              [(ngModel)]="filters.statuses"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label><strong>Status Reasons:</strong></label>
            <ng-select
              class="w-100"
              bindValue="value"
              bindLabel="display"
              name="reasons"
              placeholder="Filter by Reasons"
              [multiple]="true"
              [items]="statusReasonFilterOptions"
              (remove)="onStatusReasonFilterChange()"
              (change)="onStatusReasonFilterChange()"
              [(ngModel)]="filters.reasons"
              [disabled]="statusReasonFilterOptions.length === 0"
            />
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group w-100">
            <label>Prescriber Last:</label>
            <input
              id="prescriberLastName"
              name="prescriberLastName"
              class="form-control"
              type="text"
              (ngModelChange)="onFilterChange()"
              [(ngModel)]="filters.prescriberLastName"
            />
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group w-100">
            <label>Prescriber First:</label>
            <input
              id="prescriberFirstName"
              name="prescriberFirstName"
              class="form-control"
              type="text"
              (ngModelChange)="onFilterChange()"
              [(ngModel)]="filters.prescriberFirstName"
            />
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group w-100">
            <label>Prescriber NPI: </label>
            <input
              id="prescriberNpi"
              name="prescriberNpi"
              class="form-control"
              type="text"
              (ngModelChange)="onFilterChange()"
              [(ngModel)]="filters.prescriberNpi"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label><strong>Sort By:</strong></label>
            <ng-select
              class="w-100"
              name="cursorType"
              bindValue="value"
              bindLabel="display"
              [multiple]="false"
              [items]="cursorTypeOptions"
              [clearable]="false"
              [searchable]="false"
              (change)="onCursorTypeChange()"
              [(ngModel)]="cursorType"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group high-value">
            <strong>High Value:</strong>
            <mat-slide-toggle
              name="highValue"
              [color]="'primary'"
              [(ngModel)]="filters.highValue"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
<div class="row mt-1">
  <div class="col text-right">
    <app-patient-captures-search-summary [count]="count" (resetFiltersClick)="onResetFilters()"/>
  </div>
</div>
