<ng-container *ngIf="!loading; else: spinner">
  <table class="table table-sm" *ngIf="referralMatchOptions.length > 0; else: noReferralMatchOptions">
    <thead>
      <th>
        Referral Date
      </th>
      <th>
        Referral Type
      </th>
      <th>
        Referral Notes
      </th>
      <th>
        Specialty
      </th>
      <th>
        Prescriber
      </th>
      <th>
        Additional Details
      </th>
      <th>
        Task ID
      </th>
      <th>
        Submitted By
      </th>
      <th>
        Submitted At
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let option of referralMatchOptions; let i = index"
          [class.selected]="option.selected" class="referral">
        <td>
          <div class="form-check" [ngClass]="{ disabled: option.disabled }">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="'clientTaskRmv' + i"
              [(ngModel)]="option.selected"
              [disabled]="option.disabled"
              (change)="onReferralVerificationChange(option)">
            <label class="form-check-label" [for]="'clientTaskRmv' + i">
              {{ option.clientReferralVerification.referralDate | date: 'MM/dd/yyyy' }}
            </label>
          </div>
        </td>
        <td>
          {{ option.clientReferralTaskSubmission.choiceReasons | referralVerificationReasonShortNames | joinList }}
        </td>
        <td>
          {{ option.clientReferralTaskSubmission.choiceOtherReasonNote | blankValueDash }}
        </td>
        <td>
          {{ option.clientReferralVerification.referralSpecialty | blankValueDash }}
        </td>
        <td>
          <div>{{ option.clientReferralVerification.prescriber.fullName }}</div>
          <div>NPI: {{ option.clientReferralVerification.prescriber.npi }}</div>
          <div>{{ option.clientReferralVerification.prescriber.displaySpecialty }}</div>
        </td>
        <td>
          {{ option.clientReferralTaskSubmission.notes | blankValueDash }}
        </td>
        <td>
          {{ option.clientReferralTaskSubmission.taskId }}
        </td>
        <td>
          {{ option.clientReferralTaskSubmission.submittedBy.fullName }}
        </td>
        <td>
          <div>{{ option.clientReferralTaskSubmission.submittedAt | date: 'MM/dd/yyyy' }}</div>
          <div>{{ option.clientReferralTaskSubmission.submittedAt | date: 'h:mm a' }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #spinner>
  <div class="mt-1 mb-2 ml-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noReferralMatchOptions>
  <div class="my-2">Patient has no applicable referral tasks.</div>
</ng-template>
