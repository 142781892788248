<fieldset class="border mt-2 mb-3 pl-2">
  <legend class="w-auto m-0 p-0">Referral {{ position + 1 }}</legend>
  <div class="pb-2 pl-1 form-inline">
    <label><strong>Documentation Date</strong></label>
    <div class="input-group referral-date ml-2">
      <input
        name="referralDate"
        type="date"
        class="form-control referral-date-input"
        autocomplete="off"
        autofocus
        placeholder="MM/DD/YYYY"
        [disabled]="disabled"
        [(ngModel)]="referralDate"
        (blur)="onReferralDateBlur()"
      />
      <button
        *ngIf="referralDate && !disabled"
        (click)="onDateClearClick()"
        class="btn clear-button"
        type="button">
        <span>×</span>
      </button>
    </div>
    <div *ngIf="!disabled" class="actions ml-2">
      <a
        *ngIf="position > 0"
        href="#/remove"
        class="text-danger ml-1 text-small"
        (click)="onRemoveClick($event, verification)">
        Remove
      </a>
    </div>
  </div>
</fieldset>
