<ng-container *ngIf="isCaptureAdmin">
  <div class='row mb-2'>
    <div class='col-12 text-right'>
      <nav class="nav nav-pills float-right">
        <a routerLinkActive="active"
           [routerLink]="detailsUrl"
           [routerLinkActiveOptions]="{ exact: true }"
           class="nav-item nav-link">

          <div class="d-flex">
            <mat-icon class="material-icons-round">featured_play_list</mat-icon>
            <div class="nav-text">Task Details</div>
          </div>
        </a>

        <a routerLinkActive="active"
           [routerLink]="historyItemsUrl"
           [routerLinkActiveOptions]="{ exact: true }"
           class="nav-item nav-link">

          <div class="d-flex">
            <mat-icon class="material-icons-round">fact_check</mat-icon>
            <div class="nav-text">Task History</div>
          </div>
        </a>
      </nav>

    </div>
  </div>
</ng-container>

