<div class="form-group col-auto">
  <ng-select
    [placeholder]="label"
    [disabled]="!clientId"
    [(ngModel)]="selectedClaimImport"
    (change)="onChange()">

    <ng-option *ngFor="let import of claimImports" [value]="import">
      <strong class="option-primary-text">{{ import.name }}</strong>
      <span class="option-secondary-text">{{ import.createdAt | date: 'MM/dd/yyyy' }} by {{ import.user.email }}</span>
    </ng-option>

  </ng-select>
</div>
