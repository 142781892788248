import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { EhrReferralMatchOption } from 'app/core/models/ehr-referral-match-option.model';
import { ReferralMatchVerificationsService } from 'app/core/services/referral-match-verifications.service';
import { EhrReferralVerificationsService, EhrReferralVerificationUpdate } from 'app/core/services/ehr-referral-verifications.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { isConsultNoteVerificationFrozen, resolveEhrVerification } from 'app/core/lib/verification-utils';
import { keyBy } from 'lodash-es';
import { VerificationType } from 'app/core/enums/verification-type.enum';
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { ReferralMatchVerificationEvent, ReferralMatchVerificationEventType } from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-edit-referral-match-verifications-ehr',
  templateUrl: './edit-referral-match-verifications-ehr.component.html',
  styleUrls: ['./edit-referral-match-verifications-ehr.component.scss'],
})
export class EditReferralMatchVerificationsEhrComponent {
  _patientPrescriber: PatientPrescriber;

  @Input() patientAttachment: PatientAttachment;
  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  ehrReferralVerifications: EhrReferralVerification[] = [];
  referralMatchVerifications: ReferralMatchVerification[] = [];
  referralMatchOptions: EhrReferralMatchOption[] = [];
  loadingReferralMatchOptions = true;

  constructor(
    private authService: AuthenticationService,
    private referralMatchVerificationsService: ReferralMatchVerificationsService,
    private ehrReferralVerificationsService: EhrReferralVerificationsService
  ) { }

  get patientPrescriber(): PatientPrescriber {
    return this._patientPrescriber;
  }

  @Input()
  set patientPrescriber(pp: PatientPrescriber) {
    this._patientPrescriber = pp;

    this.updateReferralMatchVerifications();
    this.loadEhrReferralVerifications();
  }

  onNewEhrReferralVerification(event: EhrReferralVerificationUpdate) {
    const newVerification = event.ehrReferralVerification;

    this.ehrReferralVerifications.push(newVerification);
    this.handleNewEhrReferralVerification(newVerification);
    this.setupReferralMatchOptions();

    const newOption =
      this.referralMatchOptions.find(option => option.ehrReferralVerification === newVerification);

    this.handleRmvSelectionChange(newOption);
  }

  onEhrReferralVerificationEdited(event: EhrReferralVerificationUpdate) {
    const updatedVerification = event.ehrReferralVerification;

    const index =
      this.ehrReferralVerifications.findIndex(verification => verification.id === updatedVerification.id);

    if (index >= -1) {
      this.ehrReferralVerifications[index] = updatedVerification;
      this.setupReferralMatchOptions();
    }

    const associatedPPRmv =
      this.patientPrescriber.referralMatchVerifications.find((rmv: ReferralMatchVerification) =>
        rmv.ehrReferralVerificationId === updatedVerification.id
      );

    if (associatedPPRmv && associatedPPRmv.ehrReferralVerification) {
      associatedPPRmv.ehrReferralVerification = updatedVerification;
    }
  }

  onReferralVerificationChange(option: EhrReferralMatchOption) {
    this.handleRmvSelectionChange(option);
  }

  private updateReferralMatchVerificationForOption(option: EhrReferralMatchOption) {
    const updatedVerification = { ...option.verification };
    const updatedOption: EhrReferralMatchOption = { ...option };

    if (option.selected) {
      updatedOption.ehrReferralVerification = option.ehrReferralVerification;
    }
    updatedOption.verification = updatedVerification;

    this.referralMatchVerificationsService.updateForOption(
      this.patientPrescriber.id,
      updatedOption,
      this.verificationChange
    ).subscribe();
  }

  private createOrUpdateReferralMatchVerification(referralMatchVerification: ReferralMatchVerification) {
    this.referralMatchVerificationsService.
      createOrUpdate(this.patientPrescriber.id, referralMatchVerification).
      subscribe((rmv: ReferralMatchVerification) => {
        const changeEvent =
          new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changed, rmv);

        this.verificationChange.emit(changeEvent);
      });
  }

  protected loadEhrReferralVerifications() {
    const filters = { patientId: this.patientPrescriber.patientId };

    this.ehrReferralVerificationsService.getList(filters).subscribe(result => {
      this.ehrReferralVerifications = result.ehrReferralVerifications;
      this.setupReferralMatchOptions();
      this.loadingReferralMatchOptions = false;
    });
  }

  private setupReferralMatchOptions() {
    const filteredRmvs =
      this.referralMatchVerifications.filter(rmv => !!rmv.ehrReferralVerificationId);

    const rmvsByErvId =
      keyBy(filteredRmvs, (rmv: ReferralMatchVerification) => rmv.ehrReferralVerificationId);

    this.referralMatchOptions =
      this.ehrReferralVerifications.map(ehrReferralVerification => {
        const resolvedVerification =
          resolveEhrVerification(ehrReferralVerification, rmvsByErvId);

        const disabled = isConsultNoteVerificationFrozen(
          this.authService,
          this.patientAttachment,
          VerificationType.referralMatch,
          resolvedVerification
        );

        return new EhrReferralMatchOption(
          resolvedVerification,
          ehrReferralVerification,
          !!resolvedVerification.id,
          disabled
        )
      });
  }

  protected handleRmvSelectionChange(option: EhrReferralMatchOption) {
    this.updateReferralMatchVerificationForOption(option);
  }

  protected handleNewEhrReferralVerification(newVerification: EhrReferralVerification) {
    const newRmv = new ReferralMatchVerification();
    newRmv.ehrReferralVerificationId = newVerification.id;

    this.createOrUpdateReferralMatchVerification(newRmv);
  }

  private updateReferralMatchVerifications() {
    this.referralMatchVerifications = [...this.patientPrescriber.referralMatchVerifications];
  }
}
