<app-capture-history-item-title>
  {{ displayStatus }}
</app-capture-history-item-title>

<app-capture-history-item-body>
  <p>
    {{ description }}

    <ng-container *ngIf="captureHistoryItem.relatedRecord">
      via peer capture
      <a href="/capture-admin/captures/{{ captureHistoryItem.relatedRecord.id }}" target="_blank">{{ captureHistoryItem.relatedRecord.claimIdentifier }}</a>.
    </ng-container>
  </p>

  <p *ngIf="verifiedDuringNoReferralPeriod">
    CE's policies and procedures supported a verification without a referral on file at the time of verification.
  </p>
</app-capture-history-item-body>
