<table class="table table-bordered table-hover">
  <thead class="thead-dark">
    <tr>
      <th *ngIf="includeClientColumn">Client</th>
      <th>Location</th>
      <th>Earliest<br /> Record</th>
      <th>Last<br/> Received</th>
      <th>Encounters</th>
      <th>Referrals</th>
      <th>Client<br /> Prescriptions</th>
      <th>Current<br /> Eligibility Status</th>
      <th class="text-center">Eligibility Period</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let patientInteractionLocation of patientInteractionLocations">
      <td *ngIf="includeClientColumn">
        {{ patientInteractionLocation.client.name }}
      </td>
      <td>{{ patientInteractionLocation.name }}</td>
      <td>{{ patientInteractionLocation.firstServiceDate | date: 'MM/dd/yyyy' | blankValueDash }}</td>
      <td>{{ patientInteractionLocation.lastReceivedAt | date: 'MM/dd/yyyy' | blankValueDash }}</td>
      <td>{{ patientInteractionLocation.encounterCount | number }}</td>
      <td>{{ patientInteractionLocation.referralCount | number }}</td>
      <td>{{ patientInteractionLocation.clientPrescriptionCount | number }}</td>
      <td class="eligibility-status" [ngClass]="patientInteractionLocation.eligibilityStatus">
        {{ keyedEligibilityStatusOptions[patientInteractionLocation.eligibilityStatus].display }}
      </td>
      <td *ngIf="!patientInteractionLocation.eligibilityPeriodsEnabled"
          class="eligibility-periods-disabled"
          colspan="2">
        This client does not manage 340B locations data in the app
      </td>
      <td class="eligibility-periods" *ngIf="patientInteractionLocation.eligibilityPeriodsEnabled">
        <ng-container
          [ngTemplateOutlet]="eligibilityPeriods"
          [ngTemplateOutletContext]="{ patientInteractionLocation: patientInteractionLocation }" />
      </td>
      <td class="location-actions"
          [ngClass]="patientInteractionLocation.eligibilityStatus"
          *ngIf="patientInteractionLocation.eligibilityPeriodsEnabled">
        <ng-container
          [ngTemplateOutlet]="eligibilityActions"
          [ngTemplateOutletContext]="{ patientInteractionLocation: patientInteractionLocation }" />
      </td>
    </tr>
  </tbody>
</table>

<ng-template #eligibilityActions let-patientInteractionLocation="patientInteractionLocation">
  <ng-container *ngIf="!patientInteractionLocation.saving; else: spinner">
    <ng-container *ngIf="!patientInteractionLocation.editing">
      <button
        type="button"
        class="btn btn-secondary edit-button"
        *ngIf="patientInteractionLocation.eligibilityStatus === eligibilityStatuses.new ||
               patientInteractionLocation.eligibilityStatus === eligibilityStatuses.ineligible"
        (click)="onEditClick(patientInteractionLocation)">
        Mark Eligible
      </button>

      <button
        type="button"
        class="btn btn-secondary edit-button"
        *ngIf="patientInteractionLocation.eligibilityStatus === eligibilityStatuses.new"
        (click)="onTouchLocationClick(patientInteractionLocation)">
        Mark Ineligible
      </button>

      <button
        type="button"
        class="btn btn-secondary edit-button"
        *ngIf="patientInteractionLocation.eligibilityStatus === eligibilityStatuses.eligible"
        (click)="onEditClick(patientInteractionLocation)">
        Edit
      </button>
    </ng-container>

    <button
      type="button"
      class="btn btn-primary save-button"
      *ngIf="patientInteractionLocation.editing"
      [disabled]="!patientInteractionLocation.eligibilityPeriods[0].startDate"
      (click)="onSaveClick(patientInteractionLocation)">
      Save
    </button>

    <button
      type="button"
      class="btn btn-secondary cancel-button"
      *ngIf="patientInteractionLocation.editing"
      (click)="onCancelClick(patientInteractionLocation)">
      Cancel
    </button>
  </ng-container>
</ng-template>

<ng-template #eligibilityPeriods let-patientInteractionLocation="patientInteractionLocation">
  <ng-container *ngIf="patientInteractionLocation.eligibilityPeriods.length > 0">
    <ng-container *ngIf="patientInteractionLocation.editing">
      <div class="eligibility-period-fields">
        <div class="eligibility-period-date-field-container">
          <div>Start:</div>
          <input
            name="startDate"
            type="date"
            class="form-control"
            autocomplete="off"
            autofocus
            placeholder="MM/DD/YY"
            (change)="onEligibilitPeriodDateChange(patientInteractionLocation)"
            [(ngModel)]="patientInteractionLocation.eligibilityPeriods[0].startDate" />
        </div>
        &mdash;
        <div class="eligibility-period-date-field-container">
          <div>Term:</div>
          <input
            name="terminationDate"
            type="date"
            class="form-control"
            autocomplete="off"
            autofocus
            placeholder="MM/DD/YY"
            (change)="onEligibilitPeriodDateChange(patientInteractionLocation)"
            [(ngModel)]="patientInteractionLocation.eligibilityPeriods[0].terminationDate" />
        </div>
      </div>

      <div class="small my-2 termination-date-note">
        Note: An eligibility period term date is not required.
      </div>

      <ng-container
        [ngTemplateOutlet]="eligibilityPeriodList"
        [ngTemplateOutletContext]="{ patientInteractionLocation: patientInteractionLocation, slice: 1 }" />

      <div class="text-danger" *ngFor="let error of patientInteractionLocation.errors">
        {{ error }}
      </div>
    </ng-container>

    <ng-container *ngIf="!patientInteractionLocation.editing">
      <ng-container
        [ngTemplateOutlet]="eligibilityPeriodList"
        [ngTemplateOutletContext]="{ patientInteractionLocation: patientInteractionLocation, slice: 0 }" />
    </ng-container>

  </ng-container>

  <ng-container *ngIf="patientInteractionLocation.eligibilityPeriods.length === 0">
    <app-blank-value-dash class="blank-eligibility-periods" />
  </ng-container>
</ng-template>

<ng-template #eligibilityPeriodList let-patientInteractionLocation="patientInteractionLocation" let-slice="slice">
  <div *ngFor="let eligibilityPeriod of asLocation(patientInteractionLocation).eligibilityPeriods | slice: slice; index as i"
    [ngClass]="{ current: i === 0 && !patientInteractionLocation.editing }"
    class="eligibility-period">

    <a *ngIf="enableLinkToChangelog; else plainText" class="date" target="_blank"
      routerLink="/capture-admin/change-log/PatientInteractionLocationEligibilityPeriod/{{eligibilityPeriod.id}}">
      <ng-container *ngTemplateOutlet="dates; context: { eligibilityPeriod: eligibilityPeriod }" />
    </a>

    <ng-template #plainText>
      <ng-container *ngTemplateOutlet="dates; context: { eligibilityPeriod: eligibilityPeriod }" />
    </ng-template>
  </div>
</ng-template>

<ng-template #dates let-eligibilityPeriod="eligibilityPeriod">
  <span [ngClass]="{'inactive': isInactive(eligibilityPeriod)}">
    <span>
      {{ eligibilityPeriod.startDate | date: 'MM/dd/yyyy' }}
    </span>
    &mdash;
    <span>
      <ng-container *ngIf="eligibilityPeriod.terminationDate">
        {{ eligibilityPeriod.terminationDate | date: 'MM/dd/yyyy' }}
      </ng-container>
      <ng-container *ngIf="!eligibilityPeriod.terminationDate">
        Present
      </ng-container>
    </span>
  </span>
</ng-template>

<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>
