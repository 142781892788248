<ng-container *ngIf="!collapsed && !!checkedWrittenDates?.length">
  <p>Relevant Written Dates:</p>

  <ul>
    <li *ngFor="let checkedWrittenDate of checkedWrittenDates">
      {{ checkedWrittenDate.date | date: 'M/d/yyyy' }}

      <fa-icon
        *ngIf="checkedWrittenDate.dateWithinRange"
        [icon]="['fas', 'check']" size="1x" class="check ml-2" />
      <fa-icon
        *ngIf="checkedWrittenDate.dateWithinRange === false"
        [icon]="['fas', 'exclamation-triangle']" size="1x" class="warning ml-2" />
    </li>
  </ul>
</ng-container>
