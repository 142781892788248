import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { ClientReferralVerification } from 'app/core/models/client-referral-verification.model';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ClientReferralMatchOption } from 'app/core/models/client-referral-match-option.model';
import { ReferralMatchVerificationsService } from 'app/core/services/referral-match-verifications.service';
import { ClientReferralVerificationsService, PagedClientReferralVerifications } from 'app/core/services/client-referral-verifications.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { ReferralMatchSource } from 'app/core/enums/referral-match-source.enum';
import { isConsultNoteVerificationFrozen, resolveClientVerification } from 'app/core/lib/verification-utils';
import { keyBy } from 'lodash-es';
import { VerificationType } from 'app/core/enums/verification-type.enum';
import { ReferralMatchVerificationEvent } from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-edit-referral-match-verifications-client',
  templateUrl: './edit-referral-match-verifications-client.component.html',
  styleUrls: ['./edit-referral-match-verifications-client.component.scss'],
})
export class EditReferralMatchVerificationsClientComponent {
  _patientPrescriber: PatientPrescriber;

  @Input() patientAttachment: PatientAttachment;
  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  referralMatchOptions: ClientReferralMatchOption[] = [];
  loadingReferralMatchOptions = true;

  constructor(
    private authService: AuthenticationService,
    private referralMatchVerificationsService: ReferralMatchVerificationsService,
    private clientReferralVerificationsService: ClientReferralVerificationsService
  ) { }

  get patientPrescriber(): PatientPrescriber {
    return this._patientPrescriber;
  }

  @Input()
  set patientPrescriber(pp: PatientPrescriber) {
    this._patientPrescriber = pp;

    this.fetchClientReferralVerifications();
  }

  onReferralVerificationChange(option: ClientReferralMatchOption) {
    const updatedVerification = { ...option.verification };
    const updatedOption = { ...option };

    if (option.selected) {
      updatedVerification.peerClientReferralVerification = true;
    }
    updatedOption.verification = updatedVerification;

    this.referralMatchVerificationsService.updateForOption(
      this.patientPrescriber.id,
      updatedOption,
      this.verificationChange
    ).subscribe();
  }

  private fetchClientReferralVerifications() {
    const filters = {
      patientId: this.patientPrescriber.patientId,
      hasClientReferralTaskSubmission: true,
      includeClientReferralTaskSubmission: true,
      includePrescriber: true
    };

    this.clientReferralVerificationsService.getList(filters).
      subscribe(({ clientReferralVerifications }: PagedClientReferralVerifications) => {
        this.setupReferralMatchOptions(clientReferralVerifications);
        this.loadingReferralMatchOptions = false;
      });
  }

  private setupReferralMatchOptions(clientReferralVerifications: ClientReferralVerification[]) {
    const filteredRmvs =
      this.patientPrescriber.
        referralMatchVerifications.filter(rmv => rmv.source === ReferralMatchSource.clientReferralTask ||
          rmv.source === ReferralMatchSource.peerClientReferralVerification);

    const rmvsByCrvId = keyBy(filteredRmvs, (rmv: ReferralMatchVerification) => rmv.clientReferralVerificationId);

    this.referralMatchOptions =
      clientReferralVerifications.map(clientReferralVerification => {
        const resolvedVerification =
          resolveClientVerification(clientReferralVerification, rmvsByCrvId);

        return new ClientReferralMatchOption(
          resolvedVerification,
          clientReferralVerification,
          clientReferralVerification.clientReferralTaskSubmission,
          !!resolvedVerification.id,
          this.isClientTaskReferralMatchVerificationDisabled(resolvedVerification)
        )
      });
  }

  private isClientTaskReferralMatchVerificationDisabled(referralMatchVerification: ReferralMatchVerification) {
    return referralMatchVerification.source === ReferralMatchSource.clientReferralTask ||
      isConsultNoteVerificationFrozen(
        this.authService,
        this.patientAttachment,
        VerificationType.referralMatch,
        referralMatchVerification
      );
  }
}
