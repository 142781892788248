import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-patient-prescriber-reports.component.html',
})
export class CaptureAdminPatientPrescriberReportsComponent {
  reportsUrl = `${environment.captureApi.url}/patient_prescriber_reports`;
  reportsCountUrl = `${environment.captureApi.url}/patient_prescriber_reports/count`;
}
