<div class="card">
  <div class="card-body">
    <h5 class="mb-4">Grouped Patients</h5>

    <table class="table table-bordered" *ngIf="patient.subPatients.length > 0; else: noGroupedPatients">
      <thead class="thead-dark">
        <th>Patient ID</th>
        <th>MRN</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Other Last Names</th>
        <th>DOB</th>
        <th>Gender</th>
        <th>EMPI</th>
        <th>SSN</th>
        <th>Phone</th>
        <th>Address</th>
        <th>Insurance Member ID</th>
        <th>EHR Instance</th>
        <th>Match Type</th>
        <th>Match Key(s)</th>
        <th>Status</th>
      </thead>

      <tbody>
        <tr *ngFor="let subPatient of patient.subPatients">
          <td>
            <a routerLink="/capture-admin/patients/{{ subPatient.id }}">
              {{ subPatient.id }}
            </a>
          </td>
          <td>
            {{ subPatient.mrn }}
          </td>
          <td>
            {{ subPatient.firstName }}
          </td>
          <td>
            {{ subPatient.lastName }}
          </td>
          <td>
            <span *ngFor="let alternateName of subPatient.alternateNames">{{ alternateName }}</span>
            <span *ngIf="subPatient.alternateNames.length === 0"><app-blank-value-dash /></span>
          </td>
          <td>
            {{ subPatient.dob }}
          </td>
          <td>
            {{ subPatient.gender }}
          </td>
          <td>
            {{ subPatient.empi | blankValueDash }}
          </td>
          <td>
            <app-sensitive-string *ngIf="subPatient.hasSsn"
              (valueAccessed)="onSsnAccessed(subPatient.id)"
              [value]="subPatient.decryptedSsn"
              [hiddenTextLength]="12"
            />
            <span *ngIf="!subPatient.hasSsn"><app-blank-value-dash /></span>
          </td>
          <td>
            <div *ngFor="let phoneNumber of subPatient.phoneNumbers">
              <app-phone-number [number]="phoneNumber" />
            </div>
            <span *ngIf="subPatient.phoneNumbers.length === 0"><app-blank-value-dash /></span>
          </td>
          <td>
            <div *ngFor="let address of subPatient.addresses">
              <div>{{ address.line1 }}</div>
              <div>{{ address.line2 }}</div>
              <div>{{ address.city }}, {{ address.state }} {{ address.zip }}</div>
            </div>
            <span *ngIf="subPatient.addresses.length === 0"><app-blank-value-dash /></span>
          </td>
          <td>
            <div *ngFor="let insuranceIdentifier of subPatient.insuranceIdentifiers">
              {{ insuranceIdentifier }}
            </div>
            <span *ngIf="subPatient.insuranceIdentifiers.length === 0">
              <app-blank-value-dash />
            </span>
          </td>
          <td>
            {{ subPatient.ehrInstance | blankValueDash }}
          </td>
          <td>
            system
          </td>
          <td>
            <div *ngFor="let match of subPatient.groupingKeyMatches" class="grouping-key-match">
              <span class="grouping-key-match-kind">{{ match.kind }}</span>
              <span class="grouping-key-match-confidence">({{ match.confidence }})</span>
            </div>
          </td>
          <td>
            <app-patient-status-and-deactivate-button [patient]="subPatient" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-template #noGroupedPatients>
  <p>There are no grouped patients.</p>
</ng-template>
