<div *ngIf="!loading; else: spinner">
  <table class="table m-0" *ngIf="captures.length > 0; else: noCaptures">
    <thead>
      <tr>
        <th>CLAIM ID</th>
        <th>STATUS</th>
        <th>OUTCOME</th>
        <th>SOURCE</th>
        <th>AKA</th>
        <th class="text-right">RX #</th>
        <th>STORE ID</th>
        <th class="text-right" *ngIf="showEstimatedValue">EST. VALUE</th>
        <th class="text-right">SERVICE DATE &#x25BC;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let capture of captures">
        <td class="text-nowrap">
          <div class="text-nowrap" *ngIf="capture.id !== activeCaptureId; else: currentCapture">
            <a routerLink="{{ baseCaptureUrl + capture.id}}" target="_blank">{{ capture.candidate.claimIdentifier }}</a>
          </div>
          <ng-template #currentCapture>
            <div class="text-nowrap">{{ capture.candidate.claimIdentifier }}</div>
            <strong class="text-nowrap"><em>this capture</em></strong>
          </ng-template>
        </td>
        <td>{{ capture.status | captureStatus }}</td>
        <td>{{ capture.statusReason | captureStatusReason }}</td>
        <td>{{ capture.candidate.source }}</td>
        <td>{{ capture.candidate.ndcAkas.join(', ') }}</td>
        <td class="text-right ">
          <app-candidate-reference-number [candidate]="capture.candidate" />
        </td>
        <td>{{ capture.candidate.storeIdentifier }}</td>
        <td class="text-right" *ngIf="showEstimatedValue">{{ capture.candidate.estimatedValueForDisplay }}</td>
        <td class="text-right">{{ capture.candidate.serviceDate | date: 'MM/dd/yyyy' }}</td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #spinner>
  <div><mat-spinner diameter="30" /></div>
</ng-template>
<ng-template #noCaptures> <p>There are no captures for this prescription.</p></ng-template>
