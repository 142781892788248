<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Share LLM Playground Project</h4>
    <button type="button" class="close" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ng-select
      placeholder="Select User(s)"
      bindLabel="username"
      [multiple]="true"
      [items]="selectableUsers"
      [(ngModel)]="selectedUsers"
      (change)="onCollaboratorChange($event)"
    />

    <p class="small">
      Note: all users you share the project with will have access to edit the project.
    </p>

    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-primary ml-2" [disabled]="selectedUsers.length === 0" (click)="onShare()">Share ({{selectedUsers.length}})</button>
    </div>

    <hr>

    <div *ngIf="errorMessage" class="p-2 text-danger">
      {{ errorMessage }}
    </div>

    <h6>
      Users with Access to Project
    </h6>

    <div class="m-2 d-flex justify-content-center" *ngIf="loading">
      <mat-spinner [diameter]="28" />
    </div>

    <div class="text-grey">{{ createdByEmail }} (Owner)</div>
    <div *ngFor="let permission of currentPermissions">{{ permission.email }}
      <a
        href="#/remove"
        class="text-danger ml-1 text-small text-decoration-underline small"
        *ngIf="isNotSelf(permission.email)"
        (click)="onRemove($event, permission.id)">
        remove
      </a>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-sm btn-info ml-2" (click)="onCancel()">Done</button>
  </div>
</div>
