<ng-container *ngIf="patientReferralMatchVerifications.length > 0">
  <table class="table table-sm table-bordered mb-1">
    <thead class="thead-dark">
      <tr>
        <th>RECEIVING PROVIDER / PRACTICE</th>
        <th>SPECIALTY</th>
        <th>RECEIVING OFFICE</th>
        <th>REFERRING PROVIDER</th>
        <th>REF-DATE</th>
        <th>REF-ID</th>
        <th>STATUS</th>
        <th>NOTES</th>
        <th>CONSULT</th>
        <th>CONSULT DESC</th>
        <th>PT CONSULT DESC</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let rmv of patientReferralMatchVerifications">
        <tr>
          <td>
            <div class="d-flex">
              <div *ngIf="rmv.provider" class="selection mr-1">
                <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
              </div>
              <div>
                {{ rmv.referral.receivingProviderDisplayNameWithNpi }} <br />
                {{ rmv.referral.receivingProviderDisplaySpecialty }}
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex">
              <div *ngIf="rmv.specialty" class="selection mr-1">
                <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
              </div>
              <div>{{ rmv.referral.referralSpecialty }}</div>
            </div>
          </td>
          <td>
            <div class="d-flex">
              <div *ngIf="rmv.office" class="selection mr-1">
                <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
              </div>
              <div>
                <div>{{ rmv.referral.receivingOfficeName }}</div>
                <div><strong>FAX:</strong> {{ rmv.referral.receivingProviderFax }}</div>
              </div>
            </div>
          </td>
          <td>{{ rmv.referral.sendingProviderDisplayNameWithNpi }}</td>
          <td>{{ rmv.referral.referralDate | date: 'MM/dd/yyyy' }}</td>
          <td>{{ rmv.referral.ehrIdentifier }}</td>
          <td>{{ rmv.referral.referralEhrStatus }}</td>
          <td>{{ rmv.referral.referralEhrNotes }}</td>
          <td>{{ rmv.referral.referralConsultInEhr }}</td>
          <td>{{ rmv.referral.referralConsultEhrDescriptions }}</td>
          <td>{{ rmv.referral.patientConsultEhrDescriptions }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
