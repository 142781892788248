<div class="ml-3 m-2"><h4>Inbound Call Log</h4></div>
<div *ngIf="inboundCalls.length > 0; else: noInboundCalls">
  <div *ngIf="!refreshing" class="mx-2">
    <div class="m-2">
      <app-pagination-header [paging]="paging" [label]="'Inbound Calls'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <table class="table table-bordered table-sm">
      <thead class="thead-dark">
        <tr>
          <th>ID</th>
          <th>DATE</th>
          <th class="col-2">CREATED BY</th>
          <th class="col-2">PATIENT</th>
          <th class="col-3">OFFICE</th>
          <th class="col-1">OUTBOUND FAX ID</th>
          <th>CALL OUTCOME</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let inboundCall of inboundCalls">
          <td>
            <a
              routerLink="/capture-admin/communications/inbound-calls/{{inboundCall.id}}"
              target="_blank"
            >{{ inboundCall.id }}</a>
          </td>
          <td>{{ inboundCall.createdAt | date: 'MM/dd/yyyy' }}</td>
          <td>{{ inboundCall.submittedBy.fullName }}</td>
          <td>
            <app-patient-name [patient]="inboundCall.patient" />
          </td>
          <td><app-office [office]="inboundCall.office" /></td>
          <td>{{ inboundCall.outboundFaxId }}</td>
          <td>{{ keyedInboundCallReasonOptions[inboundCall.reason].display }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </div>
</div>
<ng-template #noInboundCalls><p class="ml-2">No inbound call logs.</p></ng-template>
