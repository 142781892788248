import { ProviderDoNotSendReason } from '../enums/provider-do-not-send-reason.enum';
import { keyBy } from 'lodash-es';

export class ProviderDoNotSendReasonOption {
  constructor(public value: string, public display: string) { }
}

const providerDoNotSendReasonOptions = [
  new ProviderDoNotSendReasonOption(ProviderDoNotSendReason.employed, "Employed"),
  new ProviderDoNotSendReasonOption(ProviderDoNotSendReason.specialty, "Specialty"),
];

const keyedDoNotSendReasonOptions = keyBy(providerDoNotSendReasonOptions, 'value');

export { keyedDoNotSendReasonOptions, providerDoNotSendReasonOptions };
