import { Component, OnInit } from '@angular/core';
import { Upload } from 'app/core/models/upload.model';
import { OutboundFaxImportKind } from 'app/core/enums/outbound-fax-import-kind.enum';
import { OutboundFaxImportsService } from 'app/core/services/outbound-fax-imports.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty } from 'lodash-es';

@Component({
  selector: 'app-capture-admin-import-outbound-faxes',
  templateUrl: './capture-admin-import-outbound-faxes.component.html',
  styleUrls: ['./capture-admin-import-outbound-faxes.component.scss'],
})
export class CaptureAdminImportOutboundFaxesComponent implements OnInit {
  uploading = false;
  formModel = {};
  errors: string[] = [];

  constructor(private outboundFaxImportsService: OutboundFaxImportsService, private router: Router) {}

  ngOnInit() {}

  onUploadStart() {
    this.uploading = true;
    this.errors = [];
  }

  onUploadCancel() {
    this.uploading = false;
  }

  onUploadComplete(upload: Upload) {
    this.outboundFaxImportsService.create(upload.signedId, OutboundFaxImportKind.standard).subscribe(
      () => {
        this.router.navigate(['/capture-admin/communications/import-outbound-faxes/history']);
        this.uploading = false;
      },
      (error: HttpErrorResponse) => {
        this.uploading = false;
        this.errors = error.error.errors;
      }
    );
  }

  onUploadError() {
    this.uploading = false;
  }

  onFormEnter($event) {
    $event.preventDefault();
  }

  public get hasError() {
    return !isEmpty(this.errors);
  }
}
