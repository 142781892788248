export enum UserSettings {
  clientPortalPrescriberTaskQueueSettings = 'clientPortalTaskQueueSettings', // original key name - keep for compat
  clientPortalConsultNoteTaskQueueSettings = 'clientPortalConsultNoteTaskQueueSettings',
  clientPortalReferralTaskQueueSettings = 'clientPortalReferralTaskPagedQueueSettings',
  clientPortalConsultNoteListSettings = 'clientPortalConsultNoteListSettings',
  captureAdminFaxQueueSettings = 'captureAdminFaxQueueSettings',
  captureAdminMyFaxQueueSettings = 'captureAdminMyFaxQueueSettings',
  captureAdminTaskListFilters = 'captureAdminTaskListFilters',
  captureAdminInboundCallSettings = 'captureAdminInboundCallSettings',
  captureAdminCaptureListFilters = 'captureAdminCaptureListFilters',
  captureAdminCaptureQueueSettings = 'captureAdminCaptureQueueSettings',
  captureAdminCaptureWorkQueueSettings = 'captureAdminCaptureWorkQueueSettings',
  captureAdminCaptureSearchQueueSettings = 'captureAdminCaptureSearchQueueSettings',
  captureAdminConsultNoteListFilters = 'captureAdminConsultNoteListFilters',
  captureAdminDirectConsultNoteListFilters = 'captureAdminDirectConsultNoteListFilters',
  captureAdminUserListFilters = 'captureAdminUserListFilters',
  userNavigationSettings = 'userNavigationSettings',
  consultNotesConsultNoteQueueSettings = 'consultNotesConsultNoteQueueSettings',
  captureAdminPatientSearchFilters = 'captureAdminPatientSearchFilters',
  captureAdminPatientFilters = 'captureAdminPatientFilters',
  captureAdminProviderQueueSettings = 'captureAdminProviderQueueSettings',
  captureAdminProviderCallLogQueueSettings = 'captureAdminProviderCallLogQueueSettings',
  captureAdminProviderOutboundFaxQueueSettings = 'captureAdminProviderOutboundFaxQueueSettings',
  captureAdminProviderMechanicalTurkReviewQueueSettings = 'captureAdminProviderMechanicalTurkReviewQueueSettings',
  captureAdminProviderOfficeStatusReviewQueueSettings = 'captureAdminProviderOfficeStatusReviewQueueSettings',
  captureAdminOfficeStatusReviewQueueSettings = 'captureAdminOfficeStatusReviewQueueSettings',
  captureAdminPrescriptionQueueSettings = 'captureAdminPrescriptionQueueSettings',
  captureAdminEncounterQueueSettings = 'captureAdminEncounterQueueSettings',
  captureAdminConsultNoteTaskQueueSettings = 'captureAdminConsultNoteTaskQueueSettings',
  captureAdminClientPrescriberTaskQueueSettings = 'captureAdminClientPrescriberTaskQueueSettings',
  captureAdminClientReferralTaskQueueSettings = 'captureAdminClientReferralTaskQueueSettings',
  captureAdminOfficeQueueSettings = 'captureAdminOfficeQueueSettings',
  captureAdminOfficeOutboundFaxQueueSettings = 'captureAdminOfficeOutboundFaxQueueSettings',
  captureAdminPatientInteractionLocationListSettings = 'captureAdminPatientInteractionLocationListSettings',
}
