import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ClientConsultNoteTask } from '../../../core/models/client-consult-note-task.model';
import { ReferralMatchVerificationsService } from '../../../core/services/referral-match-verifications.service';
import { Referral } from '../../../core/models/referral.model';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import {
  ClientReferralVerificationsService,
  PagedClientReferralVerifications,
} from '../../../core/services/client-referral-verifications.service';
import { ClientReferralVerification } from '../../../core/models/client-referral-verification.model';
import { hasReferralMatchSelection } from '../../../core/lib/verification-utils';

@Component({
  selector: 'app-client-consult-note-task-referrals',
  templateUrl: './client-consult-note-task-referrals.component.html',
  styleUrls: ['./client-consult-note-task-referrals.component.scss'],
  providers: [DatePipe],
})
export class ClientConsultNoteTaskReferralsComponent implements OnInit {
  loadingPatientReferrals = true;
  loadingClientReferralVerifications = true;

  patientReferrals: Referral[] = [];
  peerClientReferralVerificationRmvs: ReferralMatchVerification[] = [];
  clientReferralVerificationDates: string[] = [];

  @Input() task: ClientConsultNoteTask;

  constructor(
    private datePipe: DatePipe,
    private referralMatchVerificationsService: ReferralMatchVerificationsService,
    private clientReferralVerificationService: ClientReferralVerificationsService
  ) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    this.loadPatientReferrals();
    this.loadClientReferralVerifications();
  }

  private loadPatientReferrals() {
    this.loadingPatientReferrals = true;

    this.referralMatchVerificationsService.getList(this.task.patientPrescriberId).subscribe(rmvs => {
      this.loadingPatientReferrals = false;

      this.patientReferrals =
        rmvs.filter(rmv => hasReferralMatchSelection(rmv) && rmv.referral).map(rmv => rmv.referral);

      this.peerClientReferralVerificationRmvs =
        rmvs.filter(rmv => rmv.peerClientReferralVerification);
    });
  }

  private loadClientReferralVerifications() {
    this.loadingClientReferralVerifications = true;
    const filters = { patientPrescriberId: this.task.patientPrescriberId };

    this.clientReferralVerificationService.getList(filters).subscribe((results: PagedClientReferralVerifications) => {
      this.loadingClientReferralVerifications = false;
      this.clientReferralVerificationDates = results.clientReferralVerifications.map(
        (crv: ClientReferralVerification) => this.datePipe.transform(crv.referralDate, 'MM/dd/yyyy')
      );
    });
  }
}
