<div class="row">
  <div class="col-lg-8 offset-lg-4">
    <p *ngIf="mode === createPasswordModes.newUser">Hi {{ firstName }}, please create a password.</p>
    <p *ngIf="mode === createPasswordModes.passwordReset">Hi {{ firstName }}, please create a new password.</p>
    <p *ngIf="mode === createPasswordModes.forcePasswordChange">
      Hi {{ firstName }}, it’s been 90 days since you set your password. It’s time to choose a new one.
    </p>
  </div>
</div>
<div class="row">
  <div class="col-lg-4 offset-lg-4">
    <form (ngSubmit)="onSubmit(createPasswordForm)" #createPasswordForm="ngForm">
      <div class="form-group">
        <label for="password">Password</label>
        <input
          id="password"
          name="password"
          class="form-control"
          type="password"
          [(ngModel)]="formModel.password"
          [ngClass]="invalidCss('password', createPasswordForm)"
          required
          placeholder="Password"
        />
        <div class="invalid-feedback">Required.</div>
      </div>
      <div class="form-group">
        <label for="password">Re-enter Password</label>
        <input
          id="confirmPassword"
          name="confirmPassword"
          class="form-control"
          type="password"
          [(ngModel)]="formModel.confirmPassword"
          [ngClass]="invalidCss('confirmPassword', createPasswordForm)"
          required
          placeholder="Re-enter password"
        />
        <div class="invalid-feedback">Required.</div>
      </div>
      <button type="submit" class="btn btn-primary btn-block" [disabled]="submitting">Create Password</button>
      <p *ngIf="resetPasswordStatus === resetPasswordStatuses.ExpiredCode" class="text-danger my-2">
        Hi user {{ firstName }}, it looks like your registration link has expired. Please contact support
        {{ supportEmailAddress }} or by calling 1-781-214-3424.
      </p>
      <p *ngIf="resetPasswordStatus === resetPasswordStatuses.PasswordViolatesPolicy" class="text-danger my-2">
        The password entered does not meet the minimum requirements of ten characters or more, must contain one number
        and must contain at least one uppercase letter.
      </p>
      <p *ngIf="resetPasswordStatus === resetPasswordStatuses.PasswordsDoNotMatch" class="text-danger my-2">
        The passwords entered do not match.
      </p>
      <p *ngIf="resetPasswordStatus === resetPasswordStatuses.UnexpectedFailure" class="text-danger my-2">
        An unexpected error has occurred. Please try again or contact support.
      </p>
      <p *ngIf="resetPasswordStatus === resetPasswordStatuses.PasswordAlreadyUsed" class="text-danger my-2">
        Your new password can’t be any of your 10 previous passwords. Please enter a new password.
      </p>
    </form>
    <p class="mt-3 text-center">
      <strong>Need help?</strong> <br />
      Contact support at <strong><a href="mailto:{{ supportEmailAddress }}">{{ supportEmailAddress }}</a></strong> <br />
      or call us at <strong><a href="tel:1-781-214-3424">1-781-214-3424</a></strong
      >.
    </p>
  </div>
  <div class="col-lg-4">
    <div class="text-muted pt-3">
      Your password must meet the following requirements:
      <ul class="list-unstyled">
        <li>- Can’t be any of your 10 previous passwords</li>
        <li>- 10 characters or more</li>
        <li>- Contains at least one number</li>
        <li>- Contains at least one uppercase letter</li>
      </ul>
    </div>
  </div>
</div>
