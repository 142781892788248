import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

export const applyServerErrorsToFormGroup =
  (responseOrErrors: HttpErrorResponse | any, formGroup: FormGroup, errorMap = {}) => {
    let serverErrors: any;

    if (responseOrErrors.error?.errors) {
      serverErrors = responseOrErrors.error.errors;
    } else {
      serverErrors = responseOrErrors;
    }

    Object.keys(serverErrors).forEach(fieldName => {
      const mappedName = errorMap[fieldName] || fieldName;
      const childControl = formGroup.get(mappedName);

      if (childControl) {
        childControl.setErrors({ server: serverErrors[fieldName] });
      }
    });
  }

export const mapObjectToFormGroup = (formBuilder: FormBuilder, object: any, fields: string[]): FormGroup => {
  const fieldsToValues = fields.reduce((result, field) => ({ ...result, [field]: object ? object[field] : null }), {});
  return formBuilder.group(fieldsToValues);
}
