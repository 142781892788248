<app-ce-review-task-content [task]="clientReferralTask">
  <div class="col">
    <div><strong>CLIENT REFERRAL DATE</strong></div>
    {{ capture.clientReferralVerification?.referralDate | date: 'MM/dd/yyyy' }}
  </div>
  <div class="col">
    <ng-container *ngIf="clientReferralTask.mostRecentSubmission">
      <div><strong>TASK RESPONSE</strong></div>
      <div>{{ keyedChoiceOptions[clientReferralTask.mostRecentSubmission.choice].label }}</div>
      <ul class="py-1 pl-4 mb-0">
        <li *ngFor="let reason of clientReferralTask.mostRecentSubmission.choiceReasons; let i = index">
          <app-client-referral-task-option-reason
            [patient]="capture.patient"
            [prescriber]="capture.prescriber"
            [reason]="reason"
            [taskSubmission]="clientReferralTask.mostRecentSubmission" />
        </li>
      </ul>
    </ng-container>
  </div>
</app-ce-review-task-content>
