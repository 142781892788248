<div class="card mt-2 outbound-faxes">
  <div class="card-body">
    <div class="row">
      <div class="col-7"><h4 class="mb-2">Outbound Faxes</h4></div>
      <div class="col-5 text-right" *ngIf="!loading && selectedOutboundFax && outboundFaxes.length > 0">
        <a href="#/clear" class="btn btn-sm btn-danger" (click)="onClearClick($event)">Clear Selection</a>
      </div>
    </div>
    <div *ngIf="loading; else: outboundFaxList"><mat-spinner diameter="30" /></div>
  </div>
</div>
<ng-template #outboundFaxList>
  <p *ngIf="!loading && !selectedOutboundFax && outboundFaxes.length > 0" class="mt-2 p-2 bg-light">
    Please attribute this inbound fax to one of the outbound faxes below. <small><i>(optional)</i></small>
  </p>
  <div class="mt-2 outbound-faxes" *ngIf="!loading && outboundFaxes.length > 0; else: noOutboundFaxes">
    <table class="table table-sm  table-hover table-bordered">
      <thead>
        <tr>
          <th class="text-nowrap">DATE FAXED &#x25BC;</th>
          <th>PRESCRIBER</th>
          <th>OFFICE</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let outboundFax of outboundFaxes">
          <tr [ngClass]="{ matches: outboundFaxMatchesSelections(outboundFax) }">
            <td>
              <div class="form-check">
                <input
                  [id]="'outboundFax' + outboundFax.id"
                  name="selectedOutboundFaxId"
                  class="form-check-input"
                  type="radio"
                  [(ngModel)]="selectedOutboundFaxId"
                  (change)="onOutboundFaxChanges(outboundFax)"
                  [value]="outboundFax.id"
                />
                <label class="form-check-label ml-2" [id]="'outboundFax' + outboundFax.id">
                  {{ outboundFax.mostRecentAttemptAt | date: 'MM/dd/yyyy' }}
                  <div>
                    <small>
                      <strong>
                        <app-office-fax [office]="outboundFax.office" />
                      </strong>
                    </small>
                    <div *ngIf="outboundFax.status === outboundFaxStatuses.failed" class="text-danger">Fax Failed</div>
                  </div>
                </label>
              </div>
            </td>
            <td>
              <app-prescriber-name [prescriber]="outboundFax.prescriber" [linkToProvider]="true" />
            </td>
            <td>
              {{
                outboundFax.prescriberOfficesNames && outboundFax.prescriberOfficesNames.length > 0
                  ? outboundFax.prescriberOfficesNames.join(', ')
                  : null | blankValueDash
              }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
<ng-template #noOutboundFaxes>
  <p class="mt-2 p-2 bg-light" *ngIf="!selectedPatient">Please select a patient.</p>
  <p class="mt-2 p-2 bg-light" *ngIf="selectedPatient">No outbound faxes found.</p>
</ng-template>
