<h6 class="p-0 m-0 mt-2 font-weight-bold text-black-50">OTHER PATIENT DOCUMENTS</h6>
<ul class="list-group">
  <li
    *ngFor="let otherPatientAttachment of otherPatientAttachments"
    class="list-group-item list-group-item-action justify-content-between align-items-center"
    (click)="onPatientAttachmentClick(otherPatientAttachment)"
    [ngClass]="{ selected: selectedPatientAttachment && otherPatientAttachment.id === selectedPatientAttachment.id }"
  >
    <div class="selection">
      <strong>
        {{ otherPatientAttachment.markedAsInvalid ? 'INVALID' : 'VALID' }}
        - {{ otherPatientAttachment.fileName }}
        - {{ otherPatientAttachment.createdAt | date: 'MM/dd/yyyy' }}
      </strong>
    </div>
  </li>
</ul>
