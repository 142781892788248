<div class="modal-content">
  <form [formGroup]="formGroup" (submit)="onSubmit($event)">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add New Office</h4>
      <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Enter fax number to create a new office:
      </div>

      <input type="text" class="form-control mb-2" formControlName="faxNumber" />

      <button type="submit" class="btn btn-primary" [disabled]="formGroup.invalid">
        Create Office
      </button>

      <div *ngIf="errorMessage">
        <hr />

        <div class="alert alert-warning mt-2 mb-0">
          <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning mr-2" />
          {{ errorMessage }}
        </div>
      </div>

      <div *ngIf="existingOffice" class="existing-office">
        <hr />

        <div class="alert alert-warning">
          <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning mr-2" />
          An office already exists for this fax number
        </div>

        <div>
          <a href="javascript:;" (click)="onExistingOfficeClick($event)">
            {{ existingOffice.name }}
          </a>
        </div>
        <div>
          {{ existingOffice.address1 }}
        </div>
        <div>
          {{ existingOffice.address2 }}
        </div>
        <div *ngIf="existingOffice.city || existingOffice.state || existingOffice.zip">
          {{ existingOffice.city }}<span *ngIf="existingOffice.state">, {{ existingOffice.state }}</span>
          {{ existingOffice.zip }}
        </div>
        <div>
          <span *ngIf="existingOffice.phone">
          P: {{ existingOffice.phone | phoneNumber }}
          |
          </span>
          F: {{ existingOffice.fax | phoneNumber }}
        </div>
      </div>
    </div>
  </form>
</div>
