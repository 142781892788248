import { CaptureQueueCursorType } from '../../enums/capture-queue-cursor-type.enum';
import { CaptureStatus } from '../../enums/capture-status.enum';
import { CaptureStatusReason } from '../../enums/capture-status-reason.enum';
import { ClaimSource } from '../../enums/claim-source.enum';

export class CaptureQueueFilters {
  constructor(
    public statuses: CaptureStatus[] = [],
    public reasons: CaptureStatusReason[] = [],
    public clientIds: number[] = [],
    public clientStates: string[] = [],
    public sources: ClaimSource[] = [],
    public specialtyStore: boolean = false,
    public highValue: boolean = false,
    public activeClientsOnly: boolean = true,
    public expiringWithinDays: string | null = null,
    public manualFaxSent: boolean | null = null,
    public assignedUserIds: number[] = [],
    public claimIdentifier: string = '',
    public rxReferenceNumber: string = '',
    public prescriberNpi: string = '',
    public patientFirstName: string = '',
    public patientLastName: string = '',
    public patientDob: string = '',
    public prescriberFirstName: string = '',
    public prescriberLastName: string = '',
    public patientPrescriberUuid: string = '',
    public captureId: string = '',
    public ndc: string = '',
    public excludedPatientIds: number[] = [],
    public excludedPatientPrescriberIds: number[] = []
  ) {}
}

// NOTE this type is used by both the work queue page and the search page
export class CaptureQueueSettings {
  constructor(
    public filters: CaptureQueueFilters = new CaptureQueueFilters(),
    public cursorType: CaptureQueueCursorType = CaptureQueueCursorType.highestEstimatedValue
  ) {}
}
