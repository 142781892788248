export const referralSpecialtiesMap = {
  "Acute Care": "Emergency Medicine",
  "Addiction (Substance Use Disorder)": "Addiction Medicine",
  "Addiction Medicine": "Addiction Medicine",
  "Addiction Psychiatry": "Addiction Medicine",
  "Adolescent and Children Mental Health": "Psychiatry & Mental Health",
  "Adult Mental Health": "Psychiatry & Mental Health",
  "Adult Reconstructive Orthopaedic Surgery": "Orthopedic surgery",
  "Advanced Heart Failure": "Cardiology",
  "Advanced Heart Failure & Transplant Cardiology": "Cardiology",
  "Advanced Heart Failure and Transplant Cardiology": "Cardiology",
  "Advanced Practice Dental Therapist": "Dental",
  "Advanced Practice Midwife": "Obstetrics & Gynecology",
  "Aesthetics": "Plastic Surgery",
  "Allergy": "Allergy & Immunology",
  "Allergy & Immunology": "Allergy & Immunology",
  "Allergy & Sinus Surgery": "Allergy & Immunology",
  "Ambulatory Surgical": "General Surgery",
  "Anesthesiologist Assistant": "Anesthesiology",
  "Anesthesiology": "Anesthesiology",
  "Anticoagulation": "Cardiology",
  "Arthritis": "Rheumatology",
  "Assistant, Podiatric": "Podiatry",
  "Asthma": "Allergy & Immunology",
  "Athletic Trainer": "Sports Medicine",
  "Back and Neck Surgery": "Orthopedic surgery",
  "Bariatric Medicine": "Bariatric Medicine",
  "Bariatric Surgery": "Bariatric Medicine",
  "Behavioral Analyst": "Behavioral Health",
  "Behavioral Health": "Behavioral Health",
  "Behavioral Health, Neuropsychology": "Behavioral Health",
  "Behavioral Neurology & Neuropsychiatry": "Neurology",
  "Behavorial Health": "Behavioral Health",
  "Blood and Marrow Transplant": "Transplant Surgery",
  "Body Imaging": "Radiology",
  "Brain and Spine Surgery": "Neurological Surgery",
  "Breast Health": "Obstetrics & Gynecology",
  "Breast Oncology": "Oncology",
  "Breast Surgery": "General Surgery",
  "Burn Care": "Wound Care",
  "Cardiac Electrophysiologist": "Cardiology",
  "Cardiac Electrophysiology": "Cardiology",
  "Cardiac Rehabilitation": "Cardiology",
  "Cardiac Surgery": "Cardiology",
  "Cardiology": "Cardiology",
  "Cardiothoracic Surgery": "Thoracic & Cardiac Surgery",
  "Cardiothoracic Vascular Surgery": "Thoracic & Cardiac Surgery",
  "Cardiovascular": "Cardiology",
  "Cardiovascular Disease": "Cardiology",
  "Cardiovascular Surgery": "Cardiology",
  "Cataract Surgery": "Ophthalmology",
  "Certified Nurse Midwife": "Obstetrics & Gynecology",
  "Child & Adolescent Psychiatry": "Psychiatry & Mental Health",
  "Clinical & Laboratory Dermatological Immunology": "Dermatology",
  "Clinical & Laboratory Immunology": "Allergy & Immunology",
  "Clinical Biochemical Genetics": "Genetics",
  "Clinical Cardiac Electrophysiology": "Cardiology",
  "Clinical Genetics (M.D.)": "Genetics",
  "Clinical Molecular Genetics": "Genetics",
  "Clinical Neurophysiology": "Neurology",
  "Clinical Neuropsychologist": "Neurology",
  "Clinical Pharmacy Specialist": "Pharmacist",
  "Cognitive & Behavioral": "Behavioral Health",
  "Colon & Rectal Surgery": "Colon & Rectal Surgery",
  "Colorectal Surgery": "Colon & Rectal Surgery",
  "Comfort and Palliative Care": "Palliative Care",
  "Community Based Residential Treatment Facility, Mental Illness": "Psychiatry & Mental Health",
  "Community/Behavioral Health": "Behavioral Health",
  "Community/Retail Pharmacy": "Pharmacist",
  "Corneal and Contact Management": "Ophthalmology",
  "Counseling": "Behavioral Health",
  "Counselor": "Behavioral Health",
  "Critical Access": "Emergency Medicine",
  "Critical Care": "Emergency Medicine",
  "Critical Care Medicine": "Emergency Medicine",
  "Cystic Fibrosis": "Pulmonology",
  "Dental Assistant": "Dental",
  "Dental Hygienist": "Dental",
  "Dental Laboratory Technician": "Dental",
  "Dental Public Health": "Dental",
  "Dentist": "Dental",
  "Dentist Anesthesiologist": "Dental",
  "Dentistry": "Dental",
  "Dermatologic Surgery": "Dermatology",
  "Dermatological Immunology": "Dermatology",
  "Dermatology": "Dermatology",
  "Dermatology, Wound Care": "Dermatology",
  "Dermatopathology": "Dermatology",
  "Developmental - Behavioral Pediatrics": "Behavioral Health",
  "Developmental ?\u00ac\u00d8???\u00ac\u03a9 Behavioral Pediatrics": "Behavioral Health",
  "Developmental \u221a\u00d8?\u00ac\u03a9 Behavioral Pediatrics": "Behavioral Health",
  "Developmental \u221a\u00d8\u00ac\u00f8\u00ac\u03a9 Behavioral Pediatrics": "Behavioral Health",
  "Developmental \u00d4\u00f8\u03a9 Behavioral Pediatrics": "Behavioral Health",
  "Diabetes Management": "Endocrinology, Diabetes & Metabolism",
  "Diagnostic & Interventional Radiology": "Radiology",
  "Diagnostic Neuroimaging": "Radiology",
  "Diagnostic Radiology": "Radiology",
  "Diagnostic Ultrasound": "Radiology",
  "Doula": "Obstetrics & Gynecology",
  "Ears,Nose and Throat": "Otolaryngology",
  "Echocardiography": "Cardiology",
  "Electrodiagnostic Medicine": "Cardiology",
  "Electrophysiology": "Cardiology",
  "Electrophysiology,Clinical": "Cardiology",
  "Emergency": "Emergency Medicine",
  "Emergency Care": "Emergency Medicine",
  "Emergency Medical Services": "Emergency Medicine",
  "Emergency Medical Technician, Basic": "Emergency Medicine",
  "Emergency Medical Technician, Paramedic": "Emergency Medicine",
  "Emergency Medicine": "Emergency Medicine",
  "Emergency Medicine Specialist": "Emergency Medicine",
  "Endocine": "Endocrinology, Diabetes & Metabolism",
  "Endocrine Surgery": "Endocrinology, Diabetes & Metabolism",
  "Endocrinology": "Endocrinology, Diabetes & Metabolism",
  "endocrinology": "Endocrinology, Diabetes & Metabolism",
  "Endocrinology Diabetes & Metabolism": "Endocrinology, Diabetes & Metabolism",
  "Endocrinology, Diabetes & Metabolism": "Endocrinology, Diabetes & Metabolism",
  "Endocrinology, Diabetes, & Metabolism": "Endocrinology, Diabetes & Metabolism",
  "Endocrinology,Diabetes &  Metabolism": "Endocrinology, Diabetes & Metabolism",
  "Endodontics": "Dental",
  "Endovascular": "Vascular Surgery",
  "Endovascular Surgery": "Vascular Surgery",
  "ENT": "Otolaryngology",
  "Epidemiology": "Infectious Disease",
  "Epilepsy": "Neurology",
  "Epilepsy Unit": "Neurology",
  "Facial Plastic Surgery": "Plastic Surgery",
  "Family Nurse Practitioner, Emergency Medicine": "Emergency Medicine",
  "Female Pelvic Medicine and Reconstructive Surgery": "Obstetrics & Gynecology",
  "Fertility": "Reproductive Endocrinology & Infertility",
  "Fertility & Reproductive Medicine": "Reproductive Endocrinology & Infertility",
  "Foot & Ankle Surgery": "Orthopedic surgery",
  "Foot and Ankle Surgery": "Orthopedic surgery",
  "Foot Surgery": "Orthopedic surgery",
  "Forensic Psychiatry": "Psychiatry & Mental Health",
  "Gastroenterology": "Gastroenterology",
  "Gastroenterology & Hepatology": "Gastroenterology",
  "General Orthopedics": "Orthopedic surgery",
  "General Surgery": "General Surgery",
  "Geneticist, Medical (PhD)": "Genetics",
  "Genetics": "Genetics",
  "Genetics & Metabolism": "Genetics",
  "Genitourinary Oncology": "Oncology",
  "Geriatric Psychiatry": "Psychiatry & Mental Health",
  "Glaucoma": "Ophthalmology",
  "Gynecologic Oncology": "Oncology",
  "Gynecology": "Obstetrics & Gynecology",
  "Hand Surgery": "Orthopedic surgery",
  "Headache Medicine": "Neurology",
  "Hematology": "Hematology",
  "Hematology & Oncology": "Hematology & Oncology",
  "Hemodialysis": "Hematology",
  "Hepatobiliary and Pancreatic Surgery": "General Surgery",
  "Hepatobiliary Surgery": "General Surgery",
  "Hepatology": "Hepatology",
  "Hip & Knee Surgery": "Orthopedic surgery",
  "HIV/AIDs": "Infectious Disease",
  "Home Health": "Home Health",
  "Home Health Aide": "Home Health",
  "Hormone Replacement Therapy": "Obstetrics & Gynecology",
  "Hormone Therapy": "Obstetrics & Gynecology",
  "Hospice": "Palliative Care",
  "Hospice & Palliative Care": "Palliative Care",
  "Hospice and Palliative Medicine": "Palliative Care",
  "Hospice Care, Community Based": "Palliative Care",
  "Hospital Medicine": "Hospitalist",
  "Hospitalist": "Hospitalist",
  "Hyperbaric Medicine & Wound Care": "Wound Care",
  "Hypertension Specialist": "Cardiology",
  "Immunologic Disease": "Allergy & Immunology",
  "Immunology": "Allergy & Immunology",
  "In Home Supportive Care": "Home Health",
  "Infectious Disease": "Infectious Disease",
  "Inflammatory Bowel Disease": "Gastroenterology",
  "Infusion Therapy": "Oncology",
  "Interventional Cardiology": "Cardiology",
  "Interventional Gastroenterology": "Gastroenterology",
  "Interventional Pain Medicine": "Pain Medicine",
  "Interventional Pulmonology": "Pulmonology",
  "Invasive-Interventional Cardiology": "Cardiology",
  "Knee Surgery": "Orthopedic surgery",
  "Lactation Consultant": "Obstetrics & Gynecology",
  "Laparoscopic Surgery": "General Surgery",
  "Laryngology Fellow": "Otolaryngology",
  "Liver Cancer": "Oncology",
  "Low Vision Rehabilitation": "Ophthalmology",
  "Magnetic Resonance Imaging (MRI)": "Radiology",
  "Male Hormone therapy": "Endocrinology, Diabetes & Metabolism",
  "Marriage & Family Therapist": "Psychiatry & Mental Health",
  "Marriage & Family Therapy": "Psychiatry & Mental Health",
  "Maternal & Fetal Medicine": "Obstetrics & Gynecology",
  "Maternal Fetal Medicine": "Obstetrics & Gynecology",
  "Maternal Newborn": "Obstetrics & Gynecology",
  "Mechanotherapist": "Physical Therapy & Rehabilitation",
  "Medical Genetics": "Genetics",
  "Medical Genetics, Ph.D. Medical Genetics": "Genetics",
  "Medical Oncology": "Oncology",
  "Medical Oncology and Hematology": "Hematology & Oncology",
  "Medical-Surgical": "General Surgery",
  "Mental Health": "Psychiatry & Mental Health",
  "Mental Health (Including Community Mental Health Center)": "Psychiatry & Mental Health",
  "Mental Retardation & Developmental Disabilities": "Neurology",
  "Micrographic Dermatologic Surgery": "Dermatology",
  "Midwife": "Obstetrics & Gynecology",
  "Midwife, Lay": "Obstetrics & Gynecology",
  "Mohs Surgery": "Dermatology",
  "MOHS-Micrographic Surgery": "Dermatology",
  "Neonatal, Critical Care": "Emergency Medicine",
  "Nephrology": "Nephrology",
  "Neurodevelopmental Disabilities": "Neurology",
  "Neurodiagnostics": "Neurology",
  "Neuroendocrinology": "Neurology",
  "Neuroimmunology": "Neurology",
  "Neurological Care": "Neurology",
  "Neurological Surgery": "Neurological Surgery",
  "Neurology": "Neurology",
  "Neurology with Special Qualifications in Child Neurology": "Neurology",
  "Neuromuscular Medicine": "Neurology",
  "Neuromusculoskeletal Medicine": "Neurology",
  "Neuromusculoskeletal Medicine & OMM": "Neurology",
  "Neuromusculoskeletal Medicine, Sports Medicine": "Sports Medicine",
  "Neuro-Oncology": "Oncology",
  "Neuro-Ophthalmology": "Ophthalmology",
  "Neurophysiology": "Neurology",
  "Neurophysiology, Neurodevelopmental Disabilities": "Neurology",
  "Neuropsychiatry": "Psychiatry & Mental Health",
  "Neuropsychologist": "Psychiatry & Mental Health",
  "Neuropsychology": "Psychiatry & Mental Health",
  "Neuroradiology": "Neurology",
  "Neurorehabilitation": "Neurology",
  "Neuroscience": "Neurology",
  "Neurosurgery": "Neurological Surgery",
  "Neurotology": "Neurology",
  "Nuclear Cardiology": "Cardiology",
  "Nuclear Imaging & Therapy": "Radiology",
  "Nuclear Medicine": "Radiology",
  "Nurse Anesthetist, Certified Registered": "Anesthesiology",
  "Nursing Home and Rehabilitation Services": "Home Health",
  "Obstetric, High-Risk": "Obstetrics & Gynecology",
  "Obstetric, Inpatient": "Obstetrics & Gynecology",
  "Obstetrics": "Obstetrics & Gynecology",
  "Obstetrics & Gynecology": "Obstetrics & Gynecology",
  "Obstetrics and Gynecology": "Obstetrics & Gynecology",
  "Oculofacial Plastic Surgery": "Plastic Surgery",
  "Oncology": "Oncology",
  "Ophthalmic Plastic & Reconstructive Surgery": "Plastic Surgery",
  "Ophthalmology": "Ophthalmology",
  "Ophthalmology Glaucoma Specialist": "Ophthalmology",
  "Optometrist": "Optometry",
  "Optometry": "Optometry",
  "Oral & Maxillofacial Surgery": "Oral & Maxillofacial Surgery",
  "Oral and Maxillofacial Surgery": "Oral & Maxillofacial Surgery",
  "Oral Medicinist": "Dental",
  "Organ Transplant": "Transplant Surgery",
  "Orthodontics and Dentofacial Orthopedics": "Dental",
  "Orthopaedic Surgery": "Orthopedic surgery",
  "Orthopaedic Surgery of the Spine": "Orthopedic surgery",
  "Orthopaedic Trauma": "Orthopedic surgery",
  "Orthopaedics": "Orthopedic surgery",
  "Orthopedic": "Orthopedic surgery",
  "Orthopedic FNP": "Orthopedic surgery",
  "Orthopedic Surgery": "Orthopedic surgery",
  "Orthopedic Surgery, Pain Medicine": "Orthopedic surgery",
  "Orthopedics": "Orthopedic surgery",
  "Orthotist": "Orthopedic surgery",
  "Otolaryngic Allergy": "Allergy & Immunology",
  "Otolaryngology": "Otolaryngology",
  "Otolaryngology/Facial Plastic Surgery": "Plastic Surgery",
  "Otology": "Otology neurotology",
  "Otology & Neurotology": "Otology neurotology",
  "Otology & Neurotology, Facial Plastic Surgery": "Otology neurotology",
  "Otoneurology": "Otology neurotology",
  "Pain": "Pain Medicine",
  "Pain Management": "Pain Medicine",
  "Pain Medicine": "Pain Medicine",
  "Pediatric Allergy and Immunology": "Allergy & Immunology",
  "Pediatric Allergy/Immunology": "Allergy & Immunology",
  "Pediatric Anesthesiology": "Anesthesiology",
  "Pediatric Cardiology": "Cardiology",
  "Pediatric Critical Care Medicine": "Emergency Medicine",
  "Pediatric Dentistry": "Dental",
  "Pediatric Dermatology": "Dermatology",
  "Pediatric Dermatology Resident": "Dermatology",
  "Pediatric Emergency Medicine": "Emergency Medicine",
  "Pediatric Endocrinology": "Endocrinology, Diabetes & Metabolism",
  "PEDIATRIC ENDOCRONOLOGIST": "Endocrinology, Diabetes & Metabolism",
  "Pediatric Endocronology": "Endocrinology, Diabetes & Metabolism",
  "Pediatric Gastroenterology": "Gastroenterology",
  "Pediatric Hematology-Oncology": "Hematology & Oncology",
  "PEDIATRIC HEPATOLOGY": "Hepatology",
  "Pediatric Infectious Diseases": "Infectious Disease",
  "PEDIATRIC NEPHROLOGY": "Nephrology",
  "Pediatric Neurology": "Neurology",
  "Pediatric Oncology": "Oncology",
  "Pediatric Ophthalmology": "Ophthalmology",
  "Pediatric Orthopaedic Surgery": "Orthopedic surgery",
  "Pediatric Otolaryngology": "Otolaryngology",
  "Pediatric Otorhinolaryngology": "Otolaryngology",
  "Pediatric Psychiatry": "Psychiatry & Mental Health",
  "Pediatric Pulmonology": "Pulmonology",
  "Pediatric Radiology": "Radiology",
  "Pediatric Rehabilitation Medicine": "Physical Therapy & Rehabilitation",
  "Pediatric Rheumatology": "Rheumatology",
  "Pediatric Surgery": "General Surgery",
  "Pediatric Transplant Hepatology": "Transplant Surgery",
  "Pediatric Urology": "Urology",
  "Pediatrics, Critical Care": "Emergency Medicine",
  "Perinatal": "Obstetrics & Gynecology",
  "Periodontics": "Dental",
  "Personal Emergency Response Attendant": "Emergency Medicine",
  "Pharmacist": "Pharmacist",
  "Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist": "Pharmacist",
  "Pharmacotherapy": "Pharmacist",
  "Pharmacy": "Pharmacist",
  "Pharmacy Technician": "Pharmacist",
  "Phlebology": "Vascular Surgery",
  "Physiatrist": "Physical Therapy & Rehabilitation",
  "Physiatry": "Physical Therapy & Rehabilitation",
  "Physical Medicine": "Physical Therapy & Rehabilitation",
  "Physical Medicine & Rehabilitation": "Physical Therapy & Rehabilitation",
  "Physical Medicine and Rehabilitation": "Physical Therapy & Rehabilitation",
  "Physical Therapist": "Physical Therapy & Rehabilitation",
  "Physical Therapy": "Physical Therapy & Rehabilitation",
  "Physical Therapy Assistant": "Physical Therapy & Rehabilitation",
  "Plastic & Reconstructive Surgery": "Plastic Surgery",
  "Plastic and Reconstructive Surgery": "Plastic Surgery",
  "Plastic Surgery": "Plastic Surgery",
  "Plastic Surgery within the Head & Neck": "Plastic Surgery",
  "Plastic Surgery Within the Head and Neck": "Plastic Surgery",
  "PMHNP": "Psychiatry & Mental Health",
  "Podiatric": "Podiatry",
  "Podiatrist": "Podiatry",
  "Podiatry": "Podiatry",
  "Primary Podiatric Medicine": "Podiatry",
  "Procedural Dermatology": "Dermatology",
  "Prosthodontics": "Dental",
  "Psych/Mental Health": "Psychiatry & Mental Health",
  "Psych/Mental Health, Adult": "Psychiatry & Mental Health",
  "Psych/Mental Health, Child & Adolescent": "Psychiatry & Mental Health",
  "Psych/Mental Health, Child & Family": "Psychiatry & Mental Health",
  "Psych/Mental Health, Community": "Psychiatry & Mental Health",
  "Psych/Mental Health, Geropsychiatric": "Psychiatry & Mental Health",
  "Psychiatric": "Psychiatry & Mental Health",
  "Psychiatric Hospital": "Psychiatry & Mental Health",
  "Psychiatric Nurse Practitioner": "Psychiatry & Mental Health",
  "Psychiatric/Mental Health": "Psychiatry & Mental Health",
  "Psychiatry": "Psychiatry & Mental Health",
  "Psychiatry & Neurology": "Psychiatry & Mental Health",
  "Psychiatry &amp; Neurology": "Psychiatry & Mental Health",
  "Psychoanalyst": "Psychiatry & Mental Health",
  "Psychologist": "Psychiatry & Mental Health",
  "Psychosomatic Medicine": "Psychiatry & Mental Health",
  "Psychotherapy": "Psychiatry & Mental Health",
  "Pulmonary Disease": "Pulmonology",
  "Pulmonary Hypertension": "Pulmonology",
  "Pulmonary Medicine": "Pulmonology",
  "Pulmonology": "Pulmonology",
  "Radiation Oncology": "Radiology",
  "Radiologic Technologist": "Radiology",
  "Radiology": "Radiology",
  "Rehabilitation": "Physical Therapy & Rehabilitation",
  "Rehabilitation Hospital": "Physical Therapy & Rehabilitation",
  "Rehabilitation Practitioner": "Physical Therapy & Rehabilitation",
  "Reproductive Endocrinology": "Reproductive Endocrinology & Infertility",
  "Reproductive Endocrinology/Infertility": "Reproductive Endocrinology & Infertility",
  "Reproductive Hormone Therapy": "Reproductive Endocrinology & Infertility",
  "Retina": "Ophthalmology",
  "Rheumatology": "Rheumatology",
  "Sexual and Reproductive Health": "Obstetrics & Gynecology",
  "Sexual Health": "Obstetrics & Gynecology",
  "Sleep Medicine": "Sleep Medicine",
  "Sleep Medicine, Allergy & Immunology": "Allergy & Immunology",
  "Sleep Specialist, PhD": "Sleep Medicine",
  "Spinal Cord Injury Medicine": "Neurological Surgery",
  "Spinal Cord Medicine": "Neurological Surgery",
  "Spine Surgery": "Neurological Surgery",
  "Sports Medicine": "Sports Medicine",
  "Sports Medicine, Physical Therapy": "Sports Medicine",
  "Stem Cell Transplant": "Transplant Surgery",
  "Substance Abuse Rehabilitation Facility": "Addiction Medicine",
  "Surgery": "General Surgery",
  "Surgery of the Hand": "Orthopedic surgery",
  "Surgery Oncology": "Oncology",
  "Surgery Transplant": "Transplant Surgery",
  "Surgery Trauma": "General Surgery",
  "Surgical": "General Surgery",
  "Surgical Assistant": "General Surgery",
  "Surgical Critical Care": "General Surgery",
  "Surgical Critical Care-Trauma": "General Surgery",
  "Surgical Oncology": "Oncology",
  "Surgical Technologist": "General Surgery",
  "Therapeutic Radiology": "Radiology",
  "Thoracic Surgery": "Thoracic & Cardiac Surgery",
  "Thoracic Surgery (Cardiothoracic Vascular Surgery)": "Thoracic & Cardiac Surgery",
  "Transplant Hepatology": "Transplant Surgery",
  "transplant nephrology": "Transplant Surgery",
  "Transplant Surgery": "Transplant Surgery",
  "Transplantation Immunology": "Allergy & Immunology",
  "Transplantation Surgery": "Transplant Surgery",
  "Trauma": "Emergency Medicine",
  "Trauma and Acute Care Surgery": "General Surgery",
  "Trauma Surgery": "General Surgery",
  "Ultrasound": "Radiology",
  "Urogynecology": "Urogynecology",
  "Urologic Oncology": "Oncology",
  "Urologic Surgery": "Urology",
  "Urology": "Urology",
  "Vascular & Endovascular Medicine": "Vascular Surgery",
  "Vascular & Interventional Radiology": "Radiology",
  "Vascular Interventional Radiology": "Radiology",
  "Vascular Medicine": "Vascular Surgery",
  "Vascular Neurology": "Neurology",
  "Vascular Surgery": "Vascular Surgery",
  "Vein Specialist": "Vascular Surgery",
  "Vision Therapy": "Ophthalmology",
  "Vitreoretinal": "Ophthalmology",
  "Weight Management": "Bariatric Medicine",
  "Weight Management/ Bariatric Surgery": "Bariatric Medicine",
  "Women": "Obstetrics & Gynecology",
  "Women's Health": "Obstetrics & Gynecology",
  "Women's Health Care, Ambulatory": "Obstetrics & Gynecology",
  "Women's Health, Urology": "Urology",
  "Wound Care": "Wound Care"
}

export const referralSpecialties =
  [...new Set(Object.values(referralSpecialtiesMap))].sort();
