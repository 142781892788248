import { keyBy } from 'lodash-es';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { Referral } from '../../../core/models/referral.model';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PatientService, ReferralsResult } from '../../../core/services/patient.service';
import { ReferralMatchVerificationsService } from '../../../core/services/referral-match-verifications.service';
import { hasReferralMatchSelection, isConsultNoteVerificationFrozen, resolveReferralMatchVerification } from '../../../core/lib/verification-utils';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';
import { VerificationType } from '../../../core/enums/verification-type.enum';
import { ReferralMatchOption } from 'app/core/models/referral-match-option.model';
import {
  ReferralMatchVerificationEvent,
  ReferralMatchVerificationEventType
} from 'app/core/models/referral-match-verification-event.model';

@Component({
  selector: 'app-edit-referral-match-verifications-standard',
  templateUrl: './edit-referral-match-verifications-standard.component.html',
  styleUrls: ['./edit-referral-match-verifications-standard.component.scss'],
})
export class EditReferralMatchVerificationsStandardComponent implements OnInit {
  _patientPrescriber: PatientPrescriber;

  @Input() patientAttachment: PatientAttachment;
  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  loadingReferrals = false;
  eligibleReferrals: Referral[] = [];
  ineligibleReferrals: Referral[] = [];
  showIneligibleReferrals = false;
  editReferralMatchOptions: ReferralMatchOption[] = [];

  constructor(
    private authService: AuthenticationService,
    private patientService: PatientService,
    private referralMatchVerificationsService: ReferralMatchVerificationsService
  ) { }

  ngOnInit() {
    this.loadReferralMatchVerifications();
  }

  get patientPrescriber(): PatientPrescriber {
    return this._patientPrescriber;
  }

  @Input()
  set patientPrescriber(pp: PatientPrescriber) {
    this._patientPrescriber = pp;
    this.initEditReferralMatchOptions();
  }

  onReferralVerificationChange(referralMatchVerification: ReferralMatchVerification) {
    this.createOrUpdateReferralMatchVerification(referralMatchVerification);
  }

  private createOrUpdateReferralMatchVerification(referralMatchVerification: ReferralMatchVerification) {
    this.referralMatchVerificationsService.
      createOrUpdate(this.patientPrescriber.id, referralMatchVerification).
      subscribe((rmv: ReferralMatchVerification) => {
        const changeEvent =
          new ReferralMatchVerificationEvent(ReferralMatchVerificationEventType.changed, rmv);

        this.verificationChange.emit(changeEvent);
      });
  }

  private loadReferralMatchVerifications() {
    this.loadingReferrals = true;
    this.patientService.getReferrals(this.patientAttachment.patient.id).subscribe((result: ReferralsResult) => {
      if (result.meta.eligibilityBasedOnLocation) {
        this.showIneligibleReferrals = true;

        this.eligibleReferrals =
          result.referrals.filter(referral => referral.locationEligibleDuringServiceDate);

        this.ineligibleReferrals =
          result.referrals.filter(referral => !referral.locationEligibleDuringServiceDate);
      } else {
        this.showIneligibleReferrals = false;
        this.eligibleReferrals = result.referrals;
      }

      this.initEditReferralMatchOptions();
      this.loadingReferrals = false;
    });
  }

  private initEditReferralMatchOptions() {
    const filteredRmvs = this.patientPrescriber.referralMatchVerifications.filter(rmv => rmv.referral);
    const keyedReferralVerifications = keyBy(filteredRmvs, (rmv: ReferralMatchVerification) => rmv.referral.id);

    this.editReferralMatchOptions = this.eligibleReferrals.map(referral => {
      const resolvedVerification = resolveReferralMatchVerification(referral, keyedReferralVerifications);

      return new ReferralMatchOption(
        resolvedVerification,
        referral,
        hasReferralMatchSelection(resolvedVerification),
        isConsultNoteVerificationFrozen(
          this.authService,
          this.patientAttachment,
          VerificationType.referralMatch,
          resolvedVerification
        )
      );
    });
  }
}
