<div *ngIf="!refreshing; else: spinner" id="consultNoteDetailPage">
  <app-consult-note-title [patientAttachment]="excludedPatientAttachment" />
  <div class="row other-consult-note">
    <div class="col-5">
      <div class="border rounded container-fluid p-0 mb-1">
        <div class="mx-1 row small-gutters">
          <div class="col-auto">
            <strong>CLIENT</strong>
            <div>{{ excludedPatientAttachment.client.humanizedName }}</div>
          </div>
          <div class="col-auto">
            <strong>CE-PATIENT</strong>
            <app-patient-name [patient]="excludedPatientAttachment.patient" />
          </div>
        </div>
      </div>
      <app-other-consult-notes-selector
        *ngIf="selectedPatientAttachment; else: noOthers"
        [selectedPatientAttachment]="selectedPatientAttachment"
        [otherPatientAttachments]="otherPatientAttachments"
        (selectionChange)="onSelectionChange($event)"
      />
    </div>
    <div *ngIf="selectedPatientAttachment" class="col-7" >
      <h6 class="p-0 m-0 font-weight-bold text-black-50">CONSULT NOTE INFORMATION</h6>
      <div class="border rounded container-fluid p-0 mb-2 bg-light">
        <div class="mx-1 row small-gutters">
          <div class="col-auto">
            <strong>ID</strong>
            <div>{{ selectedPatientAttachment.id }}</div>
          </div>
          <div class="col-auto">
            <strong>STATUS</strong>
            <div>{{ statusOptions[selectedPatientAttachment.status]?.display }}</div>
          </div>
          <div class="col-auto">
            <strong>OFFICE</strong>
            <app-consult-note-office [faxedProviderOffice]="selectedPatientAttachment.faxedProviderOffice" />
          </div>
        </div>
      </div>
      <h6 class="p-0 m-0 mt-2 font-weight-bold text-black-50">FAX INFORMATION</h6>
      <div class="border rounded container-fluid p-0 mb-2 bg-light">
        <div class="mx-1 row small-gutters">
          <div class="col-auto">
            <strong>STATUS</strong>
            <div>{{ selectedPatientAttachment.inboundFax.status | faxStatus }}</div>
          </div>
          <div class="col-auto">
            <strong>RECEIVED AT</strong>
            <div>{{ selectedPatientAttachment.inboundFax.receivedAt | date: 'M/d/yyyy h:mm a' }}</div>
          </div>
          <div class="col-auto">
            <strong>PROCESSED AT</strong>
            <div>{{ selectedPatientAttachment.inboundFax.processedAt | date: 'MM/dd/yyyy  hh:mm a' | blankValueDash }}</div>
          </div>
          <div class="col-auto">
            <strong>PATIENT</strong>
            <ng-container *ngIf="selectedPatientAttachment.inboundFax.patient; else: noData">
              <div>{{ selectedPatientAttachment.inboundFax.patient.fullName }}</div>
              <div class="small text-nowrap">
                <strong>MRN:</strong> {{ selectedPatientAttachment.inboundFax.patient.mrn }}
              </div>
            </ng-container>
          </div>
          <div class="col-auto">
            <strong>PRESCRIBER</strong>
            <app-prescriber-name
              [linkToProvider]="true"
              [showSpecialtiesDetails]="true"
              [prescriber]="selectedPatientAttachment.inboundFax.prescriber" />
          </div>
        </div>
      </div>
      <app-pdf-file-viewer *ngIf="!usePdfKitViewer"
        [pdfFile]="selectedPatientAttachment"
        [pdfFileType]="pdfFileType"
      >
        <a
          routerLink="/capture-admin/consult-notes/{{selectedPatientAttachment.id}}"
          target="_blank"
          class="btn btn-sm btn-secondary text-white">
          Open Consult Note Page
        </a>
      </app-pdf-file-viewer>
      <div class="row" *ngIf="selectedPatientAttachment.id">
        <div class="col-9"></div>
        <div class="col-3 text-right pb-2">
          <a *ngIf="usePdfKitViewer"
            routerLink="/capture-admin/consult-notes/{{selectedPatientAttachment.id}}"
            target="_blank"
            class="btn btn-sm btn-secondary text-white">
            Open Consult Note Page
          </a>
        </div>
      </div>

      <app-pdfkit-file-viewer *ngIf="usePdfKitViewer && selectedPatientAttachment.url"
        [pdfFile]="selectedPatientAttachment"
        [pdfFileType]="pdfFileType"
        class="card"
        height="80vh"
      />
    </div>
  </div>
</div>
<ng-template #spinner>
  <mat-spinner />
</ng-template>
<ng-template #noOthers>
  <p>No other consult notes found.</p>
</ng-template>
<ng-template #noData>
  <div><app-blank-value-dash /></div>
</ng-template>
