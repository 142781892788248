<div class="card">
  <div class="card-body">
    <form>
      <div class="row small-gutters search-fields">
        <div class="col-md-2">
          <div class="form-group">
            <label><strong>Status:</strong></label>
            <ng-select
              class="w-100"
              bindValue="value"
              bindLabel="display"
              name="statuses"
              placeholder="Filter by Status"
              [multiple]="true"
              [items]="statusFilterOptions"
              (remove)="onStatusFilterChange()"
              (change)="onStatusFilterChange()"
              [(ngModel)]="filters.statuses"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label><strong>Status Reasons:</strong></label>
            <ng-select
              class="w-100"
              bindValue="value"
              bindLabel="display"
              name="reasons"
              placeholder="Filter by Reasons"
              [multiple]="true"
              [items]="statusReasonFilterOptions"
              (remove)="onStatusReasonFilterChange()"
              (change)="onStatusReasonFilterChange()"
              [(ngModel)]="filters.reasons"
              [disabled]="statusReasonFilterOptions.length === 0"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label><strong>Sort By:</strong></label>
            <ng-select
              class="w-100"
              name="cursorType"
              bindValue="value"
              bindLabel="display"
              [multiple]="false"
              [items]="cursorTypeOptions"
              [clearable]="false"
              [searchable]="false"
              (change)="onCursorTypeChange()"
              [(ngModel)]="cursorType"
            />
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group high-value">
            <label><strong>High Value:</strong></label>
            <mat-slide-toggle
              name="highValue"
              [color]="'primary'"
              [(ngModel)]="filters.highValue"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group active">
            <label><strong>Active:</strong></label>
            <mat-slide-toggle
              name="active"
              [color]="'primary'"
              [(ngModel)]="filters.activeClientsOnly"
              [disableRipple]="true"
              (change)="onFilterChange()" />
          </div>
        </div>

        <div class="form-group col-auto">
          <div class="manual-fax-sent">
            <label class="manual-fax-sent-label mr-3"><strong>Manual Fax Sent:</strong></label>
            <div class="manual-fax-sent-options">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="manualFaxSent"
                  id="manualFaxSentYes"
                  (change)="onFilterChange()"
                  [value]="true"
                  [(ngModel)]="filters.manualFaxSent"
                />
                <label class="form-check-label" for="manualFaxSentYes">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="manualFaxSent"
                  id="manualFaxSentNo"
                  (change)="onFilterChange()"
                  [value]="false"
                  [(ngModel)]="filters.manualFaxSent"
                />
                <label class="form-check-label" for="manualFaxSentNo">No</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="manualFaxSent"
                  id="manualFaxSentAll"
                  (change)="onFilterChange()"
                  [value]="null"
                  [(ngModel)]="filters.manualFaxSent"
                />
                <label class="form-check-label" for="manualFaxSentAll">All</label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
<div class="row mt-1">
  <div class="col text-right">
    <app-provider-captures-search-summary [count]="count" (resetFiltersClick)="onResetFilters()"/>
  </div>
</div>