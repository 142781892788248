<div class="d-flex align-middle">
  <div class="title">
    <h5>{{ title }}</h5>
  </div>
  <div class="file">
    <p class="text-right">
      <small>{{ patientAttachment.fileName }}</small>
    </p>
  </div>
</div>
