<div class="form-group col-auto">
  <label>{{ label }}:</label>

  <div class="input-group mx-1">
    <input
      type="date"
      [(ngModel)]="values.from"
      [max]="values.to"
      (change)="onChange()"
      class="form-control"
    />
  </div>

  <div class="input-group mx-1">
    <input
      type="date"
      [(ngModel)]="values.to"
      [min]="values.from"
      (change)="onChange()"
      class="form-control"
    />
  </div>
</div>
