<div id="validatePatientPrescriptions" class="card mt-1">
  <h6 class="card-title m-0 px-2 py-1 d-flex">
    <mat-icon>local_pharmacy</mat-icon>
    <div class="ml-1 pt-1">Patient Prescriptions</div>
  </h6>
  <div class="card-body p-1">
    <ng-container *ngIf="!loading && prescriptions.length > 0; else: noPrescriptions">
      <app-prescription-list [prescriptions]="prescriptions" [small]="true" [dark]="true" [fixedHeader]="true" />
    </ng-container>
  </div>
  <ng-template #noPrescriptions> <div class="m-2">No prescriptions found.</div> </ng-template>
</div>
