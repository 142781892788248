<div class="config-value">
  <ng-container *ngIf="isFlag">
    <select
      id="{{ identifier }}{{ valueSuffix }}"
      name="{{ identifier }}{{ valueSuffix }}"
      class="form-control"
      required
      [ngClass]="{ 'is-invalid': !!valueValidationError }"
      (ngModelChange)="onValueChange($event)"
      [ngModel]="value"
      [disabled]="!definition.editable"
    >
      <option
        *ngFor="let option of flagOptions"
        [disabled]="option[1] === undefined"
        [hidden]="option[1] === undefined"
        [value]="option[1]"
      >
        {{ option[0] }}
      </option>
    </select>
  </ng-container>
  <ng-container *ngIf="!isFlag">
    <input
      type="text"
      id="{{ identifier }}{{ valueSuffix }}"
      name="{{ identifier }}{{ valueSuffix }}"
      ngDefaultControl
      required
      class="form-control"
      [ngClass]="{ 'is-invalid': !!valueValidationError }"
      (ngModelChange)="onValueChange($event)"
      [disabled]="!definition.editable"
      [ngModel]="value"
    />
  </ng-container>
  <div *ngIf="valueValidationError && valueValidationError.message" class="invalid-feedback">
    {{ valueValidationError.message }}
  </div>
</div>
