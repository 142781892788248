import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { UserStatus } from '../../../core/enums/user-status.enum';
import { UsersService } from '../../../core/services/users.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
})
export class UserActionsComponent implements OnInit {
  currentUserId = null;
  userStatuses = UserStatus;
  processing = false;

  @Input() user: User;
  @Output() public userChange = new EventEmitter<User>();

  constructor(
    private authService: AuthenticationService,
    private userService: UsersService,
    private toastService: AppToastService
  ) {}

  ngOnInit() {
    this.currentUserId = this.authService.currentUser.id;
  }

  onDeactivateClick($event) {
    $event.preventDefault();

    if (confirm(`Are you sure you want to deactivate ${this.user.fullName}?`)) {
      this.processing = true;

      this.userService.deactivate(this.user).subscribe((user: User) => {
        this.userChange.emit(user);
        this.processing = false;
        this.toastService.show(`"${user.fullName}" has been deactivated.`, {
          cssClass: 'alert-success',
        });
      });
    }
  }

  onReactivateClick($event) {
    $event.preventDefault();

    if (confirm(`Are you sure you want to reactivate ${this.user.fullName}?`)) {
      this.processing = true;

      this.userService.reactivate(this.user).subscribe((user: User) => {
        this.userChange.emit(user);
        this.processing = false;
        this.toastService.show(`"${user.fullName}" has been reactivated.`, {
          cssClass: 'alert-success',
        });
      });
    }
  }

  onResetPasswordClick($event) {
    $event.preventDefault();
    this.processing = true;

    this.userService.resetPassword(this.user).subscribe((user: User) => {
      this.userChange.emit(user);
      this.processing = false;
      this.toastService.show(`A reset password email has been sent to "${user.fullName}".`, {
        cssClass: 'alert-success',
      });
    });
  }

  onUnlockClick($event) {
    $event.preventDefault();
    this.processing = true;

    this.userService.unlock(this.user).subscribe((user: User) => {
      this.userChange.emit(user);
      this.processing = false;
      this.toastService.show(`User ${user.fullName} is now active.`, {
        cssClass: 'alert-success',
      });
    });
  }

  onResendInvitationClick($event) {
    $event.preventDefault();
    this.processing = true;

    this.userService.resendInvite(this.user).subscribe((user: User) => {
      this.userChange.emit(user);
      this.processing = false;
      this.toastService.show(`Invitation has been resent to "${user.fullName}".`, {
        cssClass: 'alert-success',
      });
    });
  }

  canResetPassword(user) {
    return (user.status === this.userStatuses.active || user.status === this.userStatuses.passwordReset) &&
      !user.samlProvider;
  }
}
