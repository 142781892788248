import { Component, OnInit } from '@angular/core';
import { ClientPrescriberTaskStatus } from 'app/core/enums/client-prescriber-task-status.enum';
import { UserSettings } from 'app/core/enums/user-settings.enum';
import { TaskFilters, TaskQueueSettings } from 'app/core/models/user-settings/task-settings.model';
import { ClientsService } from 'app/core/services/clients.service';
import { UserSettingsService } from 'app/core/services/user-settings.service';
import { ClientReferralTask } from '../../../core/models/client-referral-task.model';
import { LimitOffsetPaging } from '../../../core/models/paged-results/limit-offset-paging.model';
import {
  ClientReferralTasksService,
  PagedClientReferralTasks,
} from '../../../core/services/client-referral-tasks.service';
import { displayTaskStatus } from 'app/core/options/task-status.opts';

@Component({
  selector: 'app-capture-admin-client-referral-tasks',
  templateUrl: './capture-admin-client-referral-tasks.component.html',
  styleUrls: ['./capture-admin-client-referral-tasks.component.scss'],
})
export class CaptureAdminClientReferralTasksComponent implements OnInit {
  tasks: ClientReferralTask[] = [];
  paging: LimitOffsetPaging;

  filters: TaskFilters = new TaskFilters();
  taskStatuses = [];
  specialties = [];
  manufacturers = [];
  sources = [];
  clients = [];

  loading = true;
  exportUrl = null;

  constructor(
    private clientReferralTasksService: ClientReferralTasksService,
    private userSettingsService: UserSettingsService,
    private clientsService: ClientsService,
  ) {}

  ngOnInit() {
    this.init();
  }

  filtersChange(filters) {
    this.filters = filters;
    this.loadTasks(1);
    this.saveQueueSettings();
  }

  onPageSizeChange(size) {
    this.paging.pageSize = size;
    this.loadTasks(this.paging.currentPage);
  }

  onPageChange(page) {
    this.loadTasks(page);
  }

  private init() {
    this.paging = LimitOffsetPaging.empty;
    this.loadQueueSettings();

    this.loadStatuses();
    this.loadSpecialties();
    this.loadManufacturers();
    this.loadSources();
    this.loadClients();

    this.loadTasks(1);
  }

  private loadTasks(page) {
    this.loading = true;
    const paging = { page, pageSize: this.paging.pageSize };

    this.clientReferralTasksService.getList(paging, this.filters).subscribe((result: PagedClientReferralTasks) => {
      this.tasks = result.clientReferralTasks;
      this.paging = result.meta.paging;
      this.loading = false;
    });
  }

  private loadStatuses() {
    this.taskStatuses = Object.values(ClientPrescriberTaskStatus).map(v => ({
      value: v,
      display: displayTaskStatus(v),
    }));
  }

  private loadSpecialties() {
    this.clientsService.getSpecialties().subscribe(result => {
      this.specialties = result;
    });
  }

  private loadManufacturers() {
    this.clientReferralTasksService.getManufacturers().subscribe(result => {
      this.manufacturers = result;
    });
  }

  private loadSources() {
    this.clientReferralTasksService.getSources().subscribe(result => {
      this.sources = result;
    });
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private loadQueueSettings() {
    const queueSettings: TaskQueueSettings = this.userSettingsService.get<TaskQueueSettings>(
      UserSettings.captureAdminClientReferralTaskQueueSettings
    );

    if (queueSettings) {
      this.paging = queueSettings.paging;
      this.filters = queueSettings.filters;
    }
  }

  private saveQueueSettings(position = null) {
    const queueSettings = new TaskQueueSettings();
    queueSettings.position = position;
    queueSettings.paging = this.paging;
    queueSettings.filters = this.filters;
    this.userSettingsService.save<TaskQueueSettings>(
      UserSettings.captureAdminClientReferralTaskQueueSettings,
      queueSettings
    );
  }

}
