import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { PatientPrescriber } from 'app/core/models/patient-prescriber.model';
import { Capture } from "app/core/models/capture.model";
import { EhrReferralVerificationsService } from "app/core/services/ehr-referral-verifications.service";
import { EhrReferralVerification } from 'app/core/models/ehr-referral-verification.model';
import { EhrReferralMatchOption } from 'app/core/models/ehr-referral-match-option.model';
import { keyBy } from 'lodash-es';
import {
  resolveEhrVerification,
  getAllSelectedReferralMatchVerifications,
  isReferralMatchVerificationDisabled,
} from 'app/core/lib/verification-utils';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { AuthenticationService } from "app/core/services/authentication.service";
import { ReferralMatchVerificationsService } from 'app/core/services/referral-match-verifications.service';
import { PatientPrescriberService } from 'app/core/services/patient-prescriber.service';
import { Subscription } from 'rxjs';
import { handleSimpleChanges } from 'app/core/lib/component-utils';
import { CaptureService } from 'app/core/services/capture.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { showCaptureTransitionedToast } from 'app/core/lib/verification-utils';
import { EhrReferralVerificationUpdate } from "app/core/services/ehr-referral-verifications.service";

@Component({
  template: ''
})
export class ReferralMatchVerificationsEhrBaseComponent implements OnInit, OnChanges {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() verificationChangeInProgress = false;

  ehrReferralVerifications: EhrReferralVerification[] = [];
  referralMatchVerifications: ReferralMatchVerification[] = [];
  pendingRmvs: Set<ReferralMatchVerification> = new Set<ReferralMatchVerification>();
  loadingReferralMatchOptions = true;
  referralMatchOptions: EhrReferralMatchOption[] = [];
  editable: boolean;
  patientPrescriberChangedSubscription: Subscription;

  constructor(
    protected authService: AuthenticationService,
    protected ehrReferralVerificationsService: EhrReferralVerificationsService,
    protected referralMatchVerificationsService: ReferralMatchVerificationsService,
    protected patientPrescriberService: PatientPrescriberService,
    protected captureService: CaptureService,
    protected toastService: AppToastService,
  ) {
  }

  ngOnInit() {
    if (this.editable === undefined) {
      this.editable = !this.capture.frozen;
    }

    this.updateReferralMatchVerifications();
    this.loadEhrReferralVerifications();
    this.subscribeToPatientPrescriberChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    handleSimpleChanges(changes, (inputName: string) => {
      if (inputName === 'capture' || inputName === 'verificationChangeInProgress') {
        this.refreshReferralMatchOptions();
      }
    });
  }

  onNewEhrReferralVerification(event: EhrReferralVerificationUpdate) {
    const newVerification = event.ehrReferralVerification;

    this.ehrReferralVerifications.push(newVerification);
    this.handleNewEhrReferralVerification(newVerification);
    this.setupReferralMatchOptions();

    const newOption =
      this.referralMatchOptions.find(option => option.ehrReferralVerification === newVerification);

    this.handleRmvSelectionChange(newOption);

    if (event.transitionedCaptureIds.includes(this.capture.id)) {
      this.reloadCaptureAfterTransition();
    }
  }

  onEhrReferralVerificationEdited(event: EhrReferralVerificationUpdate) {
    const updatedVerification = event.ehrReferralVerification;

    const index =
      this.ehrReferralVerifications.findIndex(verification => verification.id === updatedVerification.id);

    if (index >= -1) {
      this.ehrReferralVerifications[index] = updatedVerification;
      this.setupReferralMatchOptions();
    }

    if (event.transitionedCaptureIds.includes(this.capture.id)) {
      this.reloadCaptureAfterTransition();
    }
  }

  onReferralVerificationChange(option: EhrReferralMatchOption) {
    this.handleRmvSelectionChange(option);
  }

  private subscribeToPatientPrescriberChanges() {
    this.patientPrescriberChangedSubscription = this.patientPrescriberService.patientPrescriberChanged.subscribe(
      ({ patientPrescriber }) => {
        if (patientPrescriber.id === this.patientPrescriber.id) {
          this.patientPrescriber = patientPrescriber;
          this.updateReferralMatchVerifications();
          this.setupReferralMatchOptions();
        }
      }
    );
  }

  private updateReferralMatchVerifications() {
    this.referralMatchVerifications = [...this.patientPrescriber.referralMatchVerifications];
  }

  protected loadEhrReferralVerifications() {
    const filters = { patientId: this.patientPrescriber.patientId };

    this.ehrReferralVerificationsService.getList(filters).subscribe(result => {
      this.ehrReferralVerifications = result.ehrReferralVerifications;
      this.setupReferralMatchOptions();
      this.loadingReferralMatchOptions = false;
    });
  }

  protected setupReferralMatchOptions() {
    const filteredRmvs =
      this.referralMatchVerifications.filter(rmv => !!rmv.ehrReferralVerificationId);

    const rmvsByErvId: { [ervId: number]: ReferralMatchVerification } =
      keyBy(filteredRmvs, (rmv: ReferralMatchVerification) => rmv.ehrReferralVerificationId);

    const allSelectedRmvs = getAllSelectedReferralMatchVerifications(this.patientPrescriber);

    this.referralMatchOptions =
      this.ehrReferralVerifications.map(ehrReferralVerification => {
        const resolvedVerification =
          resolveEhrVerification(ehrReferralVerification, rmvsByErvId);

        return new EhrReferralMatchOption(
          resolvedVerification,
          ehrReferralVerification,
          !!resolvedVerification.id || this.pendingRmvs.has(resolvedVerification),
          this.referralMatchVerificationDisabled(allSelectedRmvs, resolvedVerification)
        );
      });
  }

  protected refreshReferralMatchOptions() {
    const allSelectedRmvs = getAllSelectedReferralMatchVerifications(this.patientPrescriber);

    this.referralMatchOptions.forEach(option => {
      option.disabled = this.referralMatchVerificationDisabled(allSelectedRmvs, option.verification);
    });

    this.referralMatchOptions = [...this.referralMatchOptions];
  }

  protected referralMatchVerificationDisabled(
    allSelectedRmvs: ReferralMatchVerification[],
    referralMatchVerification: ReferralMatchVerification
  ) {
    return this.verificationChangeInProgress ||
      isReferralMatchVerificationDisabled(
        referralMatchVerification,
        this.capture,
        allSelectedRmvs,
        this.authService
      );
  }

  protected handleRmvSelectionChange(_option: EhrReferralMatchOption) {
  }

  protected handleNewEhrReferralVerification(_newVerification: EhrReferralVerification) {
  }

  private reloadCaptureAfterTransition() {
    this.captureService.getCapture(this.capture.id).subscribe(capture => {
      showCaptureTransitionedToast(this.toastService, capture.status);
      this.captureService.notifyCaptureChanged(capture);
    });
  }
}
