<div class="row mx-0">
  <div class="col-7 px-0 text-left">
    <h3>My Fax Queue</h3>
  </div>
  <div class="col-5 no-padding">
    <app-user-stats />
  </div>
</div>
<div *ngIf="!loading; else: spinner">
  <section *ngIf="inboundFaxes.length > 0; else: noFaxes">
    <app-pagination-header
      [label]="'faxes'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)"
    />
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>CLIENT</th>
          <th>STATUS</th>
          <th class="text-nowrap">RECEIVED AT &#x25BC;</th>
          <th>FROM</th>
          <th class="text-center">PAGES</th>
          <th>PATIENT</th>
          <th>PRESCRIBER</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fax of inboundFaxes; let i = index">
          <td>{{ fax.client.humanizedName }}</td>
          <td>{{ fax.status | faxStatus }}</td>
          <td class="text-nowrap">{{ fax.receivedAt | date: 'MM/dd/yyyy hh:mm a' }}</td>
          <td class="text-nowrap">
            <fa-icon [icon]="['fas', 'fax']" size="sm" />
            <app-phone-number [number]="fax.callerNumber" [noValue]="'UNKNOWN CALLER'" />
          </td>
          <td class="text-center">{{ fax.pages | number }}</td>
          <td><app-patient-name [patient]="fax.patient" [showAlternateNames]=false /></td>
          <td><app-prescriber-name [prescriber]="fax.prescriber" /></td>
          <td>
            <ul class="nav">
              <li class="nav-item"><a class="nav-link" href="#" (click)="download($event, fax)"> DOWNLOAD </a></li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/capture-admin/my-work/faxes/{{fax.id}}" (click)="onViewClick(i)">
                  VIEW
                </a>
              </li>
              <li class="nav-item">
                <app-user-presence category="fax" [targetId]="fax.id" />
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noFaxes>
  <section><p>It's time to face the fax, you're all caught up!</p></section>
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
