export enum ProviderStatus {
  new = 'new',
  assigned = 'assigned',
  inProgress = 'in_progress',
  complete = 'complete',
  confirmed = 'confirmed',
  dataCollection = 'data_collection',
  mechanicalTurkReview = 'mechanical_turk_review',
  noInformationFound = 'no_information_found',
  deceasedOrRetired = 'deceased_or_retired',
}
