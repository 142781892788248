<td>
  <div>
    <a routerLink="{{task.id}}" (click)="onViewClick()">{{ task.patient.fullName }}</a>
  </div>
  <div class="small text-nowrap"><strong>MRN:</strong> {{ task.patient.mrn }}</div>
  <app-user-presence category="clientPrescriberTask" [targetId]="task.id" />
</td>
<td><app-prescriber-name [prescriber]="task.prescription.prescriber" [showSpecialtiesDetails]="true" /></td>
<td>{{ task.humanizedStatus }}</td>
<td>{{ task.source | claimSource | blankValueDash }}</td>
<td><app-prescription-name [prescription]="task.prescription" /></td>
<td class="text-center"><app-task-estimated-value [rank]="task.estimatedValueRank" [value]="task.estimatedValue" /></td>
<td class="text-center"><app-task-estimated-value [rank]="task.patientEstimatedValueRank" [value]="task.patientEstimatedValue" /></td>
<td class="text-right">
  <app-expiration-date [expirationDate]="task.nextClaimExpirationDate" />
</td>
