import { Component, Input, OnInit } from '@angular/core';
import { PatientAttachment } from 'app/core/models/patient-attachment.model';
import { PatientAttachmentsService } from 'app/core/services/patient-attachments.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { PdfFileService } from 'app/core/services/pdf-file.service';
import { PdfFile } from 'app/core/interfaces/pdf-file';
import { OcrResult } from 'app/core/models/ocr_result.model';

@Component({
  selector: 'app-consult-note-ocr-backfill',
  templateUrl: './consult-note-ocr-backfill.component.html',
  styleUrls: ['./consult-note-ocr-backfill.component.scss']
})
export class ConsultNoteOcrBackfillComponent implements OnInit {
  pdfFile: PdfFile;
  ocrResult: OcrResult;
  ocrDone: boolean;
  showButton = false;
  annotationsRunning = false;
  toolTip = "";

  @Input() set patientAttachment(patientAttachment: PatientAttachment) {
    this.ocrResult = patientAttachment.ocrResult;
    this.pdfFile = patientAttachment;
    this.setFlags();
  }

  constructor(
    private patientAttachmentsService: PatientAttachmentsService,
    private pdfFileService: PdfFileService,
    private toastService: AppToastService,
  ) {}

  ngOnInit(): void {
    this.pdfFileService.pdfFileChanged.subscribe((pdfFileChanged) => {
      this.pdfFile = pdfFileChanged.pdfFile;
      this.setFlags();
    });
  }

  onOcrBackfillClick($event: Event): void {
    this.annotationsRunning = true;
    this.patientAttachmentsService.backfillOcr(this.pdfFile.id).subscribe(
      (pa) => {
        this.patientAttachmentsService.notifyPatientAttachmentChanged(pa);
      },
      () => {
        this.toastService.show('An error occurred.', { cssClass: 'alert-danger' })
      }
    )
  }

  private setFlags(): void {
    this.showButton = !this.ocrResult ||
      (this.ocrResult && this.ocrResult.status !== 'processing' && this.ocrResult.skipAnnotations) ||
      (this.ocrResult && this.ocrResult.status !== 'processing' && this.ocrResult.lastSubmittedAt < this.pdfFile.fileCreatedAt) ||
      (this.ocrResult && this.ocrResult.status === 'failed');
    this.ocrDone = !!this.ocrResult;
    this.setToolTip();
  }

  private setToolTip(): void {
    if (this.ocrDone) {
      if (!this.ocrResult.skipAnnotations) {
        this.toolTip = "The OCR data is out of date. This may take a few minutes.";
      }
    } else {
      this.toolTip = "The consult note has not been OCR'ed yet. This may take a few minutes.";
    }
  }

}
