<div class="row mb-2">
  <div class="col">
    <div><strong>TASK TYPE</strong></div>
    <div>{{ task.humanizedType }}</div>
  </div>
  <ng-content />
</div>
<div class="row mb-2">
  <div class="col-6">
    <div class="form-group">
      <label for="additionalDetails"><strong>ADDITIONAL DETAILS PROVIDED BY CE</strong></label>
      <textarea
        class="form-control"
        id="additionalDetails"
        rows="3"
        name="additionalDetails"
        [(ngModel)]="task.notes"
        [disabled]="true"
      ></textarea>
    </div>
  </div>
</div>
