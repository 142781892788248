<ng-container *ngIf="!loading; else: spinner">
  <app-user-header [user]="user">
    <app-user-actions [user]="user" (userChange)="onUserChange($event)" />
  </app-user-header>

  <div>
    <div class="my-3">
      <strong> EMAIL </strong>
      <div>{{ user.email }}</div>
    </div>
    <div class="my-3">
      <strong> PHONE </strong>
      <div>
        <app-phone-number
          [number]="user.phone"
          [dialpad]="true" />
      </div>
    </div>
    <div class="my-3">
      <strong> TYPE </strong>
      <div><app-user-type [user]="user" /></div>
    </div>
    <div class="my-3">
      <strong> COGNITO USERNAME </strong>
      <div>{{ user.username }}</div>
    </div>
    <div class="my-3">
      <strong> SAML Authentication Provider </strong>
      <div>{{ user.samlProvider && user.samlProvider.displayName }}</div>
    </div>
    <div class="my-3">
      <strong> LAST SEEN </strong>
      <div>{{ user.lastSeenAt | date: 'MM/dd/yyyy  hh:mm a' | blankValueDash }}</div>
    </div>
    <div class="my-3">
      <strong> EULA ACCEPTANCE </strong>
      <div>{{ user.eulaDate | date: 'MM/dd/yyyy' | blankValueDash }}</div>
    </div>
    <div class="my-3" *ngIf="user.userType === userTypes.captureAdmin">
      <strong> ASSIGNED CLIENTS </strong>
      <div>{{ assignedClients() }}</div>
    </div>
  </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
