<div class="scrollable">
  <table class="table table-sm table-striped">
    <thead>
      <th>ID</th>
      <th>DATE</th>
      <th>REFERRING PROVIDER</th>
      <th>SPECIALTY</th>
      <th>REFERRED TO OFFICE</th>
      <th>REFERRED TO PROVIDER</th>
    </thead>
    <tbody>
      <tr *ngFor="let referral of referrals">
        <td>{{ referral.ehrIdentifier }}</td>
        <td>{{ referral.referralDate | date: 'MM/dd/yyyy' }}</td>
        <td>
          {{ referral.sendingProviderFirst }} {{ referral.sendingProviderLast }} ({{ referral.sendingProviderNpi }})
        </td>
        <td>{{ referral.referralSpecialty }}</td>
        <td>{{ referral.receivingOfficeName }}</td>
        <td>
          {{ referral.receivingProviderFirst }} {{ referral.receivingProviderLast }} ({{
            referral.receivingProviderNpi
          }})
        </td>
      </tr>
    </tbody>
  </table>
</div>
