<div *ngIf="!refreshing; else: spinner" class="icc-capture-detail">

  <div class="row mx-0 mb-2">
    <div class="col-6 px-0 text-left">
      <nav class="nav nav-pills">
        <a
          class="nav-item nav-link"
          routerLink="../{{capture.id}}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Capture Details
        </a>
      </nav>
    </div>
  </div>

  <div class="border py-2 rounded">
    <div class="row mx-1">
      <div class="col">
        <strong>CAPTURE ID</strong>
        <div>{{ capture.id }}</div>
      </div>
      <div class="col">
        <strong>STATUS</strong>
        <div>{{ capture.status }}</div>
      </div>
      <div class="col">
        <strong>CLIENT</strong>
        <div>{{ capture.client.humanizedName }}</div>
        <div *ngIf="capture.ehrAccess" class='ehr-access'>EHR Access</div>
      </div>
      <div class="col">
        <strong>RX-PATIENT</strong>
        <div>{{ capture.claim.patientFirst }} {{ capture.claim.patientLast }}</div>
        <div class="small text-nowrap"><strong>MRN:</strong> {{ capture.claim.patientMrn }}</div>
        <div class="small text-nowrap"><strong>DOB:</strong> {{ capture.claim.patientDob | date: 'MM/dd/yyyy' }}</div>
      </div>
      <div class="col">
        <strong>OPPORTUNITY VALUE</strong>
        <div>{{ capture.opportunityValue | currency }}</div>
      </div>
    </div>
  </div>

  <div class="card mt-1">
    <div class="card-body">
      <h4 class="eligible-header">AUTOMATED CLAIM ELIGIBILITY CHECKS</h4>
      <div *ngIf="capture.eligibilityStatus === 'eligible'" class="eligible-flag pass">ELIGIBLE</div>
      <div *ngIf="capture.eligibilityStatus !== 'eligible'" class="eligible-flag fail">INELIGIBLE</div>
      <div *ngIf="capture.providerStatus === 'eligible'" class="pass">
        <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
        <strong>PRESCRIBER CHECK</strong>
        <span>The script was written by an eligible prescriber during time the prescriber was eligible for the client organization</span>
      </div>
      <div *ngIf="capture.providerStatus !== 'eligible'" class="fail">
        <fa-icon [icon]="['fas', 'times-circle']" size="1x" class="check" />
        <strong>PRESCRIBER CHECK</strong>
        <span>The script was not written by an eligible prescriber during time the prescriber was eligible for the client organization</span>
      </div>
      <div *ngIf="capture.medicaidStatus === 'eligible'" class="pass">
        <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
        <strong>MEDICAID CHECK</strong>
        <span>This is a non medicaid claim or this pharmacy carves in Medicaid claims</span>
      </div>
      <div *ngIf="capture.medicaidStatus !== 'eligible'" class="fail">
        <fa-icon [icon]="['fas', 'times-circle']" size="1x" class="check" />
        <strong>MEDICAID CHECK</strong>
        <span>This is an ineligible Medicaid claim or we have insufficient information to categorize this claim</span>
      </div>
      <div *ngIf="capture.drugStatus === 'eligible' || capture.drugExemptionStatus === 'exempt'" class="pass">
        <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
        <strong>DRUG CHECK</strong>
        <span>This is a 340B eligible drug</span>
      </div>
      <div *ngIf="capture.drugStatus !== 'eligible' && capture.drugExemptionStatus !== 'exempt'" class="fail">
        <fa-icon [icon]="['fas', 'times-circle']" size="1x" class="check" />
        <strong>DRUG CHECK</strong>
        <span>This is not a 340B eligible drug</span>
      </div>
      <div *ngIf="capture.pricingStatus === 'complete'" class="pass">
        <fa-icon [icon]="['fas', 'check-circle']" size="1x" class="check" />
        <strong>PRICING CHECK</strong>
        <span>All pricing information is available for this claim</span>
      </div>
      <div *ngIf="capture.pricingStatus !== 'complete'" class="fail">
        <fa-icon [icon]="['fas', 'times-circle']" size="1x" class="check" />
        <strong>PRICING CHECK</strong>
        <span>All pricing information is not available for this claim</span>
      </div>
    </div>
  </div>

  <div class="row m-0 p-0">
    <div class="col card mt-1 bg-light">
      <div class="card-body">
        <h4>ELIGIBILITY FINDINGS</h4>
        <ng-container *ngIf="capture.eligibilityFindings">
          <h5 class="mt-3">Findings</h5>
          <div class="row my-2">
            <div class="col">
              <strong>ELIGIBILITY FINDING</strong>
              <div>{{ capture.eligibilityFindings.eligibilityStatus }}</div>
            </div>
            <div class="col">
              <strong>FINDINGS CATEGORY</strong>
              <div>{{ capture.eligibilityFindings.eligibilityStatusReason }}</div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <strong>FINDINGS NOTE</strong>
              <div>{{ capture.eligibilityFindings.note }}</div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <strong>SUBMITTED TO CLIENT</strong>
              <div>{{ capture.eligibilityFindings.clientSubmitDate | date: 'MM/dd/yyyy' }}</div>
            </div>
          </div>
          <h5 class="mt-3">Eligible Findings Details</h5>
          <div class="row my-2">
            <div class="col">
              <strong>DEPARTMENT</strong>
              <div>{{ capture.eligibilityFindings.department }}</div>
            </div>
            <div class="col">
              <strong>ENCOUNTER ID</strong>
              <div>{{ capture.eligibilityFindings.encounterIdentifier }}</div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <strong>EHR-MRN</strong>
              <div>{{ capture.eligibilityFindings.patientMrn }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col card bg-light mt-1 ml-1">
      <div class="card-body">
        <h4>CLAIM DETAILS</h4>
        <h5 class="mt-3">TPA Information</h5>
        <div class="row my-2">
          <div class="col">
            <strong>RX-TPA</strong>
            <div>{{ capture.claim.thirdPartyAdminister?.name }}</div>
          </div>
          <div class="col">
            <strong>RX-TPA CLAIM ID</strong>
            <div>{{ capture.claim.claimIdentifier }}</div>
          </div>
        </div>
        <h5 class="mt-3">Prescriber Information</h5>
        <div class="row my-2">
          <div class="col">
            <strong>RX-PRESCRIBER</strong>
            <div>{{ capture.claim.prescriberFirst }} {{ capture.claim.prescriberLast }}</div>
            <div class="small">
              <strong>NPI:</strong>
              {{ capture.claim.prescriberNpi }}
            </div>
          </div>
        </div>
        <h5 class="mt-3">Drug Information</h5>
        <div class="row my-2">
          <div class="col">
            <strong>RX-NDC</strong>
            <div>{{ capture.claim.ndc }}</div>
          </div>
          <div class="col">
            <strong>RX-WRITTEN DATE</strong>
            <div>{{ capture.claim.writtenDate | date: 'MM/dd/yyyy' }}</div>
          </div>
        </div>
        <div class="row my-2">
          <div class="col">
            <strong>RX-DRUG</strong>
            <div>{{ capture.claim.drugDescription }}</div>
          </div>
          <div class="col">
            <strong>RX-SERVICE DATE</strong>
            <div>{{ capture.claim.serviceDate | date: 'MM/dd/yyyy' }}</div>
          </div>
        </div>
        <div class="row my-2">
          <div class="col">
            <strong>RX-#</strong>
            <div>{{ capture.claim.referenceNumber }}</div>
            <div class="small">
              <strong>Fill:</strong>
              {{ capture.claim.fillNumber }}
            </div>
          </div>
          <div class="col">
            <strong>RX-PHARMACY</strong>
            <div>{{ capture.claim.pharmacyName }}</div>
            <div class="small">
              <strong>ID:</strong>
              {{ capture.claim.pharmacyIdentifier }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
