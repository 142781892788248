import { CaptureTransition } from './capture-transition.enum';

export enum CaptureStatus {
  newStatus = 'new',
  missingPatientInteraction = 'missing_patient_interaction',
  needsPatientMatchApproval = 'needs_patient_match_approval',
  needsReferralMatchApproval = 'needs_referral_match_approval',
  needsNewConsultNote = 'needs_new_consult_note',
  needsProviderInfo = 'needs_provider_info',
  needsEvidence = 'needs_evidence',
  pending = 'pending',
  readyToVerify = 'ready_to_verify',
  gatherEvidenceBlocked = 'gather_evidence_blocked',
  ceReview = 'ce_review',
  internalReview = 'internal_review',
  onlyNeedsPatientInteraction = 'only_needs_patient_interaction',
  rejected = 'rejected',
  clientRejected = 'client_rejected',
  discarded = 'discarded',
  verified = 'verified',
  verifiedInternalConsultant = 'verified_internal_consultant',
  verifiedClientPrescription = 'verified_client_prescription',
  verifiedClientPrescriptionInternalConsultant = 'verified_client_prescription_internal_consultant',
  verifiedClientPrescriberList = 'verified_client_prescriber_list',
  verifiedClientHasConsultNote = 'verified_client_has_consult_note',
  verifiedMissingPatientInteractionOverride = 'verified_missing_patient_interaction_override',
  verifiedClientDoesNotRequireConsult = 'verified_client_does_not_require_consult',
  verifiedClientPrescriberCheckNotRequired = 'verified_client_prescriber_check_not_required',
  reversed = 'reversed',
}

export const statusToTransition = {
  [CaptureStatus.newStatus]: CaptureTransition.newStatus,
  [CaptureStatus.missingPatientInteraction]: CaptureTransition.missingPatientInteraction,
  [CaptureStatus.needsPatientMatchApproval]: CaptureTransition.needsPatientMatchApproval,
  [CaptureStatus.needsEvidence]: CaptureTransition.needsEvidence,
  [CaptureStatus.gatherEvidenceBlocked]: CaptureTransition.gatherEvidenceBlocked,
  [CaptureStatus.pending]: CaptureStatus.pending,
  [CaptureStatus.ceReview]: CaptureTransition.ceReview,
  [CaptureStatus.internalReview]: CaptureTransition.internalReview,
  [CaptureStatus.rejected]: CaptureTransition.reject,
  [CaptureStatus.verified]: CaptureTransition.verify,
  [CaptureStatus.verifiedClientPrescriberList]: CaptureTransition.verifyClientPrescriberList,
  [CaptureStatus.discarded]: CaptureTransition.discard,
  [CaptureStatus.clientRejected]: CaptureTransition.clientReject,
};

export const availableTransitions = {
  [CaptureStatus.newStatus]: [CaptureTransition.needsEvidence],
  [CaptureStatus.missingPatientInteraction]: [CaptureTransition.needsEvidence],
  [CaptureStatus.needsPatientMatchApproval]: [CaptureTransition.needsEvidence],
  [CaptureStatus.needsEvidence]: [
    CaptureTransition.needsEvidence,
    CaptureTransition.gatherEvidenceBlocked,
    CaptureTransition.needsReferralMatchApproval,
    CaptureTransition.ceReview,
    CaptureTransition.internalReview,
    CaptureTransition.reject,
    CaptureTransition.verify,
    CaptureTransition.discard,
  ],
  [CaptureStatus.gatherEvidenceBlocked]: [
    CaptureTransition.needsEvidence,
    CaptureTransition.gatherEvidenceBlocked,
    CaptureTransition.ceReview,
    CaptureTransition.internalReview,
    CaptureTransition.reject,
    CaptureTransition.verify,
    CaptureTransition.discard,
  ],
  [CaptureStatus.onlyNeedsPatientInteraction]: [CaptureTransition.needsEvidence],
  [CaptureStatus.pending]: [
    CaptureTransition.needsEvidence,
    CaptureTransition.ceReview,
    CaptureTransition.internalReview,
    CaptureTransition.reject,
    CaptureTransition.verify,
    CaptureTransition.discard,
  ],
  [CaptureStatus.readyToVerify]: [CaptureTransition.verify],
  [CaptureStatus.internalReview]: [
    CaptureTransition.needsEvidence,
    CaptureTransition.gatherEvidenceBlocked,
    CaptureTransition.ceReview,
    CaptureTransition.internalReview,
    CaptureTransition.reject,
    CaptureTransition.verify,
    CaptureTransition.verifyClientPrescriberList,
    CaptureTransition.discard,
  ],
  [CaptureStatus.ceReview]: [
    CaptureTransition.needsEvidence,
    CaptureTransition.gatherEvidenceBlocked,
    CaptureTransition.ceReview,
    CaptureTransition.internalReview,
    CaptureTransition.reject,
    CaptureTransition.verify,
    CaptureTransition.discard,
  ],
  [CaptureStatus.verified]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedInternalConsultant]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedClientDoesNotRequireConsult]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedClientPrescription]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedClientPrescriptionInternalConsultant]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedClientPrescriberList]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedClientHasConsultNote]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedMissingPatientInteractionOverride]: [CaptureTransition.reverse],
  [CaptureStatus.verifiedClientPrescriberCheckNotRequired]: [CaptureTransition.reverse],
};
