<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"> <a routerLinkActive="active" [routerLink]="['../']"> LLM Playground </a> </li>
    <li class="breadcrumb-item active" aria-current="page">
      {{ project?.name }}
    </li>

    <li *ngIf="viewingBatchResults" class="ml-auto">
      Viewing prompt for batch {{currentBatchName}}
      <button class="btn btn-sm btn-secondary ml-2" (click)="onCopyBatchPrompt()">Copy To Project</button>
      <button class="btn btn-sm btn-info ml-2" (click)="onPromptBack()">Back</button>
    </li>

    <li *ngIf="!viewingBatchResults" class="ml-auto">
      <span class="text-danger" *ngIf="saveError">{{saveError}}</span>
      <a href="#" class="mr-2" *ngIf="isOwner" (click)="onDeleteProject($event)">Delete</a>
      <a href="#" class="btn btn-sm btn-primary" (click)="onShareProject($event)">Share</a>
      <mat-spinner class="mr-2 inline-spinner" *ngIf="isSaving" [diameter]="14" />
      <button class="btn btn-sm btn-info" type="button" (click)="onPromptSave()" [disabled]="responseLoading">
        Save
      </button>
    </li>
  </ol>
</nav>

<form *ngIf="promptForm" [formGroup]="promptForm" (ngSubmit)="onPromptSave()">
  <div class="row no-gutters mb-4 text-left">
    <div class="col-3">
      <div class="form-group mr-2">
        <label class="my-0"><strong>Model</strong></label>
        <select *ngIf="model && !viewingBatchResults" class="form-control" formControlName="model">
          <option *ngFor="let modelOption of modelMetadata" [value]="modelOption.value"> {{ modelOption.name }} </option>
        </select>
        <input *ngIf="viewingBatchResults" readonly class="form-control" [value]="modelName">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group mr-2">
        <label class="my-0"><strong>Temperature</strong></label>
        <input [readonly]="viewingBatchResults" class="form-control" type="number" id="temperature" step="0.01" min="0" max="2" pattern="^\d+(\.\d{1,2})?$" formControlName="temperature" required>
        <div class="text-danger" *ngIf="temperature.touched && temperature.errors?.required">
          Temperature is required.
        </div>
        <div class="text-danger" *ngIf="temperature.touched && (temperature.errors?.min || temperature.errors?.max)">
          Temperature must be between 0.00 and 2.00 (inclusive).
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group my-0 py-0 mr-2">
        <label class="my-0">
            <strong>Max Tokens Per Response
            <span
              ngbTooltip="This limits size of the answer from the assistant and applies to each individual response. Responses hitting this limit will contain an error message."
              class="material-icons icon-16px">
              info_outline
            </span>
          </strong>
        </label>
        <input [readonly]="viewingBatchResults" type="number" class="form-control" id="maxTokens" formControlName="maxTokens" placeholder="No Limit">
      </div>
      <div class="text-danger" *ngIf="maxTokens.touched && (maxTokens.errors?.min || promptForm.errors?.maxTokensInvalid)">
        Max Tokens must be between 1 and {{ maxTokensMax }} (inclusive).
      </div>
    </div>
    <div class="col-3">
      <label class="my-0"><strong>Tokens Used</strong></label>
      <input type="text" class="form-control" readonly value="{{totalTokens}}">
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-6">
      <label>System Message</label>
      <textarea
        [readonly]="viewingBatchResults"
        id="systemMessage"
        formControlName="systemMessage"
        class="form-control"
        rows="22"
      ></textarea>
      <div formArrayName="examples">
        <div class="mt-3 mb-2">
          <h6>Examples</h6>
        </div>
        <ng-container *ngFor="let example of examples.controls; let i=index" [formGroupName]="i">
          <ng-container *ngIf="!example.value._destroy">
            <hr *ngIf="shouldDisplayHr(i)">
            <div class="d-flex justify-content-between align-items-center">
              <label for="userMessage">User</label>
              <a href="#" *ngIf="!viewingBatchResults && shouldDisplayExampleDelete" (click)="deleteExample($event, i)" class="ml-auto">Delete</a>
            </div>
            <textarea
              (input)="onExampleChange()"
              [readonly]="viewingBatchResults"
              formControlName="userMessage"
              class="form-control mb-2"
              rows="9"
            ></textarea>
            <label for="assistantMessage">Assistant</label>
            <textarea
              (input)="onExampleChange()"
              [readonly]="viewingBatchResults"
              formControlName="assistantMessage"
              class="mb-2 form-control"
              rows="9"
            ></textarea>
          </ng-container>
        </ng-container>
        <button type="button" *ngIf="!viewingBatchResults" class="btn btn-sm btn-info" (click)="addExample()">Add Example</button>
      </div>
    </div>

    <div class="col-md-6">
      <div class="mb-2 d-flex justify-content-between align-items-center">
        <label>Chat</label>
        <div class="model-gray" *ngIf="allChatsUsedTheSameModel">{{chats[0].model}}</div>
      </div>
      <div #chatContainer class="chat-container border" *ngIf="responseLoading || chats.length > 0">
        <div *ngFor="let chat of chats">
          <div class="row no-gutters mb-1">
            <div class="col-11 mr-auto chat-balloon scrollable-div u-100 p-2 ml-3 mt-2 border rounded bg-light">
              <pre class="text-pre-wrap">{{chat.userMessage}}</pre>
            </div>
          </div>
          <div class="row no-gutters mb-1" *ngIf="chat.assistantMessage">
            <div class="col-11 mr-auto chat-balloon chat-assistant scrollable-div u-100 p-2 ml-3 mt-2 border rounded">
              <div class="model-gray right-align" *ngIf="!allChatsUsedTheSameModel">{{chat.model}}</div>
              <pre [ngClass]="{'text-danger': chat.status === 'failed'}" class="text-pre-wrap">{{chat.assistantMessage || "error"}}</pre>
              <div class="text-danger" *ngIf="chat.finishReason === 'length'">
                Truncated due to Max Tokens Per Response setting
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 d-flex justify-content-center" *ngIf="responseLoading">
          <mat-spinner [diameter]="28" />
        </div>
      </div>
      <textarea
        [readonly]="viewingBatchResults"
        (keydown.enter)="onEnterSubmit($event)"
        formControlName="userMessage"
        class="mt-4 form-control"
        rows="9"
      ></textarea>
      <div class="mt-2 d-flex justify-content-between align-items-center">
        <div>
          <div *ngIf="promptForm.errors?.atLeastOnePromptFieldRequired" class="text-danger">
            At least one prompt field is required.
          </div>
          <div class="text-danger text-right" *ngIf="responseError.length > 0">
            {{ responseError }}
          </div>
        </div>
        <div>
          <ng-container *ngIf="chats.length > 0 && !viewingBatchResults">
            <button type="button" class="btn btn-sm btn-info" [disabled]="disableAutomatedSubmission" (click)="onResubmitFirst()">Resubmit First</button>
            <button type="button" class="btn btn-sm btn-info" (click)="onClearChat()">Clear</button>
          </ng-container>
          <button *ngIf="!viewingBatchResults" class="btn btn-sm btn-primary" type="button" (click)="onPromptSave(true)" [disabled]="promptForm.invalid || responseLoading">Send</button>
        </div>
      </div>
    </div>
  </div>
</form>

<hr/>

<div class="row ">
  <div class="col-8 text-left">
    <h3>Batch Runs</h3>
  </div>
  <div *ngIf="promptForm" class="col-4 text-right">
    <button *ngIf="!viewingBatchResults" [disabled]="disableAutomatedSubmission" class="btn btn-sm btn-primary" (click)="onOpenAddBatchModal()">Save & Add Batch Run</button>
  </div>

  <div class="col-12" *ngIf="batches?.length > 0; else: noBatches">
    <app-llm-playground-batches-table
      [batches]="batches"
      (view)="onViewBatchPrompt($event)"
      (delete)="onDeleteBatchRun($event)"
    />
  </div>
  <ng-template #noBatches>
    <section class="col-12"><p>There are no batch runs.</p></section>
  </ng-template>
</div>
