<h3>Inbound Call Log</h3>
<form novalidate class="row no-gutters">
  <div class="filter form-group col-5 pr-1">
    <ng-select
      class="w-100"
      bindValue="id"
      bindLabel="humanizedName"
      name="clientIds"
      placeholder="Filter by Client"
      [multiple]="true"
      [items]="clients"
      (remove)="onFilterChange()"
      (change)="onFilterChange()"
      [(ngModel)]="filters.selectedClientIds"
    />
  </div>
  <div class="col-3 pr-1"><button class="btn btn-info clear-button" (click)="onClearClick($event)">Clear</button></div>
  <div class="col-4 text-right">
    <a routerLink="/capture-admin/communications/inbound-calls/new" class="btn btn-primary">Log Call</a>
  </div>
</form>
<div *ngIf="!loading; else: spinner">
  <section *ngIf="inboundCalls.length > 0; else: noInboundCalls">
    <app-pagination-header
      [label]="'inbound calls'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)"
    />
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>CLIENT</th>
          <th>OUTCOME</th>
          <th>PATIENT</th>
          <th>PRESCRIBER</th>
          <th>CREATED AT</th>
          <th>CREATED BY</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let inboundCall of inboundCalls; let i = index">
          <td>{{ inboundCall.client.humanizedName }}</td>
          <td>{{ keyedReasonOptions[inboundCall.reason].display }}</td>
          <td><app-patient-name [patient]="inboundCall.patient" /></td>
          <td><app-prescriber-name [prescriber]="inboundCall.prescriber" /></td>
          <td>{{ inboundCall.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
          <td>{{ inboundCall.submittedBy.fullName }}</td>
          <td>
            <ul class="nav">
              <li class="nav-item">
                <a
                  class="nav-link"
                  routerLink="/capture-admin/communications/inbound-calls/{{inboundCall.id}}"
                  (click)="onViewClick()"
                >
                  VIEW
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noInboundCalls>
  <section><p>There are no inbound calls.</p></section>
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
