<div class="card mt-1">
  <div class="card-body">
    <h4>Audit</h4>
    <div *ngIf="!processing; else: spinner">
      <ng-container *ngIf="capture.userAuditOutcome; else: auditForm">
        <strong>Audit Outcome</strong>
        <p>
          {{ capture.userAuditOutcome.status | captureUserAuditOutcomeStatus }}
        </p>

        <div *ngIf="capture.userAuditOutcome.statusReasons && capture.userAuditOutcome.statusReasons.length > 0 ">
          <strong>Reasons</strong>
          <p>
            {{ capture.userAuditOutcome.statusReasons | captureUserAuditOutcomeStatusReasons }}
          </p>
        </div>

        <div *ngIf="capture.userAuditOutcome.note">
          <strong>Audit Note</strong>
          <p>
            {{ capture.userAuditOutcome.note }}
          </p>
        </div>

        <div class="row">
          <div class="col-2">
            <strong>Audited By</strong>
            <div>
              {{ capture.userAuditOutcome.auditingUser.email }}
            </div>
          </div>
          <div class="col-2">
            <strong>Audited On</strong>
            <div>
              {{ capture.userAuditOutcome.createdAt | date: 'MM/dd/yyyy' }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #auditForm>
  <form *ngIf="canAudit; else: noAuditMessage" (ngSubmit)="onSubmit()">
    <div class="alert-danger text-danger p-1 mb-2" *ngIf="serverErrorMessage">
      An error occurred while saving this audit: {{ serverErrorMessage }}
    </div>

    <label>Audit Outcome</label>

    <div class="d-flex align-items-center">
      <div class="form-group w-50 mr-2">
        <select class="form-control" [(ngModel)]="formModel.status" name="status" (change)="onFormControlChange()">
          <option [ngValue]="null" [selected]="true"> -- Select Outcome -- </option>
          <option *ngFor="let outcomeStatusOption of availableOutcomeStatusOptions" [ngValue]="outcomeStatusOption.value">
            {{ outcomeStatusOption.display }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <button class="btn btn-primary" type="submit" [disabled]="!allowSubmit">
          Save Outcome
        </button>
      </div>
    </div>

    <div *ngIf="formModel.status === captureUserAuditOutcomeStatus.noncompliant || formModel.status === captureUserAuditOutcomeStatus.compliantWithMinorFindings">
      <label>Select Reasons(s)</label>

      <span>
        (Required)
      </span>

      <div *ngFor="let outcomeStatusReasonOption of availableOutcomeStatusReasonOptions">
        <label>
          <input type="checkbox"
                 value=""
                 [id]="'status_reason_' + outcomeStatusReasonOption.value"
                 [name]="'status_reason_' + outcomeStatusReasonOption.value"
                 (change)="onFormControlChange()"
                 [(ngModel)]="formModel.statusReasons[outcomeStatusReasonOption.value]" />
          {{ outcomeStatusReasonOption.display }}
        </label>
      </div>
    </div>

    <label>
      Audit Note
      <span *ngIf="formModel.status === captureUserAuditOutcomeStatus.noncompliant || formModel.status === captureUserAuditOutcomeStatus.compliantWithMinorFindings">
        (Required)
      </span>
    </label>

    <textarea [(ngModel)]="formModel.note" name="note" class="form-control w-50" (keyup)="onFormControlChange()"></textarea>
  </form>
</ng-template>

<ng-template #spinner>
  <div class="py-2">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
    Saving audit...
  </div>
</ng-template>

<ng-template #noAuditMessage>
  Capture has not been audited.
</ng-template>
