import { Component, Input, OnInit } from '@angular/core';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';

@Component({
  selector: 'app-patient-peer-referral-match-verifications',
  templateUrl: './patient-peer-referral-match-verifications.component.html',
  styleUrls: ['./patient-peer-referral-match-verifications.component.scss'],
})
export class PatientPeerReferralMatchVerificationsComponent implements OnInit {
  @Input() referralMatchVerifications: ReferralMatchVerification[];

  constructor() {}

  ngOnInit() {}
}
