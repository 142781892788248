import { Component, OnInit } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { UsersService } from '../../../core/services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFormMode } from '../../components/user-form/user-form.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-capture-admin-user-edit',
  templateUrl: './capture-admin-user-edit.component.html',
  styleUrls: ['./capture-admin-user-edit.component.scss'],
})
export class CaptureAdminUserEditComponent implements OnInit {
  user: User;
  loading = false;
  formModes = UserFormMode;

  constructor(private usersService: UsersService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.init();
  }

  private init() {
    const userId = this.route.snapshot.paramMap.get('id');
    this.loadUser(userId);
  }

  private loadUser(userId): void {
    this.loading = true;
    this.usersService.get(userId).subscribe(
      user => {
        this.user = user;
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/404'], { replaceUrl: true });
        } else {
          console.error(err);
        }
      }
    );
  }
}
