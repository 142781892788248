import { ProviderInformationCollectionDetails } from "./provider-information-collection-details.model";

export class Provider {
  id: number;
  npi: string;
  first: string;
  last: string;
  displayName: string;
  status: string;
  credentials: string;
  captureClientNames: string[];
  captureClientStates: string[];
  affiliatedClientNames: string[];
  classification: string;
  specialization: string;
  specialtyConfirmed: boolean;
  doNotSendClients: string;
  doNotSendReason: string;
  eligibleCount: number;
  ineligibleCount: number;
  confirmed: boolean;
  updatedAt: string;
  note: string;
  additionalDetailsDns: string;
  displaySpecialty: string;
  informationCollectionDetails?: ProviderInformationCollectionDetails;
}
