<form *ngIf="drugMatchVerification">
  <app-verification-validation [key]="validationKey">
    <div>Does the consult note reference the claim RX or class of RX?</div>
    <div class="form-inline">
      <div class="form-check form-check-line">
        <input
          id="drugMatchVerificationMatchesYes"
          name="drugMatchVerificationMatchesYes"
          class="form-check-input form-control"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onDrugMatchesChange()"
          [(ngModel)]="drugMatchVerification.matches"
          [value]="true"
        />
        <label class="form-check-label" for="drugMatchVerificationMatchesYes">Yes</label>
      </div>
      <div class="form-check form-check-line mx-2">
        <input
          id="drugMatchVerificationMatchesNo"
          name="drugMatchVerificationMatchesNo"
          class="form-check-input form-control"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onDrugMatchesChange()"
          [(ngModel)]="drugMatchVerification.matches"
          [value]="false"
        />
        <label class="form-check-label" for="drugMatchVerificationMatchesNo">No</label>
      </div>
      <div class="alert alert-warning mt-1" *ngIf="patientAttachment.drugMatchNotRequired">
        This client does not require that the drug be referenced on the consult note. If the drug is not referenced you may select <em>No</em> and verify the capture.
      </div>
    </div>
  </app-verification-validation>
</form>
<form class="form">
  <div class="form-group mt-2">
    <label for="notes">ADDITIONAL DETAIL</label>
    <fa-icon *ngIf="!showAdditionalDetails" [icon]="['fas', 'plus-square']" size="sm" (click)="onPlusClick()" />
    <fa-icon
      *ngIf="showAdditionalDetails"
      [icon]="['fas', 'minus-square']"
      size="sm"
      (click)="onMinusClick()"
    />
    <textarea
      id="notes"
      name="notes"
      class="form-control"
      *ngIf="showAdditionalDetails"
      [disabled]="verificationFrozen"
      (change)="onAdditionalTextChange()"
      [(ngModel)]="drugMatchVerification.notes"
      rows="5"
    >
    </textarea>
  </div>
</form>
