import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientReferralMatchOption } from 'app/core/models/client-referral-match-option.model';

@Component({
  selector: 'app-referral-match-verifications-client-listing',
  templateUrl: './referral-match-verifications-client-listing.component.html',
  styleUrls: ['./referral-match-verifications-client-listing.component.scss'],
})
export class ReferralMatchVerificationsClientListingComponent {
  @Input() loading = false;
  @Input() referralMatchOptions: ClientReferralMatchOption[] = [];

  @Output() referralVerificationChange = new EventEmitter<ClientReferralMatchOption>();

  onReferralVerificationChange(referralMatchOption: ClientReferralMatchOption) {
    this.referralVerificationChange.emit(referralMatchOption);
  }
}
