<div class="my-2">
  <app-drug-akas [matchingPrescriberDrug]="matchingPrescriberDrug" />
  <div class="row no-gutters mt-3">
    <div class="col">
      <div><strong>DRUG GROUP</strong></div>
      <div>{{ matchingPrescriberDrug.drugGroup && matchingPrescriberDrug.drugGroup.name }}</div>
    </div>
    <div class="col ml-3">
      <div><strong>VALUE</strong></div>
      <div>{{ matchingPrescriberDrug.totalEstimatedValue | currency }}</div>
    </div>
  </div>
  <div class="row no-gutters mt-3">
    <div class="col">
      <div><strong>RX-DRUG(S)</strong></div>
      <div>{{ matchingPrescriberDrug.relatedDrugNames | joinList }}</div>
    </div>
    <div class="col ml-3">
      <div><strong>NDC(S)</strong></div>
      <div>{{ matchingPrescriberDrug.relatedNdcs | joinList }}</div>
    </div>
  </div>
  <div class="row no-gutters mt-3">
    <div class="col">
      <div><strong>PRESCRIBER(S)</strong></div>
      <div>{{ prescriberNames | joinList }}</div>
    </div>
    <div class="col ml-3">
      <div><strong>RX-WRITTEN DATE(S)</strong></div>
      <span *ngFor="let checkedWrittenDate of checkedWrittenDates; last as isLast">
        {{ checkedWrittenDate.date | date: 'M/d/yyyy' }}

        <ng-container>
          <fa-icon
            *ngIf="checkedWrittenDate.dateWithinRange === true"
            [icon]="['fas', 'check']" size="1x" class="check" />
          <fa-icon
            *ngIf="checkedWrittenDate.dateWithinRange === false"
            [icon]="['fas', 'exclamation-triangle']" size="1x" class="warning" />

          <span *ngIf="!isLast" class="mr-2">,</span>
        </ng-container>
      </span>
    </div>
  </div>
  <div class="row no-gutters mt-3">
    <div class="col">
      <div><strong>CAPTURE COUNT</strong></div>
      <div>{{ matchingPrescriberDrug.relatedCaptureCount }}</div>
    </div>
  </div>
</div>
