<ng-container *ngIf="!loading; else: spinner">
  <div class="row">
    <div class="col-10">
      <h3>
        {{ client.humanizedName }}
      </h3>
    </div>
    <div class="col-2 text-right">
      <a routerLink="/capture-admin/client-management/clients/{{client.id}}/change-log">
        Change Log
      </a>
    </div>
  </div>

  <app-client-form [client]="client" [mode]="formModes.update" />
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
