<div class="content">
  <div class="row mx-0">
    <div class="col-4 px-0 text-left">
      <h3>Captures</h3>
    </div>

    <div class="col-8 px-0 text-right capture-advancement-queue-count-container">
      <span>
        Capture Advancements In Progress:
        <span class="capture-advancement-queue-count">
          <ng-container *ngIf="advancementQueueCount !== null">
            {{ advancementQueueCount | number }}
          </ng-container>
          <ng-container *ngIf="advancementQueueCount === null">
            -
          </ng-container>
        </span>
      </span>
    </div>
  </div>

  <app-capture-queue-settings (settingsChange)="onSettingsChange()" />
  <div class="mt-2" *ngIf="!loadingResults; else: spinner">
    <ng-container *ngIf="captureQueueResults.length > 0; else: noCaptures">
      <div>
        <app-capture-queue-results [captureQueueResults]="captureQueueResults" />
        <div *ngIf="hasMoreResults" class="text-center mb-4">
          <a href="#next" (click)="onNextClick($event)" class="load-next-captures">
            View Next {{ limit }} Captures
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #spinner>
    <div class="mt-2 ml-1"><mat-spinner diameter="30" /></div>
  </ng-template>
  <ng-template #noCaptures> <p class="mt-2 ml-1">No captures found. Try modifying the filters.</p></ng-template>
</div>
