<app-import-nav />

<h5 class="mt-3">Import Eligible Providers</h5>

<div class="alert alert-danger" *ngIf="hasError">
  There was a problem uploading the file.
  <ul class="mt-2">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>

<app-icc-import-upload
  (uploadComplete)="onUploadComplete($event)"
  (uploadStart)="onUploadStart()"
/>

<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 pl-2">
    <li class="py-2">
      Possible fields
      <ul class="pl-2">
        <li><code>npi</code> <span>(required, 10 digit)</span></li>
        <li><code>first_name</code></li>
        <li><code>last_name</code></li>
        <li><code>start_date</code> <span>(required, date, format MM/DD/YYYY)</span></li>
        <li><code>termination_date</code> <span>(date, format MM/DD/YYYY)</span></li>
        <li><code>credentials</code></li>
        <li><code>specialty</code></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div>
        <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive.</small>
      </div>
      <div class="bg-light p-2 border w-100">
        <small>
          <strong>
            <code class="csv-header">
              npi,first_name,last_name,start_date,termination_date,credentials,specialty
            </code>
          </strong>
          <br />
          <code>1233143336,John,Doe,10/15/2010,,MD,Cardiology</code><br />
          <code>1233143337,Jane,Doe,03/01/2020,04/01/2022,CPN,Pediatrics</code><br />
        </small>
      </div>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
