<div class="form-group col-auto">
  <label>
    {{ label }}:

    <input
      *ngIf="type === textOptionType.text"
      type="text"
      class="form-control form-control-sm text-filter text-filter-text"
      (change)="onChange()"
      [(ngModel)]="value" />

    <input
      *ngIf="type === textOptionType.integer"
      type="text"
      class="form-control form-control-sm text-filter text-filter-integer"
      (change)="onChange()"
      appExcludeInputPattern
      excludedPattern="\D"
      [(ngModel)]="value" />
  </label>
</div>

