<app-import-nav />

<div *ngIf="!loading; else: spinner">
  <section class="mt-3" *ngIf="patientInteractionLocationImports.length > 0; else: noInvoiceImports">
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>FILENAME</th>
          <th>STATUS</th>
          <th class="text-nowrap text-right">FILES</th>
          <th class="text-nowrap text-right">DATE IMPORTED</th>
          <th class="text-right">TOTAL ROWS</th>
          <th class="text-right">PROCESSED</th>
          <th class="text-right">SKIPPED</th>
          <th class="text-right">IMPORTED</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let import of patientInteractionLocationImports">
          <td>
            {{ import.filename | blankValueDash }}
          </td>
          <td [attr.title]="import.status === 'failed' ? import.failureMessage : null">
            {{ import.status | titlecase }}
            <fa-icon [hidden]="!import.active" [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
            <div [hidden]="!import.active">
              <button class="btn btn-sm btn-danger" (click)="onCancelClick($event, import)">CANCEL</button>
            </div>
          </td>
          <td class="text-right">
            <small>
              <div ngbDropdown class="d-inline-block">
                <button id="downloadDropdown" class="btn btn-sm btn-secondary" ngbDropdownToggle>Download</button>
                <div ngbDropdownMenu aria-labelledby="downloadDropdown">
                  <a [hidden]="!import.hasSkippedFile"
                      class="dropdown-item btn-sm"
                      href="#downloadSkipped"
                      (click)="onDownloadSkippedClick($event, import)">
                    Skipped Records File
                  </a>

                  <a class="dropdown-item btn-sm"
                     href="#downloadOriginal"
                     (click)="onDownloadOriginalClick($event, import)">
                    Uploaded File
                  </a >
                </div>
              </div>
            </small>
          </td>
          <td class="text-right">
            {{ import.createdAt | date: 'M/d/yyyy h:mm a' }}
            <div>
              <small>{{ import.username }}</small>
            </div>
          </td>
          <td class="text-right">{{ import.totalRows | number: '' }}</td>
          <td class="text-right">{{ import.processed | number: '' }}</td>
          <td class="text-right">{{ import.skipped | number: '' }}</td>
          <td class="text-right">{{ import.imported | number: '' }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</div>
<ng-template #noInvoiceImports>
  <section class="mt-3"><p>There are no imports.</p></section>
</ng-template>
<ng-template #spinner>
  <section class="mt-3"><mat-spinner diameter="30" /></section>
</ng-template>
