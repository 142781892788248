<ng-container *ngIf="!editing">
  <span>
    {{ keyedStatusOptions[providerOffice.status]?.display | blankValueDash }}
  </span>
  <span>
    <a href="javascript:;" (click)="onEdit($event)">
      <fa-icon [icon]="['fas', 'pen']" />
    </a>
  </span>
</ng-container>
<ng-container *ngIf="editing">
  <form [formGroup]="formGroup" (submit)="onSubmit()" #form="ngForm">
    <div class="mb-2 text-right">
      <button class="btn btn-sm btn-secondary" (click)="onCancelEdit($event)">
        <fa-icon [icon]="['fas', 'times']" />
      </button>
      <button class="btn btn-sm btn-primary">
        <fa-icon [icon]="['fas', 'check']" />
      </button>
    </div>
    <div>
      <ng-select
        bindValue="value"
        bindLabel="display"
        formControlName="status"
        [items]="providerOfficeStatusOptions"
        [clearable]="false"
        appFormControlValidityClass />
    </div>
  </form>
</ng-container>

