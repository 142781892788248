<div *ngIf="!adding">
  <a href="javascript:;" (click)="onAdd($event)">
    <fa-icon [icon]="['fas', 'plus-square']" /> <span *ngIf="!minimizeControls">Add Note</span>
  </a>
</div>
<div *ngIf="adding">
  <form (submit)="onSubmit()">
    <div>
      <textarea
        name="newNote"
        [formControl]="newNote"
        class="w-100"
        rows="3">
      </textarea>
    </div>
    <div class="text-right">
      <button class="btn btn-sm btn-secondary" (click)="onCancelAdd($event)">
        <fa-icon *ngIf="minimizeControls" [icon]="['fas', 'times']" />
        <span *ngIf="!minimizeControls">Cancel</span>
      </button>
      <button class="btn btn-sm btn-primary">
        <fa-icon *ngIf="minimizeControls" [icon]="['fas', 'check']" />
        <span *ngIf="!minimizeControls">Save</span>
      </button>
    </div>
  </form>
</div>