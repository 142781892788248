<app-import-nav />

<h5 class="mt-3">Upload Fax Merge File (Legacy)</h5>
<div class="row no-gutters" [hidden]="uploading">
  <div class="col-12">
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading the file.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <div>
      <app-file-upload
        [fileType]="'.csv'"
        (uploadStart)="onUploadStart()"
        (uploadCancel)="onUploadCancel()"
        (uploadError)="onUploadError()"
        (uploadComplete)="onUploadComplete($event)"
      />
    </div>
  </div>
</div>
<div class="row no-gutters" [hidden]="!uploading">
  <div class="col-12">
    <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
    Uploading File...
  </div>
</div>
<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 pl-2">
    <li class="py-2">
      Required fields
      <ul class="pl-2">
        <li><code>sweepStartDate</code> <span>(format must be MM/DD/YYYY)</span></li>
        <li><code>claimIdentifier</code></li>
        <li><code>candidate.client</code></li>
        <li><code>patientId</code></li>
        <li><code>candidate.prescriberNpi</code></li>
        <li><code>fax</code></li>
        <li><code>template</code></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div><small><strong>NOTE:</strong> Extra columns will be ignored</small></div>
      <div class="bg-light p-2 border w-100">
        <strong><code class="csv-header">sweepStartDate,candidate.client,candidate.prescriberNpi,patientId,candidate.claimIdentifier,fax,template</code></strong
        ><br />
        <code>12/19/2019,xfh123123,cvhs,1912908450,121212,16506964984,1</code><br />
        <code>12/20/2019,tt123456,providence,1184038705,343434,18046220552,2</code><br />
        <code>12/20/2019,zz1291919,marana,1184038705,3434343,15403711662,3</code><br />
      </div>
    </li>
    <li class="py-2"><code>fax</code> is the receiving fax number of the practice</li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
