<div>
  <section>
    <div class="row mx-0 mb-2">
      <div class="col-10 px-0">
        <h3>
          340B Client Locations
        </h3>
      </div>
    </div>

    <app-patient-interaction-locations-explainer
      [expanded]="this.uiOptions.explainerExpanded"
      (expandOrCollapse)="onExplainerExpandOrCollapse($event)" />

    <div class="container-fluid p-2 border rounded bg-light mb-2">
      <form>
        <div class="row">

          <div class="col-2">
            <div class="form-group">
              <label><strong>Eligibility Status:</strong></label>
              <ng-select
                class="w-100"
                bindValue="value"
                bindLabel="display"
                name="eligibilityStatus"
                placeholder="Filter by Status"
                [multiple]="true"
                [items]="eligibilityStatusFilterOptions"
                (remove)="onFilterChange()"
                (change)="onFilterChange()"
                [(ngModel)]="filters.eligibilityStatuses" />
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label><strong>Clients:</strong></label>
              <ng-select
                class="w-100"
                bindValue="id"
                bindLabel="humanizedName"
                name="clientIds"
                placeholder="Filter by Client"
                [multiple]="true"
                [items]="clientsFilterOptions"
                (remove)="onFilterChange()"
                (change)="onFilterChange()"
                [(ngModel)]="filters.clientIds" />
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <label><strong>Location:</strong></label>
              <input
                type="text"
                class="form-control"
                name="name"
                placeholder="Filter by Location"
                (keyup)="onLocationKeyUp()"
                [(ngModel)]="filters.name">
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label><strong>Entity Type:</strong></label>
              <ng-select
                class="w-100"
                name="entityTypes"
                placeholder="Filter by Entity Type"
                [multiple]="true"
                [items]="clientEntityTypeFilterOptions"
                (remove)="onFilterChange()"
                (change)="onFilterChange()"
                [(ngModel)]="filters.clientEntityTypes" />
            </div>
          </div>

          <div class="col-2">
            <div class="form-group">
              <label><strong>Assigned Client Lead:</strong></label>
              <ng-select
                class="w-100"
                bindValue="id"
                bindLabel="username"
                name="assignedClientLeadUserIds"
                placeholder="Filter by Client Lead"
                [multiple]="true"
                [items]="assignedClientLeadUsersFilterOptions"
                (remove)="onFilterChange()"
                (change)="onFilterChange()"
                [(ngModel)]="filters.assignedClientLeadUserIds" />
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12 text-right">
            <div class="form-group mt-2">
              <button type="button" class="btn btn-info mt-0 clear-button" (click)="onResetFiltersClick()">Clear Filters</button>
              <ng-container *ngIf="exportUrl">
                <a class="btn btn-primary"
                   routerLinkActive="active"
                   routerLink="{{ exportUrl }}">
                  Export
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>

    <app-pagination-header
      [label]="'locations'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)" />

    <app-patient-interaction-location-list
      *ngIf="!loading; else: spinner"
      [patientInteractionLocations]="patientInteractionLocations" />

    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />

  </section>
</div>

<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>
