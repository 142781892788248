<h3>Processing Queues</h3>
<div *ngIf="sqsQueueSummary !== undefined; else: spinner">
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th>FILE QUEUE COUNT</th>
        <th>FILE PART QUEUE COUNT</th>
        <th>CLAIM QUEUE COUNT</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{sqsQueueSummary.fullFileCount}}</td>
        <td>{{sqsQueueSummary.filePartsCount}}</td>
        <td>{{sqsQueueSummary.claimCount}}</td>
      </tr>
    </tbody>
  </table>
</div>
<h3>File Monitoring</h3>
<div class="mb-3">
  <form novalidate (keydown.enter)="onFormEnter($event)">
    <div class="row no-gutters flex-row align-items-end">
      <div class="col-4">
        <div class="form-group my-0 py-0 mr-2">
          <label class="my-0"><strong>Partners</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="partnerNames"
            placeholder="Filter by Partner"
            [multiple]="true"
            [items]="claimSourceOptions"
            (remove)="onPartnerNamesChange()"
            (change)="onPartnerNamesChange()"
            [(ngModel)]="formModel.selectedPartnerNames" />
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-info ml-1 mt-0 clear-button" (click)="onClearAllClick($event)">Clear All</button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!loading; else: spinner">
  <div *ngIf="partnerFiles.length > 0; else: noFiles">
    <app-pagination-header [paging]="paging" [label]="'partnerFiles'" (pageSizeChange)="onPageSizeChange($event)" />
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th class="text-nowrap">ID</th>
          <th>CREATED</th>
          <th>PARTNER</th>
          <th class="text-nowrap">FILENAME</th>
          <th class="text-nowrap">ROWS</th>
          <th class="text-nowrap">ERRS</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let partnerFile of partnerFiles">
          <tr>
            <td>{{ partnerFile.id }}</td>
            <td>{{ partnerFile.createdAt | date: 'MM/dd/yyyy h:mm a' }}</td>
            <td>{{ partnerFile.partnerName }}</td>
            <td>{{ partnerFile.fileName }}</td>
            <td class="text-center">{{ partnerFile.totalRows }}</td>
            <td *ngIf="partnerFile.errorCount > 0" class="text-center">
              <a href="#" (click)="onDownloadErrorsClick($event, partnerFile)">
                {{ partnerFile.errorCount }}
              </a>
            </td>
            <td *ngIf="partnerFile.errorCount === 0" class="text-center">
              {{ partnerFile.errorCount }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </div>
</div>
<ng-template #noFiles>
  <section>
    <p>
      There are no partner files. Try modifying the filters or
      <strong><u><a href="#" (click)="onClearAllClick($event)">clearing them</a></u></strong>.
    </p>
  </section>
</ng-template>
<ng-template #spinner> <mat-spinner diameter="30" /> </ng-template>

