<div *ngIf="active">
  <div *ngIf="!this.enableDeactivateDate">
    <button class="btn btn-secondary" (click)="showDeactivateDate($event)">Deactivate</button>
  </div>
  <div *ngIf="this.enableDeactivateDate" class="deactivate-date-edit bg-light p-3">
    <div *ngIf="this.showDeactivateDateIsRequiredMessage" class="text-danger">
      Please enter a date before saving.
    </div>
    <input type="date" name="deactivateDate" [(ngModel)]="deactivateDate" />
    <div>
      <a class="text-primary" href="#" (click)="saveDeactivateDate($event)">Save</a>
      <a class="pl-2" href="#" (click)="hideDeactivateDate($event)">Cancel</a>
    </div>
  </div>
</div>
