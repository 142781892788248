<div class="modal-content">
  <form [formGroup]="formGroup">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Batch Test Run</h4>
      <button type="button" class="close" aria-label="Close" (click)="onUploadCancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <p>
        This will create multiple independent chats with the <em>current</em> System Message, Examples,
        and each User Message included in the CSV file.
      </p>

      <div class="form-group">
        <label for="name">Run Name</label>
        <input type="text" id="name" autocomplete="off" class="form-control" formControlName="name">
      </div>

      <div class="form-group" *ngIf="upload?.file.name">
        <label for="name">File</label>
        <input type="text" readonly class="form-control" value="{{upload.file.name}}">
      </div>

      <app-file-upload
        *ngIf="!upload"
        [fileType]="'.csv'"
        (uploadStart)="onUploadStart()"
        (uploadCancel)="onUploadCancel()"
        (uploadError)="onUploadError($event)"
        (uploadComplete)="onUploadComplete($event)"
        uploadButtonLabel="Create Test Run"
        [uploadButtonDisabled]="formGroup.invalid"
      />

      <ul *ngIf="!upload" >
        <li>CSV format only</li>
        <li>Required Field: INPUT1</li>
        <li>All other fields uploaded will be preserved in your output file.</li>
      </ul>
    </div>
    <div *ngIf="errorMessage" class="p-2 text-danger">
      {{ errorMessage }}
    </div>

    <div class="m-2 d-flex justify-content-center" *ngIf="loading">
      <mat-spinner [diameter]="28" />
    </div>
  </form>
</div>
