<div class="alert alert-warning mt-2">
  <h4 class="mb-2 alert-warning">
    <fa-icon [icon]="['fas', 'exclamation-triangle']" size="1x" class="warning" />
    This Fax May Contain Multiple Patients
  </h4>
  <div>Did you find multiple patients in the PDF?</div>
  <form novalidate>
    <div class="form-check">
      <input
        (change)="onMultiplePatientsValidChange()"
        [(ngModel)]="multiplePatientsValid"
        type="radio"
        id="multiplePatientsValid"
        name="multiplePatientsValid"
        [value]="true"
        class="form-check-input"
      />
      <label class="form-check-label" for="multiplePatientsValid">Yes</label>
    </div>
    <div class="form-check">
      <input
        (change)="onMultiplePatientsValidChange()"
        [(ngModel)]="multiplePatientsValid"
        type="radio"
        id="multiplePatientsInvalid"
        name="multiplePatientsValid"
        [value]="false"
        class="form-check-input"
      />
      <label class="form-check-label" for="multiplePatientsInvalid">No</label>
    </div>
  </form>
</div>
