import { Component, Input } from '@angular/core';
import { CaptureQueueResult } from '../../../core/models/capture-lists/capture-queue-result.model';
import { CaptureStatus } from '../../../core/enums/capture-status.enum';
import { ClaimSourceOptions } from '../../../core/options/claim-source.opts';

@Component({
  selector: 'app-capture-queue-results',
  templateUrl: './capture-queue-results.component.html',
  styleUrls: ['./capture-queue-results.component.scss'],
})
export class CaptureQueueResultsComponent {
  captureStatus = CaptureStatus;
  claimSourceDisplay = {};

  @Input() captureQueueResults: CaptureQueueResult[] = [];
  @Input() displayPatient = true;
  @Input() displayClient = true;
  @Input() displayProvider = true;
  @Input() openInNewTab = false;

  constructor() {
    ClaimSourceOptions.forEach(opt => {
      this.claimSourceDisplay[opt.value] = opt.display;
    });
  }
}
