<div class="row">
  <div class="col-12">
    <h3>Mechanical Turk Reviews</h3>

    <form [formGroup]="formGroup" novalidate>
      <div class="container-fluid p-2 border bg-light mb-2">
        <div class="row">
          <div class="col-2 pr-1">
            <div class="form-group">
              <label>
                <strong>Sort By:</strong>
              </label>
              <ng-select
                  class="w-100"
                  name="sortBy"
                  bindValue="value"
                  bindLabel="display"
                  [multiple]="false"
                  [items]="sortByOptions"
                  [clearable]="false"
                  [searchable]="false"
                  formControlName="sort" />
            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mt-2" *ngIf="!loading; else: spinner">
  <app-pagination-header [paging]="paging" [label]="'providers'" (pageSizeChange)="onPageSizeChange($event)" />

  <div class="mt-2" *ngIf="providers.length > 0; else: noProviders">
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>Provider Status</th>
          <th>Provider Information</th>
          <th>Specialization/Classification</th>
          <th>Office Information</th>
          <th>Mechanical Turk Task Details</th>
          <th>HIT Submitted On</th>
          <th>Estimated Value</th>
          <th>Nearest Expiration</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let provider of providers">
          <td class="text-nowrap">
            <app-provider-status-edit [provider]="provider" (providerStatusChanged)="onProviderStatusChange($event)" />
          </td>
          <td>
            <div>
              <a href="/capture-admin/providers/{{provider.npi}}" target="_blank">
                <span *ngIf="provider.credentials">
                  {{ provider.first }} {{ provider.last }}, {{ provider.credentials }}
                </span>
                <span *ngIf="!provider.credentials">
                  {{ provider.first }} {{ provider.last }}
                </span>
              </a>
            </div>
            <div>
              NPI: {{ provider.npi }}
            </div>
          </td>
          <td>
            {{ provider.specialization | blankValueDash }} /
            {{ provider.classification | blankValueDash }}
          </td>
          <td>
            <div *ngIf="provider.firstOffice">
              <app-office [office]="provider.firstOffice" />
              <div class="small">
                Office Status: {{ keyedOfficeStatusOptions[provider.firstOffice.status]?.display }}
              </div>
            </div>

            <div *ngIf="provider.officeCount > 1">
              <a class="all-offices"
                 href="/capture-admin/providers/{{ provider.npi }}"
                 target="_blank">
                View All Offices
              </a>
            </div>

            <app-blank-value-dash *ngIf="!provider.firstOffice" />
          </td>
          <td>
            <div>
              <strong *ngIf="provider.informationCollectionDetails?.resultIssues">
                <span *ngIf="provider.informationCollectionDetails.resultIssues.length > 0">
                  {{ collectionIssues(provider.informationCollectionDetails) }}
                </span>
                <span *ngIf="provider.informationCollectionDetails.resultIssues.length === 0">
                  HIT Complete - No Issues
                </span>
              </strong>
              <app-blank-value-dash *ngIf="!provider.informationCollectionDetails?.resultIssues" />
            </div>
            <div class="small">
              CMS Specialization: {{ provider.informationCollectionDetails?.cmsSpecialization | blankValueDash }}
            </div>
            <div class="small">
              CMS Classification: {{ provider.informationCollectionDetails?.cmsClassification | blankValueDash }}
            </div>
          </td>
          <td>
            <span *ngIf="provider.informationCollectionDetails?.mechanicalTurkTasks?.length > 0">
              {{ provider.informationCollectionDetails.
                  mechanicalTurkTasks[provider.informationCollectionDetails.mechanicalTurkTasks.length - 1].
                  createdAt | date: 'MM/dd/yyyy' }}
            </span>
            <app-blank-value-dash *ngIf="provider.informationCollectionDetails?.mechanicalTurkTasks?.length === 0" />
          </td>
          <td>
            <span *ngIf="provider.prescriberEstimatedValue > 0">
              {{ provider.prescriberEstimatedValue | currency: 'USD':'symbol':'1.0-0' }}
            </span>

            <app-blank-value-dash *ngIf="provider.prescriberEstimatedValue === 0" />
          </td>
          <td>
            {{ provider.prescriberNextClaimExpirationDate | date: 'MM/dd/yyyy' | blankValueDash }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange()" />
    </div>
  </div>
</div>

<ng-template #spinner>
  <div *ngIf="loading" class="ml-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noProviders>
  <p class="ml-1">No provider requiring review.</p>
</ng-template>
