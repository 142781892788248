
<div class="modal-header px-3 py-1">
  <h5 class="modal-title text-white">Edit Specialist Encounter Date</h5>
</div>
<div class="modal-body">
  <div class="mb-2">
    Remove and/or add specialist encounter dates from this consult note. Per the client configuration, the specialist encounter date needs to be within
    <strong>{{ capture.client.specialistEncounterTimeframeInMonths }} months</strong>
    of the written dates listed to the right. You will not be able to save your changes unless all Verified Rx Written Dates have valid specialist encounter dates.</div>
  <div class="row">
    <div class="col">
      <div><strong>Specialist Encounter Dates</strong></div>
      <ng-container *ngFor="let verification of specialistEncounterVerifications; let i = index">
        <app-specialist-encounter-verification
          [unrestrictedMode]="true"
          [capture]="capture"
          [patientAttachment]="patientAttachment"
          [verification]="verification"
          [position]="i"
          (changeVerification)="onChange(i, $event)"
          (removeVerification)="onRemove(i)"
        />
      </ng-container>
      <div>
        <button class="btn btn-sm btn-primary" (click)="onAddClick($event)">
          <fa-icon class="text-white" [icon]="['fas', 'plus-circle']" /> Add Encounter Date
        </button>
      </div>
    </div>
    <div class="col">
      <div><strong>Verified Rx Written Dates</strong></div>
      <ng-container *ngIf="!loadingCaptures; else: spinner">
        <div *ngFor="let capture of verifyingCaptures; let i = index">
          - {{ capture.candidate.product }} -
          <span class="date-and-check">
            <strong>{{ capture.candidate.writtenDate | date:'MM/dd/yyyy' }}</strong>
            <fa-icon *ngIf="writtenDateIsValid[i]" [icon]="['fas', 'check-circle']" size="1x" class="check" />
            <fa-icon *ngIf="!writtenDateIsValid[i]" [icon]="['fas', 'times-circle']" size="1x" class="times" />
          </span>
        </div>
      </ng-container>
      <ng-template #spinner><mat-spinner diameter="30" /></ng-template>
    </div>
  </div>
  <div class="text-right">
    <button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!loadingCaptures && !allWrittenDatesAreValid" (click)="onSaveClick()">Save</button>
  </div>
</div>
