<form *ngIf="patientMatchVerification">
  <div *ngIf="!collapsed" class="mt-1">Does patient in consult note match patient on claim and CE encounter?</div>
  <div class="form-inline">
    <div class="form-check form-check-line">
      <input
        id="patientMatchVerificationMatchesYes"
        name="patientMatchVerificationMatchesYes"
        class="form-check-input form-control"
        type="radio"
        [disabled]="verificationFrozen"
        (change)="onPatientMatchesChange()"
        [(ngModel)]="patientMatchVerification.matches"
        [value]="true"
      />
      <label class="form-check-label" for="patientMatchVerificationMatchesYes">Yes</label>
    </div>
    <div class="form-check form-check-line mx-2">
      <input
        id="patientMatchVerificationMatchesNo"
        name="patientMatchVerificationMatchesNo"
        class="form-check-input form-control"
        type="radio"
        [disabled]="verificationFrozen"
        (change)="onPatientMatchesChange()"
        [(ngModel)]="patientMatchVerification.matches"
        [value]="false"
      />
      <label class="form-check-label" for="patientMatchVerificationMatchesNo">No</label>
    </div>
  </div>
  <app-additional-details-input
    *ngIf="!hideAdditionalDetails"
    id="pmvNotes"
    [frozen]="verificationFrozen"
    [value]="patientMatchVerification.notes"
    (valueChange)="onAdditionalTextChange($event)"
  />
</form>
