<div class="wrapper">
  <div class="row">
    <div class="col sidebar pl-0 pr-0 sticky-top">
      <ul class="nav nav-sidebar flex-column">
        <li class="nav-item">
          <a class="nav-link" routerLink="captures" routerLinkActive="active">
            <mat-icon>search</mat-icon>
            <p>Search</p>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link position-relative"
            [routerLink]="activeTasksLink"
            [ngClass]="{ active: isTasksLinkActive }"
          >
            <mat-icon>playlist_add_check</mat-icon> <app-badge [value]="reviewableClientTasks" />
            <p>Tasks</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link position-relative" routerLink="consult-notes/manual" routerLinkActive="active">
            <mat-icon>insert_drive_file</mat-icon> <app-badge [value]="ehrUploadablePatientAttachments" />
            <p>Consult Notes</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="reports/captured-claim-reports" routerLinkActive="active">
            <mat-icon>bar_chart</mat-icon>
            <p>Reports</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="patientInteractionLocationManagementEnabled">
          <a class="nav-link position-relative" routerLink="locations" routerLinkActive="active">
            <mat-icon>lists</mat-icon> <app-badge [value]="newPatientInteractionLocations" />
            <p>Locations</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="program-overview" routerLinkActive="active">
            <mat-icon>business</mat-icon>
            <p>R1 Program</p>
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="showTasksSubNav" class="col-2 sub-nav pl-3 pt-3 sticky-top">
      <h4>Tasks</h4>
      <ul class="nav flex-column">
        <li *ngIf="clientReferralTasksEnabled" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="tasks/client-referral-tasks">
            Referral Check&nbsp;<app-badge [value]="reviewableClientReferralTasks" [small]="true" />
          </a>
        </li>
        <li *ngIf="clientConsultNoteTasksEnabled" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="tasks/client-consult-note-tasks">
            Consult Note Check&nbsp;<app-badge [value]="reviewableClientConsultNoteTasks" [small]="true" />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="tasks/client-prescriber-tasks">
            Client Prescriber Check<app-badge [value]="reviewableClientPrescriberTasks" [small]="true" />
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="showReportsSubNav" class="col-2 sub-nav pl-3 pt-3 sticky-top">
      <h4>Reports</h4>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="reports/captured-claim-reports">
            Captured Claims Report
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="reports/verified-prescription-reports">
            Patient Verified Prescription Report
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="reports/capture-invoice-line-item-reports">
            Invoice Report
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="reports/client-prescriber-task-reports">
            Client Prescriber Check Report
          </a>
        </li>
        <li *ngIf="clientReferralTasksEnabled" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="reports/client-referral-task-reports">
            Client Referral Check Report
          </a>
        </li>
        <li *ngIf="clientConsultNoteTasksEnabled" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="reports/client-consult-note-task-reports">
            Client Consult Note Check Report
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="showConsultNotesSubNav" class="col-2 sub-nav pl-3 pt-3 sticky-top">
      <h4>Consult Notes</h4>
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="consult-notes/manual">
            Requiring Manual Download <app-badge [value]="ehrUploadablePatientAttachments" />
          </a>
        </li>
        <li *ngIf="directEnabled" class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="consult-notes/direct"> Sent Via Direct Message </a>
        </li>
      </ul>
    </div>

    <div class="content pt-2" [ngClass]="{ 'col-9': showingSubNav, 'col-11': !showingSubNav }">
      <router-outlet />
    </div>
  </div>
</div>
