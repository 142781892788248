"use strict";

/**
 * protocols
 * Returns the protocols of an input url.
 *
 * @name protocols
 * @function
 * @param {String|URL} input The input url (string or `URL` instance)
 * @param {Boolean|Number} first If `true`, the first protocol will be returned. If number, it will represent the zero-based index of the protocols array.
 * @return {Array|String} The array of protocols or the specified protocol.
 */
module.exports = function protocols(input, first) {
  if (first === true) {
    first = 0;
  }
  var prots = "";
  if (typeof input === "string") {
    try {
      prots = new URL(input).protocol;
    } catch (e) {}
  } else if (input && input.constructor === URL) {
    prots = input.protocol;
  }
  var splits = prots.split(/\:|\+/).filter(Boolean);
  if (typeof first === "number") {
    return splits[first];
  }
  return splits;
};