<ng-template #spinner>
  <div><mat-spinner diameter="30" /></div>
</ng-template>

<ng-container *ngIf="!isLoading; else: spinner">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLinkActive="active" [routerLink]="['../../projects']"> LLM Playground </a>
      </li>
      <li class="breadcrumb-item">
        <a routerLinkActive="active" [routerLink]="['../../projects', batch?.project.id]"> {{ batch?.project.name }} </a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ batch?.name }}
      </li>

      <li class="ml-auto">
        <a href="#" (click)="onDownloadBatchResults($event, batch.id)">
          Download
        </a>
      </li>
    </ol>
  </nav>

  <table class="table table-bordered table-hover table-resizable-columns">
    <thead class="thead-dark">
      <tr>
        <th>Row</th>
        <th *ngFor="let header of batch.csvData[0]"
          [appResizableColumn]="true"
          class="resizable-header"
        >{{header}}<span *ngIf="header === 'MODEL'">*</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of batch.csvData.slice(1); let i = index">
        <td>{{ i + 1}}</td>
        <td *ngFor="let cell of row">
          <pre class="text-pre-wrap scrollable-cell">{{ cell }}</pre>
        </td>
      </tr>
    </tbody>
  </table>
  * Azure model names for individual responses may contain only the base model version, not the variant actually used.
</ng-container>
