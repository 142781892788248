import { Component, OnDestroy, OnInit } from '@angular/core';
import { OutboundFaxImport } from 'app/core/models/outbound-fax-import.model';
import { OutboundFaxImportKind } from 'app/core/enums/outbound-fax-import-kind.enum';
import { OutboundFaxImportsService, AttachedFile } from 'app/core/services/outbound-fax-imports.service';
import { DownloadService } from 'app/core/services/download.service';
import { JobStatus } from 'app/core/enums/job-status.enum';

@Component({
  selector: 'app-capture-admin-import-outbound-faxes-history',
  templateUrl: './capture-admin-import-outbound-faxes-history.component.html',
  styleUrls: ['./capture-admin-import-outbound-faxes-history.component.scss'],
})
export class CaptureAdminImportOutboundFaxesHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  outboundFaxImports: OutboundFaxImport[] = [];
  nextCheckTimeout = null;

  constructor(private outboundFaxImportsService: OutboundFaxImportsService, private downloadService: DownloadService) {}

  ngOnInit() {
    this.loadImports(true);
  }

  ngOnDestroy() {
    clearTimeout(this.nextCheckTimeout);
  }

  onCancelClick($event, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.cancel(outboundFaxImport.id).subscribe(faxImport => {
      const index = this.outboundFaxImports.findIndex(imp => imp.id === faxImport.id);

      if (index > -1) {
        this.outboundFaxImports[index] = faxImport;
        this.outboundFaxImports = [...this.outboundFaxImports];
      }
    });
  }

  onDownloadOriginalClick($event, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.get(outboundFaxImport.id).subscribe(faxImport => {
      this.downloadService.download(faxImport.fileUrl);
    });
  }

  onDownloadSkippedClick($event, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.generateSkippedFile(outboundFaxImport.id).subscribe(({ skippedFile }) => {
      if (skippedFile.url) {
        this.downloadService.download(skippedFile.url);
      } else {
        alert('Failed to generate file.');
      }
    });
  }

  onDownloadOutcomesClick($event, outboundFaxImport: OutboundFaxImport) {
    $event.preventDefault();
    this.outboundFaxImportsService.generateOutcomeFile(outboundFaxImport.id).subscribe(({ outcomeFile }) => {
      if (outcomeFile.url) {
        this.downloadService.download(outcomeFile.url);
      } else {
        alert('Failed to generate file.');
      }
    });
  }

  skippedAndOutcomeFilesAvailable(outboundFaxImport: OutboundFaxImport) {
    return outboundFaxImport.status !== JobStatus.initialized;
  }

  trackImportItemsBy(index: number, item: OutboundFaxImport) {
    return `${item.id}-${item.status}-${item.totalRows}-${item.queued}-${item.skipped}-${item.scheduledWithRetries}-${
      item.scheduledWithoutRetries
    }`;
  }

  private loadImports(loading) {
    this.loading = loading;
    this.outboundFaxImportsService.getList(OutboundFaxImportKind.standard).subscribe(data => {
      this.outboundFaxImports = data.outboundFaxImports;
      this.loading = false;

      const anyActive: boolean = this.outboundFaxImports.some(faxImport => faxImport.active);

      if (anyActive) {
        this.nextCheckTimeout = setTimeout(() => this.loadImports(false), 1250);
      }
    });
  }
}
