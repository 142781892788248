<div class="bg-light border rounded p-3">
  <form (ngSubmit)="onSubmit()">
    <h5>
      Was {{ task.patient.fullName }} prescribed the drug referenced on this task at a 340B-eligible location by one of
      your providers?
    </h5>
    <div class="form-inline mb-2">
      <div class="form-check form-check-line">
        <input
          id="yes"
          name="yesNo"
          class="form-check-input form-control"
          type="radio"
          [value]="true"
          [(ngModel)]="formModel.yesNo"
        />
        <label for="yes" class="form-check-label">Yes</label>
      </div>
      <div class="form-check form-check-line ml-3">
        <input
          id="no"
          name="yesNo"
          class="form-check-input form-control"
          type="radio"
          [value]="false"
          [(ngModel)]="formModel.yesNo"
          [disabled]="disabled"
        />
        <label for="no" class="form-check-label">No</label>
      </div>
    </div>
    <p>
      By selecting "Yes" I confirm that according to my organization's 340B Policies and Procedures, there is sufficient
      evidence to compliantly capture this patient’s drug for 340B and that the following is true:
    </p>
    <ul class="px-3">
      <li>This prescription originated from a valid 340B location</li>
      <li>
        A medication order or record of prescription is present for this drug on or near the written date for this same
        patient and prescriber
      </li>
      <li>Other documentation is available to support compliant capture</li>
    </ul>
    <div class="form-group">
      <label for="notes" class="my-1"><strong>ADDITIONAL DETAILS</strong> ({{ additionalDetailsRequired ? "Required" : "Optional" }})</label>
      <textarea
        id="notes"
        name="notes"
        class="form-control"
        [(ngModel)]="formModel.notes"
        rows="5"
        [disabled]="disabled"> </textarea>
    </div>

    <div *ngIf="serverError" class="mb-3">
      <div class="alert-danger p-2">{{ serverErrorMsg }}</div>
    </div>

    <div class="text-right" *ngIf="!disabled">
      <button [disabled]="saveDisabled" type="submit" class="btn btn-primary">SUBMIT</button>
      <a routerLink="/ce-portal/tasks/client-prescriber-tasks" [replaceUrl]="true" class="btn btn-secondary">CANCEL</a>
    </div>
  </form>
</div>
