<table class="table table-sm border mb-0" *ngIf="prescriptions.length > 0; else: noPrescriptions">
  <thead>
    <tr>
      <th class="text-dark drug-col">DRUG (AKA)</th>
      <th class="text-dark text-right text-nowrap">WRITTEN DATE &#x25BC;</th>
      <th class="text-dark text-center">VALID CONSULT NOTE?</th>
      <th class="text-dark text-center">SUBMITTED TO R1</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let prescriptionOption of prescriptionOptions">
      <td>
        <ng-container *ngIf="prescriptionOption.drugs && prescriptionOption.drugs.length > 0">
          <div *ngFor="let drug of prescriptionOption.drugs">
            <div>{{ drug.name }}</div>
            <div class="small"><strong>NDC:</strong> {{ drug.ndc }} <span class="flagged-for-esp p-1" *ngIf="drug.flaggedForEsp">ESP</span></div>
            <div class="small">{{ drug.manufacturer }}</div>
          </div>
        </ng-container>
        <div class="small">
          {{ prescriptionOption.prescriptionFamily.akas && prescriptionOption.prescriptionFamily.akas | slice:0:4 | joinList }}
        </div>
      </td>
      <td class="text-right">{{ prescriptionOption.writtenDate | date: 'MM/dd/yyyy' }}</td>
      <td class="text-center">
        <div *ngIf="prescriptionOption.verification">
          <span *ngIf="prescriptionOption.verification.valid; else: encounterDateWarning">
            <fa-icon [icon]="['fas', 'check']" class="check" />
            {{ prescriptionOption.verification.encounterDate | date: 'MM/dd/yyyy' }}
          </span>
        </div>
      </td>
      <td class="text-center">
        <app-client-task-submitted-prescription
          *ngIf="prescriptionOption.submitted"
          [verified]="prescriptionOption.verified"
          [rejected]="prescriptionOption.rejected"
          [submission]="prescriptionOption.submission"
        />
        <app-client-task-reject-prescription
          *ngIf="!prescriptionOption.submitted && !formDisabled"
          [prescriptionId]="prescriptionOption.id"
          [rejection]="prescriptionOption.rejection"
          (rejectionChange)="onPrescriptionRejectionChange($event)"
        />
      </td>
    </tr>
  </tbody>
</table>
<ng-template #encounterDateWarning>
  <div class="encounter-date-warning d-flex justify-content-around">
    <div><fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning" /></div>
    <div class="warning-message">
      <p>A consult note dated within {{ specialistEncounterTimeframeInMonths }} months of the written date is needed.</p>
      <a href="#addConsultNote" *ngIf="!formDisabled" (click)="onAddConsultNoteClick($event)">
        Add Consult Note
      </a>
    </div>
  </div>
</ng-template>
<ng-template #noPrescriptions>
  <section><p>There are no prescriptions.</p></section>
</ng-template>
