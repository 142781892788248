import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { keyBy } from 'lodash-es';
import { Capture } from "app/core/models/capture.model";
import { PatientPrescriber } from "app/core/models/patient-prescriber.model";
import { ReferralMatchOption } from "app/core/models/referral-match-option.model";
import { Referral } from "app/core/models/referral.model";
import { AuthenticationService } from "app/core/services/authentication.service";
import { PatientService, ReferralsResult } from "app/core/services/patient.service";
import {
  getAllSelectedReferralMatchVerifications,
  hasReferralMatchSelection,
  isReferralMatchVerificationDisabled,
  resolveReferralMatchVerification
} from '../../../core/lib/verification-utils';
import { ReferralMatchVerification } from "app/core/models/referral-match-verification.model";
import { ReferralMatchVerificationEvent } from "app/core/models/referral-match-verification-event.model";

@Component({ template: '' })
export abstract class ReferralMatchVerificationsStandardBaseComponent implements OnInit {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;
  @Input() verificationChangeInProgress = false;

  @Output() verificationChange = new EventEmitter<ReferralMatchVerificationEvent>();

  loadingReferrals = false;
  referralMatchOptions: ReferralMatchOption[] = [];

  eligibleReferrals: Referral[] = [];
  ineligibleReferrals: Referral[] = [];
  showIneligibleReferrals = false;

  constructor(
    protected authService: AuthenticationService,
    protected patientService: PatientService
  ) { }

  ngOnInit() {
    this.loadReferralMatchVerifications();
  }

  onReferralVerificationChange(referralMatchVerification: ReferralMatchVerification) {
    // up to the subclass to implement
  }

  protected loadReferralMatchVerifications() {
    this.loadingReferrals = true;
    this.patientService.getReferrals(this.patientPrescriber.patientId).subscribe((result: ReferralsResult) => {
      if (result.meta.eligibilityBasedOnLocation) {
        this.showIneligibleReferrals = true;

        this.eligibleReferrals =
          result.referrals.filter(referral => referral.locationEligibleDuringServiceDate);

        this.ineligibleReferrals =
          result.referrals.filter(referral => !referral.locationEligibleDuringServiceDate);
      } else {
        this.showIneligibleReferrals = false;
        this.eligibleReferrals = result.referrals;
      }

      this.setupReferralMatchOptions();
      this.loadingReferrals = false;
    });
  }

  protected setupReferralMatchOptions() {
    this.referralMatchOptions = this.eligibleReferrals.map(referral => {
      const resolvedVerification = resolveReferralMatchVerification(referral, this.keyedReferralVerifications);

      return new ReferralMatchOption(
        resolvedVerification,
        referral,
        hasReferralMatchSelection(resolvedVerification),
        this.isReferralMatchVerificationDisabledOrFormDisabled(this.allSelectedRmvs, resolvedVerification)
      );
    });
  }

  protected get keyedReferralVerifications() {
    const filteredRmvs = this.patientPrescriber.referralMatchVerifications.filter(rmv => rmv.referral);
    return keyBy(filteredRmvs, rmv => rmv.referral.id);
  }

  protected get allSelectedRmvs() {
    return getAllSelectedReferralMatchVerifications(this.patientPrescriber);
  }

  protected isReferralMatchVerificationDisabledOrFormDisabled(
    allSelectedRmvs: ReferralMatchVerification[],
    resolvedVerification: ReferralMatchVerification
  ) {
    return this.verificationChangeInProgress ||
      isReferralMatchVerificationDisabled(resolvedVerification, this.capture, allSelectedRmvs, this.authService);
  }

  protected refreshReferralMatchOptions() {
    const allSelectedRmvs = getAllSelectedReferralMatchVerifications(this.patientPrescriber);

    this.referralMatchOptions.forEach(option => {
      option.disabled = this.isReferralMatchVerificationDisabledOrFormDisabled(allSelectedRmvs, option.verification);
    });
  }
}
