<div *ngIf="!loading; else: spinner">
  <table class="table table-bordered" *ngIf="inboundCalls.length > 0; else: noInboundCalls">
    <thead class="thead-dark">
      <th>ID</th>
      <th>DATE CALLED</th>
      <th>CREATED BY</th>
      <th>OFFICE</th>
      <th>OUTBOUND FAX ID</th>
      <th>CALL OUTCOME</th>
    </thead>
    <tbody>
      <tr *ngFor="let inboundCall of inboundCalls">
        <td>
          <a
            routerLink="/capture-admin/communications/inbound-calls/{{inboundCall.id}}"
            target="_blank"
          >{{ inboundCall.id }}</a>
        </td>
        <td>{{ inboundCall.createdAt | date: 'MM/dd/yyyy' }}</td>
        <td>{{ inboundCall.submittedBy.fullName }}</td>
        <td><app-office [office]="inboundCall.office" /></td>
        <td>{{ inboundCall.outboundFaxId }}</td>
        <td>{{ keyedInboundCallReasonOptions[inboundCall.reason].display }}</td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
<ng-template #noInboundCalls>
  <p>There are no inbound calls.</p>
</ng-template>