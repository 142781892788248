import { ProviderStatus } from '../enums/provider-status.enum';
import { keyBy } from 'lodash-es';

export class ProviderStatusOption {
  constructor(public value: string, public display: string, public hide: boolean) { }
}

const providerStatusOptions = [
  new ProviderStatusOption(ProviderStatus.new, "New", false),
  new ProviderStatusOption(ProviderStatus.assigned, "Assigned", false),
  new ProviderStatusOption(ProviderStatus.inProgress, "In Progress", true),
  new ProviderStatusOption(ProviderStatus.complete, "Complete", false),
  new ProviderStatusOption(ProviderStatus.confirmed, "Confirmed", false),
  new ProviderStatusOption(ProviderStatus.mechanicalTurkReview, "Mechanical Turk Review", false),
  new ProviderStatusOption(ProviderStatus.dataCollection, "Data Collection", true),
  new ProviderStatusOption(ProviderStatus.noInformationFound, "No Information Found", true),
  new ProviderStatusOption(ProviderStatus.deceasedOrRetired, "Deceased/Retired", false),
];

const keyedStatusOptions = keyBy(providerStatusOptions, 'value');

const filterProviderStatusOptions = (providerStatus: string) =>
  providerStatusOptions.filter(statusOption => !statusOption.hide || statusOption.value === providerStatus)

export { keyedStatusOptions, providerStatusOptions, filterProviderStatusOptions };
