<form>
  <div class="mt-2">
    <h5>Patient Referrals From EHR Feeds</h5>
    <app-referral-match-verifications-standard
      [capture]="capture"
      [patientPrescriber]="patientPrescriber"
      [verificationChangeInProgress]="verificationChangeInProgress"
      (verificationChange)="onVerificationChange($event)" />
  </div>
  <div class="mt-3">
    <h5>Patient Referrals From Client Tasks</h5>
    <app-referral-match-verifications-client
      [capture]="capture"
      [patientPrescriber]="patientPrescriber"
      [verificationChangeInProgress]="verificationChangeInProgress"
      (verificationChange)="onVerificationChange($event)" />
  </div>
  <div class="mt-3">
    <h5>Referral Evidence Found in EHR</h5>
    <app-referral-match-verifications-ehr
      [capture]="capture"
      [patientPrescriber]="patientPrescriber"
      [verificationChangeInProgress]="verificationChangeInProgress"
      (verificationChange)="onVerificationChange($event)" />
  </div>
  <div class="mt-3" *ngIf="showClientReferralDatesSection">
    <h5>Client Referral Dates</h5>
    <div class="bg-light border px-2">
      <app-referral-match-verifications-client-dates
        [capture]="capture"
        [patientPrescriber]="patientPrescriber" />
    </div>
  </div>
  <div class="form-group mt-3 mb-2">
    <app-referral-match-verifications-notes
      [patientPrescriber]="patientPrescriber"
      [notesFrozen]="notesFrozen"
      (notesChanged)="onNotesChange($event)" />
  </div>
  <ng-container *ngFor="let validationKey of validationKeys">
    <app-verification-validation [key]="validationKey" />
  </ng-container>
  <div *ngIf="needsEvidence && !capture.shouldNotifyNoReferralRequired" class="m-0 mt-1 p-1 alert alert-warning">
    At least one patient referral must be selected or client referral date specified for the capture to be in Needs
    Evidence. Transition capture to Needs Referral Match Approval to clear selection.
  </div>
  <div *ngIf="capture.shouldNotifyNoReferralRequired" class="m-0 mt-1 p-1 alert alert-success">
    Covered Entity’s policies and procedures support the capture of this claim if a consult note--that meets all
    requirements--has been retrieved, regardless of whether a referral is present.
  </div>
  <div *ngIf="capture.wasVerifiedDuringNoReferralPeriod" class="m-0 mt-1 p-1 alert alert-success">
    At the time of verification, the Covered Entity’s policies and procedures supported the capture of this claim if a
    consult note--that meets all requirements--had been retrieved, regardless of whether a referral was present.
  </div>
</form>
