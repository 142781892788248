<div class="row mx-0 mb-2">
  <div class="col-6 px-0 text-left">
    <app-provider-nav [provider]="provider" />
  </div>
  <div class="col-6 text-right">
    <a class="nav-link" routerLink="/capture-admin/providers/{{ provider.npi }}/audits" target="_blank">
      View Change Log
    </a>
  </div>
</div>

<div *ngIf="!editing" class="card mb-2">
  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <strong> PROVIDER NAME </strong>
        <div>
          {{ provider.displayName }}
          <app-copy-to-clipboard [text]="provider.displayName" />
        </div>
        <div>
          {{ provider.npi }}
          <app-copy-to-clipboard [text]="provider.npi" />
        </div>
      </div>
      <div class="col-3">
        <strong> SPECIALTY / CLASSIFICATION </strong>
        <div>{{ provider.specialization | blankValueDash }} / {{ provider.classification | blankValueDash }}</div>
        <div>{{ provider.specialtyConfirmed ? '(Confirmed)' : '' }}</div>
      </div>
      <div class="col-3">
        <strong> PROVIDER STATUS </strong>
        <div>{{ keyedStatusOptions[provider.status].display }}</div>
      </div>
      <div class="col-2">
        <strong> STATE(S) </strong>
        <div>{{ provider.captureClientStates?.join(', ') }}</div>
      </div>
      <div class="col-1 text-right">
        <a href="javascript:;" (click)="onEdit($event)"><fa-icon [icon]="['fas', 'pen']" /></a>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-3">
        <strong> CAPTURE CLIENT(S) </strong>
        <div>{{ provider.captureClientNames?.join(', ') }}</div>
      </div>
      <div class="col-3">
        <strong> AFFILIATED CLIENT(S) </strong>
        <div>{{ provider.affiliatedClientNames?.join(', ') }}</div>
      </div>
      <div class="col-3">
        <strong> DNS </strong>
        <div>{{ keyedDoNotSendReasonOptions[provider.doNotSendReason]?.display | blankValueDash }}</div>
      </div>
      <div class="col-3">
        <strong> DNS ADDITIONAL DETAILS</strong>
        <div [innerHTML]="provider.additionalDetailsDns | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
<app-provider-header-edit
  *ngIf="editing"
  [provider]="provider"
  (cancel)="onEditDetailsCancel()"
  (updated)="onUpdatedDetails($event)" />
