export enum ClientConfigurationOptionKey {
  advanceOnCuratedPrescriberSpecialty = 'advance_on_curated_prescriber_specialty',
  allowEncounterOnlyMatches = 'allow_encounter_only_matches',
  bypassPrimaryCareRejectionTransitioners = 'bypass_primary_care_rejection_transitioners',
  clientConsultNoteTaskDisallowAssignToPar8o = 'client_consult_note_task_disallow_assign_to_par8o',
  clientConsultNoteTaskMinimumEstimatedValue = 'client_consult_note_task_minimum_estimated_value',
  clientFileOverdueEncountersCheckIntervalInDays = 'client_file_overdue_encounters_check_interval_in_days',
  clientFileOverduePrescribersCheckIntervalInDays = 'client_file_overdue_prescribers_check_interval_in_days',
  clientFileOverduePrescriptionsCheckIntervalInDays = 'client_file_overdue_prescriptions_check_interval_in_days',
  clientFileOverdueReferralsCheckIntervalInDays = 'client_file_overdue_referrals_check_interval_in_days',
  clientPrescriberFuzzyMatchMinimumEstimatedValueWagOnly = 'client_prescriber_fuzzy_match_minimum_estimated_value_wag_only',
  clientPrescriberList = 'client_prescriber_list',
  clientPrescriberTaskMinimumEstimatedValue = 'client_prescriber_task_minimum_estimated_value',
  clientPrescriberTaskNotRequiredToVerify = 'client_prescriber_task_not_required_to_verify',
  clientPrescriptionMatchCaptureTransitions = 'client_prescription_match_capture_transitions',
  clientReferralTaskMinimumEstimatedValue = 'client_referral_task_minimum_estimated_value',
  collectMfaPhoneNumber = 'collect_mfa_phone_number',
  disableOutboundFaxes = 'disable_outbound_faxes',
  drugNotRequiredOnConsultNote = 'drug_not_required_on_consult_note',
  employedWrittenDateTimeframeInMonths = 'employed_written_date_timeframe_in_months',
  enablePatientGrouping = 'enable_patient_grouping',
  enableWagMissingPatientInteractionOverride = 'enable_wag_missing_patient_interaction_override',
  espExpirationOverrideTimePeriodInDays = 'esp_expiration_override_time_period_in_days',
  findSubClientByHrsaId = 'find_sub_client_by_hrsa_id',
  iccIneligibleMedicaidClaimsViaTpaDesignation = 'icc_ineligible_medicaid_claims_via_tpa_designation',
  includeInAccredoPatientExport = 'include_in_accredo_patient_export',
  includeInOptumProviderExport = 'include_in_optum_provider_export',
  includeInWagBillingApiRequests = 'include_in_wag_billing_api_requests',
  moveNeedsEvidenceCapturesToCeReviewEmrConsultNoteReviewRequired = 'move_needs_evidence_captures_to_ce_review_emr_consult_note_review_required',
  patientAttachmentNotRequiredToVerify = 'patient_attachment_not_required_to_verify',
  patientInteractionLocationsIneligibleByDefault = 'patient_interaction_locations_ineligible_by_default',
  referralDateMustBeBeforeRxWrittenDate = 'referral_date_must_be_before_rx_written_date',
  referralNotRequiredToVerify = 'referral_not_required_to_verify',
  serviceDateRangeInMonths = 'service_date_range_in_months',
  specialistEncounterTimeframeInMonths = 'specialist_encounter_timeframe_in_months',
  useUpdatedEmailWorkflowForNewAndExpiringTaskAlerts = 'use_updated_email_workflow_for_new_and_expiring_task_alerts',
  ehrAccess = 'ehr_access',
}
