<div class="card mt-1">
  <div class="card-body">
    <h4>Validate Prescription Written at CE</h4>

    <form [formGroup]="formGroup">
      <div>
        <p>
          After reviewing the client's EHR, were you able to find evidence that
          <strong>{{ capture.patient.fullName }}</strong>
          was prescribed the drug mentioned on the capture at a 340B-eligible location
          by one of the CE's providers?
        </p>

        <ul class="valid-evidence-choice">
          <li>
            <label>
              <input
                type="radio"
                formControlName="prescriptionWrittenAtCe"
                name="prescriptionWrittenAtCe"
                [value]="true" />
              Yes
            </label>
          </li>
          <li>
            <label>
              <input
                type="radio"
                formControlName="prescriptionWrittenAtCe"
                name="prescriptionWrittenAtCe"
                [value]="false" />
              No
            </label>
          </li>
        </ul>

        <p>
          By selecting "Yes" I confirm that according to the CE's 340B Policies and Procedures, there is sufficient
          evidence to compliantly capture this patient's drug for 340B and that the following is true:
        </p>

        <ul>
          <li>
            The prescription originated from a valid 340B location
          </li>
          <li>
            A medication order or record of prescription is present for this drug on or near the written date for
            this same patient and prescriber
          </li>
          <li>
            Other documentation is available to support compliant capture
          </li>
        </ul>

        <label for="notes">CLIENT NOTE</label>

        <textarea
          class="form-control"
          formControlName="notes"
          id="notes"
          placeholder="Enter additional details to be viewed by the client"></textarea>

        <div class="mt-3" *ngIf="!frozen">
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="processing || formGroup.controls.prescriptionWrittenAtCe.value !== true"
            (click)="onVerifyClick()">
            Verify
          </button>
          <button
            type="button"
            class="btn btn-danger"
            [disabled]="processing || formGroup.controls.prescriptionWrittenAtCe.value !== false"
            (click)="onRejectClick()">
            Reject
          </button>
        </div>

        <div class="alert alert-danger mt-3" *ngIf="serverError">
          A server error occurred while processing this match. Please try again or contact support.
          <div class="server-errors">
            <table>
              <thead class="thead-dark">
                {{ serverErrorResponse.summary }}
              </thead>
              <tr *ngFor="let serverErrorDetail of serverErrorResponse.details">
                <td>{{ serverErrorDetail }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </form>

  </div>
</div>
