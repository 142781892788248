import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Project } from '../models/llm-playground.model';
import { isBlank } from '../../core/lib/js-utils';

@Injectable()
export class LlmPlaygroundProjectsService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  create(name: string): Observable<Project> {
    return this.http.post<Project>(`${environment.captureApi.url}/llm_playground/projects`, {
      llm_playground_project: { name },
    });
  }

  getList(): Observable<Project[]> {
    return this.http.get<Project[]>(`${environment.captureApi.url}/llm_playground/projects`);
  }

  get(id: number): Observable<Project> {
    return this.http.get<Project>(`${environment.captureApi.url}/llm_playground/projects/${id}`);
  }

  destroy(id: number): Observable<Project> {
    return this.http.delete<Project>(`${environment.captureApi.url}/llm_playground/projects/${id}`);
  }

  destroyChatHistory(project: Project): Observable<Project> {
    const projectParams = {
      id: project.id,
      prompt: {
        id: project.prompt.id,
        total_tokens: 0,
        user_message: '',
        chats: project.prompt.chats.map(chat => ({ id: chat.id, _destroy: true })),
      },
    };

    return this.http.put<Project>(`${environment.captureApi.url}/llm_playground/projects/${project.id}`, {
      project: projectParams,
    });
  }

  update(project: Project, submit: boolean): Observable<Project> {
    // remove blank/default examples
    let i = project.prompt.examples.length;

    while (i--) {
      const example = project.prompt.examples[i];
      if (
        isBlank(example.id) &&
        isBlank(example.userMessage) &&
        isBlank(example.assistantMessage)
      ) {
        project.prompt.examples.splice(i, 1);
      }
    }

    if (submit) {
      project.prompt.chats = [{ userMessage: project.prompt.userMessage }];
      project.prompt.userMessage = '';
    }

    return this.http.put<Project>(`${environment.captureApi.url}/llm_playground/projects/${project.id}`, {
      project,
      submit,
    });
  }
}
