<div *ngIf="!loading; else: spinner">

  <p class="alert alert-danger" role="alert" *ngIf="creationError">{{ creationError }}</p>

  <div class="container-fluid border rounded bg-light">
    <form (ngSubmit)="generate()" class="form-inline">

      <div class="form-row align-items-center report-options">

        <ng-template #defaultNewReportOptionsTemplate />

        <ng-template
          [ngTemplateOutlet]="newReportOptionsTemplate || defaultNewReportOptionsTemplate"
          [ngTemplateOutletContext]="{ filtersForm: filtersForm, outputOptionsForm: outputOptionsForm }" />

        <div class="form-group col-auto">
          <button class="btn btn-primary" type="submit" [disabled]="!newReportOptionsValid || generating">
            <span [hidden]="generating">Generate Report</span>
            <span [hidden]="!generating">
              <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" /> Generating Report
            </span>
          </button>
        </div>

      </div>

    </form>
  </div>

  <section *ngIf="reports.length > 0; else: noReports">
    <h3>{{ title || 'Reports' }}</h3>

    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>User</th>
          <th>Started</th>
          <th>Duration</th>
          <th>Status</th>
          <th>{{ totalColumnHeader }}</th>
          <th *ngIf="displayProcessedColumn">Processed</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let report of reports">
          <tr class="row-primary">
            <td>
              {{ report.username }}
            </td>
            <td>{{ report.startedAt | date: 'M/d/yyyy hh:mm a' }}</td>
            <td>{{ report.elapsedWords }}</td>
            <td>{{ report.status | titlecase }}</td>
            <td>{{ report.totalRows | number: '' }}</td>
            <td *ngIf="displayProcessedColumn">{{ report.processedRows | number: '' }}</td>
            <td>
              <button class="btn btn-primary" [disabled]="report.status !== 'completed'" (click)="download(report)">
                Download
              </button>
              <button class="btn btn-secondary" *ngIf="isProcessing(report)" (click)="cancel(report)">Cancel</button>
            </td>
          </tr>
          <tr class="row-secondary" *ngIf="forceReportDetailsDisplay || hasAppliedOptions(report)">
            <td colspan="8">

              <ng-template #defaultReportDetailsTemplate />

              <ng-template
                [ngTemplateOutlet]="reportDetailsTemplate || defaultReportDetailsTemplate"
                [ngTemplateOutletContext]="{ report: report }" />

            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </section>
</div>
<ng-template #noReports>
  <section>
    <p>
      Click 'Generate Report' to create a new
      <ng-container *ngIf="availableRecordCount">
        report with <strong>{{ availableRecordCount | number: '' }}</strong> rows.
      </ng-container>
      <ng-container *ngIf="!availableRecordCount">
        report.
      </ng-container>
    </p>
  </section>
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
