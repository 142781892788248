<table class="table table-bordered table-hover">
  <thead class="thead-dark">
  <tr>
    <th>CLAIM-ID</th>
    <th>STATUS</th>
    <th>VERIFIED AT</th>
    <th>SOURCE</th>
    <th>CLIENT</th>
    <th>MRN</th>
    <th>RX-PATIENT</th>
    <th>CE-PATIENT</th>
    <th>RX-DOB</th>
    <th>RX-PRESCRIBER-NAME</th>
    <th>VALUE</th>
    <th>RX-DRUG</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let capture of captureQueueResults">
    <td>
      <a routerLink="/capture-admin/captures/{{capture.id}}">{{ capture.candidate.claimIdentifier }}</a>
      <div>
        <app-user-presence category="capture" [targetId]="capture.id" />
      </div>
    </td>
    <td class="status-column">
      <strong>{{ capture.status | captureStatus }}</strong>
      <br />
      {{ capture.statusReason | captureStatusReason }}
    </td>
    <td>{{ capture.verifiedAt | date: 'MM/dd/yyyy' }}</td>
    <td>{{ capture.candidate.source }}</td>
    <td>
      {{ capture.client.name }}
      <div *ngIf="!capture.client.active" class="inactive-client">
        INACTIVE
      </div>
    </td>
    <td>{{ capture.sourcePatient.mrn }}</td>
    <td>{{ capture.candidate.patientFullName }}</td>
    <td>{{ capture.sourcePatient.fullName }}</td>
    <td>{{ capture.sourcePatient.dob }}</td>
    <td>
      <app-prescriber-name [prescriber]="capture.prescriber" [linkToProvider]="true" />
    </td>
    <td>
      {{ capture.estimatedValueForDisplay }}
      <app-high-value-indicator [capture]="capture" />
    </td>
    <td>{{ capture.candidate.product }}</td>
  </tr>
  </tbody>
</table>
