<div id="validatePatientEncounters" class="card mt-1">
  <h6 class="card-title m-0 px-2 py-1 d-flex">
    <mat-icon>event</mat-icon>
    <div class="ml-1 pt-1">Patient Encounters</div>
  </h6>
  <div class="card-body p-1">

    <nav *ngIf="showIneligibleEncounters" class="nav nav-pills mb-2">
      <a class="nav-item nav-link active"
         [class.active]="currentTab === 'eligibleEncounters'"
         (click)="tabClick($event, 'eligibleEncounters')"
         href="#">
        Eligible Encounters ({{ eligibleEncounters.length }})
      </a>
      <a class="nav-item nav-link"
         [class.active]="currentTab === 'ineligibleEncounters'"
         (click)="tabClick($event, 'ineligibleEncounters')"
         href="#">
        Ineligible Encounters ({{ ineligibleEncounters.length }})
      </a>
    </nav>

    <ng-container
      *ngIf="!showIneligibleEncounters || currentTab === 'eligibleEncounters'"
      [ngTemplateOutlet]="encounterList"
      [ngTemplateOutletContext]="{ encounters: eligibleEncounters }" />

    <ng-container
      *ngIf="currentTab === 'ineligibleEncounters'"
      [ngTemplateOutlet]="encounterList"
      [ngTemplateOutletContext]="{ encounters: ineligibleEncounters, rowClass: 'ineligible' }" />

  </div>
</div>

<ng-template #encounterList let-encounters="encounters" let-rowClass="rowClass">
  <ng-container *ngIf="!loading && encounters.length > 0; else: noEncounters">
    <table class="table table-sm">
      <thead class="thead-dark">
      <th>ID</th>
      <th>DATE</th>
      <th>PROVIDER</th>
      <th>ENC-LOCATION</th>
      <th>ENC-TYPE</th>
      </thead>
      <tbody>
      <tr *ngFor="let encounter of encounters" class="encounter" [ngClass]="rowClass">
        <td>{{ encounter.ehrIdentifier }}</td>
        <td>{{ encounter.encounterDate | date: 'MM/dd/yyyy' }}</td>
        <td>{{ encounter.providerDisplayNameWithNpi }}</td>
        <td>{{ encounter.location }}</td>
        <td>{{ encounter.encounterType }}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</ng-template>

<ng-template #noEncounters> <div class="m-2">No encounters found.</div> </ng-template>
