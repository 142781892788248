<h3>Provider Info Requests</h3>
<div *ngIf="!loading; else: spinner">
  <section *ngIf="prescriberIncorrectInformationRequests.length > 0; else: noRequests">
    <app-pagination-header
      [label]="'provider info requests'"
      [paging]="paging"
      (pageSizeChange)="onPageSizeChange($event)"
    />
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>CLIENT</th>
          <th>USER</th>
          <th>PRESCRIBER</th>
          <th>SPECIALIZATION / CLASSIFICATION</th>
          <th>COMMENTS</th>
          <th>STATUS</th>
          <th>DATE REPORTED</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let request of prescriberIncorrectInformationRequests; let i = index">
          <td>{{ request.client.humanizedName }}</td>
          <td>{{ request.submittedBy.fullName }}</td>
          <td><app-prescriber-name [prescriber]="request.prescriber" [linkToProvider]="true" /></td>
          <td>{{ request.prescriber.displaySpecialty }}</td>
          <td>{{ request.comments }}</td>
          <td>
            <span *ngIf="request.resolvedAt">
              Resolved ({{ request.resolvedBy.email }})
            </span>
            <span *ngIf="!request.resolvedAt">
              New
            </span>
          </td>
          <td>{{ request.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
          <td>
            <ul class="nav">
              <li class="nav-item">
                <a href="#" (click)="resolve($event, request)" *ngIf="!request.resolvedAt">
                  Resolve
                </a>
                <a href="#" (click)="unresolve($event, request)" *ngIf="request.resolvedAt">
                  Cancel
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noRequests>
  <section><p>There are no provider info requests.</p></section>
</ng-template>
<ng-template #spinner> <mat-spinner /> </ng-template>
