<td class="text-center">
  <fa-icon *ngIf="collapsed" class="text-secondary" [icon]="['fas', 'plus-square']" />
  <fa-icon *ngIf="!collapsed" class="text-secondary" [icon]="['fas', 'minus-square']" />
</td>
<td *ngIf="forProvider"><app-patient-name [patient]="prescription.patient" /></td>
<td>
  <ng-container *ngIf="prescription.drugs && prescription.drugs.length > 0">
    <div *ngFor="let drug of prescription.drugs" class="mb-1">
      <div>
        {{ drug.name }}
        <app-copy-to-clipboard [text]="drug.name" />
      </div>
      <div class="small">
        <strong>NDC:</strong> {{ drug.ndc }}
        <app-copy-to-clipboard [text]="drug.ndc" />
      </div>
    </div>
  </ng-container>
</td>
<td *ngIf="!forProvider"><app-prescriber-name [prescriber]="prescription.prescriber" /></td>
<td *ngIf="!forProvider">
  <div>{{ prescription.prescriber.displaySpecialty }}</div>
  <small *ngIf="prescription.prescriber.specialtyConfirmed"><strong>CONFIRMED</strong></small>
</td>
<td class="text-center">{{ prescription.writtenDate | date: 'MM/dd/yyyy' }}</td>
<td class="text-center" *ngIf="showEstimatedValue">{{ prescription.estimatedValue | currency }}</td>
<td>{{ prescription.sources && prescription.sources.join(', ') }}</td>
<td class="text-center">
  <span
    class="py-1 px-3 rounded borders"
    [ngClass]="{
      'highlight-high-value': prescription.needsEvidenceCapturesCount > 0 && prescription.highValue,
      'highlight-not-high-value': prescription.needsEvidenceCapturesCount > 0 && !prescription.highValue
    }"
  >
    {{ prescription.needsEvidenceCapturesCount }}
  </span>
</td>
<td class="text-center">
  <span
    class="py-1 px-3 rounded borders"
    [ngClass]="{
      'highlight-high-value': prescription.pendingCapturesCount > 0 && prescription.highValue,
      'highlight-not-high-value': prescription.pendingCapturesCount > 0 && !prescription.highValue
    }"
  >
    {{ prescription.pendingCapturesCount }}
  </span>
</td>
<td class="text-center">
  <span [ngClass]="{ 'py-1 px-3 rounded borders highlight-rejected': prescription.rejectedCapturesCount > 0 }">{{
    prescription.rejectedCapturesCount
  }}</span>
</td>
<td class="text-center">
  <span
    class="py-1 px-3 rounded borders"
    [ngClass]="{
      'highlight-high-value': prescription.ceReviewCapturesCount > 0 && prescription.highValue,
      'highlight-not-high-value': prescription.ceReviewCapturesCount > 0 && !prescription.highValue
    }"
  >
    {{ prescription.ceReviewCapturesCount }}
  </span>
</td>
<td class="text-center">{{ prescription.verifiedCapturesCount }}</td>
<td class="text-center">
  <span [ngClass]="{ 'py-1 px-3 rounded borders highlight-reversed': prescription.reversedCapturesCount > 0 }">{{
    prescription.reversedCapturesCount
  }}</span>
</td>
<td class="text-center">{{ prescription.otherCapturesCount }}</td>
<td class="text-center">{{ prescription.capturesCount }}</td>
