<div class="d-flex flex-column h-100">
  <app-navbar />
  <app-toast />
  <div *ngIf="!browserSupported" class="alert alert-danger text-center mb-0">
    ALERT: We've detected an unsupported web browser. Continuing with this browser will likely result in poor
    functionality. Please use recent versions Google Chrome, Mozilla Firefox or Microsoft Edge to access R1 web
    applications.
  </div>
  <div class="main" [ngClass]="{ 'container-fluid': legacyLayout }"><router-outlet /></div>
  <app-fat-footer *ngIf="showFatFooter" />
</div>
