<div class="content">
  <div class="row mx-0">
    <div class="col-7 px-0 text-left">
      <h3>My Consult Notes</h3>
    </div>
    <div class="col-5 no-padding">
      <app-user-stats />
    </div>
  </div>

  <app-consult-note-queue-assign-user
    *ngIf="canAssignUser"
    [enabled]="hasConsultNoteSelection"
    [selectedCount]="consultNoteSelectionCount"
    (assignUser)="handleAssignUser($event)"
    (clearSelection)="handleClearSelection()"
    [hideOnNoSelection]="false"
    [totalCount]="resultCount"
    />
  <div class="mt-2" *ngIf="!loadingResults; else: spinner">
    <ng-container *ngIf="consultNoteQueueResults.length > 0; else: noConsultNotes">
      <div>
        <app-consult-note-queue-results
          [consultNoteQueueResults]="consultNoteQueueResults"
          [selectedConsultNoteIds]="selectedConsultNoteIds"
          (selectionChanged)="handleSelectionChange($event)"
          [canAssignUser]="canAssignUser"
          [myWorkQueue]="true"
        />
        <div *ngIf="hasMoreResults" class="text-center mb-4">
          <a href="#next" (click)="onNextClick($event)" class="load-next-consult-notes">
            View Next {{ limit }} Consult Notes
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #spinner>
    <div class="mt-2 ml-1"><mat-spinner diameter="30" /></div>
  </ng-template>
  <ng-template #noConsultNotes> <p class="mt-2 ml-1">No consult notes found. Try modifying the filters.</p></ng-template>
</div>
