<form (ngSubmit)="onFormSubmit()" [formGroup]="userForm">
  <div class="row">
    <div class="col-6">
      <div class="form-group is-invalid">
        <label><strong>First Name</strong></label>
        <input
          id="firstName"
          name="firstName"
          class="form-control"
          [ngClass]="invalidCss('firstName')"
          formControlName="firstName"
        />
        <div class="invalid-feedback">First Name is required.</div>
      </div>
      <div class="form-group">
        <label><strong>Last Name</strong></label>
        <input
          id="lastName"
          name="lastName"
          class="form-control"
          [ngClass]="invalidCss('lastName')"
          formControlName="lastName"
        />
        <div class="invalid-feedback">Last Name is required.</div>
      </div>
      <div class="form-group">
        <ng-container *ngIf="mode === formModes.create">
          <label><strong>Email</strong></label>
          <input id="email" name="email" class="form-control" [ngClass]="invalidCss('email')" formControlName="email" />
          <div *ngIf="emailRequired" class="invalid-feedback">Email is required.</div>
          <div *ngIf="!emailRequired && emailInvalid" class="invalid-feedback">Email is invalid.</div>
          <div *ngIf="emailInUse" class="invalid-feedback">Email is already is use.</div>
        </ng-container>
        <ng-container *ngIf="mode === formModes.update">
          <label><strong>Email</strong></label>
          <div>{{ user.email | blankValueDash }}</div>
        </ng-container>
      </div>
      <div class="form-group">
        <label><strong>Phone</strong></label>
        <input id="phone" name="phone" class="form-control" formControlName="phone" [ngClass]="invalidCss('phone')" />
        <div *ngIf="phoneRequired" class="invalid-feedback">Phone is required.</div>
        <div *ngIf="!phoneRequired && phoneInvalid" class="invalid-feedback">Phone is invalid.</div>
      </div>
      <div class="form-group user-type">
        <label><strong>Type</strong></label>
        <div class="form-check">
          <input
            id="captureAdmin"
            class="form-check-input"
            type="radio"
            name="userType"
            formControlName="userType"
            [ngClass]="invalidCss('userType')"
            [value]="userTypes.captureAdmin"
          />
          <label class="form-check-label" for="captureAdmin"> Capture Admin </label>
        </div>
        <div class="form-check">
          <input
            id="cePortal"
            class="form-check-input"
            type="radio"
            name="userType"
            formControlName="userType"
            [ngClass]="invalidCss('userType')"
            [value]="userTypes.cePortal"
          />
          <label class="form-check-label" for="cePortal"> CE Portal </label>
        </div>
        <div class="form-check">
          <input
            id="cePortalConsultant"
            class="form-check-input"
            type="radio"
            name="userType"
            formControlName="userType"
            [ngClass]="invalidCss('userType')"
            [value]="userTypes.cePortalConsultant"
          />
          <label class="form-check-label" for="cePortalConsultant"> CE External Consultant </label>
        </div>
        <div class="form-check">
          <input
            id="cePortalInternalConsultant"
            class="form-check-input"
            type="radio"
            name="userType"
            formControlName="userType"
            [ngClass]="invalidCss('userType')"
            [value]="userTypes.cePortalInternalConsultant"
          />
          <label class="form-check-label" for="cePortalInternalConsultant"> Internal Consultant </label>
        </div>
        <div class="form-check">
          <input
            id="partnerPortal"
            class="form-check-input"
            type="radio"
            name="userType"
            formControlName="userType"
            [ngClass]="invalidCss('userType')"
            [value]="userTypes.partnerPortal"
          />
          <label class="form-check-label" for="partnerPortal"> Partner Portal </label>
          <div class="invalid-feedback">Type is required.</div>
        </div>
        <div class="form-check">
          <input
            id="llmPlaygroundUser"
            class="form-check-input"
            type="radio"
            name="userType"
            formControlName="userType"
            [ngClass]="invalidCss('userType')"
            [value]="userTypes.llmPlaygroundUser"
          />
          <label class="form-check-label" for="llmPlaygroundUser"> LLM Playground User </label>
        </div>
      </div>
      <div [hidden]="userForm.controls['userType'].value !== userTypes.captureAdmin" class="form-group">
        <label><strong>User Roles</strong></label>
        <div>
          <ng-select
            class="select-roles"
            bindValue="name"
            bindLabel="humanizedName"
            formControlName="roleNames"
            placeholder="Select Roles(s)"
            [multiple]="true"
            [items]="roles"
            [ngClass]="invalidCss('roleNames')"
          />
        </div>
      </div>
      <div
        [hidden]="!isCePortalUserType(userForm.controls['userType'].value)"
        class="form-group"
      >
        <label><strong>Clients</strong></label>
        <div>
          <ng-select
            class="select-clients"
            bindValue="name"
            bindLabel="humanizedName"
            formControlName="clientNames"
            placeholder="Select Client(s)"
            [multiple]="true"
            [items]="clients"
            [ngClass]="invalidCss('clientNames')"
          />
        </div>
        <div *ngIf="isControlInvalid('clientNames')" class="invalid-feedback d-block">
          At least one client is required.
        </div>
      </div>
      <div [hidden]="userForm.controls['userType'].value !== userTypes.partnerPortal" class="form-group">
        <label><strong>Source</strong></label>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="source"
              formControlName="source"
              [ngClass]="invalidCss('source')"
              [value]="claimSources.walgreens"
              />
            Walgreens
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="source"
              formControlName="source"
              [ngClass]="invalidCss('source')"
              [value]="claimSources.hudsonHeadwaters" />
            Hudson Headwaters
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="source"
              formControlName="source"
              [ngClass]="invalidCss('source')"
              [value]="claimSources.equiscripts" />
            Equiscripts
          </label>
          <div class="invalid-feedback">Source is required.</div>
        </div>
      </div>
      <div
        [hidden]="userForm.controls['userType'].value !== userTypes.captureAdmin"
        class="form-group"
      >
        <label><strong>Assigned Clients</strong></label>
        <div>
          <ng-select
            class="select-clients"
            bindValue="name"
            bindLabel="humanizedName"
            formControlName="assignedClients"
            placeholder="Select Client(s)"
            [multiple]="true"
            [items]="clients"
            [ngClass]="invalidCss('assignedClients')"
          />
        </div>
        <div *ngIf="isControlInvalid('assignedClients')" class="invalid-feedback d-block">
          At least one client is required.
        </div>
      </div>
      <div
        [hidden]="!isCePortalUserType(userForm.controls['userType'].value)"
        class="form-group"
      >
        <label><strong>SAML Authentication Provider</strong></label>
        <div>
          <ng-select
            class="single-row"
            bindValue="id"
            bindLabel="displayName"
            formControlName="samlProvider"
            placeholder="Select Saml Provider"
            [items]="samlProviders"
          />
        </div>
      </div>
      <div class="text-right actions">
        <button type="submit" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
      </div>
    </div>
  </div>
</form>
