<div *ngIf="!refreshing; else: spinner">
  <div class="row no-gutters align-items-end mb-1">
    <div class="col">
      <strong>{{ patientAttachment.fileName }}</strong>
    </div>
    <div class="col text-right">
      <button class="btn btn-sm btn-secondary" (click)="onDownloadClick($event)">
        <fa-icon [icon]="['fas', 'cloud-download-alt']" /> Download
      </button>
    </div>
  </div>
  <app-pdf-file-viewer
    [pdfFile]="patientAttachment"
    [pdfFileType]="pdfFileType"
    [initialPage]="1"
    [hideRotationControls]="true"
    height="80vh"
  />
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
