<div class="card mt-2">
  <div class="card-body">
    <h5>Encounters</h5>
    <ng-container *ngIf="!loadingEncounters; else: encounterSpinner">
      <nav *ngIf="showIneligibleEncounters" class="nav nav-pills mb-2">
        <a class="nav-item nav-link active"
           [class.active]="currentTab === 'eligibleEncounters'"
           (click)="tabClick($event, 'eligibleEncounters')"
           href="#">
          Eligible Encounters ({{ eligibleEncounters.length }})
        </a>
        <a class="nav-item nav-link"
           [class.active]="currentTab === 'ineligibleEncounters'"
           (click)="tabClick($event, 'ineligibleEncounters')"
           href="#">
          Ineligible Encounters ({{ ineligibleEncounters.length }})
        </a>
      </nav>

      <ng-container
        *ngIf="!showIneligibleEncounters || currentTab === 'eligibleEncounters'"
        [ngTemplateOutlet]="encounterList"
        [ngTemplateOutletContext]="{ encounters: eligibleEncounters }" />

      <ng-container
        *ngIf="currentTab === 'ineligibleEncounters'"
        [ngTemplateOutlet]="encounterList"
        [ngTemplateOutletContext]="{ encounters: ineligibleEncounters, rowClass: 'ineligible' }" />
    </ng-container>
  </div>
</div>

<ng-template #encounterList let-encounters="encounters" let-rowClass="rowClass">
  <div *ngIf="encounters.length > 0; else: noEncounters">
    <p>Encounters provided by client encounter feed.</p>
    <div class="scrollable">
      <table class="table table-sm table-striped">
        <thead>
          <th>ENC-ID</th>
          <th class="text-nowrap">ENC-DATE &#x25BC;</th>
          <th class="text-nowrap">ENC-PROVIDER</th>
          <th class="text-nowrap">ENC-LOCATION</th>
          <th class="text-nowrap">ENC-TYPE</th>
        </thead>
        <tbody>
          <tr *ngFor="let encounter of encounters" class="encounter" [ngClass]="rowClass">
            <td>{{ encounter.ehrIdentifier }}</td>
            <td>{{ encounter.encounterDate | date: 'MM/dd/yyyy' }}</td>
            <td>{{ encounter.providerFirst }} {{ encounter.providerLast }} ({{ encounter.providerNpi }})</td>
            <td>{{ encounter.location }}</td>
            <td>{{ encounter.encounterType }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #noEncounters> <p>There are no encounters.</p> </ng-template>
<ng-template #encounterSpinner> <mat-spinner [diameter]="30" /> </ng-template>
