import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { MedicalClassification } from 'app/core/models/medical-classification.model';

@Injectable()
export class MedicalClassificationService extends ApiService {
  constructor(private http: HttpClient) {
    super();
  }

  getList(): Observable<{ medicalClassifications: MedicalClassification[] }> {
    return this.http.get<{ medicalClassifications: MedicalClassification[] }>(
      `${environment.captureApi.url}/medical_classifications`
    );
  }
}
