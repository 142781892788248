<h3>Add New Provider</h3>

<form [formGroup]="formGroup" (submit)="onSubmit()" #form="ngForm">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label>Provider First Name (Required)</label>
        <input
          class="form-control"
          formControlName="first"
          appFormControlValidityClass>
        <app-form-control-server-errors
          [form]="form"
          controlName="first" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label>Provider Last Name (Required)</label>
        <input
          class="form-control"
          formControlName="last"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="last" />
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <label>Provider Credential</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="credentials"
          [items]="providerCredentialOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="credentials" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label>NPI (Required)</label>
        <input
          class="form-control"
          formControlName="npi"
          appFormControlValidityClass
          maxlength="10">
        <app-form-control-server-errors
          [form]="form"
          controlName="npi" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label>Provider Status (Required)</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="status"
          [items]="providerStatusOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="status" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label>Provider Classification</label>
        <ng-select
          bindValue="name"
          bindLabel="name"
          formControlName="classification"
          [items]="medicalClassifications"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="classification" />
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label>Provider Specialization</label>
        <ng-select
          bindValue="name"
          bindLabel="name"
          formControlName="specialization"
          [items]="medicalSpecializations"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="specialization" />
      </div>
    </div>
    <div class="col">
      <div class="form-group py-2 px-2 border" id="specialty-confirmed">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="specialtyConfirmed">
          <label class="form-check-label">Specialty Confirmed</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label>Do Not Send Reason</label>
        <ng-select
          bindValue="value"
          bindLabel="display"
          formControlName="doNotSendReason"
          [items]="providerDoNotSendReasonOptions"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="doNotSendReason" />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Do Not Send Reason Details</label>
        <input
          class="form-control"
          formControlName="additionalDetailsDns"
          appFormControlValidityClass />
        <app-form-control-server-errors
          [form]="form"
          controlName="additionalDetailsDns" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <div class="form-group">
        <label>Note</label>
        <textarea
          class="form-control"
          formControlName="note"
          rows="5"
          appFormControlValidityClass>
        </textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-10 text-right actions">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="form.submitted && formGroup.invalid || saving">
        Save
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onCancelClick()"
        [disabled]="saving">
        Cancel
      </button>
    </div>
  </div>

</form>
