<form (ngSubmit)="onUploadSubmit()" class="form-inline" [ngClass]="{ disabled: disabled }">
  <div class="form-group custom-file-form-group">
    <label *ngIf="!hideLabel"> <strong>File</strong> </label>
    <div class="custom-file" id="customFile">
      <input
        id="fileInput"
        [accept]="fileType"
        type="file"
        class="custom-file-input"
        (change)="onFileChange($event)"
        #fileInput
        [disabled]="disabled"
      />
      <label class="custom-file-label" for="fileInput"> {{ fileName() }} </label>
    </div>
  </div>
  <div [ngClass]="{'pt-4': !hideLabel}" class="form-group ml-sm-2" *ngIf="file">
    <button type="submit" [disabled]="disabled || uploadButtonDisabled" class="btn btn-primary">
      {{ uploadButtonLabel }}
    </button>
    <button type="reset" [disabled]="disabled" class="btn btn-secondary ml-sm-1" (click)="onCancelClick()">
      Cancel
    </button>
  </div>
</form>
