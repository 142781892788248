<div class="scrollable">
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th>
          Referral Date
        </th>
        <th>
          Referral Type
        </th>
        <th>
          Referral Notes
        </th>
        <th>
          Specialty
        </th>
        <th>
          Prescriber
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rmv of referralMatchVerifications">
        <td>
          {{ rmv.clientReferralVerification.referralDate | date: 'MM/dd/yyyy' }}
        </td>
        <td>
          {{ rmv.clientReferralVerification.clientReferralTaskSubmission.choiceReasons | referralVerificationReasonShortNames | joinList }}
        </td>
        <td>
          {{ rmv.clientReferralVerification.clientReferralTaskSubmission.choiceOtherReasonNote | blankValueDash }}
        </td>
        <td>
          {{ rmv.clientReferralVerification.referralSpecialty | blankValueDash }}
        </td>
        <td>
          <div>{{ rmv.clientReferralVerification.prescriber.fullName }}</div>
          <div>NPI: {{ rmv.clientReferralVerification.prescriber.npi }}</div>
          <div>{{ rmv.clientReferralVerification.prescriber.displaySpecialty }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
