<ng-container *ngIf="!refreshing; else: spinner">
  <h6 class="card-title m-0 px-2 py-1 d-flex">
    <mat-icon>event</mat-icon>
    <div class="ml-1 pt-1">Provider Encounters</div>
  </h6>

  <div class="row ml-2 mt-2">
    <nav *ngIf="provider.ineligibleCount > 0" class="nav nav-pills m-2">
      <a
        class="nav-item nav-link"
        href="#"
        (click)="navClick($event, true)"
        [class.active]="eligible === true"
        [class.disabled]="eligible === true"
      >
        Eligible Encounters <small>({{provider.eligibleCount}})</small>
      </a>
      <a
        class="nav-item nav-link"
        href="#"
        (click)="navClick($event, false)"
        [class.active]="eligible === false"
        [class.disabled]="eligible === false"
      >
        Ineligible Encounters <small>({{provider.ineligibleCount}})</small>
      </a>
    </nav>
  </div>
  <div *ngIf="encounters.length > 0; else: noEncounters">
    <div class="m{{ eligible ? 'eligible' : 'inelibible' }} t-2">
      <app-pagination-header [paging]="paging" [label]="'encounters'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <div class="mt-2" *ngIf="!refreshing">
      <table class="table table-bordered table-hover">
        <thead class="thead-dark">
          <tr>
            <th>ID</th>
            <th>EHR ID</th>
            <th>PATIENT</th>
            <th>DATE</th>
            <th>PROVIDER</th>
            <th>CLIENT</th>
            <th>ENC-LOCATION</th>
            <th>ENC-TYPE</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let encounter of encounters">
            <td>
              {{ encounter.id }}
            </td>
            <td>
              {{ encounter.ehrIdentifier }}
            </td>
            <td>
              {{ encounter.patientFirst }} {{ encounter.patientLast }} ({{ encounter.patientDob | date: 'MM/dd/yyyy' }})
            </td>
            <td>
              {{ encounter.encounterDate | date: 'MM/dd/yyyy' }}
            </td>
            <td>
              {{ encounter.providerDisplayNameWithNpi }}
            </td>
            <td>
              {{ encounter.client }}
            </td>
            <td>
              {{ encounter.location }}
            </td>
            <td>
              {{ encounter.encounterType }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2">
        <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
      </div>
    </div>
  </div>
  <ng-template #noEncounters><p class="ml-1">No {{ eligible ? 'eligible' : 'inelibible' }} encounters.</p></ng-template>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>

