<h3>Patients</h3>
<div class="row">
  <div class="col-12">
    <app-patient-filters [filters]="filters" (filtersChange)="onFiltersChange($event)" />
  </div>
</div>

<div *ngIf="validSearch; else: invalidSearch">
  <div *ngIf="!refreshing; else: spinner">
    <div *ngIf="patients.length > 0; else noPatients">
      <div class="mt-2">
        <div class="row no-gutters mb-1">
          <div class="col-6">
            <span>Viewing {{ viewing }} of <strong>{{ paging.count | number: '' }} </strong>patients</span>
          </div>
          <div class="col-6 text-right">
            Page {{ paging.currentPage }} of {{ paging.totalPages }}
          </div>
        </div>
      </div>

      <div class="mt-2">
        <table class="table table-bordered table-hover">
          <thead class="thead-dark">
            <tr>
              <th>Name</th>
              <th>Patient ID</th>
              <th>Main Patient ID</th>
              <th>DOB</th>
              <th>Gender</th>
              <th>EHR Instance</th>
              <th>MRN</th>
              <th>Client</th>
              <th>Patient Status</th>
              <th>Capture Count</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let patient of patients">
              <td>
                <a
                  routerLink="/capture-admin/patients/{{ patientMainPatientId(patient) }}"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }">
                  {{ patient.fullName }}
                </a>
              </td>
              <td>
                {{ patient.id }}
              </td>
              <td>
                {{ patient.mainPatientId | blankValueDash }}
              </td>
              <td>
                {{ patient.dob | date: 'M/dd/yyyy' }}
              </td>
              <td>
                {{ patient.gender | gender }}
              </td>
              <td>
                {{ patient.ehrInstance | blankValueDash }}
              </td>
              <td>
                {{ patient.mrn }}
              </td>
              <td>
                {{ patient.clientName }}
              </td>
              <td>
                {{ patientStatus(patient) }}
              </td>
              <td>
                {{ patient.captureCount }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2">
          <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div *ngIf="refreshing" class="ml-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noPatients>
  <p class="ml-1">No patients.</p>
</ng-template>

<ng-template #invalidSearch>
  <p class="mt-2 ml-1">Please use one of the filters above to limit the number of patients.</p>
</ng-template>

