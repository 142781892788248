<form>
  <div class="form-group">
    <label for="notes" class="my-1"><strong>ADDITIONAL DETAILS </strong>({{ additionalDetailsRequired ? "Required" : "Optional" }})</label>
    <textarea
      id="notes"
      name="notes"
      class="form-control"
      rows="3"
      [(ngModel)]="formModel.notes"
      (change)="onNotesChange()"
      [disabled]="formDisabled || task.frozen"
    >
    </textarea>
  </div>
  <div [hidden]="!consultNoteTaskCreationAllowed" class="form-group">
    <div class="form-check">
      <input
        id="collectConsultNote"
        name="collectConsultNote"
        class="form-check-input"
        type="checkbox"
        [(ngModel)]="formModel.collectConsultNote"
      />
      <label for="collectConsultNote" class="form-check-label">
        <small>
          I have a relevant consult note for this patient and prescriber, and I would like to complete the consult note
          task following submission of this task. <br />NOTE: If you do not select this option, R1 will attempt to
          collect the consult note for you.
        </small>
      </label>
    </div>
  </div>
</form>
<div *ngIf="serverError" class="mb-3">
  <div class="alert-danger p-2">{{ serverErrorMsg }}</div>
</div>
<div class="text-right mt-2">
  <button *ngIf="submitAllowed" class="btn btn-primary" [disabled]="!submitEnabled" (click)="onSubmitClick($event)">
    <fa-icon [icon]="['fas', 'cloud-upload-alt']" /> Submit to R1
  </button>
  <button *ngIf="nextButtonEnabled" class="btn btn-secondary" (click)="onNextClick($event)">
    Next <fa-icon [icon]="['fas', 'angle-double-right']" />
  </button>
</div>
