<app-import-nav />
<h5 class="mt-3">Import Medicaid Codes</h5>

<div class="alert alert-danger" *ngIf="hasError">
  There was a problem uploading the file.
  <ul class="mt-2">
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>

<app-icc-import-upload
  (uploadComplete)="onUploadComplete($event)"
  (uploadStart)="onUploadStart()"
/>

<div class="mt-3 border p-3 rounded">
  <strong>File Requirements</strong>
  <ol class="mx-3 pl-2">
    <li class="py-2">
      Possible fields
      <ul class="pl-2">
        <li><code>bin</code> <span>(required, 6 digit)</span></li>
        <li><code>pcn</code></li>
        <li><code>group_code</code></li>
      </ul>
    </li>
    <li class="py-2">
      Example file
      <div>
        <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive.</small>
      </div>
      <div class="bg-light p-2 border w-100">
        <small>
          <strong>
            <code class="csv-header">
              bin,pcn,group_code
            </code>
          </strong>
          <br />
          <code>123456,INM,RXINN01</code><br />
          <code>123457,ASPROD1,RX0797</code><br />
        </small>
      </div>
    </li>
    <li class="py-2"><code>.csv</code> only</li>
  </ol>
</div>
