import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ClientChoreStatus } from 'app/core/enums/client-chore-status.enum';
import { AvailableClientChore } from 'app/core/models/available-client-chore.model';
import { Client } from 'app/core/models/client.model';
import { MinimalClient } from 'app/core/models/minimal-client.model';
import { ClientChoreFilters } from 'app/core/models/user-settings/client-chore-settings.model';
import { User } from 'app/core/models/user.model';
import { ClientChoresService } from 'app/core/services/client-chores.service';
import { ClientsService } from 'app/core/services/clients.service';
import { UsersService } from 'app/core/services/users.service';
import { sortBy } from 'lodash-es';

class ClientChoreStatusOption {
  value: string;
  display: string
}

@Component({
  selector: 'app-client-chore-filters',
  templateUrl: './client-chore-filters.component.html',
  styleUrls: ['./client-chore-filters.component.scss'],
})
export class ClientChoreFiltersComponent implements OnInit {
  @Input() filters: ClientChoreFilters = new ClientChoreFilters();
  @Output() filterChange = new EventEmitter<ClientChoreFilters>();

  @Input() clients: (Client | MinimalClient)[];
  @Input() statuses: ClientChoreStatus[];
  @Input() showSubmittedByFilter = true;

  users: User[];
  availableChores: AvailableClientChore[];
  statusOptions: ClientChoreStatusOption[];

  constructor(
    private clientChoresService: ClientChoresService,
    private clientsService: ClientsService,
    private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.loadClients();
    this.loadUsers();
    this.loadAvailableChores();
    this.mapStatusOptions();
  }

  private loadClients() {
    if (!this.clients) {
      this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
        this.clients = result.clients;
      });
    }
  }

  private loadUsers() {
    this.clientChoresService.getSubmittingUsers().subscribe(result => {
      this.users = sortBy(result.users, user => user.fullName.toLowerCase());
    });
  }

  private mapStatusOptions() {
    const titleCasePipe = new TitleCasePipe();
    this.statusOptions =
      this.statuses.map(v => ({ value: v, display: titleCasePipe.transform(v) }));
  }

  private loadAvailableChores() {
    this.clientChoresService.getAvailableChores().subscribe(result => {
      this.availableChores = sortBy(result.availableClientChores, availableChore => availableChore.displayName);
    });
  }

  onFilterChange() {
    this.filterChange.emit(this.filters);
  }
}
