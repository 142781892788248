<div class="form-inline">
  <div class="form-check form-check-line">
    <input
      [id]="'drugMatchVerificationMatchesYes' + position"
      [name]="'drugMatchVerificationMatchesYes' + position"
      class="form-check-input form-control"
      type="radio"
      [disabled]="drugMatchOption.frozen"
      (change)="onDrugMatchesChange(drugMatchOption)"
      [(ngModel)]="drugMatchOption.verification.matches"
      [value]="true"
    />
    <label class="form-check-label" [for]="'drugMatchVerificationMatchesYes' + position">Yes</label>
  </div>
  <div class="form-check form-check-line mx-2">
    <input
      [id]="'drugMatchVerificationMatchesNo' + position"
      [name]="'drugMatchVerificationMatchesNo' + position"
      class="form-check-input form-control"
      type="radio"
      [disabled]="drugMatchOption.frozen"
      (change)="onDrugMatchesChange(drugMatchOption)"
      [(ngModel)]="drugMatchOption.verification.matches"
      [value]="false"
    />
    <label class="form-check-label" [for]="'drugMatchVerificationMatchesNo' + position">No</label>
  </div>
</div>
