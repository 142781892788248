import { Component, Input, OnInit } from '@angular/core';
import { Prescriber } from '../../../core/models/prescriber.model';
import { Patient } from '../../../core/models/patient.model';
import { PatientAttachment } from '../../../core/models/patient-attachment.model';
import { PagedPatientAttachments, PatientAttachmentsService } from '../../../core/services/patient-attachments.service';
import { DownloadService } from '../../../core/services/download.service';

@Component({
  selector: 'app-patient-prescriber-consult-notes',
  templateUrl: './patient-prescriber-consult-notes.component.html',
  styleUrls: ['./patient-prescriber-consult-notes.component.scss'],
})
export class PatientPrescriberConsultNotesComponent implements OnInit {
  @Input() patient: Patient;
  @Input() prescriber: Prescriber;

  loading = false;
  patientAttachments: PatientAttachment[] = [];

  constructor(private patientAttachmentsService: PatientAttachmentsService, private downloadService: DownloadService) {}

  ngOnInit() {
    this.loadPatientAttachments();
  }

  download($event, patientAttachment: PatientAttachment) {
    $event.preventDefault();
    this.patientAttachmentsService.get(patientAttachment.id).subscribe(attachment => {
      this.downloadService.download(attachment.url);
    });
  }

  private loadPatientAttachments() {
    this.loading = true;

    this.patientAttachmentsService
      .getPatientPrescriberList(this.patient.id, this.prescriber.id)
      .subscribe((pageResults: PagedPatientAttachments) => {
        this.loading = false;
        this.patientAttachments = pageResults.patientAttachments;
      });
  }
}
