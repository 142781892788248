<div *ngIf="!loading; else: spinner">
  <table class="table w-100">
    <thead class="thead-dark">
      <tr>
        <th>ID</th>
        <th>Office Details</th>
        <th>Office Communication Method</th>
        <th>Office Fax Status</th>
        <th>Provider Fax Status</th>
        <th>Association Note</th>
      </tr>
    </thead>

    <tbody *ngIf="providerOffices.length > 0">
      <tr *ngFor="let providerOffice of providerOffices" class="col-4">
        <td>{{ providerOffice.office.id }}</td>
        <td>
          <app-office [office]="providerOffice.office" />
        </td>
        <td>{{ providerOffice.office.communicationMethod }}</td>
        <td>{{ keyedOfficeStatusOptions[providerOffice.office.status]?.display }}</td>
        <td class="status-column">
          <app-provider-office-fax-status [providerOffice]="providerOffice" />
        </td>
        <td class="small notes-column">
          <app-notes-for-model
            [model]="providerOffice"
            [modelType]="'ProviderOffice'"
            [minimizeControls]="true"
          />
        </td>
      </tr>
    <tbody>

    <tbody *ngIf="providerOffices.length === 0">
      <tr>
        <td colspan="999" class="no-results">
          No offices associated to this provider yet.
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #spinner> <mat-spinner /> </ng-template>
