export enum ProviderOfficeStatus {
  validFax = 'valid_fax',
  verified = 'verified',
  unresponsiveOffice = 'unresponsive_office',
  unresponsiveProvider = 'unresponsive_provider',
  verifiedSuccessful = 'verified_successful',
  verifiedSuccessful2 = 'verified_successful_2',
  verifiedSuccessful3 = 'verified_successful_3',
  transmittedNoResponse = 'transmitted_no_response',
  preferred = 'preferred',
  testing = 'testing',
  testingMechanicalTurk = 'testing_mechanical_turk',
  testingBops = 'testing_bops',
  faxFailed = 'fax_failed',
  providerNoLongerAtOffice = 'provider_no_longer_at_office',
  officeOptedOut = 'office_opted_out',
  notAMedicalOffice = 'not_a_medical_office',
  providerNotAtOffice = 'provider_not_at_office'
}
