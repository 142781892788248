import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  templateUrl: './capture-admin-faxable-patient-prescriber-office-reports.component.html',
})
export class CaptureAdminFaxablePatientPrescriberOfficeReportsComponent {
  reportsUrl = `${environment.captureApi.url}/faxable_patient_prescriber_office_reports`;
  reportsCountUrl = `${environment.captureApi.url}/faxable_patient_prescriber_office_reports/count`;

  constructor(private formBuilder: UntypedFormBuilder) {}

  filtersForm = this.formBuilder.group({
    processedDate: [null],
  });
}
