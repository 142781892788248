<div class="card mt-1">
  <div class="card-body">
    <h4>Validate Specialist Encounter Timeframe</h4>
    <form class="form" *ngIf="verification">
      <p>Specialist encounter date.</p>
      <div class="row align-items-center no-gutters">
        <div class="col-auto">
          <input
            name="encounterDate"
            class="form-control"
            type="date"
            [(ngModel)]="verification.encounterDate"
            disabled="true"
          />
        </div>
        <div class="col-auto ml-2">
          <fa-icon [icon]="['fas', 'check']" size="2x" class="text-success" />
        </div>
      </div>
    </form>
  </div>
</div>
