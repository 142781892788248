<div class="ml-1 pb-3">
  <div>
    <strong>STATUS</strong>
    <p>{{ capture.status | captureStatus }}</p>
  </div>
  <div *ngIf="reason">
    <strong>REASONS</strong>
    <ul class="pl-4">
      <li class="py-1">{{ reason.display }}</li>
    </ul>
  </div>
  <div *ngIf="patientPrescriberNote?.active">
    <strong>NOTES FOR REFERRAL AND CONSULT NOTE TASKS</strong>
    <div class="bg-light border px-2 mt-2 mb-3">
      <app-note [note]="patientPrescriberNote" />
    </div>
  </div>
  <div *ngIf="verificationOutcome.additionalDetails">
    <strong>ADDITIONAL DETAILS</strong>
    <p>{{ verificationOutcome.additionalDetails }}</p>
  </div>
</div>
