<div class="prescription-list-wrapper">
  <table class="prescription-list table"
         [ngClass]="{ 'table-sm': small }">
    <thead [ngClass]="{ 'thead-dark': dark }">
      <tr>
        <th>&nbsp;</th>
        <th *ngIf="forProvider">PATIENT</th>
        <th>DRUG</th>
        <th *ngIf="!forProvider">PRESCRIBER</th>
        <th *ngIf="!forProvider">PRESCRIBER SPECIALITY</th>
        <th class="text-right">WRITTEN DATE</th>
        <th class="text-right" *ngIf="showEstimatedValue">EST. VALUE</th>
        <th>SOURCE</th>
        <th class="text-center">NEEDS EVIDENCE</th>
        <th class="text-center">PENDING</th>
        <th class="text-center">REJECTED</th>
        <th class="text-center">CE REVIEW</th>
        <th class="text-center">VERIFIED</th>
        <th class="text-center">REVERSED</th>
        <th class="text-center">OTHER</th>
        <th class="text-center">TOTAL</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let prescription of prescriptions trackBy: prescriptionTrackedBy">
        <tr
          app-prescription-list-row
          class="prescription-list-row"
          [prescription]="prescription"
          [collapsed]="isPrescriptionCollapsed(prescription)"
          [showEstimatedValue]="showEstimatedValue"
          [forProvider]="forProvider"
          [ngClass]="{ 'bg-light': !isPrescriptionCollapsed(prescription) }"
          (click)="onToggleClick(prescription)"
        ></tr>
        <tr
          app-prescription-list-row-detail
          class="prescription-item-row bg-light"
          [prescription]="prescription"
          [activeCaptureId]="activeCaptureId"
          [isOpen]="!isPrescriptionCollapsed(prescription)"
          [ngClass]="{ collapse: isPrescriptionCollapsed(prescription) }"
        ></tr>
      </ng-container>
    </tbody>
  </table>
</div>
