<h3 class="my-4 heading text-center">Forgot your password?</h3>
<div class="row">
  <div class="col-lg-4 offset-lg-4">
    <p>Enter your email and we'll send you a link to reset your password.</p>
    <form (ngSubmit)="onSubmit(forgotPasswordForm)" #forgotPasswordForm="ngForm">
      <div class="form-group">
        <label for="email">Email address</label>
        <input
          id="email"
          name="email"
          class="form-control"
          type="email"
          [(ngModel)]="email"
          required
          placeholder="Email"
        />
        <div class="invalid-feedback">Email address is required.</div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">Reset Password</button>
      <p *ngIf="submitted" class="text-danger my-2">
        An email has been sent to your inbox with instructions on resetting your password. If you do not receive an email within 10 minutes please contact R1 support.
      </p>
    </form>
    <p class="mt-3 text-center">
      Need help? <br/>Contact support at <strong><a href="mailto:{{ supportEmailAddress }}">{{ supportEmailAddress }}</a></strong> or call us at <strong><a href="tel:1-781-214-3424">1-781-214-3424</a></strong
      >.
    </p>
  </div>
</div>
