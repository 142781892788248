<div class="container-fluid p-2 border rounded bg-light">
  <form>
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label><strong>Status:</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="statuses"
            placeholder="Filter by Status"
            [multiple]="true"
            [items]="statusFilterOptions"
            (remove)="onStatusFilterChange()"
            (change)="onStatusFilterChange()"
            [(ngModel)]="filters.statuses"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label><strong>Status Reasons:</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="reasons"
            placeholder="Filter by Reasons"
            [multiple]="true"
            [items]="statusReasonFilterOptions"
            (remove)="onStatusReasonFilterChange()"
            (change)="onStatusReasonFilterChange()"
            [(ngModel)]="filters.reasons"
            [disabled]="statusReasonFilterOptions.length === 0"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label><strong>Clients:</strong></label>
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="humanizedName"
            name="clientIds"
            placeholder="Filter by Client"
            [multiple]="true"
            [items]="clientFilterOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.clientIds"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label><strong>Assigned Client Lead:</strong></label>
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="username"
            name="assignedUserIds"
            placeholder="Filter by Assigned Client Lead"
            [multiple]="true"
            [items]="assignedUsersFilterOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.assignedUserIds"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label><strong>State:</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="clientStates"
            placeholder="Filter by Client State"
            [multiple]="true"
            [items]="stateOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.clientStates"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label><strong>Sources:</strong></label>
          <ng-select
            class="w-100"
            bindValue="value"
            bindLabel="display"
            name="sources"
            placeholder="Filter by Source"
            [multiple]="true"
            [items]="claimSourceOptions"
            (remove)="onFilterChange()"
            (change)="onFilterChange()"
            [(ngModel)]="filters.sources"
          />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label><strong>Sort By:</strong></label>
          <ng-select
            class="w-100"
            name="cursorType"
            bindValue="value"
            bindLabel="display"
            [multiple]="false"
            [items]="cursorTypeOptions"
            [clearable]="false"
            [searchable]="false"
            (change)="onCursorTypeChange()"
            [(ngModel)]="cursorType"
          />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-9">
        <div class="form-row align-items-center">
          <div class="form-group col-auto">
            <strong>High Value:</strong>
            <mat-slide-toggle
              name="highValue"
              [color]="'primary'"
              [(ngModel)]="filters.highValue"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="form-group col-auto">
            <strong>Specialty:</strong>
            <mat-slide-toggle
              name="specialtyStore"
              [color]="'primary'"
              [(ngModel)]="filters.specialtyStore"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="form-group col-auto">
            <strong>Active:</strong>
            <mat-slide-toggle
              name="active"
              [color]="'primary'"
              [(ngModel)]="filters.activeClientsOnly"
              [disableRipple]="true"
              (change)="onFilterChange()"
            />
          </div>
          <div class="form-group col-auto">
            <div class="manual-fax-sent">
              <label class="manual-fax-sent-label mr-3"><strong>Manual Fax Sent:</strong></label>
              <div class="manual-fax-sent-options">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="manualFaxSent"
                    id="manualFaxSentYes"
                    (change)="onFilterChange()"
                    [value]="true"
                    [(ngModel)]="filters.manualFaxSent"
                  />
                  <label class="form-check-label" for="manualFaxSentYes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="manualFaxSent"
                    id="manualFaxSentNo"
                    (change)="onFilterChange()"
                    [value]="false"
                    [(ngModel)]="filters.manualFaxSent"
                  />
                  <label class="form-check-label" for="manualFaxSentNo">No</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="manualFaxSent"
                    id="manualFaxSentAll"
                    (change)="onFilterChange()"
                    [value]="null"
                    [(ngModel)]="filters.manualFaxSent"
                  />
                  <label class="form-check-label" for="manualFaxSentAll">All</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-auto">
            <label><strong>Days To Expiration:</strong></label>
          </div>
          <div class="form-group col-1">
            <input
              type="text"
              class="form-control form-control-sm"
              name="expiringWithinDays"
              appExcludeInputPattern
              excludedPattern="\D"
              maxlength="3"
              [(ngModel)]="filters.expiringWithinDays"
              (keyup)="onFilterChange()" />
          </div>
        </div>
      </div>
      <div class="col-3 text-right">
        <div>
          <span [hidden]="!updatingCaptureCount">
            <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
          </span>
          <span [hidden]="updatingCaptureCount"
            ><strong>{{ captureCount | number }}</strong> {{ captureCount === 1 ? 'capture' : 'captures' }}
          </span>
          | <a href="#resetFilters" class="text-danger reset-filters" (click)="onResetClick($event)">Reset Filters</a>
        </div>
      </div>
    </div>
  </form>
</div>
