export class Project {
  constructor(
    public name?: string,
    public batches?: Batch[],
    public examples?: Example[],
    public prompt?: Prompt,
    public id?: number,
    public createdByName?: string,
    public createdByEmail?: string,
    public collaboratorPermissions?: CollaboratorPermission[],
    public createdAt?: Date,
    public updatedAt?: Date
  ) {}
}

export class Prompt {
  constructor(
    public chats?: Chat[],
    public modelMetadata?: Array<{ value: string; name: string, maxTokens: number }>,
    public model?: string,
    public temperature?: number,
    public maxTokens?: number,
    public examples?: Example[],
    public systemMessage?: string,
    public userMessage?: string,
    public id?: number,
    public totalTokens?: number
  ) {}
}

export class Example {
  constructor(
    public userMessage?: string,
    public assistantMessage?: string,
    public id?: number,
    public promptId?: string,
    public _destroy?: boolean
  ) {}
}

export class Chat {
  constructor(
    public status?: string,
    public updatedAt?: Date,
    public batchIndex?: number,
    public userMessage?: string,
    public assistantMessage?: Date,
    public promptId?: string,
    public finishReason?: string,
    public id?: number,
    public model?: string
  ) {}
}

export class Batch {
  constructor(
    public name: string,
    public status: string,
    public id?: number,
    public results?: string,
    public promptId?: string,
    public createdAt?: Date,
    public createdByName?: string,
    public createdByEmail?: string,
    public modelName?: string,
    public llmPlaygroundProjectId?: number
  ) {}
}

export class BatchDetail {
  constructor(
    public name: string,
    public status: string,
    public id?: number,
    public results?: string,
    public promptId?: string,
    public createdAt?: Date,
    public createdByName?: string,
    public modelName?: string,
    public llmPlaygroundProjectId?: number,
    public prompt?: Prompt,
    public project?: Project,
    public csvData?: string[][]
  ) {}
}

export class CollaboratorPermission {
  constructor(
    public userId: string,
    public id?: number,
    public userName?: string,
    public email?: string,
    public createdByName?: string,
    public updatedAt?: Date,
    public createdAt?: Date,
  ) { }
}
