<div class="card mt-2">
  <div class="card-body">
    <h4 class="mb-2">Process Fax</h4>
    <form novalidate>
      <div class="form-check">
        <input
          (change)="onStatusChange()"
          [(ngModel)]="faxOutcome.status"
          type="radio"
          id="validEvidence"
          name="status"
          class="form-check-input"
          [value]="faxStatuses.validEvidence"
        />
        <label class="form-check-label" for="validEvidence"> Valid </label>
      </div>
      <div class="form-check">
        <input
          (change)="onStatusChange()"
          [(ngModel)]="faxOutcome.status"
          type="radio"
          id="notValidEvidence"
          name="status"
          class="form-check-input"
          [value]="faxStatuses.notValidEvidence"
        />
        <label class="form-check-label" for="notValidEvidence"> File is not valid evidence </label>
        <div class="form-check" *ngFor="let reasonOption of reasonOptions">
          <fieldset [disabled]="faxOutcome.status !== faxStatuses.notValidEvidence">
            <input
              (change)="onStatusReasonChange(reasonOption.value)"
              type="radio"
              [id]="reasonOption.value"
              name="statusReason"
              class="form-check-input"
              [(ngModel)]="faxOutcome.statusReason"
              [value]="reasonOption.value"
            />
            <label [for]="reasonOption.value" class="form-check-label"> {{ reasonOption.display }} </label>
          </fieldset>
        </div>
      </div>
      <div class="form-check">
        <input
          (change)="onStatusChange()"
          [(ngModel)]="faxOutcome.status"
          type="radio"
          id="needsManualIntervention"
          name="status"
          class="form-check-input"
          [value]="faxStatuses.needsManualIntervention"
        />
        <label class="form-check-label" for="needsManualIntervention"> PHI Trash </label>
      </div>
      <div class="form-group my-2">
        <label for="notes" class="my-1">ADDITIONAL DETAILS</label>
        <fa-icon *ngIf="!showNotes" [icon]="['fas', 'plus-square']" size="sm" (click)="onNotesPlusClick()" />
        <fa-icon *ngIf="showNotes" [icon]="['fas', 'minus-square']" size="sm" (click)="onNotesMinusClick()" />
        <textarea
          id="notes"
          name="notes"
          class="form-control"
          *ngIf="showNotes"
          (change)="onNotesChange()"
          [(ngModel)]="faxOutcome.notes"
          rows="5"
        >
        </textarea>
      </div>
    </form>
  </div>
</div>
