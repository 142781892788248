<app-import-nav />

<div *ngIf="!loading; else: spinner">
  <section class="mt-3" *ngIf="referralPrescriberSpecialtyMappingImports.length > 0; else: noImports">
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>STATUS</th>
          <th class="text-nowrap text-right">IMPORTED AT &#x25BC;</th>
          <th class="text-right">PROCESSED</th>
          <th class="text-right">ADDED</th>
          <th class="text-right">REMOVED</th>
          <th class="text-right">FAILURE MESSAGE</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let referralPrescriberSpecialtyMappingImport of referralPrescriberSpecialtyMappingImports">
          <td>
            {{ referralPrescriberSpecialtyMappingImport.status | titlecase }}
          </td>
          <td class="text-right">
            {{ referralPrescriberSpecialtyMappingImport.createdAt | date: 'M/d/yyyy h:mm a' }}
            <div>
              <small>{{ referralPrescriberSpecialtyMappingImport.username }}</small>
            </div>
          </td>
          <td class="text-right">{{ referralPrescriberSpecialtyMappingImport.processed | number: '' }}</td>
          <td class="text-right">{{ referralPrescriberSpecialtyMappingImport.added | number: '' }}</td>
          <td class="text-right">{{ referralPrescriberSpecialtyMappingImport.removed | number: '' }}</td>
          <td class="text-right">{{ referralPrescriberSpecialtyMappingImport.failureMessage }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</div>
<ng-template #noImports>
  <section class="mt-3"><p>There are no imports.</p></section>
</ng-template>
<ng-template #spinner>
  <section class="mt-3"><mat-spinner diameter="30" /></section>
</ng-template>
