<div class="card mt-1">
  <div class="card-body">
    <div class="row">
      <div class="col"><h4>Patient Prescriptions</h4></div>
      <div class="col text-right">
        <div class="d-inline-flex justify-content-around align-items-center">
          <div class="legend-block high-value mr-1 rounded"></div>
          <div class="mr-2">High value</div>
          <div class="legend-block rejected mr-1 rounded"></div>
          <div>Rejected/Reversed</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="alert alert-primary" *ngIf="filterByPrescriber">
          Only showing prescriptions prescribed by <strong>{{ prescriberFullName }}</strong>
        </div>
        <app-prescription-list
          [activePrescriptionId]="activePrescriptionId"
          [activeCaptureId]="activeCaptureId"
          [prescriptions]="prescriptions"
          [fixedHeader]="fixedHeader"
        />
      </div>
    </div>
  </div>
</div>
