import { Component, Input, OnChanges } from '@angular/core';
import { Capture } from '../../../core/models/capture.model';
import { ReferralMatchVerification } from '../../../core/models/referral-match-verification.model';
import { PatientPrescriber } from '../../../core/models/patient-prescriber.model';

@Component({
  selector: 'app-referral-match-verifications-client-dates',
  templateUrl: './referral-match-verifications-client-dates.component.html',
  styleUrls: ['./referral-match-verifications-client-dates.component.scss'],
})
export class ReferralMatchVerificationsClientDatesComponent implements OnChanges {
  @Input() capture: Capture;
  @Input() patientPrescriber: PatientPrescriber;

  clientReferralMatchVerifications: ReferralMatchVerification[] = [];

  ngOnChanges() {
    this.loadClientReferralMatchVerifications();
  }

  private loadClientReferralMatchVerifications() {
    this.clientReferralMatchVerifications =
      this.patientPrescriber.referralMatchVerifications.
        filter(rmv => rmv.client && !rmv.hasClientReferralTaskSubmission);
  }
}
