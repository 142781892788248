<div *ngIf="!loading; else: spinner">
  <table class="table m-0" *ngIf="captures.length > 0; else: noCaptures">
    <thead>
      <tr>
        <th>CLAIM ID</th>
        <th>STATUS</th>
        <th class="text-right text-nowrap">SERVICE DATE &#x25BC;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let capture of captures">
        <td class="text-nowrap">
          <a routerLink="/capture-admin/captures/{{capture.id}}" target="_blank">{{
            capture.candidate.claimIdentifier
          }}</a>
        </td>
        <td>{{ capture.status | captureStatus }}</td>
        <td class="text-right">{{ capture.candidate.serviceDate | date: 'MM/dd/yyyy' }}</td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #spinner>
  <div><mat-spinner diameter="30" /></div>
</ng-template>
<ng-template #noCaptures> <p>There are no captures for this fax.</p></ng-template>
