<h3>Client Chores</h3>

<form novalidate>
  <div class="container-fluid p-2 border rounded bg-light mb-2">
    <div class="row">

      <div class="col-11">
        <app-client-chore-filters
          [filters]="filters"
          [statuses]="filterableStatuses"
          (filterChange)="onFilterChange()" />
      </div>

      <div class="col-1 text-right">
        <a routerLink="/capture-admin/tools/client-chore/create" class="btn btn-primary">New</a>
      </div>

    </div>
  </div>
</form>

<div *ngIf="!loading; else: spinner">
  <section>
    <div class="row">
      <div class="col-12">
        <app-pagination-header
          [label]="'client chores'"
          [paging]="paging"
          (pageSizeChange)="onPageSizeChange($event)"
          [includeResetFiltersButton]="true"
          (resetFiltersClick)="onClearClick()" />

        <app-client-chore-list
          [clientChores]="clientChores"
          [canManageChores]="true" />

        <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
      </div>
    </div>
  </section>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
