<app-file-check-nav />

<form [formGroup]="formGroup" novalidate>
  <div class="container-fluid p-2 border bg-light mb-2 filters">
    <div class="row">
      <div class="col-5 pr-1">
        <div class="form-group" formGroupName="filters">
          <ng-select
            class="w-100"
            bindValue="id"
            bindLabel="humanizedName"
            name="clientIds"
            placeholder="Filter by Client"
            formControlName="clientIds"
            [multiple]="true"
            [items]="clients" />
        </div>
      </div>
    </div>
  </div>
</form>

<div *ngIf="!loading; else: spinner">
  <section class="mt-3">
    <app-pagination-header
      [paging]="paging"
      [label]="'file checks'"
      (pageSizeChange)="onPageSizeChange($event)"
      [includeResetFiltersButton]="true"
      (resetFiltersClick)="onResetFiltersClick()" />

    <table *ngIf="fileChecks.length > 0; else: noFileChecks" class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th>FILE</th>
          <th>TYPE</th>
          <th>STATUS</th>
          <th>CLIENT</th>
          <th class="text-right">STARTED AT &#x25BC;</th>
          <th class="text-right">DURATION</th>
          <th class="text-right">TOTAL ROWS</th>
          <th class="text-right">PROCESSED</th>
          <th class="text-right">INVALID</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fileCheck of fileChecks">
          <td>{{ fileCheck.fileName }}</td>
          <td>
            {{ fileTypeToDisplayName[fileCheck.fileType] }}
            <div class="text-muted text-nowrap">
              <small>{{ fileCheck.delimiter }} delimited</small>
            </div>
          </td>
          <td>
            <app-file-check-status [fileCheck]="fileCheck" />
            <div *ngIf="fileCheck.active">
              <button class="btn btn-sm btn-danger" (click)="onCancelClick($event, fileCheck)">
                <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
                CANCEL
              </button>
            </div>
          </td>
          <td>{{ fileCheck.client.humanizedName }}</td>
          <td class="text-right">
            {{ fileCheck.createdAt | date: 'M/d/yyyy h:mm a' }}
            <div>
              <small>{{ fileCheck.username }}</small>
            </div>
          </td>
          <td class="text-right">{{ fileCheck.durationInWords }}</td>
          <td class="text-right">{{ fileCheck.totalRows | number: '' }}</td>
          <td class="text-right">{{ fileCheck.processedRows | number: '' }}</td>
          <td class="text-right">{{ fileCheck.invalidRows | number: '' }}</td>
          <td class="text-center">
            <div ngbDropdown class="d-inline-block text-nowrap">
              <button id="downloadDropdown" class="btn btn-secondary" ngbDropdownToggle>Download</button>
              <div ngbDropdownMenu aria-labelledby="downloadDropdown">
                <a class="dropdown-item" href="#downloadErrorFile" (click)="onDownloadErrorFileClick($event, fileCheck)"
                  >Error File</a
                >
                <a
                  class="dropdown-item"
                  href="#downloadOriginalFile"
                  (click)="onDownloadOriginalFileClick($event, fileCheck)"
                  >Original File</a
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2">
      <app-pagination [paging]="paging" (pageChange)="onPageChange()" />
    </div>
  </section>
</div>
<ng-template #noFileChecks>
  <section class="mt-3"><p>There are no file checks.</p></section>
</ng-template>
<ng-template #spinner>
  <section class="mt-3"><mat-spinner diameter="30" /></section>
</ng-template>
