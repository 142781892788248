<app-client-tasks-filter
  [filters]="filters"
  (filtersChange)="filtersChange($event)"
  [loading]="loading"
  [exportUrl]="exportUrl"
  [enableExport]="tasks.length > 0"
  [taskStatuses]="taskStatuses"
  [specialties]="specialties"
  [manufacturers]="manufacturers"
  [sources]="sources"
  [encounterPatientInteractionLocations]="encounterPatientInteractionLocations"
  [useEstimatedValueRanks]="true"
  />
<div class="mt-2" *ngIf="!loading; else: spinner">
  <section *ngIf="tasks.length > 0; else: noTasks">
    <app-pagination-header [paging]="paging" [label]="'tasks'" (pageSizeChange)="onPageSizeChange($event)" />
    <table class="table table-bordered table-hover">
      <thead class="thead-dark">
        <tr>
          <th app-sortable-column="patientFullName" [sort]="sort" (sortChange)="onSortChange($event)">PATIENT</th>
          <th app-sortable-column="prescriberFullName" [sort]="sort" (sortChange)="onSortChange($event)">PRESCRIBER</th>
          <th app-sortable-column="status" [sort]="sort" (sortChange)="onSortChange($event)">STATUS</th>
          <th class="text-nowrap" app-sortable-column="source" [sort]="sort" (sortChange)="onSortChange($event)">
            SOURCE
          </th>
          <th app-sortable-column="lastViewedAt" [sort]="sort" (sortChange)="onSortChange($event)" class="text-right">
            LAST VIEWED
          </th>
          <th app-sortable-column="prescriptionCount" [sort]="sort" (sortChange)="onSortChange($event)"  class="text-center">
            PRESCRIPTIONS ASSOCIATED
          </th>
          <th app-sortable-column="estimatedValue"
              [sort]="sort"
              (sortChange)="onSortChange($event)"
              class="text-center text-nowrap">
            ESTIMATED VALUE
          </th>
          <th app-sortable-column="patientEstimatedValue"
              [sort]="sort"
              (sortChange)="onSortChange($event)"
              class="text-center text-nowrap">
            PATIENT ESTIMATED VALUE
          </th>
          <th class="text-right" app-sortable-column="nextClaimExpirationDate" [sort]="sort" (sortChange)="onSortChange($event)">
            EXPIRES ON
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let task of tasks; let i = index">
          <tr
            app-client-referral-task-list-row
            class="client-referral-task-list-row"
            [task]="task"
            [position]="i"
            (viewTask)="onViewTask(i)"
          ></tr>
        </ng-container>
      </tbody>
    </table>
    <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
  </section>
</div>
<ng-template #noTasks>
  <section><p>There are no tasks. Try modifying the filters.</p></section>
</ng-template>
<ng-template #disableExport>
  <button type="button" class="btn btn-primary" [disabled]="true">Export</button>
</ng-template>
<ng-template #spinner> <mat-spinner class="mt-2" [diameter]="30" /> </ng-template>
