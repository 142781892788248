<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.cssClass"
  [autohide]="toast.keepOpen ? false : true"
  [delay]="toast.delay || 5000"
  (hidden)="hide(toast)">
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl" />
  </ng-template>

  <ng-template #text>
    <button *ngIf="toast.showCloseButton" aria-label="Close" class="close" type="button" (click)="hide(toast)">
      <span aria-hidden="true">&times;</span>
    </button>

    <span [innerHTML]="toast.textOrTpl"></span>
  </ng-template>
</ngb-toast>
