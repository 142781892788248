<ng-container *ngIf="!loadingReferrals; else: spinner">
  <nav *ngIf="showIneligibleReferrals" class="nav nav-pills mb-2">
    <a class="nav-item nav-link active"
       [class.active]="currentTab === 'eligibleReferrals'"
       (click)="tabClick($event, 'eligibleReferrals')"
       href="#">
      Eligible Referrals ({{ eligibleReferrals.length }})
    </a>
    <a class="nav-item nav-link"
       [class.active]="currentTab === 'ineligibleReferrals'"
       (click)="tabClick($event, 'ineligibleReferrals')"
       href="#">
      Ineligible Referrals ({{ ineligibleReferrals.length }})
    </a>
  </nav>

  <ng-container
    *ngIf="!showIneligibleReferrals || currentTab === 'eligibleReferrals'"
    [ngTemplateOutlet]="referralMatchOptionList"
    [ngTemplateOutletContext]="{ referrals: eligibleReferrals }" />

  <ng-container
    *ngIf="currentTab === 'ineligibleReferrals'"
    [ngTemplateOutlet]="ineligibleReferralList"
    [ngTemplateOutletContext]="{ referrals: ineligibleReferrals }" />
</ng-container>

<ng-template #referralMatchOptionList let-referrals="referrals">
  <table class="table table-sm" *ngIf="referralMatchOptions.length > 0; else: noPatientReferrals">
    <thead>
    <tr>
      <th>RECEIVING PROVIDER / PRACTICE</th>
      <th>SPECIALTY</th>
      <th>RECEIVING OFFICE</th>
      <th>REFERRING PROVIDER</th>
      <th>REF-LOCATION</th>
      <th>REF-DATE</th>
      <th>REF-ID</th>
      <th>STATUS</th>
      <th>NOTES</th>
      <th>CONSULT</th>
      <th>CONSULT DESC</th>
      <th>PT CONSULT DESC</th>
      <th>PATIENT MRN</th>
      <th>EHR INSTANCE</th>
    </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let option of referralMatchOptions; let i = index">
        <tr [class.selected]="option.selected" class="referral">
          <td>
            <div class="form-check" [ngClass]="{ disabled: option.disabled }">
              <input
                type="checkbox"
                class="form-check-input"
                [id]="'rmvProvider' + i"
                [disabled]="option.disabled"
                [(ngModel)]="option.verification.provider"
                (change)="onReferralMatchOptionChange(option)"
              />
              <label class="form-check-label" [for]="'rmvProvider' + i">
                {{ option.referral.receivingProviderDisplayNameWithNpi }} <br />
                {{ option.referral.receivingProviderDisplaySpecialty }}
              </label>
            </div>
          </td>
          <td>
            <div class="form-check" [ngClass]="{ disabled: option.disabled }">
              <input
                type="checkbox"
                class="form-check-input"
                [id]="'rmvSpecialty' + i"
                [disabled]="option.disabled"
                [(ngModel)]="option.verification.specialty"
                (change)="onReferralMatchOptionChange(option)"
              />
              <label class="form-check-label" [for]="'rmvSpecialty' + i">
                {{ option.referral.referralSpecialty }}
              </label>
            </div>
          </td>
          <td>
            <div class="form-check" [ngClass]="{ disabled: option.disabled }">
              <input
                type="checkbox"
                class="form-check-input"
                [id]="'rmvOffice' + i"
                [disabled]="option.disabled"
                [(ngModel)]="option.verification.office"
                (change)="onReferralMatchOptionChange(option)"
              />
              <label class="form-check-label" [for]="'rmvOffice' + i">
                <div>{{ option.referral.receivingOfficeName }}</div>
                <small><strong>FAX:</strong> <app-phone-number [number]="option.referral.receivingProviderFax" /></small>
              </label>
            </div>
          </td>
          <td>{{ option.referral.sendingProviderDisplayNameWithNpi }}</td>
          <td>{{ option.referral.sendingLocation }}</td>
          <td>{{ option.referral.referralDate | date: 'MM/dd/yyyy' }}</td>
          <td>{{ option.referral.ehrIdentifier }}</td>
          <td>{{ option.referral.referralEhrStatus }}</td>
          <td>{{ option.referral.referralEhrNotes }}</td>
          <td>{{ option.referral.referralConsultInEhr }}</td>
          <td>{{ option.referral.referralConsultEhrDescriptions }}</td>
          <td>{{ option.referral.patientConsultEhrDescriptions }}</td>
          <td>{{ option.referral.sourcePatientMrn }}</td>
          <td>{{ option.referral.ehrInstance }}</td>
        </tr>
        <tr *ngIf="option.referral.referralReason" class="referral-reason" [ngClass]="{ selected: option.selected }">
          <td colspan="14"><strong>REFERRAL REASON:</strong> {{ option.referral.referralReason }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<ng-template #ineligibleReferralList let-referrals="referrals">
  <table class="table table-sm" *ngIf="referrals.length > 0; else: noPatientReferrals">
    <thead>
    <tr>
      <th>RECEIVING PROVIDER / PRACTICE</th>
      <th>SPECIALTY</th>
      <th>RECEIVING OFFICE</th>
      <th>REFERRING PROVIDER</th>
      <th>REF-LOCATION</th>
      <th>REF-DATE</th>
      <th>REF-ID</th>
      <th>STATUS</th>
      <th>NOTES</th>
      <th>CONSULT</th>
      <th>CONSULT DESC</th>
      <th>PT CONSULT DESC</th>
    </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let referral of referrals; let i = index">
        <tr class="referral ineligible">
          <td>
            {{ referral.receivingProviderDisplayNameWithNpi }} <br />
            {{ referral.receivingProviderDisplaySpecialty }}
          </td>
          <td>{{ referral.referralSpecialty }}</td>
          <td>
            <div>{{ referral.receivingOfficeName }}</div>
            <small><strong>FAX:</strong> <app-phone-number [number]="referral.receivingProviderFax" /></small>
          </td>
          <td>{{ referral.sendingProviderDisplayNameWithNpi }}</td>
          <td>{{ referral.sendingLocation }}</td>
          <td>{{ referral.referralDate | date: 'MM/dd/yyyy' }}</td>
          <td>{{ referral.ehrIdentifier }}</td>
          <td>{{ referral.referralEhrStatus }}</td>
          <td>{{ referral.referralEhrNotes }}</td>
          <td>{{ referral.referralConsultInEhr }}</td>
          <td>{{ referral.referralConsultEhrDescriptions }}</td>
          <td>{{ referral.patientConsultEhrDescriptions }}</td>
          <td>{{ referral.sourcePatientMrn }}</td>
          <td>{{ referral.ehrInstance }}</td>
        </tr>
        <tr *ngIf="referral.referralReason" class="referral-reason">
          <td colspan="14"><strong>REFERRAL REASON:</strong> {{ referral.referralReason }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<ng-template #spinner>
  <div class="mt-1 mb-2 ml-1"><mat-spinner diameter="30" /></div>
</ng-template>

<ng-template #noPatientReferrals>
  <div class="my-2">No patient referrals found.</div>
</ng-template>
