<div class="card">
  <div class="card-body">
    <h5>Prescriber</h5>

    <app-prescriber-incorrect-information-prompt
      [client]="task.client"
      relatedRecordType="ClientReferralTask"
      [relatedRecordId]="task.id"
      [prescriber]="task.prescriber" />

    <div class="row mb-2 pr-2">
      <div class="col">
        <div><strong>PRESCRIBER</strong></div>
        <div><app-prescriber-name [prescriber]="task.prescriber" /></div>
        <br />
        <div><strong>SPECIALTY</strong></div>
        <div>{{ task.prescriber.displaySpecialty }}</div>
      </div>
      <div class="col">
        <app-prescriber-offices-scrollable [prescriber]="task.prescriber" [acceptableFaxStatusOnly]="true" />
      </div>
    </div>
  </div>
</div>
<app-client-task-encounters [patient]="task.patient" />
<div class="card mt-2">
  <div class="card-body">
    <app-client-referral-task-related-tasks [task]="task" />
  </div>
</div>
<div class="card mt-2">
  <div class="card-body">
    <app-patient-prescriber-consult-notes
      [patient]="task.patient"
      [prescriber]="task.prescriber"
    />
  </div>
</div>
<ng-template #noEncounters> <p>There are no encounters.</p> </ng-template>
<ng-template #encounterSpinner> <mat-spinner [diameter]="30" /> </ng-template>
