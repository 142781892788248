import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Provider } from 'app/core/models/provider.model';
import { ProviderService } from 'app/core/services/provider.service';
import { Client } from 'app/core/models/client.model';
import { MedicalClassification } from 'app/core/models/medical-classification.model';
import { MedicalSpecialization } from 'app/core/models/medical-specialization.model';
import { NavigationService } from 'app/core/services/navigation.service';
import { ClientsService } from 'app/core/services/clients.service';
import { MedicalSpecializationService } from 'app/core/services/medical-specialization.service';
import { MedicalClassificationService } from 'app/core/services/medical-classification.service';
import { mapObjectToFormGroup, applyServerErrorsToFormGroup } from 'app/core/lib/form-utils';
import { providerStatusOptions, filterProviderStatusOptions } from 'app/core/options/provider-status.opts';
import { providerCredentialOptions } from 'app/core/options/provider-credential.opts';
import { providerDoNotSendReasonOptions } from 'app/core/options/provider-do-not-send-reason.opts';
import { ProviderStatus } from 'app/core/enums/provider-status.enum';

@Component({
  selector: 'app-capture-admin-provider-new',
  styleUrls: ['./capture-admin-provider-new.component.scss'],
  templateUrl: './capture-admin-provider-new.component.html',
})
export class CaptureAdminProviderNewComponent implements OnInit {
  formGroup: FormGroup;
  saving = false;
  clients: Client[];
  medicalClassifications: MedicalClassification[] = [];
  medicalSpecializations: MedicalSpecialization[] = [];
  providerCredentialOptions = providerCredentialOptions;
  providerDoNotSendReasonOptions = providerDoNotSendReasonOptions;
  providerStatusOptions;

  @ViewChild('form') public form: FormGroupDirective;

  constructor(
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
    private navigationService: NavigationService,
    private clientsService: ClientsService,
    private medicalClassificationService: MedicalClassificationService,
    private medicalSpecializationService: MedicalSpecializationService,
  ) { }

  ngOnInit() {
    const queryParams = this.navigationService.queryParams;

    const provider = new Provider;
    provider.npi = provider.npi || queryParams.npi;
    provider.status = ProviderStatus.new;

    this.formGroup = mapObjectToFormGroup(this.formBuilder, provider, [
      'first',
      'last',
      'npi',
      'status',
      'credentials',
      'classification',
      'specialization',
      'internalNotes',
      'doNotSendReason',
      'additionalDetailsDns',
      'specialtyConfirmed',
      'note',
    ]);

    this.providerStatusOptions = filterProviderStatusOptions(ProviderStatus.new);

    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });

    this.medicalClassificationService.getList().subscribe(({ medicalClassifications }) => {
      this.medicalClassifications = medicalClassifications;
    });

    this.medicalSpecializationService.getList().subscribe(({ medicalSpecializations }) => {
      this.medicalSpecializations = medicalSpecializations;
    });
  }

  onSubmit() {
    this.saving = true;

    this.providerService.create(this.formGroup.value).subscribe(
      provider => this.handleSaveSuccess(provider),
      error => this.handleSaveFailure(error)
    );
  }

  onCancelClick() {
    this.navigationService.navigateTo(`/capture-admin/providers/all`);
  }

  private handleSaveSuccess(provider: Provider) {
    this.navigationService.navigateTo(`/capture-admin/providers/${provider.npi}`);
  }

  private handleSaveFailure(response: HttpErrorResponse) {
    this.saving = false;

    if (response.status === 400) {
      applyServerErrorsToFormGroup(response, this.formGroup);
    } else {
      console.error(response);
    }
  }
}
