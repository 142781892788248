<div class="row mx-0 mb-2">
  <div class="col-10 px-0">
    <h3>Change Logs: {{ auditableType }}: {{ auditableId }}</h3>
  </div>
  <ng-container *ngIf="!loading else: spinner">
    <div class="col-10">
      <app-pagination-header [paging]="paging" [label]="'audits'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>

    <div class="audit-list-wrapper col-12">
      <table class="audit-list table table-bordered table-hover">
        <thead class="thead-dark">
          <tr>
            <th>Field</th>
            <th>Changed At</th>
            <th>Action</th>
            <th>New Value</th>
            <th>Previous Value</th>
            <th>Changing User</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let audit of audits">
            <ng-container *ngFor="let item of audit.auditedChanges | keyvalue">
              <tr>
                <td>{{ item.key }}</td>
                <td>{{ audit.createdAt | date: 'M/d/yyyy h:mm a' }}</td>
                <td>{{ audit.action }}</td>
                <td>{{ newValue(item.value) }}</td>
                <td>{{ previousValue(item.value) }}</td>
                <td>{{ audit?.user?.email }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </ng-container>
  <ng-template #spinner> <mat-spinner /> </ng-template>
</div>
