<h5>New Locations</h5>

<p class="font-italic" *ngIf="!loading && patientInteractionLocations.length > 0">
  NOTE: Data from locations in this list will not be used to capture claims until a review is completed.
</p>

<ng-container *ngIf="!loading; else: spinner">
  <app-pagination-header
    [label]="'locations to review'"
    [paging]="paging"
    (pageSizeChange)="onPageSizeChange($event)" />

  <app-patient-interaction-location-list
    *ngIf="patientInteractionLocations.length > 0"
    [includeClientColumn]="false"
    [patientInteractionLocations]="patientInteractionLocations" />

  <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
</ng-container>

<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>
