import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { isNull } from 'lodash-es';
import { ApiService } from './api.service';
import { PagedResults } from '../models/paged-results/paged-results.model';
import { Cursor, CursorBasedPagingMeta, NextResult } from '../models/paged-results/cursor-based-paging.model';
import { CaptureQueueResult } from '../models/capture-lists/capture-queue-result.model';
import { CaptureQueueCursorType } from '../enums/capture-queue-cursor-type.enum';
import { environment } from '../../../environments/environment';
import { CaptureQueueFilters, CaptureQueueSettings } from '../models/user-settings/capture-queue-settings.model';
import { CaptureQueueFilterName } from '../enums/capture-queue-filter-name.enum';
import { YesNo } from '../enums/yes-no.enum';
import { CursorParams } from '../enums/cursor-params.enum';
import { Capture } from '../models/capture.model';
import { UserSettingsService } from './user-settings.service';
import { CaptureStatus } from '../enums/capture-status.enum';
import { UserSettings } from '../enums/user-settings.enum';

// IMPORTANT: this is service is used for both capture work queue and the capture search page
// in the capture_admin section app. for the capture search used in the
// CE portal and partner portal see CaptureSearchService
// in all likelihood 'work' and 'search' will be combined in the future, but keeping them seperate for now
export enum CaptureQueueType {
  work = 'work',
  search = 'search',
}

export class CaptureQueueResultsContext {
  constructor(
    public captureQueueResults: CaptureQueueResult[],
    public cursor: Cursor<CaptureQueueCursorType>,
    public count: number,
    public scrollPosition: number
  ) {}
}

export class PagedCaptureQueueResults
  implements PagedResults<CaptureQueueResult, CursorBasedPagingMeta<CaptureQueueCursorType>> {
  captureQueueResults: CaptureQueueResult[] = new Array<CaptureQueueResult>();
  meta: CursorBasedPagingMeta<CaptureQueueCursorType> = new CursorBasedPagingMeta<CaptureQueueCursorType>();

  get records() {
    return this.captureQueueResults;
  }
}

const resolveDefaultFilters = (queueType: CaptureQueueType) => {
  if (queueType === CaptureQueueType.work) {
    return new CaptureQueueFilters([CaptureStatus.pending]);
  }

  return new CaptureQueueFilters();
};

@Injectable()
export class CaptureQueueService extends ApiService {
  private _queueType = CaptureQueueType.work;
  private _keyedResultsContext = {
    [CaptureQueueType.work]: null,
    [CaptureQueueType.search]: null,
  };

  constructor(private http: HttpClient, private userSettingsService: UserSettingsService) {
    super();
  }

  get queueType(): CaptureQueueType {
    return this._queueType;
  }

  set queueType(queueType: CaptureQueueType) {
    this._queueType = queueType;
  }

  cacheResultsContext(queueType: CaptureQueueType, resultsContext: CaptureQueueResultsContext) {
    this._keyedResultsContext[queueType] = resultsContext;
  }

  restoreResultsContext(queueType: CaptureQueueType) {
    const cachedContext = this._keyedResultsContext[queueType];
    this._keyedResultsContext[queueType] = null;
    return cachedContext;
  }

  getUserSettings(): CaptureQueueSettings {
    const queueSettings = this.userSettingsService.get<CaptureQueueSettings>(this.activeUserSettingsKey);

    if (!queueSettings) {
      return new CaptureQueueSettings(resolveDefaultFilters(this.queueType));
    } else {
      return queueSettings;
    }
  }

  saveUserSettings(captureWorkQueueSettings: CaptureQueueSettings) {
    this.userSettingsService.save<CaptureQueueSettings>(this.activeUserSettingsKey, captureWorkQueueSettings);
  }

  getCount(): Observable<{ count: number }> {
    const settings: CaptureQueueSettings = this.getUserSettings();
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    return this.http.get<{ count: number }>(
      `${environment.captureApi.url}/capture_queue/count?${filterParams.toString()}`
    );
  }

  getResults(cursor: Cursor<CaptureQueueCursorType> = null, limit = null): Observable<PagedCaptureQueueResults> {
    const settings: CaptureQueueSettings = this.getUserSettings();
    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);

    if (limit) {
      filterParams.append('limit', limit);
    }

    let cursorParams: URLSearchParams = null;
    if (cursor) {
      cursorParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);
    } else {
      cursorParams = this.buildCursorParams(settings.cursorType);
    }

    return this.http.get<PagedCaptureQueueResults>(
      `${environment.captureApi.url}/capture_queue?${filterParams.toString()}&${cursorParams.toString()}`
    );
  }

  getNextResult(capture: Capture, additionalFilters: CaptureQueueFilters = null): Observable<NextResult> {
    const settings: CaptureQueueSettings = this.getUserSettings();
    const cursor = this.cursorFromCapture(settings.cursorType, capture);

    const filterParams: URLSearchParams = this.buildFilterParams(settings.filters);
    const additionalParams: URLSearchParams = this.buildAdditionalNextResultFilters(additionalFilters);
    const cursorParams: URLSearchParams = this.buildCursorParams(cursor.name, cursor.id, cursor.value);

    return this.http
      .get<{ nextResult: NextResult }>(
        `${
          environment.captureApi.url
        }/capture_queue/next?${filterParams.toString()}&${cursorParams.toString()}&${additionalParams.toString()}`
      )
      .pipe(
        mergeMap((resp: { nextResult: NextResult }) => {
          if (resp.nextResult) {
            return of(resp.nextResult);
          } else {
            return this.handleNoResult(filterParams, cursorParams);
          }
        })
      );
  }

  private handleNoResult(filterParams: URLSearchParams, cursorParams: URLSearchParams): Observable<NextResult> {
    cursorParams.delete(CursorParams.id);
    cursorParams.delete(CursorParams.value);

    return this.http
      .get<{ nextResult: NextResult }>(
        `${environment.captureApi.url}/capture_queue/next?${filterParams.toString()}&${cursorParams.toString()}`
      )
      .pipe(
        mergeMap((resp: { nextResult: NextResult }) => {
          if (resp) {
            return of(resp.nextResult);
          } else {
            return of(null);
          }
        })
      );
  }

  private buildFilterParams(filters: CaptureQueueFilters): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    this.appendFilterParam(CaptureQueueFilterName.claimIdentifier, filters.claimIdentifier, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.captureId, filters.captureId, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.ndc, filters.ndc, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.rxReferenceNumber, filters.rxReferenceNumber, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.prescriberNpi, filters.prescriberNpi, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.prescriberLastName, filters.prescriberLastName, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.prescriberFirstName, filters.prescriberFirstName, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.patientLastName, filters.patientLastName, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.patientFirstName, filters.patientFirstName, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.patientDob, filters.patientDob, urlSearchParams);
    this.appendFilterParam(
      CaptureQueueFilterName.patientPrescriberUuid,
      filters.patientPrescriberUuid,
      urlSearchParams
    );
    this.appendFilterListParams(CaptureQueueFilterName.clientIds, filters.clientIds, urlSearchParams);
    this.appendFilterListParams(CaptureQueueFilterName.clientStates, filters.clientStates, urlSearchParams);
    this.appendFilterListParams(CaptureQueueFilterName.statuses, filters.statuses, urlSearchParams);
    this.appendFilterListParams(CaptureQueueFilterName.reasons, filters.reasons, urlSearchParams);
    this.appendFilterListParams(CaptureQueueFilterName.sources, filters.sources, urlSearchParams);
    this.appendFilterFlagParam(CaptureQueueFilterName.specialtyStore, filters.specialtyStore, urlSearchParams);
    this.appendFilterFlagParam(CaptureQueueFilterName.highValue, filters.highValue, urlSearchParams);
    this.appendFilterParam(CaptureQueueFilterName.expiringWithinDays, filters.expiringWithinDays, urlSearchParams);
    this.appendNullableFilterFlagParam(CaptureQueueFilterName.manualFaxSent, filters.manualFaxSent, urlSearchParams);
    this.appendFilterListParams(CaptureQueueFilterName.assignedUserIds, filters.assignedUserIds, urlSearchParams);
    this.appendFilterFlagParam(CaptureQueueFilterName.activeClientsOnly, filters.activeClientsOnly, urlSearchParams);

    return urlSearchParams;
  }

  private buildAdditionalNextResultFilters(filters: CaptureQueueFilters): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    if (filters) {
      this.appendFilterListParams(
        CaptureQueueFilterName.excludedPatientIds,
        filters.excludedPatientIds,
        urlSearchParams
      );
      this.appendFilterListParams(
        CaptureQueueFilterName.excludedPatientPrescriberIds,
        filters.excludedPatientPrescriberIds,
        urlSearchParams
      );
    }

    return urlSearchParams;
  }

  private buildCursorParams(cursorType: CaptureQueueCursorType, id: number = null, value: string = null) {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append(CursorParams.name, cursorType);

    if (!isNull(id)) {
      urlSearchParams.append(CursorParams.id, id.toString());
    }

    if (!isNull(value)) {
      urlSearchParams.append(CursorParams.value, value);
    }

    return urlSearchParams;
  }

  private appendFilterListParams<T>(paramName: string, values: T[], urlSearchParams: URLSearchParams) {
    if (values && values.length > 0) {
      values.forEach(v => urlSearchParams.append(paramName, v.toString()));
    }
  }

  private appendFilterFlagParam(paramName: string, value: boolean, urlSearchParams: URLSearchParams) {
    if (value) {
      urlSearchParams.append(paramName, YesNo.yes.toString());
    }
  }

  private appendFilterParam(paramName: string, value: string, urlSearchParams: URLSearchParams) {
    if (value) {
      urlSearchParams.append(paramName, value);
    }
  }

  private appendNullableFilterFlagParam(paramName: string, value: boolean, urlSearchParams: URLSearchParams) {
    if (value === true) {
      urlSearchParams.append(paramName, YesNo.yes.toString());
    } else if (value === false) {
      urlSearchParams.append(paramName, YesNo.no.toString());
    }
  }

  private cursorFromCapture(cursorType: CaptureQueueCursorType, capture: Capture): Cursor<CaptureQueueCursorType> {
    const cursor = new Cursor<CaptureQueueCursorType>();
    cursor.name = cursorType;
    cursor.id = capture.id;
    cursor.value = this.determineCursorValue(cursor.name, capture);
    return cursor;
  }

  private determineCursorValue(cursorType: CaptureQueueCursorType, capture: Capture): string {
    switch (cursorType) {
      case CaptureQueueCursorType.mostRecentlyCreated:
        return capture.createdAt.toString();
      case CaptureQueueCursorType.mostRecentlyVerified:
        return capture.verifiedAt && capture.verifiedAt.toString();
      case CaptureQueueCursorType.highestEstimatedValue:
        return capture.candidate.estimatedValue.toString();
      case CaptureQueueCursorType.highestPendingEstimatedValue:
        return capture.pendingEstimatedValue.toString();
      default:
        return null;
    }
  }

  get activeUserSettingsKey(): UserSettings {
    if (this.queueType === CaptureQueueType.search) {
      return UserSettings.captureAdminCaptureSearchQueueSettings;
    }

    return UserSettings.captureAdminCaptureQueueSettings;
  }
}
