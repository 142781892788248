<form>
  <div class="form-inline ml-1">
    <div
      class="form-check form-check-line w-100 border rounded"
      [ngClass]="{ 'marked-as-invalid': selectedAttachment.markedAsInvalid, error: errorMessage }">
      <input
        id="patientAttachmentMarkedAsInvalid"
        name="patientAttachmentMarkedAsInvalid"
        class="form-check-input"
        type="checkbox"
        [disabled]="!canMarkAsInvalid"
        (change)="onMarkedAsInvalidChanged($event)"
        [(ngModel)]="selectedAttachment.markedAsInvalid" />

      <label class="form-check-label" for="patientAttachmentMarkedAsInvalid">
        Mark consult note as invalid.
      </label>

      <span *ngIf="errorMessage" class="error-text pl-2"> ({{ errorMessage }})</span>
    </div>
  </div>
</form>
