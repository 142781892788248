import { ProviderOfficeStatus } from '../enums/provider-office-status.enum';
import { keyBy } from 'lodash-es';

export class ProviderOfficeStatusOption {
  constructor(public value: string, public display: string) { }
}

const providerOfficeStatusOptions = [
  new ProviderOfficeStatusOption(ProviderOfficeStatus.validFax, "Valid Fax"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.verified, "Verified"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.unresponsiveOffice, "Unresponsive Office"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.unresponsiveProvider, "Unresponsive Provider"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.verifiedSuccessful, "Verified - Successful"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.verifiedSuccessful2, "Verified - Successful 2"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.verifiedSuccessful3, "Verified - Successful 3"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.transmittedNoResponse, "Transmitted - No Response"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.preferred, "Preferred"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.testing, "Testing"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.testingMechanicalTurk, "Testing (Mechanical Turk)"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.testingBops, "Testing (BOPS)"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.faxFailed, "Fax Failed - Nonfunctional"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.providerNoLongerAtOffice, "Provider No Longer at Office"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.officeOptedOut, "Office Opted Out"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.notAMedicalOffice, "Not a Medical Office"),
  new ProviderOfficeStatusOption(ProviderOfficeStatus.providerNotAtOffice, "Provider Not at Office"),
];

const keyedStatusOptions = keyBy(providerOfficeStatusOptions, 'value');

const providerOfficeShortCodes = {
  [ProviderOfficeStatus.verified]: 'V',
  [ProviderOfficeStatus.validFax]: 'VF',
  [ProviderOfficeStatus.unresponsiveOffice]: 'UO',
  [ProviderOfficeStatus.unresponsiveProvider]: 'UP',
  [ProviderOfficeStatus.verifiedSuccessful]: 'VS',
  [ProviderOfficeStatus.verifiedSuccessful2]: 'VS',
  [ProviderOfficeStatus.verifiedSuccessful3]: 'VS',
  [ProviderOfficeStatus.transmittedNoResponse]: 'TNR',
  [ProviderOfficeStatus.preferred]: 'P',
  [ProviderOfficeStatus.faxFailed]: 'FFN',
  [ProviderOfficeStatus.testing]: 'T',
  [ProviderOfficeStatus.testingMechanicalTurk]: 'TMT',
  [ProviderOfficeStatus.testingBops]: 'TB',
  [ProviderOfficeStatus.providerNoLongerAtOffice]: 'PNLO',
  [ProviderOfficeStatus.officeOptedOut]: 'OOO',
  [ProviderOfficeStatus.notAMedicalOffice]: 'NMO',
  [ProviderOfficeStatus.providerNotAtOffice]: 'PNO',
};

const displayProviderOfficeFaxStatus = (value: string): string => {
  return providerOfficeShortCodes[value] || 'NA';
}

export {
  keyedStatusOptions,
  providerOfficeStatusOptions,
  providerOfficeShortCodes,
  displayProviderOfficeFaxStatus,
};
