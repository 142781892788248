import { Component, Input, OnInit } from '@angular/core';
import { UsersService } from '../../../core/services/users.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { AppToastService } from 'app/core/services/app-toast.service';
import { isCePortalUser, isCePortalConsultantUser, UserType } from '../../../core/enums/user-type.enum';
import { ClaimSource } from '../../../core/enums/claim-source.enum';
import { ClientsService } from '../../../core/services/clients.service';
import { SamlProvidersService } from 'app/core/services/saml-providers.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '../../../core/models/user.model';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { availableRoles } from '../../../core/enums/user-role-name.enum';
import { environment } from '../../../../environments/environment';
import { PhoneRegExp } from '../../../shared/directives/phone-validator.directive';

export enum UserFormMode {
  create,
  update,
}

const phoneValidator = (number): any => {
  if (number.pristine) {
    return null;
  }

  number.markAsTouched();

  if (PhoneRegExp.test(number.value)) {
    return null;
  }

  return {
    invalidNumber: true,
  };
};

const initUserTypeValidators = (userType, phoneControl, clientNamesControl, sourceControl, assignedClientsControl) => {
  if (userType === UserType.cePortal) {
    phoneControl.setValidators([Validators.required, phoneValidator]);
    clientNamesControl.setValidators([Validators.required]);
    sourceControl.setValidators(null);
    assignedClientsControl.setValidators(null);
  } else if (isCePortalConsultantUser(userType)) {
    phoneControl.setValidators([Validators.required, phoneValidator]);
    clientNamesControl.setValidators([Validators.required]);
    sourceControl.setValidators(null);
    assignedClientsControl.setValidators(null);
  } else if (userType === UserType.partnerPortal) {
    phoneControl.setValidators([Validators.required, phoneValidator]);
    clientNamesControl.setValidators(null);
    sourceControl.setValidators([Validators.required]);
    assignedClientsControl.setValidators(null);
  } else if (userType === UserType.captureAdmin) {
    phoneControl.setValidators([Validators.required, phoneValidator]);
    clientNamesControl.setValidators(null);
    sourceControl.setValidators(null);
    assignedClientsControl.setValidators(null);
  } else {
    phoneControl.setValidators(null);
    clientNamesControl.setValidators(null);
    sourceControl.setValidators(null);
    assignedClientsControl.setValidators(null);
  }
};

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Input() user: User = null;
  @Input() mode: UserFormMode = null;

  userForm = null;
  formModes = UserFormMode;
  saveAttempted = false;
  saving = false;
  userTypeChanged = false;
  serverErrors = {};

  clients = [];
  samlProviders = [];
  roles = [];
  userTypes = UserType;
  claimSources = ClaimSource;
  isCePortalUserType = isCePortalUser;

  constructor(
    private userService: UsersService,
    private navService: NavigationService,
    private toastService: AppToastService,
    private clientsService: ClientsService,
    private samlProvidersService: SamlProvidersService
  ) {}

  ngOnInit() {
    this.init();
  }

  onFormSubmit() {
    this.saveUser();
  }

  onCancelClick() {
    this.leavePage();
  }

  get emailRequired(): boolean {
    return this.userForm.get('email').hasError('required');
  }

  get emailInvalid(): boolean {
    return this.userForm.get('email').hasError('email');
  }

  get emailInUse(): boolean {
    return this.serverErrors['email'] === 'email_in_use';
  }

  get phoneRequired(): boolean {
    return this.userForm.get('phone').hasError('required');
  }

  get phoneInvalid(): boolean {
    return this.userForm.get('phone').hasError('invalidNumber');
  }

  invalid(control: UntypedFormControl, controlName) {
    return (control.invalid || this.serverErrors[controlName]) && this.saveAttempted;
  }

  isControlInvalid(controlName: string) {
    const control = this.userForm.get(controlName);

    if (controlName === 'clientNames' || controlName === 'source') {
      return this.invalid(control, controlName) && !this.userTypeChanged;
    } else {
      return this.invalid(control, controlName);
    }
  }

  invalidCss(controlName: string) {
    return {
      'is-invalid': this.isControlInvalid(controlName),
    };
  }

  private init() {
    this.loadClients();
    this.loadSamlProviders();
    this.loadRoles();
    this.buildForm();
  }

  private buildForm() {
    if (this.mode === UserFormMode.create) {
      this.buildCreateUserForm();
    } else if (this.mode === UserFormMode.update) {
      this.buildUpdateUserForm();
    }
  }

  private buildCreateUserForm() {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: new UntypedFormControl(''),
      userType: new UntypedFormControl('', [Validators.required]),
      clientNames: new UntypedFormControl(''),
      source: new UntypedFormControl(''),
      roleNames: new UntypedFormControl(''),
      assignedClients: new UntypedFormControl(''),
      samlProvider: new UntypedFormControl(''),
    });

    const emailControl = this.userForm.get('email');

    emailControl.valueChanges.subscribe(() => {
      delete this.serverErrors['email'];
    });

    this.initUserTypeControls();
  }

  private buildUpdateUserForm() {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.user.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.user.lastName, [Validators.required]),
      phone: new UntypedFormControl(this.user.phone),
      userType: new UntypedFormControl(
        !this.user.userType || this.user.userType === UserType.unknown ? null : this.user.userType,
        [Validators.required]
      ),
      clientNames: new UntypedFormControl(this.user.availableClients.map(c => c.name)),
      source: new UntypedFormControl(
        this.user.allowedSources && this.user.allowedSources.length > 0 ? this.user.allowedSources[0] : ''
      ),
      roleNames: new UntypedFormControl(this.user.userRoles.map(r => r.role)),
      assignedClients: new UntypedFormControl(this.user.assignedClients.map(c => c.name)),
      samlProvider: new UntypedFormControl(
        this.user.samlProvider && this.user.samlProvider.id),
    });

    this.initUserTypeControls();
  }

  private initUserTypeControls() {
    const phoneControl = this.userForm.get('phone');
    const userTypeControl = this.userForm.get('userType');
    const clientNamesControl = this.userForm.get('clientNames');
    const sourceControl = this.userForm.get('source');
    const roleNamesControl = this.userForm.get('roleNames');
    const assignedClientsControl = this.userForm.get('assignedClients');
    const samlProviderControl = this.userForm.get('samlProvider');

    userTypeControl.valueChanges.subscribe(val => {
      this.userTypeChanged = true;
      initUserTypeValidators(val, phoneControl, clientNamesControl, sourceControl, assignedClientsControl);
      const { phone } = this.userForm.value;
      phoneControl.reset(phone);
      clientNamesControl.reset('');
      sourceControl.reset('');
      roleNamesControl.reset('');
      assignedClientsControl.reset('');
      samlProviderControl.reset(null);
    });

    initUserTypeValidators(
      this.user && this.user.userType,
      phoneControl,
      clientNamesControl,
      sourceControl,
      assignedClientsControl
    );
  }

  private saveUser() {
    this.saveAttempted = true;

    if (this.userForm.valid) {
      this.saving = true;
      this.serverErrors = {};

      const saveOperation: Observable<User> = this.determineSaveOperation();

      saveOperation.subscribe(
        user => {
          this.saving = false;
          this.toastService.show(this.determineSuccessMessage(user), { cssClass: 'alert-success' });
          this.leavePage();
        },
        (err: HttpErrorResponse) => {
          this.saving = false;
          if (err.status === 400) {
            const { error: data } = err;
            this.serverErrors = data.errors;
            console.log(this.serverErrors);
          } else {
            console.error(err);
          }
        }
      );
    } else {
      this.userTypeChanged = false;
    }
  }

  private leavePage() {
    if (this.navService.currentUrl !== this.navService.previousUrl) {
      this.navService.goToPreviousUrl();
    } else {
      this.navService.goToUrl('/capture-admin/users');
    }
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private loadSamlProviders() {
    this.samlProvidersService.getList().subscribe(result => {
      this.samlProviders = result.samlProviders;
    });
  }

  private loadRoles() {
    this.roles = availableRoles;
  }

  private determineSaveOperation(): Observable<User> {
    if (this.mode === UserFormMode.create) {
      const {
        email,
        firstName,
        lastName,
        phone,
        userType,
        clientNames,
        source,
        roleNames,
        assignedClients,
        samlProvider,
      } = this.userForm.value;
      return this.userService.create(
        email,
        userType,
        firstName,
        lastName,
        phone,
        clientNames,
        source,
        roleNames,
        assignedClients,
        samlProvider
      );
    } else if (this.mode === UserFormMode.update) {
      const {
        firstName,
        lastName,
        phone,
        userType,
        clientNames,
        source,
        roleNames,
        assignedClients,
        samlProvider,
      } = this.userForm.value;
      return this.userService.update(
        this.user.id,
        userType,
        firstName,
        lastName,
        phone,
        clientNames,
        source,
        roleNames,
        assignedClients,
        samlProvider
      );
    } else {
      throw new Error('Cannot determine save operation.');
    }
  }

  private determineSuccessMessage(user: User): string {
    if (this.mode === UserFormMode.create) {
      return `User "${user.fullName}" has been created.`;
    } else if (this.mode === UserFormMode.update) {
      return `Changes to user  "${user.fullName}" have been saved.`;
    } else {
      throw new Error('Cannot determine success message.');
    }
  }
}
