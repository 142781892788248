import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Referral } from 'app/core/models/referral.model';
import { ReferralMatchVerification } from 'app/core/models/referral-match-verification.model';
import { ReferralMatchOption } from 'app/core/models/referral-match-option.model';

@Component({
  selector: 'app-referral-match-verifications-standard-listing',
  templateUrl: './referral-match-verifications-standard-listing.component.html',
  styleUrls: ['./referral-match-verifications-standard-listing.component.scss'],
})
export class ReferralMatchVerificationsStandardListingComponent {
  @Input()
  loadingReferrals = false;
  @Input()
  eligibleReferrals: Referral[] = [];
  @Input()
  ineligibleReferrals: Referral[] = [];
  @Input()
  referralMatchOptions: ReferralMatchOption[] = [];
  @Input()
  showIneligibleReferrals = false;

  @Output() referralVerificationChange = new EventEmitter<ReferralMatchVerification>();
  @Output() referralMatchOptionChange = new EventEmitter<ReferralMatchOption>();

  currentTab = 'eligibleReferrals';

  tabClick($event, tabName) {
    $event.preventDefault();
    this.currentTab = tabName;
  }

  onReferralMatchOptionChange(referralMatchOption: ReferralMatchOption) {
    this.referralVerificationChange.emit(referralMatchOption.verification);
    this.referralMatchOptionChange.emit(referralMatchOption);
  }

  onReferralVerificationChange(referralMatchVerification: ReferralMatchVerification) {
    this.referralVerificationChange.emit(referralMatchVerification);
  }
}
