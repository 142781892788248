<div class="mt-2" *ngIf="!loadingOffice; else: spinner">
  <h3>Office Details Change Log: {{ office.name }}</h3>

  <div *ngIf="!loadingAudits; else: spinner">
    <div class="col-12">
      <app-pagination-header [paging]="paging" [label]="'audits'" (pageSizeChange)="onPageSizeChange($event)" />
    </div>
    <div class="audit-list-wrapper col-12">
      <table class="audit-list table table-bordered table-hover">
        <thead class="thead-dark">
          <tr>
            <th>Changed At</th>
            <th>Action</th>
            <th>New Values</th>
            <th>Previous Values</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let audit of audits">
            <tr
              app-audit-office-create-row
              *ngIf="audit.auditableType === 'Office' && audit.action === 'create'"
              [audit]="audit"></tr>
            <tr
              app-audit-office-update-row
              *ngIf="audit.auditableType === 'Office' && audit.action === 'update'"
              [audit]="audit"></tr>
            <tr
              app-audit-note-create-row
              *ngIf="audit.auditableType === 'Note' && audit.action === 'create'"
              [audit]="audit"></tr>
            <tr
              app-audit-provider-office-create-row
              *ngIf="audit.auditableType === 'ProviderOffice' && audit.action === 'create'"
              [audit]="audit"
              [displayNpi]="true"></tr>
            <tr
              app-audit-provider-office-update-row
              *ngIf="audit.auditableType === 'ProviderOffice' && audit.action === 'update'"
              [audit]="audit"
              [displayNpi]="true"></tr>
          </ng-container>
        </tbody>
      </table>
      <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
    </div>
  </div>
</div>
<ng-template #spinner>
  <mat-spinner diameter="30" />
</ng-template>