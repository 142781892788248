import { Component, Input, OnInit } from '@angular/core';
import { Referral } from '../../../core/models/referral.model';

@Component({
  selector: 'app-patient-referrals',
  templateUrl: './patient-referrals.component.html',
  styleUrls: ['./patient-referrals.component.scss'],
})
export class PatientReferralsComponent implements OnInit {
  @Input() referrals: Referral[];

  constructor() {}

  ngOnInit() {}
}
