import { Component, Input, OnInit } from '@angular/core';
import { Capture } from 'app/core/models/capture.model';
import { AppToastService } from 'app/core/services/app-toast.service';
import { CaptureService } from 'app/core/services/capture.service';

@Component({
  selector: 'app-capture-unverify',
  templateUrl: './capture-unverify.component.html',
  styleUrls: ['./capture-unverify.component.scss']
})
export class CaptureUnverifyComponent implements OnInit {
  @Input() capture: Capture;

  constructor(
    private toastService: AppToastService,
    private captureService: CaptureService
  ) {}

  ngOnInit(): void {
  }

  unverifyClick(): void {
    this.captureService.unverify(this.capture).subscribe(
      (modifiedCapture: Capture) => this.captureService.notifyCaptureChanged(modifiedCapture),
      (_err) => this.notifyError()
    );
  }

  notifyError(): void {
    this.toastService.show(
      "An error occurred. This may mean that the capture was already sent to a partner.",
      { cssClass: 'alert-warning' }
    )
  }

}
