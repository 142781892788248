import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { sortBy, pick, cloneDeep } from 'lodash-es';
import { isNotBlank } from '../../../core/lib/js-utils';
import { DateTime } from 'luxon';
import { PatientInteractionLocationsService } from 'app/core/services/patient-interaction-locations.service';
import { PatientInteractionLocation } from 'app/core/models/patient-interaction-location.model';
import { PatientInteractionLocationEligibilityPeriod } from 'app/core/models/patient-interaction-location-eligibility-period.model';
import {
  keyedPatientInteractionLocationEligibilityStatusOptions
} from 'app/core/options/patient-interaction-location-eligibility-status.opts';
import { PatientInteractionLocationEligibilityStatus } from 'app/core/enums/patient-interaction-location-eligibility-status.enum';
import { AuthenticationService } from 'app/core/services/authentication.service';

class EditablePatientInteractionLocation extends PatientInteractionLocation {
  persistedEligibilityPeriods: PatientInteractionLocationEligibilityPeriod[];
  editing = false;
  dirty = false;
  saving = false;
  errors: string[];
}

@Component({
  selector: 'app-patient-interaction-location-list',
  templateUrl: './patient-interaction-location-list.component.html',
  styleUrls: ['./patient-interaction-location-list.component.scss'],
})
export class PatientInteractionLocationListComponent implements OnInit {
  @Input() includeClientColumn = true;

  _patientInteractionLocations: PatientInteractionLocation[] = [];
  eligibilityStatuses = PatientInteractionLocationEligibilityStatus;
  keyedEligibilityStatusOptions = keyedPatientInteractionLocationEligibilityStatusOptions;

  asLocation = (location: unknown) => location as EditablePatientInteractionLocation;

  constructor(
    private patientInteractionLocationsService: PatientInteractionLocationsService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit() {}

  @Input() set patientInteractionLocations(patientInteractionLocations: PatientInteractionLocation[]) {
    this._patientInteractionLocations = this.prepareLocations(patientInteractionLocations);
  }

  get enableLinkToChangelog() {
    return this.authService.isCaptureAdminUser;
  }

  get patientInteractionLocations() {
    return this._patientInteractionLocations;
  }

  isInactive(eligibilityPeriod: PatientInteractionLocationEligibilityPeriod) {
    return isNotBlank(eligibilityPeriod.terminationDate) && new Date(eligibilityPeriod.terminationDate) < new Date();
  }

  onEditClick(location: EditablePatientInteractionLocation) {
    this.beginLocationEdit(location);
  }

  onTouchLocationClick(location: EditablePatientInteractionLocation) {
    location.saving = true;
    this.savePatientInteractionLocation(location);
  }

  onSaveClick(location: EditablePatientInteractionLocation) {
    location.saving = true;
    this.savePatientInteractionLocation(location);
  }

  onCancelClick(location: EditablePatientInteractionLocation) {
    this.resetLocationEligibilityPeriods(location);
    location.dirty = false;
    location.editing = false;
  }

  onEligibilitPeriodDateChange(location: EditablePatientInteractionLocation) {
    delete location.errors;
  }

  private shouldAddNewPeriod(location) {
    return !location.dirty &&
      (location.eligibilityPeriods.length === 0 ||
        (location.eligibilityStatus === PatientInteractionLocationEligibilityStatus.ineligible &&
          (location.eligibilityPeriods[0].terminationDate &&
            new Date(location.eligibilityPeriods[0].terminationDate) < new Date())));
  }

  private beginLocationEdit(location) {
    if (this.shouldAddNewPeriod(location)) {
      const newPeriod = new PatientInteractionLocationEligibilityPeriod();

      if (location.eligibilityPeriods[0]) {
        const startDate = DateTime.fromISO(location.eligibilityPeriods[0].terminationDate);
        newPeriod.startDate = startDate.plus({ days: 1 }).toISODate();
      }

      location.eligibilityPeriods.unshift(newPeriod);
      location.dirty = true;
    }

    location.editing = true;
  }

  private prepareLocation(location) {
    const editableLocation = Object.assign({}, location);

    editableLocation.persistedEligibilityPeriods =
      sortBy(location.eligibilityPeriods, period => period.startDate).reverse();

    this.resetLocationEligibilityPeriods(editableLocation);

    return editableLocation;
  }

  private resetLocationEligibilityPeriods(location) {
    location.eligibilityPeriods = cloneDeep(location.persistedEligibilityPeriods);
  }

  private prepareLocations(patientInteractionLocations): EditablePatientInteractionLocation[] {
    return patientInteractionLocations.map(location => this.prepareLocation(location));
  }

  private savePatientInteractionLocation(location) {
    const attributes = pick(location, 'eligibilityPeriods');

    this.patientInteractionLocationsService.update(location.id, attributes).subscribe(
      (updatedLocation: PatientInteractionLocation) => {
        const editableUpdatedLocation = this.prepareLocation(updatedLocation);
        const locationIndex = this.patientInteractionLocations.indexOf(location);
        this.patientInteractionLocations.splice(locationIndex, 1, editableUpdatedLocation);
      },
      (response: HttpErrorResponse) => {
        if (response?.error?.errors) {
          location.errors = Object.values(response.error.errors);
        } else {
          location.errors = ["Unexpected error."];
        }

        location.saving = false;
        location.editing = true;
      }
    );
  }
}
