<div class="row no-gutters">
  <div class="col-8">
    <h5 class="m-0 p-0 text-dark">
      Drug {{ position + 1 }}: {{ name }}

      <fa-icon *ngIf="showWarningIndicator" [icon]="['fas', 'exclamation-triangle']" size="1x" class="warning" />
    </h5>
  </div>
  <div class="col-3"><ng-content /></div>
  <div class="col-1 text-right">
    <a href="#toggleCollapsed" class="toggle" (click)="onToggleCollapseClick($event)">
      <mat-icon *ngIf="!collapsed">remove</mat-icon>
      <mat-icon *ngIf="collapsed">add</mat-icon>
    </a>
  </div>
</div>
