import { Component, OnInit } from '@angular/core';
import { trim, isEmpty } from 'lodash-es';
import { AppToastService } from 'app/core/services/app-toast.service';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { reasonOptions } from '../../../core/options/inbound-call-reasons.opts';
import { InboundCallReason } from '../../../core/enums/inbound-call-reason.enum';
import { NavigationService } from '../../../core/services/navigation.service';
import { InboundCallsService } from '../../../core/services/inbound-calls.service';
import { HttpErrorResponse } from '@angular/common/http';
import { communicationMethods } from 'app/core/options/inbound-communication-communication-methods.opts';
import { InboundFaxCommunicationMethod } from 'app/core/enums/inbound-fax-communication-method.enum';

class InboundCallFormModel {
  reason: string;
  notes: string;
  outboundFaxId: number;
  communicationMethod: string;
}

const notesRequired = (inboundCallFormModel: InboundCallFormModel) => {
  if (inboundCallFormModel.reason === InboundCallReason.other) {
    return !trim(inboundCallFormModel.notes);
  } else {
    return false;
  }
};

@Component({
  selector: 'app-capture-admin-inbound-call-new',
  templateUrl: './capture-admin-inbound-call-new.component.html',
  styleUrls: ['./capture-admin-inbound-call-new.component.scss'],
})
export class CaptureAdminInboundCallNewComponent implements OnInit {
  inboundCallFormModel: InboundCallFormModel = new InboundCallFormModel();
  inboundCallReasonOptions = reasonOptions;
  serverError = false;
  validationErrors = new Array<string>();
  showNotes = false;
  communicationMethods = communicationMethods;

  constructor(
    private inboundCallsService: InboundCallsService,
    private toastService: AppToastService,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    this.inboundCallFormModel.communicationMethod = InboundFaxCommunicationMethod.standardFax;
  }

  onOutboundFaxChange(outboundFax: OutboundFax) {
    this.inboundCallFormModel.outboundFaxId = outboundFax.id;
    this.inboundCallFormModel.communicationMethod = this.defaultCommunicationMethod(outboundFax);
  }

  onReasonChange() {
    if (this.inboundCallFormModel.reason === InboundCallReason.other) {
      this.showNotes = true;
    }
  }

  onNotesPlusClick() {
    this.showNotes = true;
  }

  onNotesMinusClick() {
    this.showNotes = false;
  }

  onSubmit() {
    this.serverError = false;

    if (this.validate()) {
      this.inboundCallsService.create(this.inboundCallFormModel).subscribe(
        () => {
          this.toastService.show('Inbound call logged!', { cssClass: 'alert-success' });
          this.navService.goToUrl('/capture-admin/communications/inbound-calls');
        },
        (err: HttpErrorResponse) => {
          this.serverError = true;
          console.error(err);
        }
      );
    }
  }

  private validate() {
    this.validationErrors = new Array<string>();

    if (isEmpty(this.inboundCallFormModel.reason)) {
      this.validationErrors.push('Reason is required.');
    }

    if (notesRequired(this.inboundCallFormModel)) {
      this.validationErrors.push('Additional details are required.');
    }

    return this.validationErrors.length === 0;
  }

  private defaultCommunicationMethod(outboundFax: OutboundFax) {
    return outboundFax.office?.communicationMethod || InboundFaxCommunicationMethod.standardFax;
  }
}
