<ng-container [ngSwitch]="loginStatus">
  <ng-container *ngSwitchCase="loginStatuses.AccountDisabled">
    <h3 class="my-4 heading text-center">Account Disabled</h3>
    <div class="row">
      <div class="col-lg-4 offset-lg-4">
        <p>This account is disabled. Please call our support team: <b>1-781-214-3424</b>.</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="loginStatuses.AccountLocked">
    <h3 class="my-4 heading text-center">Account Locked</h3>
    <div class="row">
      <div class="col-lg-4 offset-lg-4">
        You have entered an incorrect password too many times. To unlock your account, please call our support team:
        <b>1-781-214-3424</b>.
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <h3 class="my-4 heading text-center">Sign in to R1 340B Recovery</h3>
    <div class="row">
      <div class="col-lg-4 offset-lg-4">
        <div *ngIf="logoutReasons.inactivity === logoutReason" class="alert alert-info text-center">
          For your security, R1 logs you out after 20 minutes. Please sign in below to continue.
        </div>
        <form (ngSubmit)="onSubmit(loginForm)" #loginForm="ngForm">
          <div class="form-group">
            <label for="email">Email address</label>
            <input
              id="email"
              name="email"
              class="form-control"
              type="email"
              [(ngModel)]="userCredentials.email"
              [ngClass]="invalidCss('email', loginForm)"
              [disabled]="emailSubmitted"
              required
              placeholder="Email"
            />
            <div class="invalid-feedback">Email address is required.</div>
          </div>
          <div *ngIf="emailSubmitted" class="form-group">
            <label for="password">Password</label>
            <input
              id="password"
              name="password"
              class="form-control"
              type="password"
              [(ngModel)]="userCredentials.password"
              [ngClass]="invalidCss('password', loginForm)"
              required
              placeholder="Password"
              autocomplete="password"
            />
            <div class="invalid-feedback">Password is required.</div>
          </div>
          <button [disabled]="submitting" type="submit" class="btn btn-block">Sign in</button>
          <p *ngIf="loginStatus === loginStatuses.InvalidCredentials" class="text-danger my-2">
            Invalid email or password. Please try again.
          </p>
          <p *ngIf="loginStatus === loginStatuses.UnexpectedFailure" class="text-danger my-2">
            An unexpected error has occurred. Please try again or contact support.
          </p>
          <p *ngIf="loginStatus === loginStatuses.DeviceNotAuthorized" class="text-danger my-2">
            Failed to authorize device. Please try again.
          </p>
        </form>
        <p class="mt-3 text-center">
          Trouble signing in? <br /><a href="/forgot-password">Reset your password</a> or Call us at
          <a href="tel:1-781-214-3424">1-781-214-3424</a>.
        </p>
      </div>
    </div>
  </ng-container>
</ng-container>
