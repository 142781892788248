<form novalidate class="p-2 border rounded bg-light" (ngSubmit)="onFormSubmit()">
  <div class="form-group">
    <label><strong>Search By Claim ID</strong></label>
    <input
      id="claimIdentifier"
      name="claimIdentifier"
      class="form-control"
      [(ngModel)]="claimIdentifier"
      (ngModelChange)="onClaimIdChange()"
    />
  </div>
  <div class="text-right actions">
    <button type="submit" class="btn btn-primary" [disabled]="!canSearch">Search</button>
    <a routerLink="/capture-admin/communications/inbound-calls" class="btn btn-secondary">Cancel</a>
  </div>
</form>
