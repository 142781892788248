<ng-container *ngIf="!editing">
  {{ keyedStatusOptions[provider.status].display }}
  <span>
    <a href="javascript:;" (click)="onEdit($event)">
      <fa-icon [icon]="['fas', 'pen']" />
    </a>
  </span>
</ng-container>
<ng-container *ngIf="editing">
  <form [formGroup]="formGroup" (submit)="onSubmit()" #form="ngForm">
    <div class="row mb-2">
      <div class="col">
        <label class="text-nowrap">Provider Status</label>
      </div>
      <div class="col text-right">
        <button class="btn btn-sm btn-secondary" (click)="onCancelEdit($event)">
          <fa-icon [icon]="['fas', 'times']" />
        </button>
        <button class="btn btn-sm btn-primary" [disabled]="saving">
          <fa-icon [icon]="['fas', 'check']" />
        </button>
      </div>
    </div>
    <div>
      <ng-select
        bindValue="value"
        bindLabel="display"
        formControlName="status"
        [items]="providerStatusOptions"
        [clearable]="false"
        appFormControlValidityClass />
    </div>
  </form>
</ng-container>