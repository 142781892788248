<div class="card mt-1 bg-light">
  <div class="card-body">
    <h4>Create Client Chore</h4>
    <div class="alert alert-danger" *ngIf="hasError">
      There was a problem uploading client chore list. All fields are required. Please try again.
      <ul class="mt-2">
        <li *ngFor="let error of errors">{{ error }}</li>
      </ul>
    </div>
    <section class="row mt-1">
      <div class="form-group col-12">
        <label for="selectedChore">Select A Chore</label>
        <select class="form-control" [(ngModel)]="selectedChore" name="selectedChore">
          <option [ngValue]="null"> -- Select Chore -- </option>
          <option *ngFor="let availableChore of availableChores" [ngValue]="availableChore">
            {{ availableChore.displayName }}
          </option>
        </select>
        <div *ngIf="selectedChore && selectedChore.description" class="mt-3 alert alert-info">
          <strong>NOTE:</strong> {{ selectedChore.description }}
        </div>
      </div>

      <div class="form-group col-12"[hidden]="hideClient()">
        <label for="selctedClient">Select A Client</label>
        <select class="form-control" [(ngModel)]="selectedClientId" name="selectedClient">
          <option [ngValue]="null"> -- Select Client -- </option>
          <option *ngFor="let client of clients" [ngValue]="client.id">{{ client.humanizedName }}</option>
        </select>
      </div>

      <div class="form-group col-12" [hidden]="!showFileUpload()">
        <app-file-upload
          [hidden]="uploadingFile"
          (uploadStart)="onUploadStart()"
          (uploadComplete)="onUploadComplete($event)"
          (uploadError)="onUploadError($event)"
          [directUploadPath]="directUploadPath"
          [fileType]="'.csv'" />
        <div class="py-2" [hidden]="!uploadingFile">
          <fa-icon [icon]="['fas', 'circle-notch']" size="sm" [spin]="true" />
          Uploading file...
        </div>
      </div>
      <div *ngIf="showFormFields()">
        <div class="form-group col-12" *ngFor="let field of selectedChore.inputs.form.fields">
          <label><strong>{{ field.label }}</strong></label><br/>

          <em>{{ field.description }}</em>

          <ng-select
            *ngIf="field.type === 'select'"
            class="w-100"
            bindValue="id"
            bindLabel="label"
            [name]="field.name"
            [placeholder]="field.placeholder"
            [multiple]="field.multiple"
            [items]="field.options"
            [(ngModel)]="params[field.name]" />

          <app-capture-status-and-reason-select
            *ngIf="field.type === 'capture_status_reasons_select'"
            [name]="field.name"
            [placeholder]="field.placeholder"
            [multiple]="field.multiple"
            [items]="field.options"
            [(model)]="params[field.name]" />

        </div>
      </div>
      <div class="m-3" *ngIf="showFileUpload()">
        <strong>File Requirements</strong>
        <ol class="mx-3 pl-2">
          <li class="py-2">
            Columns
            <ul class="pl-2" *ngFor="let column of selectedChore.inputs.file.columns">
              <li>
                <code>{{column.name}}</code>
                <small>
                  <span>{{formattedDescription(column)}}</span>
                </small>
              </li>
            </ul>
          </li>
          <li class="py-2">
            Example file
            <div>
              <small><strong>NOTE:</strong> Extra columns will be ignored and column headers are case insensitive and underscores are optional.</small>
            </div>
            <div class="bg-light p-2 border w-100">
              <small>
                <strong>
                  <code class="csv-header">
                    <span *ngFor="let column of selectedChore.inputs.file.columns"> {{column.name}}</span>
                  </code>
                </strong>
                <br />
                <code><span *ngFor="let column of selectedChore.inputs.file.columns"> {{column.example}}</span></code><br />
              </small>
            </div>
          </li>
          <li class="py-2">
            <code>.{{selectedChore.inputs.file.type}}</code>
            <small *ngIf="selectedChore.inputs.file.encoding">
              <span>(endcoding: {{selectedChore.inputs.file.encoding}})</span>
            </small>
          </li>
        </ol>
      </div>

      <div class="form-group col-12" *ngIf="showCreateButton()">
        <button type="submit"
                class="btn btn-primary"
                (click)="onSubmit()"
                [disabled]="requiredFieldMissing()">Start</button>
        <button type="button" class="btn btn-secondary" [disabled]="requiredFieldMissing()" (click)="onCancelClick()">Cancel</button>
      </div>
    </section>
  </div>
</div>
