<div class="card mt-1">
  <div class="card-body">
    <div class="d-flex align-middle justify-content-between mb-2">
      <div><h4>Validate Referral</h4></div>
      <div>
        <div class="text-right">
          <div><strong>RX WRITTEN DATE:</strong> {{ capture.candidate.writtenDate | date: 'MM/dd/yyyy' }}</div>
          <div *ngIf="canEditRmvs"><button class="btn btn-secondary" (click)="onEditRmvsClick($event)">Edit RVMs</button></div>
        </div>
      </div>
    </div>
    <app-referral-match-form
      [capture]="capture"
      [patientPrescriber]="patientPrescriber"
      [patientPrescriptions]="patientPrescriptions" />
    <app-legacy-referral-match-form
      *ngIf="displayLegacyInfo"
      [legacyReferralMatchVerification]="patientPrescriber.legacyReferralMatchVerification"
    />
  </div>
</div>
