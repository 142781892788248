<div class="row mb-2" [formGroupName]="formArrayIndex">

  <div class="col-4">
    <ng-select
      #select
      appFormControlValidityClass
      bindValue="id"
      placeholder="Search for Provider by NPI"
      formControlName="providerId"
      [items]="providers$ | async"
      [typeahead]="providersSearchInput$"
      [loading]="providersLoading">

      <ng-template ng-label-tmp let-provider="item" let-clear="clear">
        <span class="ng-value-icon left" (click)="clear(provider);" aria-hidden="true">×</span>
        <span class="ng-value-label">
          {{ provider.npi }} |
          {{ provider.first }} {{ provider.last }}
        </span>
      </ng-template>

      <ng-template ng-option-tmp let-provider="item">
        {{ provider.npi }} |
        {{ provider.first }} {{ provider.last }} |
        {{ provider.displaySpecialty | blankValueDash }}
      </ng-template>

      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div *ngIf="searchTerm.length < providersSearchMinNpiLength">
          <div class="ng-option ng-option-disabled">
            Enter at least 3 digits
          </div>
        </div>
        <div *ngIf="searchTerm.length >= providersSearchMinNpiLength">
          <div class="ng-option">
            No results found -
            <a
              href="/capture-admin/providers/all?new={{ searchTerm }}"
              target="_blank"
              (click)="select.close()">
              Add a New Provider
            </a>
          </div>
        </div>
      </ng-template>
    </ng-select>

    <app-form-control-server-errors
      controlName="providerId" />
  </div>

  <div class="col-3">
    <ng-select
      appFormControlValidityClass
      bindValue="value"
      bindLabel="display"
      placeholder="Set Provider Fax Status"
      [items]="providerOfficeStatusOptions"
      formControlName="status" />

    <app-form-control-server-errors
      controlName="status" />
  </div>

  <div class="col-5">
    <input
      appFormControlValidityClass
      class="form-control"
      type="text"
      formControlName="note"
      placeholder="Enter Note">

    <app-form-control-server-errors
      controlName="note" />
  </div>

</div>
<div class="row mb-2" *ngIf="selectedProviderAlreadyAssociated">
  <div class="col-auto alert alert-warning ml-3 text-left p-2">
    <fa-icon [icon]="['fas', 'exclamation-triangle']" class="warning"/> This provider office association already exists.
  </div>
</div>
