import { Referral } from './referral.model';
import { ClientReferralVerification } from './client-referral-verification.model';
import { EhrReferralVerification } from './ehr-referral-verification.model';

export class ReferralMatchVerification {
  id: any;
  patientPrescriberId: number;
  date: string;
  provider: boolean;
  specialty: boolean;
  office: boolean;
  client: boolean;
  peerClientReferralVerification: boolean;
  ehr: boolean;
  frozen: boolean;
  source: string;
  createdAt: string;
  clientReferralDate: string;
  referral: Referral;
  clientReferralVerification?: ClientReferralVerification;
  clientReferralVerificationId?: number
  ehrReferralVerificationId?: number
  ehrReferralVerification?: EhrReferralVerification;
  hasClientReferralTaskSubmission: boolean;
}
