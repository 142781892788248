<div class="row mx-0">
  <div class="col-7 px-0 text-left">
    <h3>My Client Chores</h3>
  </div>
</div>

<form novalidate>
  <div class="container-fluid p-2 border rounded bg-light mb-2">
    <div class="row">

      <div class="col-11">
        <app-client-chore-filters
          [filters]="filters"
          [clients]="filterableClients"
          [statuses]="filterableStatuses"
          [showSubmittedByFilter]="showSubmittedByFilter"
          (filterChange)="onFilterChange()" />
      </div>

    </div>
  </div>
</form>

<div *ngIf="!loading; else: spinner">
  <section>
    <div class="row">
      <div class="col-12">
        <app-pagination-header
          [label]="'client chores'"
          [paging]="paging"
          (pageSizeChange)="onPageSizeChange($event)"
          [includeResetFiltersButton]="true"
          (resetFiltersClick)="onClearClick()" />

        <app-client-chore-list
          [clientChores]="clientChores"
          [canManageChores]="false" />

        <app-pagination [paging]="paging" (pageChange)="onPageChange($event)" />
      </div>
    </div>
  </section>
</div>
<ng-template #spinner> <mat-spinner /> </ng-template>
