<form *ngIf="patientMatchVerification">
  <app-verification-validation [key]="validationKey">
    <div>Does patient in consult note match patient on claim and CE encounter?</div>
    <div class="form-inline">
      <div class="form-check form-check-line">
        <input
          id="patientMatchVerificationMatchesYes"
          name="patientMatchVerificationMatchesYes"
          class="form-check-input form-control"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onPatientMatchesChange()"
          [(ngModel)]="patientMatchVerification.matches"
          [value]="true"
        />
        <label class="form-check-label" for="patientMatchVerificationMatchesYes">Yes</label>
      </div>
      <div class="form-check form-check-line mx-2">
        <input
          id="patientMatchVerificationMatchesNo"
          name="patientMatchVerificationMatchesNo"
          class="form-check-input form-control"
          type="radio"
          [disabled]="verificationFrozen"
          (change)="onPatientMatchesChange()"
          [(ngModel)]="patientMatchVerification.matches"
          [value]="false"
        />
        <label class="form-check-label" for="patientMatchVerificationMatchesNo">No</label>
      </div>
    </div>
  </app-verification-validation>
</form>
<form class="form">
  <div class="form-group mt-2">
    <label for="notes">ADDITIONAL DETAIL</label>
    <fa-icon *ngIf="!showAdditionalDetails" [icon]="['fas', 'plus-square']" size="sm" (click)="onPlusClick()" />
    <fa-icon
      *ngIf="showAdditionalDetails"
      [icon]="['fas', 'minus-square']"
      size="sm"
      (click)="onMinusClick()"
    />
    <textarea
      id="notes"
      name="notes"
      class="form-control"
      *ngIf="showAdditionalDetails"
      [disabled]="verificationFrozen"
      (change)="onAdditionalTextChange()"
      [(ngModel)]="patientMatchVerification.notes"
      rows="5"
    >
    </textarea>
  </div>
</form>
