<form>
  <div class="form-inline ml-1">
    <div
      class="form-check form-check-line w-100 border rounded"
      [ngClass]="{ associated: isAssociated, error: errorMessage }"
    >
      <input
        id="patientAttachmentVerified"
        name="patientAttachmentVerified"
        class="form-check-input"
        type="checkbox"
        [disabled]="disabled"
        (change)="onPatientAttachmentAssociatedChanged($event)"
        [(ngModel)]="isAssociated"
      />
      <label class="form-check-label" for="patientAttachmentVerified">
        Associate this consult note with this patient and prescriber.
      </label>
      <span *ngIf="errorMessage" class="error-text pl-2"> ({{ errorMessage }})</span>
    </div>
  </div>
</form>
