<section>
  <app-validate-referral
    [capture]="capture"
    [patientPrescriber]="patientPrescriber"
    [patientPrescriptions]="patientPrescriptions" />
  <div class="actions mt-2 d-flex">
    <div><button class="btn btn-primary" [disabled]="!canTransition" (click)="onSaveClick()">Save</button></div>
    <div class="ml-3">
      <button class="btn btn-secondary" [disabled]="!canTransition" (click)="onSendToCeReviewClick()">
        Send To CE Review
      </button>
    </div>
    <div class="ml-3">
      <button class="btn btn-danger" [disabled]="!canTransition" (click)="onRejectClick()">Reject</button>
      <div>
        <small class="form-text"><strong>REASON: </strong>Prescriber does not receive referrals</small>
      </div>
      <div class="alert m-0 mt-1 p-0 pl-1 alert-warning">
        <small>
          Before selecting reject, be sure to thoroughly research and confirm that <br />
          the provider is <strong>Family Medicine</strong> or <strong>Primary Care</strong> and will not receive
          referrals.
        </small>
      </div>
    </div>
  </div>
</section>
<section>
  <app-patient-prescriptions [capture]="capture" [filterByPrescriber]="true" />
</section>
