import { Component, OnInit, Input } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { some, uniq, isEmpty } from 'lodash-es';
import { Client } from '../../../core/models/client.model';
import { Upload } from '../../../core/models/upload.model';
import { usStates } from '../../../core/options/states.opts';
import { ClientsService } from '../../../core/services/clients.service';
import { UploadService } from '../../../core/services/upload.service';
import { NavigationService } from '../../../core/services/navigation.service';
import { UsersService } from '../../../core/services/users.service';
import { UserType } from '../../../core/enums/user-type.enum';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { UserRoleName } from '../../../core/enums/user-role-name.enum';
import { ThirdPartyAdministersService } from 'app/core/services/third-party-administers.service';
import { DateUtils } from 'app/core/lib/date-utils';
import { isNotBlank } from 'app/core/lib/js-utils';

export enum ClientFormMode {
  create,
  update,
}

const ehrIntanceValueBlankTag = 'blank';
const ehrIntanceValueBlankPlaceholder = '(blank)';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnInit {
  @Input() client: Client = null;
  @Input() mode: ClientFormMode = null;

  clientForm = null;
  saving = false;
  states = usStates;
  logoFile: File = null;
  logoFileErrors: string[] = [];
  espNdcsFile: File = null;
  espNdcsFileErrors: string[] = [];
  deleteLogo = false;
  deleteEspNdcsFile = false;
  users = [];
  thirdPartyAdministers = [];
  clients: Client[] = [];
  entityTypes = [];
  savedHrsaIdStrings = [];
  allowedClientFileEhrInstanceValuesForDisplay = [];
  disableClaimIndentifierPrefixInput: boolean;

  constructor(
    private clientsService: ClientsService,
    private uploadService: UploadService,
    private navigationService: NavigationService,
    private usersService: UsersService,
    private authService: AuthenticationService,
    private thirdPartyAdministersService: ThirdPartyAdministersService
  ) {}

  ngOnInit() {
    this.translateAllowedClientFileEhrInstanceValuesForDisplay();
    this.loadUsers();
    this.loadThirdPartyAdministers();
    this.loadClients();
    this.loadClientEntityTypes();
    this.disableClaimIndentifierPrefixInput = isNotBlank(this.client.claimIdentifierPrefix);
    this.savedHrsaIdStrings = this.client.hrsaIdStrings || [];
  }

  onFormSubmit(form: NgForm) {
    const clientForm = form.form;

    if (clientForm.valid) {
      this.saving = true;

      this.logoFileErrors = [];
      this.espNdcsFileErrors = [];

      const saveFn = this.saveClientAndUploadFiles(clientForm);
      saveFn.subscribe(
        () => this.navigateAwayAfterComplete(),
        response => this.handleSaveError(response, clientForm)
      );
    }
  }

  private saveClientAndUploadFiles(clientForm: UntypedFormGroup): Observable<Client> {
    return this.saveClient(clientForm).pipe(
      mergeMap(() => this.handleLogoFile()),
      mergeMap(() => this.handleEspNdcsFile())
    );
  }

  private handleLogoFile(): Observable<any> {
    if (this.logoFile) {
      return this.uploadLogo().pipe(
        mergeMap(
          upload => this.associateLogoFileWithClient(upload)
        ),
        catchError((error) => {
          this.saving = false;
          this.logoFileErrors = error.error.errors;
          return EMPTY;
        })
      );
    } else {
      return of(this.client);
    }
  }

  private handleEspNdcsFile(): Observable<any> {
    if (this.espNdcsFile) {
      return this.uploadEspNdcsFile().pipe(
        mergeMap(
          upload => this.associateEspNdcsFileWithClient(upload)
        ),
        catchError((error) => {
          this.saving = false;
          this.espNdcsFileErrors = error.error.errors;
          return EMPTY;
        })
      );
    } else if (this.deleteEspNdcsFile) {
      return this.clientsService.deleteEspNdcsFile(this.client.id);
    } else {
      return of(this.client);
    }
  }

  private updateClient(client: Client) {
    this.client = client;
    return client;
  }

  private uploadLogo(): Observable<Upload> {
    return this.uploadService.upload(this.logoFile);
  }

  private uploadEspNdcsFile(): Observable<Upload> {
    return this.uploadService.upload(this.espNdcsFile);
  }

  private associateLogoFileWithClient(upload: Upload): Observable<Client> {
    return this.clientsService.uploadLogo(this.client.id, upload.signedId);
  }

  private associateEspNdcsFileWithClient(upload: Upload): Observable<Client> {
    return this.clientsService.uploadEspNdcsFile(this.client.id, upload.signedId);
  }

  private handleSaveError(response: HttpErrorResponse, clientForm: UntypedFormGroup): void {
    this.saving = false;

    if (response.status === 400) {
      const { error: data } = response;

      Object.keys(data.errors).forEach(fieldName => {
        clientForm.get(fieldName).setErrors({ server: data.errors[fieldName] });
      });
    } else {
      console.error(response);
    }
  }

  private saveClient(clientForm: UntypedFormGroup): Observable<Client> {
    const logoAttributes = this.deleteLogo ? { logo: { _destroy: true } } : {};
    const attributes = Object.assign(clientForm.value, logoAttributes);

    attributes.allowedClientFileEhrInstanceValues = this.translateAllowedClientFileEhrInstanceValuesForSave();

    if (attributes.assignedUsers) {
      // We only want to send down an array of username and not user objects
      attributes.assignedUsers = attributes.assignedUsers.map(u => u.username);
    }

    const serviceFn: Observable<Client> =
      this.mode === ClientFormMode.create
        ? this.clientsService.create(attributes)
        : this.clientsService.update(this.client.id, attributes);

    return serviceFn.pipe(map(client => this.updateClient(client)));
  }

  private navigateAwayAfterComplete() {
    if (this.client.id) {
      this.navigationService.navigateTo(`/capture-admin/client-management/clients/${this.client.id}`);
    } else {
      this.navigationService.navigateTo(`/capture-admin/client-management/clients`);
    }
  }

  private isControlInvalid(form: NgForm, controlName: string) {
    const control = form.form.get(controlName);

    if (control) {
      return control.invalid && form.submitted;
    }
  }

  onClaimTypeClick($event, clientForm) {
    clientForm.form.get('applicableToReferralClaims').setErrors(null);
    clientForm.form.get('applicableToIccClaims').setErrors(null);
  }

  invalidCss(form: NgForm, controlName: string) {
    return {
      'is-invalid': this.isControlInvalid(form, controlName),
    };
  }

  logoFileName() {
    return (this.logoFile && this.logoFile.name) || 'Choose file...';
  }

  espNdcsFileName() {
    return (this.espNdcsFile && this.espNdcsFile.name) || 'Choose file...';
  }

  onLogoFileChange(event): void {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      this.logoFile = fileInput.files[0];
    }
  }

  onEspNdcsFileChange(event): void {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      this.espNdcsFile = fileInput.files[0];
    }
  }

  addHrsaId(id: string) {
    return id;
  }

  onHrsaSelectBlur(event, select) {
    if (select.showAddTag) {
      select.selectTag();
    }
  }

  addEhrInstance(value: string) {
    return value;
  }

  onEhrInstanceSelectBlur(event, select) {
    if (select.showAddTag) {
      select.selectTag();
    }
  }

  addClientFileMonitoringEmailRecipient(id: string) {
    return id;
  }

  onClientFileMonitoringEmailRecipientsSelectBlur(event, select) {
    if (select.showAddTag) {
      select.selectTag();
    }
  }

  onDeleteLogo($event) {
    $event.preventDefault();

    delete this.client.logoUrl;
    this.deleteLogo = true;
  }

  onDeleteEspNdcsFile($event) {
    $event.preventDefault();
    this.deleteEspNdcsFile = true;
  }

  onUndoDeleteEspNdcsFile($event) {
    $event.preventDefault();
    this.deleteEspNdcsFile = false;
  }

  onCancelClick() {
    this.navigateAwayAfterComplete();
  }

  onDateDealClosedBlur(form: NgForm) {
    const dateDealClosed = form.form.get('dateDealClosed');

    if (!this.isValidDate(this.client.dateDealClosed)) {
      dateDealClosed.setValue(null);
    }
  }

  onGoLiveDateBlur(form: NgForm) {
    const goLiveDate = form.form.get('goLiveDate');

    if (!this.isValidDate(this.client.goLiveDate)) {
      goLiveDate.setValue(null);
    }
  }

  onEspSubmitStartDateBlur(form: NgForm) {
    const espSubmitStartDate = form.form.get('espSubmitStartDate');

    if (!this.isValidDate(this.client.espSubmitStartDate)) {
      espSubmitStartDate.setValue(null);
    }
  }

  onAllowedClientFileEhrInstanceValuesChange(values) {
    this.allowedClientFileEhrInstanceValuesForDisplay = uniq(
      values.map(this.translateAllowedClientFileEhrInstanceValueForDisplay)
    );
  }

  private translateAllowedClientFileEhrInstanceValueForDisplay(value) {
    if (
      !value ||
      (typeof value === 'string' &&
        (value.toLowerCase() === ehrIntanceValueBlankTag || value === ehrIntanceValueBlankPlaceholder))
    ) {
      return ehrIntanceValueBlankPlaceholder;
    } else {
      return value.toLowerCase();
    }
  }

  private translateAllowedClientFileEhrInstanceValuesForDisplay() {
    this.allowedClientFileEhrInstanceValuesForDisplay = uniq(
      this.client.effectiveAllowedClientFileEhrInstanceValues.map(
        this.translateAllowedClientFileEhrInstanceValueForDisplay
      )
    );
  }

  private translateAllowedClientFileEhrInstanceValueForSave(value) {
    if (value === ehrIntanceValueBlankPlaceholder) {
      return '';
    } else {
      return value;
    }
  }

  private translateAllowedClientFileEhrInstanceValuesForSave() {
    return this.allowedClientFileEhrInstanceValuesForDisplay.map(
      this.translateAllowedClientFileEhrInstanceValueForSave
    );
  }

  private loadUsers() {
    if (this.canManageUsers) {
      this.usersService.getByUserType([UserType.captureAdmin]).subscribe(result => {
        this.users = result.users;
      });
    }
  }

  private loadThirdPartyAdministers() {
    this.thirdPartyAdministersService.getList().subscribe(result => {
      this.thirdPartyAdministers = result.thirdPartyAdministers;
    });
  }

  private loadClients() {
    this.clientsService.getList(null, { applicableToReferralClaims: true }).subscribe(result => {
      this.clients = result.clients;
    });
  }

  private loadClientEntityTypes() {
    this.clientsService.getEntityTypes().subscribe(result => {
      this.entityTypes = result;
    });
  }

  get canManageImplementation() {
    return this.authService.hasPermissionTo(UserRoleName.manageImplementation);
  }

  get canManageUsers() {
    return this.authService.isCaptureAdminUser && this.authService.hasPermissionTo(UserRoleName.manageUsers);
  }

  get duplicateThirdPartyAdministers() {
    if (this.client.liveThirdPartyAdministers && this.client.contractedThirdPartyAdministers) {
      const liveThirdPartyAdministerIds = this.client.liveThirdPartyAdministers.map(el => el.id);
      const contractedThirdPartyAdministerIds = this.client.contractedThirdPartyAdministers.map(el => el.id);
      return some(liveThirdPartyAdministerIds, el => contractedThirdPartyAdministerIds.includes(el));
    }

    return false;
  }

  private isValidDate(date: string) {
    return DateUtils.isValid(date);
  }

  get hasLogoFileErrors() {
    return !isEmpty(this.logoFileErrors);
  }

  get hasEspNdcsFileErrors() {
    return !isEmpty(this.espNdcsFileErrors);
  }
}
